import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-expiration-modal",
  templateUrl: "./expiration-modal.component.html",
  styleUrls: ["./expiration-modal.component.scss"],
})
export class ExpirationModalComponent implements OnInit {
  @Output() closeExpired = new EventEmitter<boolean>();
  constructor() {}

  ngOnInit(): void {}

  onClose() {
    this.closeExpired.emit(false);
  }
}
