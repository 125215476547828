<app-header></app-header>
<div id="press">
  <h1 class="press-container-title">Press</h1>
  <div class="press-card shadow text-left mb-5" *ngFor="let item of pressArticles">
    <div class="col-md-3 col-12">
      <img class="w-100 pb-3 pb-md-0" [src]="item.image.public_url || 'assets/img/default-article-img.png'" />
    </div>
    <div class="col-md-9 col-12">
      <p class="press-date">{{ item.publication_at | date: "mediumDate" }}</p>
      <p class="press-title">{{ item.title }}</p>
      <p class="press-description">{{ item.summary }}</p>
      <a class="press-url" target="_blank" [href]="item.url">Read more<img src="assets/svg/more-arrow.svg" /></a>
    </div>
  </div>
</div>
<app-footer></app-footer>
