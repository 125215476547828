import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-progress-circle",
  templateUrl: "./progress-circle.component.html",
  styleUrls: ["./progress-circle.component.scss"],
})
export class ProgressCircleComponent implements OnInit {
  @Input() percent: number;
  @Input() id: number;
  @Input() mainImage: string;
  @Input() icon: string;
  @Input() color: string;
  @Input() title: string;

  constructor() {}

  ngOnInit(): void {}
}
