import { Component, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-open",
  templateUrl: "open.component.html",
  styleUrls: ["open.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class OpenComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
