<app-header></app-header>
<div class="navBarPadding pt-sm-5">
  <section id="mainSection" [ngClass]="{ 'pt-4': showBanner }">
    <div class="container" id="choose">
      <h1 class="title-13 title mb-5">
        Choose the membership <br class="show-mobile" />
        plan<br class="hide-mobile" />
        that is right for you.
      </h1>
    </div>
    <div class="container text-center" id="plans">
      <div class="row d-lg-flex align-items-lg-center">
        <div class="col-lg-4">
          <div
            class="container-fluid d-flex flex-column flex-lg-row align-items-center align-items-lg-start justify-content-lg-between text basic"
          >
            <div class="card others mt-5 mt-lg-0">
              <div class="card-body">
                <div class="overlay">
                  <!-- <img class="card-ribbon" src="../../../assets/img/membership/basic.svg" alt=""> -->
                </div>
                <div class="card-text text-center">
                  <p class="ctitle">
                    Basic<br />
                    Game Plan
                  </p>
                  <p class="csubtitle p-0 m-0">
                    1<sup>st</sup> month free<br />
                    6 months
                  </p>
                  <p class="camount d-flex d-column align-items-end justify-content-center m-0 p-0">
                    <b>$</b>6<b>50</b>
                  </p>
                  <p class="csummary">per month</p>
                  <p class="cpayment d-flex d-column align-items-end justify-content-center m-0 p-0">
                    $39<b>00</b>&nbsp;&nbsp;&nbsp;One Payment
                  </p>
                </div>
                <button
                  class="btn btn-primary rounded-pill rounded-pill btn-lg font-weight-bold px-5 py-2"
                  (click)="common.goToSignup()"
                >
                  Invest in Your Career
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 button-select-plan">
          <div
            class="container-fluid d-flex flex-column flex-lg-row align-items-center align-items-lg-start justify-content-lg-between text"
          >
            <div class="card best mt-5 mt-lg-0 shadow-lg">
              <div class="card-body">
                <img src="../../../../assets/svg/Select-icon.svg" class="selected" />
                <div class="card-text text-center">
                  <p class="ctitle px-2 mt-4">
                    Advanced<br />
                    Game Plan
                  </p>
                  <p class="csubtitle p-0 m-0">
                    1<sup>st</sup> month free<br />
                    12 months
                  </p>
                  <p class="camount extra-padding d-flex d-column align-items-end justify-content-center">
                    <b>$</b>5<b>00</b>
                  </p>
                  <p class="csummary">per month</p>
                  <p class="cpayment d-flex d-column align-items-end justify-content-center m-0 p-0">
                    $60<b>00</b>&nbsp;&nbsp;&nbsp;One Payment
                  </p>
                </div>
                <button
                  class="btn btn-primary rounded-pill btn-lg font-weight-bold px-5 py-2"
                  (click)="common.goToSignup()"
                >
                  Invest in Your Career
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div
            class="container-fluid d-flex flex-column flex-lg-row align-items-center align-items-lg-start justify-content-lg-between text"
          >
            <div class="card others mt-5 mt-lg-0">
              <div class="card-body">
                <div class="card-text text-center">
                  <p class="ctitle px-2">
                    Buy One, Gift One<br />
                    Basic Game Plan
                  </p>
                  <p class="csubtitle p-0 m-0">
                    1<sup>st</sup> month free<br />
                    6 months
                  </p>
                  <p class="camount d-flex d-column align-items-end justify-content-center m-0 p-0">
                    <b>$</b>11<b>00</b>
                  </p>
                  <p class="csummary">
                    per month<br />
                    for you and a friend
                  </p>
                  <p class="cpayment d-flex d-column align-items-end justify-content-center m-0 p-0">
                    $66<b>00</b>&nbsp;&nbsp;&nbsp;One Payment
                  </p>
                </div>
                <button
                  class="btn btn-primary rounded-pill btn-lg font-weight-bold px-5 py-2"
                  (click)="common.goToSignup()"
                >
                  Invest in Your Career
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="comparison">
    <div class="plan-row">
      <h2 class="title">Membership Plan Comparison</h2>
      <div class="row">
        <div class="col-lg-5 d-none d-lg-block"></div>
        <div class="col-lg-2 col-4">
          <div class="container-fluid text-center border-0">
            <p class="ctitle">Basic<br /></p>
          </div>
        </div>
        <div class="col-lg-2 col-4">
          <div class="container-fluid text-center">
            <div class="overlay">
              <div class="card-ribbon">
                <!-- <img class="card-ribbon" src="../../../assets/img/membership/most-popular.svg" alt=""> -->
              </div>
            </div>
            <p class="ctitle">Advanced</p>
          </div>
        </div>
        <div class="col-lg-3 col-4">
          <div class="container-fluid text-center">
            <div class="overlay">
              <div class="card-ribbon">
                <!-- <img class="card-ribbon" src="../../../assets/img/membership/best-value.svg" alt=""> -->
              </div>
            </div>
            <p class="ctitle">Buy One, Gift One</p>
          </div>
        </div>
      </div>
    </div>
    <div class="hide-mobile">
      <div class="container text-center">
        <h2 class="title">Membership Plan Comparison</h2>
      </div>
    </div>
    <div class="container plan-container">
      <div class="row hide-mobile">
        <div class="col-lg-5 d-none d-lg-block"></div>
        <div class="col-lg-2 col-4">
          <div class="container-fluid text-center border-0">
            <div class="overlay">
              <div class="card-ribbon">
                <!-- <img class="card-ribbon" src="../../../assets/img/membership/basic.svg" alt=""> -->
              </div>
            </div>
            <p class="ctitle">Basic<br /><span class="hide-mobile">Game Plan</span></p>
            <p class="camount d-flex d-column align-items-end justify-content-center m-0 p-0 hide-mobile">
              <b>$</b>6<b>50</b>
            </p>
            <p class="csummary hide-mobile"><br />per month</p>
          </div>
        </div>
        <div class="col-lg-2 col-4">
          <div class="container-fluid text-center">
            <div class="overlay">
              <div class="card-ribbon">
                <!-- <img class="card-ribbon" src="../../../assets/img/membership/most-popular.svg" alt=""> -->
              </div>
            </div>
            <p class="ctitle">Advanced<br /><span class="hide-mobile">Game Plan</span></p>
            <p class="camount d-flex d-column align-items-end justify-content-center m-0 p-0 hide-mobile">
              <b>$</b>5<b>00</b>
            </p>
            <p class="csummary hide-mobile"><br />per month</p>
          </div>
        </div>
        <div class="col-lg-3 col-4">
          <div class="container-fluid text-center">
            <div class="overlay">
              <div class="card-ribbon">
                <!-- <img class="card-ribbon" src="../../../assets/img/membership/best-value.svg" alt=""> -->
              </div>
            </div>
            <p class="ctitle">
              Buy One, Gift <br class="show-mobile" />One<br class="hide-mobile" />
              Basic <span class="hide-mobile">Game Plan</span>
            </p>
            <p class="camount d-flex d-column align-items-end justify-content-center m-0 p-0 hide-mobile">
              <b>$</b>11<b>00</b>
            </p>
            <p class="csummary hide-mobile">
              <br />per month<br />
              for you and a friend
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col px-0"><hr /></div>
      </div>
      <div
        class="row py-1"
        *ngFor="let member of membershipComparison; let i = index"
        [ngClass]="i % 2 !== 0 ? 'row-fill' : ''"
      >
        <div class="col-lg-5 text-center text-lg-left mt-2 mt-lg-0">
          {{ member.description }}
        </div>
        <div class="col-lg-2 col-4 text-center">
          <img src="../../../assets/svg/check_icon.svg" style="width: 10px" *ngIf="member.basic" />
          <img
            src="../../../assets/img/membership/false_icon.png"
            class="show-mobile"
            style="width: 22px"
            *ngIf="!member.basic"
          />
        </div>
        <div class="col-lg-2 col-4 text-center anchor-column">
          <img src="../../../assets/svg/check_icon.svg" style="width: 10px" *ngIf="member.advanced" />
          <img
            src="../../../assets/img/membership/false_icon.png"
            class="show-mobile"
            style="width: 22px"
            *ngIf="!member.advanced"
          />
        </div>
        <div class="col-lg-3 col-4 text-center">
          <img src="../../../assets/svg/check_icon.svg" style="width: 10px" *ngIf="member.buyOne" />
          <img
            src="../../../assets/img/membership/false_icon.png"
            class="show-mobile"
            style="width: 22px"
            *ngIf="!member.buyOne"
          />
        </div>
      </div>
    </div>
  </section>
  <div class="row show-mobile" id="prices-container">
    <div class="col-lg-2 col-4 show-mobile price">
      <div class="container-fluid text-center border-0 price-description">
        <p class="camount d-flex d-column align-items-end justify-content-center m-0 p-0"><b>$</b>6<b>50</b></p>
        <p class="csummary">per month</p>
      </div>
    </div>
    <div class="col-lg-2 col-4 show-mobile">
      <div class="container-fluid text-center">
        <p class="camount d-flex d-column align-items-end justify-content-center m-0 p-0"><b>$</b>5<b>00</b></p>
        <p class="csummary">per month</p>
      </div>
    </div>
    <div class="col-lg-3 col-4 show-mobile">
      <div class="container-fluid text-center">
        <p class="camount d-flex d-column align-items-end justify-content-center m-0 p-0"><b>$</b>11<b>00</b></p>
        <p class="csummary">per month for you and a friend</p>
      </div>
    </div>
  </div>
  <p class="text-center mt-5">
    <button class="btn btn-lg btn-primary rounded-pill" (click)="common.goToSignup()">
      Invest In Your Career Today
    </button>
  </p>
</div>
<app-footer></app-footer>
