<h2 mat-dialog-title class="dialog-title dialog-delete-title">Delete{{ _getType }} Confirmation</h2>
<mat-dialog-content class="mat-typography modal-dialog-content">
  <div class="body-text-wrapper">
    <p class="body-text-emphasis">Are you sure you wish to delete {{ _getName }}?</p>
    <p class="body-text">{{ _getDescription }}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="confirmation-modal-actionrow">
  <button mat-raised-button class="btn-dim confirmation-btn-cancel" [mat-dialog-close]="false">Cancel</button>
  <button mat-raised-button class="btn-dim confirmation-btn-delete" color="primary" [mat-dialog-close]="true">
    <mat-icon class="btn-delete-icon">delete</mat-icon>
    Delete
  </button>
</mat-dialog-actions>
