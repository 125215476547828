<app-header></app-header>
<div class="navBarPadding pt-sm-5">
  <ng-container *ngIf="!messageSent">
    <div [ngClass]="{ 'pt-5': showBanner }">
      <section class="pt-5">
        <h1 class="title-2 text-center">Contact Us</h1>
      </section>
    </div>
    <section class="contact-us-body container">
      <form [formGroup]="contactUsForm" (ngSubmit)="onSubmit()">
        <div class="container rounded-circle input-item">
          <div class="row">
            <div class="list-item summary-13">First Name</div>
            <ul
              class="list-group w-100"
              [ngClass]="{
                'error-box': !contactUsForm.get('firstName').valid && contactUsForm.get('firstName').touched
              }"
            >
              <div class="list-group-item">
                <div class="input-group">
                  <input
                    autofocus
                    #firstName
                    id="firstName"
                    name="firstName"
                    type="text"
                    formControlName="firstName"
                    class="form-control list-group-item"
                    style="border: 0"
                    placeholder="First Name"
                  />
                  <div
                    class="input-group-append text-danger ml-3 help-block error-seccion"
                    *ngIf="!contactUsForm.get('firstName').valid && contactUsForm.get('firstName').touched"
                  >
                    <span class="color-danger">required</span>
                    <img src="assets/svg/error-icon.svg" />
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div class="container rounded-circle input-item">
          <div class="row">
            <div class="list-item summary-13">Email Address</div>
            <ul
              class="list-group w-100"
              [ngClass]="{ 'error-box': !contactUsForm.get('email').valid && contactUsForm.get('email').touched }"
            >
              <div class="list-group-item">
                <div class="input-group">
                  <input
                    type="email"
                    formControlName="email"
                    class="form-control list-group-item"
                    style="border: 0"
                    placeholder="Email Address"
                  />
                  <div
                    class="input-group-append text-danger ml-3 help-block error-seccion"
                    *ngIf="!contactUsForm.get('email').valid && contactUsForm.get('email').touched"
                  >
                    <span *ngIf="contactUsForm.get('email').errors.required" class="color-danger">required</span>
                    <span *ngIf="contactUsForm.get('email').errors.email" class="color-danger"
                      >Please enter a valid email</span
                    >
                    <img src="assets/svg/error-icon.svg" />
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div class="container rounded-circle input-item">
          <div class="row">
            <div class="list-item summary-13">Message</div>
            <ul class="list-group w-100">
              <div class="list-group-item">
                <div class="input-group">
                  <textarea
                    rows="6"
                    formControlName="message"
                    class="form-control list-group-item"
                    style="border: 0"
                    placeholder="Write here"
                  ></textarea>
                  <div
                    class="input-group-append text-danger ml-3 help-block"
                    *ngIf="!contactUsForm.get('message').valid && contactUsForm.get('message').touched"
                  >
                    <span class="color-danger">required</span>
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div class="p-1 text-center padding-submit">
          <button type="submit" class="btn btn-primary rounded-pill px-5" [disabled]="!contactUsForm.valid">
            Submit
          </button>
        </div>
      </form>
    </section>
  </ng-container>
  <div *ngIf="messageSent" class="thx-message">
    <img src="assets/svg/gameplan/Complete_icon.svg" />
    <p class="subtitle-2 subtitle">Thank you for getting in touch!</p>
    <p>Our team will get back to you shortly</p>
    <a (click)="sendANewMessage()" class="color-primary">Send A New Message</a>
  </div>
  <section id="footer">
    <div class="subtitlex">Ways to Reach Us</div>
    <div class="container ways">
      <div class="row">
        <div class="col-lg" *ngFor="let group of ways">
          <div class="container-fluid d-flex mb-5" *ngFor="let way of group.ways">
            <div class="col1 flex-column">
              <img [ngClass]="way.extraClass" [src]="way.svg" />
            </div>
            <div class="col2 flex-column">
              <div class="mb-1 titlex">{{ way.name }}</div>
              <div *ngIf="way.link">
                <a [href]="way.link" [target]="way.target">{{ way.details }}</a>
              </div>
              <div *ngIf="!way.link">{{ way.details }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<app-footer></app-footer>
