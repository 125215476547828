<app-header></app-header>
<div id="the-team">
  <h1 class="title-13 title mb-5 text-center">Introducing The Team</h1>
  <p class="summary-5 team-summary">
    Boulo has a management team with 100+ years of experience combining all the necessary skills – AI Technology
    (machine/deep learning, neural networks and data science), market research, data analysis and modeling, product
    branding and roll out, content marketing and financial and management experience.
  </p>
  <section id="team-members" class="row">
    <div class="team-container" *ngFor="let member of teamData; let i = index">
      <div class="card shadow member-card">
        <img src="{{ member.prevPicture }}" class="member-picture" />
        <div class="member-info">
          <p class="summary-5 name">{{ member.name }}</p>
          <p class="summary-5 position">{{ member.position }}</p>
          <a class="more" href="#" data-toggle="modal" data-target="#suzanne-keith" *ngIf="i == 0"
            >Read More <img src="assets/svg/more-arrow.svg"
          /></a>
          <a class="more" href="#" data-toggle="modal" data-target="#tom-beauchamp" *ngIf="i == 1"
            >Read More <img src="assets/svg/more-arrow.svg"
          /></a>
          <a class="more" href="#" data-toggle="modal" data-target="#sonal-rinello" *ngIf="i == 2"
            >Read More <img src="assets/svg/more-arrow.svg"
          /></a>
          <a class="more" href="#" data-toggle="modal" data-target="#karen-keane" *ngIf="i == 3"
            >Read More <img src="assets/svg/more-arrow.svg"
          /></a>
        </div>
      </div>
    </div>
    <div class="modal fade member-modal" id="suzanne-keith" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content row">
          <button type="button" class="close col-md-12" data-dismiss="modal" aria-label="Close">
            <img src="assets/svg/Close-default_icon.svg" />
          </button>
          <div class="col-md-4 modal-col mt-5 mt-md-0">
            <img src="{{ teamData[0].prevPicture }}" class="member-picture" />
          </div>
          <div class="col-md-8 modal-col">
            <p class="summary-5 name">{{ teamData[0].name }}</p>
            <p class="summary-5 position">{{ teamData[0].position }}</p>
            <p class="summary-5 description">{{ teamData[0].description }}</p>
            <a
              href="https://www.linkedin.com/in/suzanna-sullivan-keith-b72b966/"
              class="btn btn-link link"
              target="_blank"
            >
              <img src="assets/svg/Linkedin.svg" />
              <p>https://www.linkedin.com/in/suzanna-sullivan-keith-b72b966/</p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade member-modal" id="tom-beauchamp" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content row">
          <button type="button" class="close col-md-12" data-dismiss="modal" aria-label="Close">
            <img src="assets/svg/Close-default_icon.svg" />
          </button>
          <div class="col-md-4 modal-col mt-5 mt-md-0">
            <img src="{{ teamData[1].prevPicture }}" class="member-picture" />
          </div>
          <div class="col-md-8 modal-col">
            <p class="summary-5 name">{{ teamData[1].name }}</p>
            <p class="summary-5 position">{{ teamData[1].position }}</p>
            <p class="summary-5 description">{{ teamData[1].description }}</p>
            <a href="https://www.linkedin.com/in/tombeauchamp/" class="btn btn-link link" target="_blank">
              <img src="assets/svg/Linkedin.svg" />
              <p>https://www.linkedin.com/in/tombeauchamp/</p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade member-modal" id="sonal-rinello" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content row">
          <button type="button" class="close col-md-12" data-dismiss="modal" aria-label="Close">
            <img src="assets/svg/Close-default_icon.svg" />
          </button>
          <div class="col-md-4 modal-col mt-5 mt-md-0">
            <img src="{{ teamData[2].prevPicture }}" class="member-picture" />
          </div>
          <div class="col-md-8 modal-col">
            <p class="summary-5 name">{{ teamData[2].name }}</p>
            <p class="summary-5 position">{{ teamData[2].position }}</p>
            <p class="summary-5 description">{{ teamData[2].description }}</p>
            <a href="https://www.linkedin.com/in/srinello/" class="btn btn-link link" target="_blank">
              <img src="assets/svg/Linkedin.svg" />
              <p>https://www.linkedin.com/in/srinello/</p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade member-modal" id="karen-keane" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content row">
          <button type="button" class="close col-md-12" data-dismiss="modal" aria-label="Close">
            <img src="assets/svg/Close-default_icon.svg" />
          </button>
          <div class="col-md-4 modal-col">
            <img src="{{ teamData[3].prevPicture }}" class="member-picture" />
          </div>
          <div class="col-md-8 modal-col mt-5 mt-md-0">
            <p class="summary-5 name">{{ teamData[3].name }}</p>
            <p class="summary-5 position">{{ teamData[3].position }}</p>
            <p class="summary-5 description">{{ teamData[3].description }}</p>
            <a href="https://www.linkedin.com/in/karen-keane-049b4213b/" class="btn btn-link link" target="_blank">
              <img src="assets/svg/Linkedin.svg" />
              <p>https://www.linkedin.com/in/karen-keane-049b4213b/</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<app-footer></app-footer>
