<div class="message-box-wrapper">
  <div class="message-list" #messageList>
    <div class="msg-date" *ngFor="let messageObj of orderedMsgs">
      <div class="date-text-msg">{{ messageObj[0] }}</div>
      <div class="msg-line-item" *ngFor="let messageItem of messageObj[1]; let i = index">
        <div [class]="_isSender(messageItem.message.sender) ? 'msg-sent' : 'msg-received'">
          <div
            class="header-container"
            *ngIf="displayMessageHeader(i, messageObj[1])"
            [ngClass]="_msgHeaderPosition(messageItem)"
          >
            <span class="sender-text">{{ messageItem.message.sender }}:</span>
            <mat-hint class="msg-timestamp">{{ messageItem.time }}</mat-hint>
          </div>

          <div class="" [ngClass]="_msgDesign(messageItem)">
            <span
              *ngIf="isUserMessage(messageItem.message)"
              class="msg-text"
              [innerHTML]="messageItem.message.text | linkify"
            ></span>
            <a *ngIf="isImageAttachment(messageItem.message)" [href]="messageItem.message.url" download>
              <img [src]="messageItem.message.url" alt="Attached Image" />
            </a>
            <a *ngIf="isFileAttachment(messageItem.message)" [href]="messageItem.message.url" download>
              <span [ngClass]="msgFileAttachmentDesign(messageItem)"
                >Download Attachment {{ messageItem.message.text }}</span
              >
            </a>
            <div *ngIf="pollIsClosed(messageItem.message)">
              <ng-container *ngIf="getChosenDate(messageItem.message.poll.options); else cancelled">
                Interview Date - {{ getChosenDate(messageItem.message.poll.options) | date: "MMM d, y, h:mm a" }}
                <app-add-to-calendar
                  [timestamp]="getChosenDate(messageItem.message.poll.options)"
                  [description]="getInterviewDescription(messageItem.message)"
                  [title]="getInterviewTitle(messageItem.message)"
                  [isEmployer]="isEmployer"
                ></app-add-to-calendar>
              </ng-container>
              <ng-template #cancelled>Interview Times Cancelled</ng-template>
            </div>
            <div *ngIf="showNoInterviewTimesSelected(messageItem.message)">
              No interview times have been selected yet.
            </div>
            <div
              *ngIf="showPollMessage(messageItem.message)"
              class="poll-message"
              (click)="openPollDialog(messageItem.message.poll)"
            >
              We'd like to schedule an interview! Please choose a date/time: {{ messageItem.message.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="message-input-wrapper">
    <form [formGroup]="sendMsgGrp" class="send-form">
      <mat-form-field appearance="outline" floatLabel="never" class="msg-field">
        <textarea
          matInput
          cdkTextareaAutosize
          [cdkAutosizeMinRows]="1"
          [cdkAutosizeMaxRows]="5"
          [disabled]="isLoading"
          formControlName="newMsg"
          placeholder="Type your message here..."
          class="send-textarea"
        ></textarea>
        <mat-error *ngIf="sendMsgGrp.get('newMsg').hasError('maxlength')" class="mb-2"
          >Message limited to 2,000 characters.</mat-error
        >
        <mat-error *ngIf="sendMsgGrp.get('newMsg').hasError('minlength')" class="mb-2"
          >Cannot send blank message.</mat-error
        >
        <mat-hint matSuffix>{{ _characterCount }} / {{ newMsgCharMax }}</mat-hint>
      </mat-form-field>

      <div class="user-input-continer">
        <span class="file-indicator">
          <button mat-icon-button (click)="removeFile()">
            <mat-icon *ngIf="selectedFile">close</mat-icon>
          </button>
          {{ selectedFile?.name }}
        </span>
        <div class="msg-btns-container">
          <app-mat-spinner-btn [loading]="isLoading">
            <button
              mat-raised-button
              [disabled]="!_isMsgValid || isLoading"
              color="accent"
              class="btn-dim msg-send-btn"
              (click)="sendMessage()"
            >
              Send
            </button>
          </app-mat-spinner-btn>

          <input
            type="file"
            #fileInput
            style="display: none"
            (change)="onFileSelected($event)"
            accept=".png, .jpg, .jpeg, .gif, .pdf, .doc, .docx, .xls, .xlsx, .txt"
          />

          <button mat-icon-button (click)="fileInput.click()" class="attachment-btn">
            <mat-icon>attach_file</mat-icon>
          </button>

          <button
            *ngIf="isEmployer"
            mat-icon-button
            (click)="openPollModal()"
            class="calendar-btn"
            matTooltip="Schedule an Interview"
            matTooltipPosition="above"
          >
            <mat-icon>calendar_today</mat-icon>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
