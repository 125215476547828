<div id="forgot-password">
  <div class="col-lg-7 forgot-quote-section">
    <div class="forgot-quote-container">
      <div class="overlay"></div>
      <div class="content">
        <i class="fas fa-quote-left fa-4x"></i>
        <div class="text">
          <span class="quote">Stick to your true<br />north-build greatness<br />for the long term.</span>
          <i class="fas fa-quote-right fa-2x"></i>
          <div class="row author-row">
            <!-- <div class="mt-1">
                            <span class="welcome">Welcome Again!</span>
                        </div> -->
            <div class="ml-auto author text-right">
              <b>Ruth Porat</b><br />
              CFO Alphabet
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-5 col-md forgot-form-section {{ success }}">
    <a routerLink="/">
      <img class="logo" src="assets/svg/Logo.svg" />
    </a>
    <p class="subtitle-2">Forgot Password</p>
    <p class="forgot-text">Enter your email below and we’ll send you a link to set a new password.</p>
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" class="forgot-password-form">
      <div class="container w-75">
        <div class="row">
          <ul class="list-group w-100">
            <div class="list-group-item">
              <div class="input-group">
                <input
                  type="email"
                  formControlName="email"
                  class="form-control list-group-item"
                  style="border: 0"
                  placeholder="Email Address"
                />
                <div
                  class="input-group-append text-danger ml-3 help-block"
                  *ngIf="!forgotPasswordForm.get('email').valid && forgotPasswordForm.get('email').touched"
                >
                  <span *ngIf="forgotPasswordForm.get('email').errors.required" class="color-danger">required</span>
                  <span *ngIf="forgotPasswordForm.get('email').errors.email" class="color-danger"
                    >Please enter a valid email</span
                  >
                </div>
              </div>
            </div>
          </ul>
        </div>
        <div class="submit-button">
          <button type="submit" [disabled]="!forgotPasswordForm.valid" class="btn btn-primary rounded-pill px-5">
            Reset
          </button>
          <a class="cancel-button" routerLink="/login">Cancel</a>
        </div>
      </div>
    </form>
  </div>
  <div class="col-md-5 success-section {{ success }}">
    <a routerLink="/">
      <img class="logo" src="../../../assets/svg/Logo.svg" />
    </a>
    <p class="subtitle-2">An email has been sent to you</p>
    <!-- <p class="forgot-text">
            We sent you an email with instructions to reset your password.
        </p> -->
    <p class="text-center my-3 no-email">
      Didn’t receive the email?
      <button class="btn btn-link btn-link-primary padding-left-0" (click)="sendEmail()">Send It Again</button>
    </p>
  </div>
</div>
<app-alert [message]="errorMessage" *ngIf="errorMessage !== null" (close)="errorMessage = null"></app-alert>
