import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { catchError, tap } from "rxjs/operators";
import { HandleHttpErrors } from "./handle-http-errors.function";
import { Router, ActivatedRoute } from "@angular/router";
import { authenticationGET, UserModel } from "../shared/components/user.module";
import { LocalStorageService, LocalStorage } from "ngx-webstorage";
import * as moment from "moment";
import { SentryService } from "./sentry.service";
import { eUserRole } from "../shared/enums/user.enums";

const API: string = environment.api;
const suffix = "HCG_";
const localStoreUserEntry = suffix + "userData";
const localStoreConfirmationEmailEntry = suffix + "ConfirmationEmail";

@Injectable({ providedIn: "root" })
export class SecurityService {
  private readonly storageIndex = "hcgUser";
  private readonly topMinutesToExpireSession: number = 20;
  private readonly topMinutesToExpireSessionOnboarding: number = 20;

  confirmationEmailSent: boolean;
  emailToSendConfirmation: string;

  aspirationsStepDefault: number;

  @LocalStorage("hcgUser") private currentUser: UserModel;
  public get IsAdmin(): boolean {
    if (this.currentUser) return this.currentUser.role === "admin";
    return false;
  }

  get Onboarding_flow_status(): "personal" | "job" | "aspirations" | "interest" | "payment" | "membership" {
    if (!this.currentUser) return null;
    if (!this.currentUser.onboarding_flow_status) return "personal";
    const flow_status = this.currentUser.onboarding_flow_status.split("_")[0].toLowerCase();
    switch (flow_status) {
      case "personal":
        return "personal";
      case "job":
        return "job";
      case "aspirations":
        switch (this.currentUser.onboarding_flow_status.toUpperCase()) {
          case "ASPIRATIONS_LONG_TERM_GOAL":
            this.aspirationsStepDefault = 1;
            break;

          case "ASPIRATIONS_SHORT_TERM_GOAL":
            this.aspirationsStepDefault = 2;
            break;

          case "ASPIRATIONS_PERSONALITY":
            this.aspirationsStepDefault = 3;
            break;

          case "ASPIRATIONS_CAREER_RESOURCE_APPS":
            this.aspirationsStepDefault = 4;
            break;

          case "ASPIRATIONS_SKILLS_FUTURE":
            this.aspirationsStepDefault = 5;
            break;

          case "ASPIRATIONS_STRENGTHS":
            this.aspirationsStepDefault = 6;
            break;

          case "ASPIRATIONS_WEAKNESSES":
            this.aspirationsStepDefault = 7;
            break;

          case "ASPIRATIONS_AVERAGE_DAY":
            this.aspirationsStepDefault = 8;
            break;
        }

        return "aspirations";
      case "interest":
        return "interest";
      default:
        return "membership";
    }
  }

  public get IsUser(): boolean {
    if (this.currentUser) return this.currentUser.role === eUserRole.Candidate || this.currentUser.role === eUserRole.Both;
    return false;
  }

  public get IsEmployer(): Boolean {
    if (this.currentUser) return this.currentUser.role === eUserRole.Employer || this.currentUser.role === eUserRole.Both;
  }

  public get _isBothUser(): Boolean {
    return this.currentUser?.role === eUserRole.Both;
  }

  public get _isUserOnly(): boolean {
    return this.currentUser?.role === eUserRole.Candidate;
  }

  public get _isEmployerOnly(): boolean {
    return this.currentUser?.role === eUserRole.Employer;
  }

  public get IsLoggedIn(): boolean {
    const sessionExpired = () => {
      if (!this.currentUser.token || !this.currentUser.lastUpdate) return true;
      const now = moment();
      return (
        now.diff(this.currentUser.lastUpdate) / 1000 / 60 >
        (this.stillOnboardingProcess() ? this.topMinutesToExpireSessionOnboarding : this.topMinutesToExpireSession)
      );
    };

    if (!this.currentUser || sessionExpired()) {
      return false;
    }

    this.keepAliveToken();
    return true;
  }

  public stillOnboardingProcess(): boolean {
    if (!this.currentUser) return false;
    return (
      !this.currentUser.onboarding_flow_status ||
      this.currentUser.onboarding_flow_status.toLowerCase() != "completed_on_boarding"
    );
  }

  public keepAliveToken(): void {
    this.currentUser.lastUpdate = moment().format();
    this.localSt.store(this.storageIndex, this.currentUser);
  }

  get UserRef(): UserModel {
    if (!this.currentUser) return null;
    return JSON.parse(JSON.stringify(this.currentUser)) as UserModel;
  }

  set setImageUrl(image_url: string) {
    this.currentUser.profile_image = image_url;
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private localSt: LocalStorageService,
    private sentryService: SentryService,
    private route: ActivatedRoute
  ) {
    if (this.UserRef) {
      this.setUserContext(this.UserRef);
    }
  }

  getToken(): string {
    if (this.currentUser && this.currentUser.token) return this.currentUser.token;
    return null;
  }

  get confirmationEmail() {
    return localStorage.getItem(localStoreConfirmationEmailEntry);
  }

  login(authInfo: Login) {
    this.clearStorage();
    return this.http.post<dataGET<authenticationGET>>(API + "users/login", { user: authInfo }, { observe: "response" }).pipe(
      catchError(HandleHttpErrors),
      tap(async (response) => {
        response.body.data.token = response.headers.get("Authorization") || response.headers.get("authorization") || null;
        await this.handleAuthentication(response.body.data);
      })
    );
  }

  getInfo() {
    let userObject = this.currentUser;
    userObject.onboarding_flow_status = "COMPLETED_ON_BOARDING";
    this.handleAuthentication(userObject);
  }

  skipTutorial(show) {
    this.currentUser.skip_tutorial = show;
  }

  autoLogin(): void {
    // Code for unfinished system inherited from HCG
    // this.user = JSON.parse(localStorage.getItem(localStoreUserEntry));
    // if(!this.user) return;
    // TODO:
  }

  logOut() {
    this.sentryService.clearUser();
    this.clearStorage();
    return this.http.delete(API + "users/logout");
  }

  clearStorage() {
    this.localSt.clear(this.storageIndex);
    localStorage.clear();
  }

  signUp(userInfo: SignUp) {
    this.clearStorage();
    this.sentryService.logSignup(userInfo);
    return this.http
      .post<dataGET<authenticationGET>>(
        API + "users",
        {
          user: {
            ...userInfo,
            password_confirmation: userInfo.password,
            terms_accepted: true,
          },
        },
        { observe: "response" }
      )
      .pipe(
        catchError(HandleHttpErrors),
        tap((response) => {
          return response;
        })
      );
  }

  resentConfirmationEmail(email: string) {
    return this.http
      .post(API + "users/resend_confirmation", {
        user: {
          email: email,
        },
      })
      .pipe(catchError(HandleHttpErrors));
  }

  forgotPassword(email: string) {
    return this.http
      .post(API + "users/password", {
        user: {
          email: email,
        },
      })
      .pipe(catchError(HandleHttpErrors));
  }

  changePassword(token: string, password: string) {
    return this.http
      .put(API + "users/password", {
        user: {
          reset_password_token: token,
          password: password,
          password_confirmation: password,
        },
      })
      .pipe(catchError(HandleHttpErrors));
  }

  private async handleAuthentication(user: authenticationGET) {
    // Starting the onboarding process...
    await this.updateLocalStorage(user);
    this.setUserContext(user);

    const returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
    if (this._isBothUser) {
      this.router.navigate(["user/login/dual"], { relativeTo: this.route.firstChild });
    } else if (this._isUserOnly && returnUrl !== "/") {
      this.router.navigate([returnUrl]);
    } else if (this._isUserOnly) {
      this.router.navigate(["user/candidate/profile"], { relativeTo: this.route.firstChild });
    } else if (this._isEmployerOnly) {
      //TODO: add check to see if they already paid, if so - go to dash or onboarding
      this.router.navigate(["user/login/membership"], { relativeTo: this.route.firstChild });
    }
  }

  private setUserContext(user: authenticationGET) {
    (window as any).Appcues.identify(user.id, { ...user, token: undefined });
    this.sentryService.setUser(user.id.toString(), user.email);
  }

  public async updateLocalStorage(user: authenticationGET) {
    user.lastUpdate = moment().format();
    await this.localSt.store(this.storageIndex, user);
  }
}

export class SignUp {
  constructor(
    public email: string,
    public password: string,
    public first_name: string,
    public last_name: string,
    public company_name: string,
    public role: string
  ) {}
}

export class Login {
  constructor(
    public email: string,
    public password: string
  ) {}
}

export interface dataGET<T> {
  data: T;
}
