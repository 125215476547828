<div
  *ngIf="!customizedStyle"
  class="simple-item noselect p-3"
  [ngClass]="{ shadow: !disabled && selected, selected: !disabled && selected, 'no-cursor': disabled }"
>
  <div class="simple-container">
    <img
      [src]="'assets/svg/' + (iconType === 'check' ? 'check-box' : 'Select-icon') + '.svg'"
      class="selectIcon"
      [ngClass]="{ selected: !disabled && selected }"
    />
  </div>
  <div style="width: 100%" (click)="onClicked()">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </div>
</div>
<div *ngIf="customizedStyle" class="simple-item2 noselect p-3" [ngClass]="{ selected: selected }" (click)="onClicked()">
  <div>
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </div>
</div>
