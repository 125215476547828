<ng-content></ng-content>

<div>
  <mat-spinner
    #spinnerRef
    color="accent"
    [class.btn-is-loading]="loading"
    [style.display]="loading ? 'unset' : 'none'"
    class="absolute-spinner"
    diameter="24"
  >
  </mat-spinner>
</div>
