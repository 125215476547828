import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MatButton } from "@angular/material/button";

@Component({
  selector: "app-mat-spinner-btn",
  templateUrl: "./mat-spinner-btn.component.html",
  styleUrls: ["./mat-spinner-btn.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MatSpinnerBtnComponent implements AfterViewInit, OnChanges {
  @ViewChild("spinnerRef", { static: true, read: ElementRef }) spinner_el: ElementRef;
  @ContentChild(MatButton, { static: true, read: ElementRef }) matButton_el: ElementRef;
  @ContentChild(MatButton, { static: true }) matButton_instance: MatButton;

  @Input() loading: boolean;

  constructor(private r2: Renderer2) {}

  ngAfterViewInit() {
    // debugger;
    if (this.r2 && this.matButton_el && this.spinner_el) {
      this.r2.appendChild(this.matButton_el.nativeElement, this.spinner_el.nativeElement);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ("loading" in changes && this.matButton_instance) {
      this.matButton_instance.disabled = !!this.loading;
    }
  }
}
