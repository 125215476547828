import { Component, Input } from "@angular/core";
import { LoggerService as Logger } from "src/app/shared/services/logger.service";

@Component({
  selector: "app-popover",
  templateUrl: "./popover.component.html",
  styleUrls: ["./popover.component.scss"],
})
export class PopoverComponent {
  @Input()
  imgSrc: string;
  isImage: boolean;

  ngOnInit(): void {
    Logger.log("isImg? ImgSrc:", { isImage: this.isImage, imgSrc: this.imgSrc });
  }
}
