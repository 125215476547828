import { Component, Inject } from "@angular/core";
import { MtxDrawer, MtxDrawerRef, MTX_DRAWER_DATA } from "@ng-matero/extensions/drawer";
import { EmployerOnboardingService } from "src/app/user/employer/employer-root/employer-root.service";

@Component({
  selector: "app-side-drawer-root",
  templateUrl: "./side-drawer-root.component.html",
  styleUrls: ["./side-drawer-root.component.scss"],
})
export class SideDrawerRootComponent {
  constructor(
    public drawerRef: MtxDrawerRef<SideDrawerRootComponent>,
    @Inject(MTX_DRAWER_DATA) public data: any,
    private _service: EmployerOnboardingService
  ) {}

  displayContentType = {
    ApplicantFilter: DisplayContentType.ApplicantFilter,
    CandidateFilter: DisplayContentType.CandidateFilter,
  };

  btnClickEvent(eventValue: string) {
    this._service.sideDrawerEvent.next(eventValue);
  }

  handleCustomComponentEvent(event: any) {
    this.data["returnValue"] = event;
    this._service.sideDrawerEvent.next("");
    this.drawerRef.dismiss(this.data);
  }
}

export enum DisplayContentType {
  ApplicantFilter = "ApplicantFilter",
  CandidateFilter = "CandidateFilter",
}
