<div class="schedule-interview-container">
  <mat-h2 mat-dialog-title class="dialog-title">Schedule an Interview</mat-h2>
  <div class="schedule-interview-content">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label class="interview-label">Select a Job</mat-label>
      <mat-select [(value)]="selectedJob">
        <mat-option *ngFor="let job of employerJobs" [value]="job">
          {{ job }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="header-for-time-slots">
      <h3 class="available-time-slot-title">Available Time Slots</h3>
      <div class="add-slot" [ngClass]="{ 'disable-btn': disableAddTimeSlotButton() }">
        <mat-icon
          class="add-slot-btn"
          (click)="addTimeSlot()"
          matTooltip="Add between 2 and 5 time slots"
          matTooltipPosition="above"
          aria-label="Button that displays a tooltip when focused or hovered"
          >add</mat-icon
        >
      </div>
    </div>

    <div class="calendar-field">
      <ng-container [formGroup]="form">
        <ng-container formArrayName="slots">
          <ng-container *ngFor="let slotForm of slots.controls; let i = index">
            <div class="slot-form-row" [formGroup]="slotForm">
              <div class="slot">
                <mat-form-field class="slot-form-field">
                  <mat-label class="interview-label"> Available Time {{ i + 1 }} </mat-label>
                  <input
                    matInput
                    formControlName="time"
                    [readonly]="true"
                    [min]="minDate"
                    [ngxMatDatetimePicker]="picker"
                    placeholder="Choose a date"
                    class="input-time"
                  />
                  <div class="icon-btns-wrapper">
                    <mat-datepicker-toggle matSuffix [for]="picker" class="picker-toggle-btn"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker
                      [defaultTime]="defaultTime"
                      [stepMinute]="15"
                      [showSeconds]="false"
                      [enableMeridian]="true"
                      #picker
                    ></ngx-mat-datetime-picker>
                    <button mat-icon-button class="delete-slot-btn">
                      <mat-icon class="delete-slot-btn-icon" matSuffix (click)="deleteTimeSlot(i)">delete</mat-icon>
                    </button>
                  </div>
                </mat-form-field>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <mat-dialog-actions class="interview-footer">
    <div class="button-container">
      <button mat-button (click)="cancel()">Cancel</button>
      <button mat-raised-button color="primary" [disabled]="disableProposeBtn()" (click)="proposeTimes()">
        Propose Times
      </button>
    </div>
  </mat-dialog-actions>
</div>
