<div class="container-fluid" (window:resize)="onResize()">
  <div class="row">
    <div class="MultiCarousel" data-items="3,5,5,7" data-slide="1" id="MultiCarousel" data-interval="1000">
      <div class="text-center">
        <img class="check" src="assets/svg/Select-icon.svg" style="visibility: hidden" />
      </div>
      <div class="MultiCarousel-inner">
        <img
          (click)="goToNav(i + 1)"
          *ngFor="let icon of icons; let i = index"
          [attr.id]="icon.id"
          [src]="icon.url"
          (data-selected)="(icon.selected)"
          class="item"
          attr.data-position="{{ i + 1 }}"
        />
      </div>
      <img src="assets/img/Left-arrow_icon.png" class="leftLst" alt="Left Icon" />
      <img src="assets/img/Right-arrow_icon.png" class="rightLst" alt="Right Icon" />
      <a #goalCarouselAnimationOnProgress goalCarouselAnimationOnProgress="0"></a>
    </div>
  </div>
</div>
