<!-- Candidate -->

<div *ngIf="loadedData">
  <add-to-calendar-button
    *ngIf="timestamp"
    [attr.name]="_title"
    [attr.description]="description"
    [attr.startDate]="_startDate"
    [attr.startTime]="_startTime"
    [attr.endTime]="_endTime"
    timeZone="America/Chicago"
    location="Boulo Scheduled Interview"
    availability="busy"
    options="'Apple','Google','Outlook.com','Yahoo'"
    listStyle="modal"
    buttonStyle="3d"
    trigger="click"
    lightMode="bodyScheme"
    hideCheckmark
    styleLight="--btn-underline: #c2ebf3;
      --btn-background: #f3f6f9;
      --btn-background-hover: #ffffff;
      --btn-text: #2a8091;
      --btn-text-hover: #5299a7;
      --btn-text-shadow: #6c6b6bbf;
      --font: 'Source Sans 3', 'Times New Roman', Times, serif;
      --checkmark-background: radial-gradient(circle,rgb(255 255 255 / 91%)  0,rgba(255 255 255 / 60%) 40%,rgba(255 255 255 / 0%) 50%);"
    styleDark="--btn-underline: #c2ebf3;
      --btn-background: #f3f6f9;
      --btn-background-hover: #ffffff;
      --btn-text: #2a8091;
      --btn-text-hover: #5299a7;
      --btn-text-shadow: #6c6b6bbf;
      --font: 'Source Sans 3', 'Times New Roman', Times, serif;
      --checkmark-background: radial-gradient(circle,rgb(255 255 255 / 91%)  0,rgba(255 255 255 / 60%) 40%,rgba(255 255 255 / 0%) 50%);"
    size="6"
  ></add-to-calendar-button>
</div>

<!-- Candidate -->
<!-- <add-to-calendar-button
  *ngIf="timestamp && !isEmployer"
  [attr.name]="title"
  [attr.description]="description"
  [attr.startDate]="startDate"
  [attr.startTime]="startTime"
  [attr.endTime]="endTime"
  timeZone="America/Chicago"
  location="Boulo Scheduled Interview"
  availability="busy"
  options="'Apple','Google','Outlook.com','Yahoo'"
  listStyle="modal"
  buttonStyle="3d"
  trigger="click"
  lightMode="bodyScheme"
  hideCheckmark
  styleLight="--btn-underline: #c2ebf3; 
    --btn-text: #ffffff; 
    --btn-text-hover: #1c6775; 
    --btn-text-shadow: ##000000b0; 
    --font: 'Source Sans 3', 'Times New Roman', Times, serif;
    --checkmark-background: radial-gradient(circle,rgb(255 255 255 / 91%)  0,rgba(255 255 255 / 60%) 40%,rgba(255 255 255 / 0%) 50%);"
  styleDark="--btn-underline: #c2ebf3; 
    --btn-text: #ffffff; 
    --btn-text-hover: #1c6775; 
    --btn-text-shadow: ##000000b0; 
    --font: 'Source Sans 3', 'Times New Roman', Times, serif;
    --checkmark-background: radial-gradient(circle,rgb(255 255 255 / 91%)  0,rgba(255 255 255 / 60%) 40%,rgba(255 255 255 / 0%) 50%);"
  size="6"
></add-to-calendar-button> -->

<!-- Employer -->
<!-- <add-to-calendar-button
  *ngIf="timestamp && isEmployer"
  [attr.name]="title"
  [attr.description]="description"
  [attr.startDate]="startDate"
  [attr.startTime]="startTime"
  [attr.endTime]="endTime"
  timeZone="America/Chicago"
  location="Boulo Scheduled Interview"
  availability="busy"
  options="'Apple','Google','Outlook.com','Yahoo'"
  listStyle="modal"
  buttonStyle="text"
  trigger="click"
  lightMode="bodyScheme"
  hideCheckmark
  styleLight="--btn-underline: #7855c1; 
    --btn-text: #673ab7; 
    --btn-text-hover: #ffffff; 
    --btn-text-shadow: ##000000b0; 
    --font: 'Source Sans 3', 'Times New Roman', Times, serif;
    --checkmark-background: radial-gradient(circle,rgb(255 255 255 / 91%)  0,rgba(255 255 255 / 60%) 40%,rgba(255 255 255 / 0%) 50%);"
  styleDark="--btn-underline: #7855c1; 
    --btn-text: #673ab7; 
    --btn-text-hover: #ffffff; 
    --btn-text-shadow: ##000000b0; 
    --font: 'Source Sans 3', 'Times New Roman', Times, serif;
    --checkmark-background: radial-gradient(circle,rgb(255 255 255 / 91%)  0,rgba(255 255 255 / 60%) 40%,rgba(255 255 255 / 0%) 50%);"
  size="6"
></add-to-calendar-button> -->

<!-- Old -->
<!-- <add-to-calendar-button 
  name="Sample Event"
  description="Play with me!2023-12-25T15:00:00.000Z"
  startDate="2023-12-25"
  startTime="15:00:00"
  endTime="17:45"
  timeZone="America/New_York"
  location="World Wide Web"
  availability="busy"
  options="'Apple','Google','iCal','Outlook.com','Yahoo'"
  listStyle="overlay"
  buttonStyle="3d"
  trigger="click"
  hideCheckmark
  size="5"
></add-to-calendar-button> -->
