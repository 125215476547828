<app-header></app-header>
<div class="" id="not-found">
  <h2 class="error-number">404</h2>
  <h2 class="error-title">Sorry, we couldn’t find the page</h2>
  <h2 class="error-message">It looks like that page doesn’t exist - please check the URL and try again</h2>
  <button routerLink="/" class="btn btn-primary rounded-pill px-5 mb-5">Go To Home</button>
  <p class="cant-find">Can’t find what you were looking for?</p>
  <a routerLink="/howitworks" class="links">Learn How It Works<img src="assets/svg/more-arrow.svg" /></a><br /><br />
  <a routerLink="/membership" class="links">See Our Plans<img src="assets/svg/more-arrow.svg" /></a><br /><br />
  <a routerLink="/contactus" class="links">Contact Us<img src="assets/svg/more-arrow.svg" /></a>
</div>
<app-footer></app-footer>
