import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FaqInterface } from "./faq-interface";
import { environment } from "src/environments/environment";

const API: string = environment.api;

@Injectable({ providedIn: "root" })
export class FaqService {
  questions: FaqInterface[];

  constructor(private http: HttpClient) {}

  private setData(questions: FaqInterface[]): void {
    this.questions = questions.slice();
  }

  getData(): FaqInterface[] {
    return this.questions.slice();
  }

  fetchData(): FaqInterface[] {
    // TODO: http request...
    // return this.http.get<FaqInterface[]>(API + '').pipe(
    //     map((questions) => {
    //         return recipes.map((v) => {
    //             if(!v.ingredients) {
    //                 v.ingredients = [];
    //             }

    //             return v;
    //         });
    //     }),
    //     tap(questions => this.setData(questions))
    // );
    this.setData([
      {
        question: "What is Artificial Intelligence?",
        answer: `Artificial intelligence (AI) refers to the simulation of human intelligence in machines that are programmed to think like humans and mimic their actions. The term may also be applied to any machine that exhibits traits associated with a human mind such as learning and problem-solving.
        The ideal characteristic of artificial intelligence is its ability to rationalize and take actions that have the best chance of achieving a specific goal.
        Boulo uses AI to compare the attributes of successful C-suite women to the attributes and career goals of our individual Aspiring members and provide curated content tailored to their individual needs and interests.
        `,
      },
      {
        question: "Why is AI important?",
        answer: `The tailored content provided by the Career algorithms is designed to reduce the time and effort required to find and take advantage of information that is beneficial to your unique career development needs.  Boulo enables you to find the needle in a haystack based on the insights and recommendations of successful C-suite female executives.`,
      },
      {
        question: "How are you different from other career resources?  LinkedIn?",
        answer: `We are the only career resource that provides a unique technology platform powered by C-Suite insights that long term will utilize AI and voice activation technology such as Alexa, Siri, and Google Home.`,
      },
      {
        question: "How often do you refresh content?",
        answer: `We are adding new content daily for our users but your visibility of the latest content depends upon your frequency of usage and completion of activities.`,
      },
      {
        question: "How does COVID impact what you developed?",
        answer: `With the advent of COVID-19, we have continued with our mission of advancing the career of all women no matter what age, background, income, geography or race. We have provided unique content to help women and men pivot their careers and look for new jobs as needed.`,
      },
      {
        question: "Who are the women C-suite executives?",
        answer: `They represent a wide range of women across the nation from C-suite officers to founders to entrepreneurs with 38% minority representation.`,
      },
      {
        question: "What are users saying?",
        answer: `Boulo is the way to map out the true career lattice of the stops and starts we all face in our careers.`,
      },
      {
        question: "How did you come up with the idea for HCG?",
        answer: `In 2016, Suzanna Keith,   Founder, launched a series of panel events led by high-achieving women for Women In Innovation (WIN) to start the dialog. The panelists jumped at the opportunity to share their perspective with a large number of women, recognizing their own limitations of time and access to be mentors, sponsors or even authors. 50% of attendees confirmed that not only were they frustrated with their own career advancement, but also needed a tool, a roadmap, to find a way to advance --- 100% were interested in the insights these women could provide.  
        Today, career resources are plentiful, but often generic, costly, inaccessible or time-consuming. This led to the creation of Boulo providing meaningful insights easily accessible for all. 
        `,
      },
      {
        question: "What is the story behind the name?",
        answer: `The Founder developed the name Boulo so that a woman could ask “Hello Alexa, Google Home or Siri, what is my career  game plan for the week?” This will allow her to be on track with her advancement to the C-suite.`,
      },
      {
        question: "What are some success stories and what type of careers?",
        answer: `As mentioned in our success stories, Nicole, one of our early innovators, used our content to rapidly map out the optimal game plan and was ultimately promoted to the role of chief administrative officer.`,
      },
      {
        question: "How long does it take to be successful?",
        answer: `Boulo is based on the belief that thoughtful insights and knowledge empower you to advance towards achieving your ambitions with greater confidence and know-how.  Ultimately, the achievement of success depends on how much time you are able and willing to spend on career development and implementing what you learn.  Boulo’s Rewards program is designed to celebrate progress along the way.`,
      },
      {
        question: "Who is the average user?",
        answer: `An Aspiring   is an ambitious woman who is looking for ways to advance in her role and in her career.  Career paths are dynamic and advancement is not always linear.  The Aspiring   can be in any career stage from entry level to senior management but has an appreciation for expert advice and insights curated for her that are easily accessible and affordable.`,
      },
      {
        question: "How much does it cost for a year? Give to a friend?",
        answer: `Here is a link to our pricing pages which explains pricing for all of our options: <a href = "/membership" >click here</a>. If you have any additional questions, please reach out to <a href = "mailto: guru@hellocareerguru.com">guru@hellocareerguru.com</a> and we will get back to you within two business days.`,
      },
      {
        question: "Can Men use Boulo?",
        answer: `Yes, of course, but please note that the C-suite insights are female related.`,
      },
      {
        question: "Do you assign mentors? Coaches?",
        answer: `Not at the current time but please contact the coaches on our affiliate links for their rates and timing.  For further information, please reach out to <a href = "mailto: guru@hellocareerguru.com">guru@hellocareerguru.com</a> and we will get back to you within two business days.`,
      },
    ]);

    return this.getData();
  }
}
