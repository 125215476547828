import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-multiselector-widget",
  templateUrl: "./multiselector-widget.component.html",
  styleUrls: ["./multiselector-widget.component.scss"],
})
export class MultiselectorWidgetComponent implements OnInit {
  @Input() options: string[];
  @Input() set defaultOptions(value: string[]) {
    value.forEach((option) => {
      this.selectedOptions[option] = true;
    });
  }
  @Output() selections = new EventEmitter<{ [option: string]: boolean }>();
  selectedOptions: { [option: string]: boolean } = {};

  constructor() {}

  ngOnInit(): void {}

  toggleOption(option: string): void {
    this.selectedOptions[option] = !this.selectedOptions[option];
    this.selections.emit(this.selectedOptions);
  }
}
