<app-header></app-header>
<div class="navBarPadding pt-sm-5">
  <section id="criteria" class="py-5">
    <div [ngClass]="{ 'pt-5': showBanner }">
      <h1 class="title-2">How can we <br class="d-block d-md-none" />help you?</h1>
    </div>
    <div class="container my-5 rounded-circle">
      <div class="row">
        <ul class="list-group w-100">
          <div class="list-group-item">
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control border border-pill"
                [(ngModel)]="criteria"
                (keydown)="search($event)"
                placeholder="Describe your issue"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <div class="input-group-append">
                <img src="assets/img/Search_icon.png" (click)="search(null)" *ngIf="!questionMadeIt" />
                <img src="assets/svg/Close-default_icon.svg" (click)="clearSearch()" *ngIf="questionMadeIt" />
              </div>
            </div>
          </div>
        </ul>
      </div>
    </div>
  </section>
  <section id="askedQuestions" class="container">
    <div class="subtitlex" *ngIf="foundQuestions.length > 0">
      {{ questionMadeIt ? "Questions found" : "Frequently Asked Questions" }}
    </div>
    <div class="noResults" *ngIf="foundQuestions.length == 0">
      <h2>No answers found, please <a routerLink="/contactus">Contact Us.</a></h2>
    </div>
    <div class="accordion mt-5" id="faqAccordion">
      <div class="card" *ngFor="let item of foundQuestions; let i = index">
        <div class="card-header" id="headingOne">
          <div class="row">
            <ul class="list-group w-100">
              <div class="list-group-item">
                <div class="row">
                  <div class="col-2 col-sm-1">
                    <button
                      class="btn btn-link text-left"
                      type="button"
                      data-toggle="collapse"
                      attr.data-target="{{ '#collapse' + i }}"
                      aria-expanded="true"
                      attr.aria-controls="{{ 'collapse' + i }}"
                    >
                      <img src="assets/svg/more-icon.svg" />
                    </button>
                  </div>
                  <div
                    class="col-10 col-sm-11 d-flex align-items-center item-text"
                    data-toggle="collapse"
                    attr.data-target="{{ '#collapse' + i }}"
                    aria-expanded="true"
                    attr.aria-controls="{{ 'collapse' + i }}"
                  >
                    {{ item.question }}
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div attr.id="{{ 'collapse' + i }}" class="collapse" aria-labelledby="headingOne" data-parent="#faqAccordion">
          <div class="card-body" [innerHTML]="item.answer" (click)="goTo($event)"></div>
        </div>
      </div>
    </div>
  </section>
  <section id="footer" class="mt-5">
    <div class="subtitlex">Build your <br class="d-block d-md-none" />career path today</div>
    <button
      type="button"
      class="btn btn-primary mt-2 mb-5 rounded-pill font-weight-bold display-1"
      (click)="common.goToSignup()"
    >
      Join Now
    </button>
  </section>
</div>
<app-footer></app-footer>
