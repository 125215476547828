<div class="row snackbar-label">
  <div class="col-12 snackbar-label">
    <span matSnackBarLabel class="d-inline-block">
      <p class="snackbar-bold-text">{{ data.boldMsg }}</p>
    </span>
    <span matSnackBarActions class="snackbar-actions d-incline-block">
      <button
        mat-button
        color="success"
        matSnackBarAction
        class="snackbar-success-action-btn"
        (click)="snackBarRef.dismissWithAction()"
      >
        Close
      </button>
    </span>
  </div>
  <div *ngIf="hasDetailsMsg" class="col-12">
    <p class="snackbar-details-text">{{ data.detailsMsg }}</p>
  </div>
</div>
