import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import "add-to-calendar-button";

@Component({
  selector: "app-add-to-calendar",
  templateUrl: "./add-to-calendar.component.html",
  styleUrls: ["./add-to-calendar.component.scss"],
})
export class AddToCalendarComponent implements OnInit {
  @Input() timestamp: string;
  @Input() description: string;
  @Input() title: string;
  @Input() isEmployer: boolean = false;

  get formattedStartDate(): string {
    return this.timestamp.substring(0, 10);
  }

  loadedData: boolean = false;
  startDate: string = "";
  startTime: string = "";
  endTime: string = "";

  ngOnInit() {
    this.formatTimestamp();
    this.loadedData = true;
  }

  get _startDate(): string {
    return this.startDate?.length > 0 ? this.startDate : "";
  }
  get _startTime(): string {
    return this.startTime?.length > 0 ? this.startTime : "";
  }
  get _endTime(): string {
    return this.endTime?.length > 0 ? this.endTime : "";
  }
  get _title(): string {
    return this.title?.length > 0 ? this.title : "";
  }

  formatTimestamp() {
    if (!this.timestamp) return;

    const chosenDate = new Date(this.timestamp);

    const year = chosenDate.getFullYear();
    const month = chosenDate.getMonth() + 1;
    const day = chosenDate.getDate();
    this.startDate = `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;

    const hours = chosenDate.getHours();
    const minutes = chosenDate.getMinutes();
    this.startTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

    const endHours = chosenDate.getHours() + 1;
    this.endTime = `${endHours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
  }

  //Figure out how to use this?
  _componentStyles(): string {
    return `
      --btn-underline: #7855c1; 
      --btn-text: #ffffff; 
      --btn-text-hover: #ffffff; 
      --btn-text-shadow: ##000000b0; 
      --font: 'Source Sans 3', 'Times New Roman', Times, serif;
      --checkmark-background: radial-gradient(circle,rgb(255 255 255 / 91%)  0,rgba(255 255 255 / 60%) 40%,rgba(255 255 255 / 0%) 50%);
      `;
  }
}
