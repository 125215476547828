//Boulo currently only uses 'Prequalified' and 'Active'
export enum eCandidateStatus {
  NewCandidate = "New Candidate",
  Prequalified = "Prequalified", //Candidate has enrolled in Boulo
  Qualified = "Qualified",
  Active = "Active", //Candidate is a Boulo Member and Boulo Verified
  Inactive = "Inactive",
  Placed = "Placed",
  NotFitForBoulo = "Not a Fit for Boulo",
  NewLead = "New Lead",
  Archive = "Archive", //Boulo's custom soft-delete
  //,ReadyToPlace = "Ready to Place" //used a lot on Bullhorn, but not on Boulo
}

export enum eCandidateTags {
  Active_Verified = "Verified Active", //candidate has all the 'Boulo Verified' fields completed
  Active_Unverified = "Unverified Active", //candidate was set to 'Active' before 'Boulo Verified' fields checked
  Preqqualified_Verified = "Prequal", //candidate is missing 1 or more 'Boulo Verified' fields
  Missing_ContactInfo = "_CI", //checkCandidateContactInfo
  Missing_WorkHistory = "_WH", //checkCandidateWorkHistroy (workhistory & resume)
  Missing_UniversalSkills = "_US", //checkIfUniversalSkillsCompleted
  Missing_AreasOfExpertise = "_AoE", //checkCandidateAreasOfExpertise
}
