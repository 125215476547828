<mat-drawer-container class="mat-drawer-container" [hasBackdrop]="true">
  <mat-drawer-content class="nav-bar">
    <mat-h2 mat-dialog-title class="dialog-title">{{ data?.title }}</mat-h2>
    <div class="side-drawer-content">
      <app-mulitiselect
        *ngIf="data?.displayContentType === displayContentType.ApplicantFilter"
        [selectedFields]="data?.selectedFilter"
        [allFields]="data.filters"
        [isFilterPrioritized]="data?.isPrioritized"
        [applyEventName]="data.primaryBtn?.eventName"
        [clearEventName]="data.secondaryBtn?.eventName"
        (chosenFileds)="handleCustomComponentEvent($event)"
      ></app-mulitiselect>

      <app-category-select
        *ngIf="data?.displayContentType === displayContentType.CandidateFilter"
        [allCategories]="data.categories"
        [allowPriortizeFilters]="false"
        [isFilterPrioritized]="data?.isPriortized"
        [inputSelectedFields]="data.selectedFields"
        [autoPopulateCategories]="data?.jobSelectedFields"
        [selectedAutoPopulateCategories]="data?.selectedAutoPopulateCategories"
        [applyEventName]="data.primaryBtn?.eventName"
        [clearEventName]="data.secondaryBtn?.eventName"
        (selectedFields)="handleCustomComponentEvent($event)"
      ></app-category-select>
    </div>

    <mat-dialog-actions class="side-drawer-modal-actionrow">
      <div class="btns">
        <button (click)="btnClickEvent(data.secondaryBtn?.eventName)" mat-button type="button">
          {{ data?.secondaryBtn?.btnName }}
        </button>
        <button (click)="btnClickEvent(data.primaryBtn?.eventName)" mat-raised-button color="primary">
          {{ data?.primaryBtn?.btnName }}
        </button>
      </div>
    </mat-dialog-actions>
  </mat-drawer-content>
</mat-drawer-container>
