import { Component } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { SecurityService } from "./services/security.service";
declare let gtag: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "hcg";
  $: any;

  constructor(
    private router: Router,
    private securityService: SecurityService
  ) {
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        gtag("config", "UA-165297258-1", {
          "/": evt.urlAfterRedirects,
        });
      }
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if (evt instanceof NavigationEnd) {
        if (securityService.UserRef == null && evt.url.startsWith("/login")) {
          (window as any).Appcues.anonymous();
        }
      }
      window.scrollTo(0, 0);
    });
  }
}
