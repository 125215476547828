import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { HandleHttpErrors } from "./handle-http-errors.function";

const API: string = environment.api;
@Injectable({
  providedIn: "root",
})
export class HomeService {
  constructor(private http: HttpClient) {}

  pressReleases() {
    return this.http.get(API + "press_releases").pipe(catchError(HandleHttpErrors));
  }
}
