<h2 mat-dialog-title class="dialog-title">Window Close Confirmation</h2>
<mat-dialog-content class="mat-typography modal-dialog-content">
  <p class="body-text">Are you sure you wish to close this dialog box? Any unsaved changes will be lost.</p>
</mat-dialog-content>
<mat-dialog-actions class="confirmation-modal-actionrow">
  <button mat-raised-button class="avoid btn-dim confirmation-btn-cancel" [mat-dialog-close]="false">Cancel</button>
  <button mat-raised-button class="avoid btn-dim confirmation-btn-save" color="primary" [mat-dialog-close]="true">
    Proceed
  </button>
</mat-dialog-actions>
