import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { OpenCommonService } from "../open-common.service";
import { MainService } from "./main-service";
import { SuccessStories } from "./main-interface";
import { Meta } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { LoggerService as Logger } from "src/app/shared/services/logger.service";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit {
  showBanner: boolean;
  carouselItems: SuccessStories[] = [];

  constructor(
    public common: OpenCommonService,
    private router: Router,
    private route: ActivatedRoute,
    public mainService: MainService,
    private metaService: Meta
  ) {}

  ngOnInit(): void {
    this.router.navigate(["../login"], { relativeTo: this.route.firstChild });
    this.common.showBannerObservable.subscribe((val) => {
      this.showBanner = val;
    });

    this.metaService.addTags([
      {
        name: "keywords",
        content: "Career, Women, Advice, Career Development, Career Advancement, C-suite, Insights",
      },
      {
        name: "description",
        content:
          "Your destination for women’s career insights and advice from our successful executive team. Subscribe to our newsletter to be the first to hear about our upcoming official product launch.",
      },
      {
        name: "facebook-domain-verification",
        content: "z1ziivefq1sc1pckxl4gstokhr8fva",
      },
    ]);

    this.carouselItems = this.mainService.successStories;
  }

  goalSelected(id: number): void {
    // Logger.log(id);
  }
}
