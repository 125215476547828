<app-header></app-header>
<div class="navBarPadding pt-sm-5">
  <section id="superstar" [ngClass]="{ 'pt-5': showBanner }">
    <div id="superstar-row">
      <div class="row subtitle">
        <div class="col-lg-7 reward-text">
          <h1 class="title-7">Superstar Rewards</h1>
          <p class="subtitle-7" style="width: 90%">
            Our exciting Rewards program will allow you to earn points towards your Super Star career.
          </p>
          <!-- <p class="pt-3 subtitle-7">
                        Level up with our tiered offering at 4 star, 5 star, 6 star and 7 star rewards.
                    </p> -->
          <button class="btn btn-primary btn-lg mt-3 px-5 rounded-pill" (click)="common.goToSignup()">Join Now</button>
        </div>
      </div>
    </div>
  </section>

  <section id="helping">
    <div class="container">
      <!-- <h2 class="title-6 text-center mx-lg-5">
                Helping to meet your goals<br class="hide-mobile">
                through <br class="show-mobile"> motivation
            </h2> -->
      <h2 class="title-6 text-center mx-lg-5">
        Helping You Achieve Your Goals <br />
        Through Motivation
      </h2>
      <p class="text-center subtitle-6">Be part of <b>Boulo</b>, it's easy!</p>
      <div class="row mt-5 align-items-end justify-content-between summary-6">
        <div class="col-lg-3 text-center">
          <img src="../../../assets/img/Rewards/Rewards-1.svg" /><br />
          Sign up and complete <br class="show-mobile" />
          the onboarding questionnaire
        </div>
        <div class="col-lg-3 text-center mt-5 mt-lg-0">
          <img src="../../../assets/img/Rewards/Rewards-2.svg" /><br />
          Choose the plan membership <br class="show-mobile" />
          that works best for you
        </div>
        <div class="col-lg-3 text-center mt-5 mt-lg-0">
          <img src="../../../assets/img/Rewards/Rewards-3.svg" /><br />
          Improve your skills<br />
          by completing tasks
        </div>
        <div class="col-lg-3 text-center mt-5 mt-lg-0">
          <img src="../../../assets/img/Rewards/Rewards-4.svg" /><br />
          Grow your career while <br class="show-mobile" />
          earning points and rewards
          <!-- Earn points and get rewards <br class="show-mobile"> growing your career -->
        </div>
      </div>
      <div class="text-center mt-5">
        <button class="btn btn-primary rounded rounded-pill" (click)="common.goToSignup()">
          Start Earning Rewards Today
        </button>
      </div>
    </div>
  </section>
</div>
<app-footer></app-footer>
