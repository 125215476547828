import { Component, OnInit } from "@angular/core";
import { OpenCommonService } from "../open-common.service";

@Component({
  selector: "app-rewards",
  templateUrl: "./rewards.component.html",
  styleUrls: ["./rewards.component.scss"],
})
// Route to this component was removed 01/2024
export class RewardsComponent implements OnInit {
  showBanner: boolean;
  constructor(public common: OpenCommonService) {}

  ngOnInit(): void {
    this.common.showBannerObservable.subscribe((val) => {
      this.showBanner = val;
    });
  }
}
