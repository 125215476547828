<app-header></app-header>
<div class="navBarPadding pt-sm-5">
  <section id="empowering">
    <div id="empowering-row">
      <h1 class="title-2">Empowering <br class="title-breakpoint" />Women</h1>
      <div class="row subtitle-2">
        <div class="col-lg-6 offset-lg-6 col-sm-7 offset-sm-5">
          <div>
            Our mission is to democratize career advancement for all women regardless of age, background, race, income,
            or geography.
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="goal" class="container-xpadding no-right-padding">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6 d-md-flex flex-md-column justify-content-md-center">
          <div class="left-container mt-5 mt-md-0">
            <h2 class="title-2 pt-5">Goal</h2>
            <img src="assets/img/how-it-works/Guru_s-goal_image.png" class="img-fluid d-block d-lg-none w-100" />
            <h3 class="subtitle-2 pt-4">
              Our goal is to empower, to educate and to<br class="d-none d-xl-block" />
              elevate all women through the use of data<br class="d-none d-xl-block" />
              science and artificial intelligence.
            </h3>
            <button type="button" class="btn btn-primary rounded-pill mt-3" (click)="common.goToSignup()">
              Join Our Community
            </button>
          </div>
        </div>
        <div class="col-6 d-none d-lg-block text-right">
          <img src="assets/img/how-it-works/Guru_s-goal_image.png" class="img-fluid w-100" />
        </div>
      </div>
    </div>
  </section>

  <section id="how-it-works" class="container-xpadding no-right-padding">
    <div class="container-fluid">
      <div class="title-4 py-lg-5 pb-0 pt-5 text-center">How Does It Work?</div>
      <div class="row">
        <div class="col-lg-6 d-md-flex flex-md-column justify-content-md-center align-items-md-center">
          <div class="left-container mt-5 mt-md-0">
            <img src="assets/img/how-it-works/Empower_image.png" class="img-fluid d-block d-lg-none w-100" />
            <h2 class="subtitle-4 pt-3 pb-1 font-weight-bold">Empower</h2>
            <h4 class="summary-4">
              Confidently take ownership of your goals, at your own pace, with actionable steps guided by insights from
              women who have achieved at the highest levels.
            </h4>
          </div>
        </div>
        <div class="col-6 d-none d-lg-block">
          <img src="assets/img/how-it-works/Empower_image.png" class="img-fluid w-100" />
        </div>
      </div>
    </div>
  </section>

  <section id="educate" class="py-2 container-xpadding no-left-padding">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6 d-none d-lg-block">
          <img src="assets/img/how-it-works/Educate_image.png" class="img-fluid w-100" />
        </div>
        <div class="col-lg-6 d-md-flex flex-md-column justify-content-md-center align-items-md-center">
          <div class="left-container mt-5 mt-md-0">
            <img src="assets/img/how-it-works/Educate_image.png" class="img-fluid d-block d-lg-none w-100" />
            <h2 class="subtitle-4 pt-3 pb-1 font-weight-bold">Educate</h2>
            <h3 class="summary-4">
              Explore curated content and resources for learning delivered to you in a customized career game plan of
              skills and behaviors to develop.
            </h3>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="elevate" class="py-2 container-xpadding no-right-padding">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6 d-md-flex flex-md-column justify-content-md-center align-items-md-center">
          <div class="left-container mt-5 mt-md-0">
            <img src="assets/img/how-it-works/Elevate_image.png" class="img-fluid d-block d-lg-none w-100" />
            <h2 class="subtitle-4 pt-3 pb-1 font-weight-bold">Elevate</h2>
            <h3 class="summary-4">
              Gain confidence in your core competencies and expand your leadership opportunities to prepare for your
              next promotion, pursue that next job or launch your own business.
            </h3>
          </div>
        </div>
        <div class="col-6 d-none d-lg-block">
          <img src="assets/img/how-it-works/Elevate_image.png" class="img-fluid w-100" />
        </div>
      </div>
    </div>
  </section>

  <section id="assesment" class="container-xpadding">
    <h2 class="title-5 c-logo title">The <span>C</span>-suite Assessment</h2>
    <h3 class="subtitle-5 margin-top-60">5 minutes could change your life!</h3>
    <h5 class="summary-5 margin-top-40 margin-bottom-120">
      Female C-suite leaders' advice, tips and insights help create a customized career game plan at your fingertips.
    </h5>
    <div id="assesment-yourself" class="pt-lg-4 pt-0 pb-2 mb-lg-5 mb-0">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 d-md-flex flex-md-column remove-padding-x">
            <div class="left-container mt-5 mt-md-0">
              <img
                src="assets/img/how-it-works/Tell-us-about-yourself_icon.png"
                class="img-fluid d-block d-lg-none w-75 mx-auto"
              />
              <h2 class="title-3 pt-lg-0 pt-3">Tell Us About Yourself</h2>
              <h2 class="subtitle-3">With Our Quick Questionnaire</h2>
              <button
                class="btn btn-link btn-link-primary btn-lg font-weight-bold padding-left-0"
                (click)="common.goToSignup()"
              >
                Start the Onboarding <i class="fa fa-arrow-right"></i>
              </button>
            </div>
          </div>
          <div class="col-6 d-none d-lg-block align-self-center text-right remove-padding-x">
            <img src="assets/img/how-it-works/Tell-us-about-yourself_icon.png" class="size-w-500" />
          </div>
        </div>
      </div>
    </div>
    <div id="assesment-membership" class="pt-lg-4 pt-0 pb-2 mb-lg-5 mb-0">
      <div class="container-fluid">
        <div class="row">
          <div class="col-6 d-none d-lg-block align-self-center text-left remove-padding-x">
            <img src="assets/img/how-it-works/Select-a-membership-level_icon.png" class="size-w-500" />
          </div>
          <div class="col-lg-6 d-md-flex flex-md-column remove-padding-x">
            <div class="left-container mt-5 mt-md-0">
              <img
                src="assets/img/how-it-works/Select-a-membership-level_icon.png"
                class="img-fluid d-block d-lg-none w-75 mx-auto"
              />
              <h2 class="title-3 pt-lg-0 pt-3">Join our community</h2>
              <h2 class="subtitle-3">By choosing a membership plan</h2>
              <button
                class="btn btn-link btn-link-primary btn-lg font-weight-bold padding-right-0"
                (click)="common.goToSignup()"
              >
                See Our Plans <i class="fa fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="assesment-gameplan" class="pt-lg-4 pt-0 pb-2 mb-lg-5 mb-0">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 d-md-flex flex-md-column remove-padding-x">
            <div class="left-container mt-5 mt-md-0">
              <img
                src="assets/img/how-it-works/Your-customized-career-game-plan_icon.png"
                class="img-fluid d-block d-lg-none w-75 mx-auto"
              />
              <h2 class="title-3 pt-lg-0 pt-3">View Your Customized Plan</h2>
              <h2 class="subtitle-3">Based on Your Profile</h2>
              <h3 class="summary-3 mt-4">
                The first time you log in, your Game Plan is ready for action. Have fun exploring<br
                  class="d-none d-xl-block"
                />
                and set your own pace.
              </h3>
            </div>
          </div>
          <div class="col-6 d-none d-lg-block align-self-center text-right remove-padding-x">
            <img src="assets/img/how-it-works/Your-customized-career-game-plan_icon.png" class="size-w-500" />
          </div>
        </div>
      </div>
    </div>
    <div id="assesment-trackprogress" class="pt-lg-4 pt-0 pb-2 mb-lg-5 mb-0">
      <div class="container-fluid">
        <div class="row">
          <div class="col-6 d-none d-lg-block align-self-center">
            <img src="assets/img/how-it-works/Track-your-progress_icon.png" class="size-h-400" />
          </div>
          <div class="col-lg-6 d-md-flex flex-md-column remove-padding-x">
            <div class="left-container mt-5 mt-md-0">
              <img
                src="assets/img/how-it-works/Track-your-progress_icon.png"
                class="img-fluid d-block d-lg-none w-25 mx-auto"
              />
              <h2 class="title-3 pt-lg-0 pt-3">Track your progress</h2>
              <h2 class="subtitle-3">With Your Personal Dashboard</h2>
              <h3 class="summary-3 mt-4">
                Accountability tools will help you define what success means to you to keep you on track to achieve your
                goals.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="assesment-goals" class="pt-lg-4 pt-0 pb-2 mb-lg-5 mb-0">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 d-md-flex flex-md-column remove-padding-x">
            <div class="left-container mt-5 mt-md-0">
              <img
                src="assets/img/how-it-works/Reach-your-goals_icon.png"
                class="img-fluid d-block d-lg-none w-75 mx-auto"
              />
              <h2 class="title-3 pt-lg-0 pt-3">Reach Your Goals</h2>
              <h2 class="subtitle-3">And Earn Rewards</h2>
              <h3 class="summary-3 mt-4">
                Yes, we want you to work towards those goals, but we believe that everyone<br
                  class="d-none d-xl-block"
                />
                deserves something special for their efforts. Earn points and redeem gifts.
              </h3>
              <button
                class="btn btn-link btn-link-primary btn-lg font-weight-bold padding-left-0"
                (click)="common.goToRewardsHelping()"
              >
                Learn More <i class="fa fa-arrow-right"></i>
              </button>
            </div>
          </div>
          <div class="col-6 d-none d-lg-block align-self-center text-right remove-padding-x">
            <img src="assets/img/how-it-works/Reach-your-goals_icon.png" class="size-w-500" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- <section id="learn-more" class="pt-3 container-xpadding">
        <div class="container">
            <div class="row">
                <div class="col text-center my-4">
                    <h2 class="title-11">About Boulo!</h2>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="container-fluid d-flex flex-column flex-xl-row align-items-center align-items-xl-start justify-content-xl-between text">
                        <div class="card shadow mt-5 mt-xl-0" style="width: 18rem;" *ngFor="let item of items">
                            <img [src]="'assets/img/how-it-works/' + item.img" class="card-img-top">
                            <div class="card-body">
                                <h5 class="summary-11 text-muted">{{ item.title }}</h5>
                                <p class="subtitle-11">{{ item.subtitle }}</p>
                                <button class="btn btn-link pl-0 btn-link-primary font-weight-bold">Read More <i class="fa fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col text-center">
                    <button class="btn btn-outline-dark btn-lg rounded-pill font-weight-bold">Visit   View</button>
                </div>
            </div>
        </div>
    </section> -->
</div>
<app-footer></app-footer>
