<div class="app-poll-dialog">
  <h1 class="title">{{ title }}</h1>

  <mat-dialog-content>
    <mat-radio-group [(ngModel)]="selectedOptionIndex">
      <mat-radio-button *ngFor="let option of data.poll.options; let i = index" [value]="i">
        <ng-container *ngIf="option.text !== '9999-12-31T00:00'; else naOption">
          {{ option.text | date: "MMM d, y, h:mm a" }}
        </ng-container>
        <ng-template #naOption>None of the Above</ng-template>
      </mat-radio-button>
    </mat-radio-group>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button [mat-dialog-close]="undefined">Cancel</button>
    <button
      mat-button
      [disabled]="selectedOptionIndex === null"
      [mat-dialog-close]="data.poll.options[this.selectedOptionIndex]"
    >
      Submit
    </button>
  </mat-dialog-actions>
</div>
