<div class="backdrop" (click)="onClose()"></div>
<div class="alert-box">
  <img src="assets/svg/alert_icon.svg" />
  <div class="message-wrapper">
    <p class="message-text">{{ message }}</p>
    <div *ngIf="confirmationEmail" class="mt-4">
      <p class="subtitle-2 confirmation-text resend">
        Didn't receive the email? <button (click)="resentConfirmationEmail()">Send It Again</button>
      </p>
    </div>
  </div>
  <div class="alert-box-actions text-center">
    <button class="btn btn-primary btn-close-alert rounded-pill" (click)="onClose()">Close</button>
  </div>
</div>
