import { Component, EventEmitter, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { iApplicantListModel, iApplicantModel, iCandidateFindModel } from "src/app/shared/models/applicant-candidate.model";
import { EmployerOnboardingService } from "../../../employer-root/employer-root.service";
import { DialogApplicantDetailsComponent } from "../../details/root/dialog-applicant-details/dialog-applicant-details.component";
import { Input } from "@angular/core";
import { LoggerService as Logger } from "src/app/shared/services/logger.service";
import { eFindCandidateTabs } from "src/app/shared/enums/employer.enums";
import { iCandidateIdSubmissionOptional, iTabDetails } from "../../../employer-root/employer.models";
import {
  DisplayContentType,
  SideDrawerRootComponent,
} from "src/app/shared/components/side-drawer-root/side-drawer-root.component";
import { sideDrawerEvents } from "src/app/shared/enums/sideDrawerEvents.enums";
import { MtxDrawer } from "@ng-matero/extensions/drawer";
import {
  FilterJobCategoryType,
  FilterOption,
  FilterParameter,
  JobOverview,
  advSearchParam,
  iFieldMultiSelect,
  iFieldMultiSelectOutPut,
} from "src/app/shared/models/filter.model";
import { UserService } from "src/app/user/user-root/user-root.service";
import { iEmployerJobModel, iJobCardModel } from "src/app/shared/models/job.model";
import { OnboardingRootService } from "src/app/user/onboarding/onboarding-root/onboarding-root.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-search-candidates-pane",
  templateUrl: "./search-candidates-pane.component.html",
  styleUrls: ["./search-candidates-pane.component.scss"],
})
export class SearchCandidatesPaneComponent implements OnInit, OnChanges, OnDestroy {
  // searchTabs = [
  //   { value: "Search", label: "Search", emptyMsg: "" },
  //   { value: "Followed", label: "Followed By", emptyMsg: "" },
  //   { value: "Saved", label: "Saved", emptyMsg: "" },
  // ];
  selectedMenuTab = new FormControl(this._service.applicantSearchChildTab);
  @Input() isLoading: boolean = true;
  @Input() isSearching: boolean = false;
  @Input() queriedCandidates: iCandidateFindModel;
  @Input() savedCandidates: iApplicantListModel[];
  @Input() querySharedCandidate: iCandidateFindModel;
  @Input() followedByCandidates: iApplicantListModel[];
  @Input() orderOfCategories: FilterParameter[];
  @Input() isUsingAISearch: boolean = false;
  @Output() orderOfCategoriesEvent = new EventEmitter<FilterParameter[]>();
  @Output() pagedEvent = new EventEmitter<PagedEventCandidates>();
  @Output() saveCandidateId = new EventEmitter<string>();
  @Output() filterCandidates = new EventEmitter<iFieldMultiSelectOutPut>();
  @Output() filterCandidatesParams = new EventEmitter<advSearchParam>();
  @Output() deleteKeyWord = new EventEmitter<boolean>();
  @Output() searchKeyWord = new EventEmitter<{ keywords: string }>();
  private subscription: Subscription;
  aiSearchedJobTitle: string = "";

  filterCount: number = 0;
  keywordQuery: string;
  locationQuery: string;
  previousPageIndex: number = -1;
  isPriortized: boolean = false;
  fullParam: string = "";
  allFields: FilterParameter[] = [];
  selectedAutoPopulateCategories: FilterParameter = undefined;
  selectedJob: iEmployerJobModel;

  constructor(
    private _service: EmployerOnboardingService,
    public dialog: MatDialog,
    private drawer: MtxDrawer,
    private _usrService: UserService
  ) {}

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.clearFilters();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.querySharedCandidate && "querySharedCandidate" in changes) {
      this.queriedCandidates = this.querySharedCandidate;
    }
  }

  ngOnInit(): void {
    this.subscription = this._service.sideDrawerEvent.subscribe((val) => {
      if (val === sideDrawerEvents.clearCandidateFilter) {
        this.clearFilters();
        this._service.sideDrawerEvent.next("");
      }
    });

    if (!this._service.bullhornData.savedCandidateIds) {
      this._service.bullhornData.savedCandidateIds = [];
    }

    if (this._service.searchForCandidateBtnClicked === true) {
      this.selectedAutoPopulateCategories = {
        category: this._service.advSearchParam.jobOverview.jobTitle,
        filters: this._service.advSearchParam.selectedFilters,
        selectedCount: this._service.advSearchParam.selectedFilters.length,
      };
      this._service.searchForCandidateBtnClicked = false;
      // this.open();
      this.applyAIFilters(this._service.advSearchParam);
      this.setFilterCount();
    }

    //this.setSavedCandidates();

    //Update saved list when tab changes (or on toggle)
    this.selectedMenuTab.valueChanges.subscribe((x) => {
      //this.setSavedCandidates();
    });
  }

  clearFilters() {
    this._service.advSearchParam = {
      keywords: null,
      selectedFilters: [],
      jobOverview: null,
      start: 0,
      count: 20,
    };

    this.selectedAutoPopulateCategories = null;
    this.setFilterCount();
  }

  clickSearchBtn(event: { keywords: string }) {
    this.searchKeyWord.emit(event);
  }

  deleteKeyWords(event: boolean) {
    this.deleteKeyWord.emit(event);
  }

  getAllSelectedValuesPerJobs(): FilterParameter[] {
    let allJobs: FilterParameter[] = [];
    for (let job of this._service._getAllJobs) {
      let jobParameter: FilterJobCategoryType = { category: "", filters: [], selectedCount: 0, id: job.id };
      jobParameter.category = job.title;
      let selectedFields = this._service.createSelectedFieldBasedOnJob(job);
      jobParameter.selectedCount = selectedFields?.length;
      jobParameter.filters = selectedFields;
      allJobs.push(jobParameter);
    }
    return allJobs;
  }

  get _selectedAIJobMatch(): string {
    return this.aiSearchedJobTitle;
  }

  private get _getCompanySavedCandidates(): string[] {
    return this._service.bullhornData.savedCandidateIds ? this._service.bullhornData.savedCandidateIds : [];
  }
  get _getTabs(): iTabDetails[] {
    return this._service.candidateTabs;
  }

  get _getListByTab(): iApplicantListModel[] {
    switch (this.selectedMenuTab.value) {
      case eFindCandidateTabs.Saved:
        return this.savedCandidates;
      case eFindCandidateTabs.Followed:
        return this.followedByCandidates;
      case eFindCandidateTabs.Search:
      default:
        return this.queriedCandidates.candidates;
    }
  }

  // private setSavedCandidates() : void {
  //   this.filteredSavedCandidates.splice(0);
  //   this.queriedCandidates?.candidates
  //       .map((candidate: iApplicantModel) =>
  //         ((this._getCompanySavedCandidates.includes(candidate.id))
  //           ? this.filteredSavedCandidates.push(candidate)
  //           : this.queriedCandidates?.candidates.slice()));
  // }

  toggleSaveCandidate(id: string) {
    this.saveCandidateId.emit(id);
  }

  setFilterCount(): void {
    this.filterCount = this._service.advSearchParam.jobOverview != null ? 1 : 0;
    for (let item of this._service.advSearchParam.selectedFilters) {
      for (let filter of item.data) {
        this.filterCount += 1;
      }
    }
  }

  get _hasQueriedResults(): boolean {
    return this.queriedCandidates?.candidates?.length >= 1;
  }

  get _hasSavedCandidates(): boolean {
    return this.savedCandidates?.length > 0;
  }

  get _getSavedCandidates(): iApplicantListModel[] {
    return this.savedCandidates?.length > 0 ? this.savedCandidates : [];
  }

  get _hasFollowingCandidates(): boolean {
    return this.followedByCandidates?.length > 0;
  }

  get _isSearching(): boolean {
    if (this.isSearching && this._service.applicantSearchChildTab !== eFindCandidateTabs.Search) {
      this._service.applicantSearchChildTab = eFindCandidateTabs.Search;
      this.selectedMenuTab.setValue(eFindCandidateTabs.Search);
    }

    return this.isSearching;
  }

  get _isViewingSearchTab(): boolean {
    return this.selectedMenuTab.value == eFindCandidateTabs.Search;
  }
  get _isViewingSavedTab(): boolean {
    return this.selectedMenuTab.value == eFindCandidateTabs.Saved;
  }
  get _isViewingFollowTab(): boolean {
    return this.selectedMenuTab.value == eFindCandidateTabs.Followed;
  }

  _setCandidateSearchMenuTabIndex(idx: number): void {
    this.selectedMenuTab.setValue(idx);
    this._service.applicantSearchChildTab = idx;
  }

  getSavedCandidates() {
    if (this._service.bullhornData.savedCandidateIds?.length > 0) {
      //TODO: query Bullhorn and pass list of ids
    }
  }

  get _getPageSize(): number {
    return this.queriedCandidates.pageSize;
  }
  get _getPageIndex(): number {
    return this.queriedCandidates.pageIndex;
  }
  get _getTotalSize(): number {
    return this.queriedCandidates.totalCandidates;
  }

  //#region Demo https://v15.material.angular.io/components/paginator/examples
  _pageIndex = 0;
  _pageSizeOptions = [20]; //not currently being used - backend always returns 20 for now.
  _showPageSizeOptions = false;
  _hidePageSize = true;
  _showFirstLastButtons = true;
  pageEvent: PageEvent;
  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.previousPageIndex = e.previousPageIndex;
    this.queriedCandidates.pageIndex = e.pageIndex;
    this.queriedCandidates.pageSize = e.pageSize;
    this._pageIndex = e.pageIndex;
    const event: PagedEventCandidates = {
      // make this be LLM with pageEvent
      pageEvent: e,
      candidateFilters: this._service.selectedCandidateFiltersOutput.selectedFields,
      selectedJob: this._service.selectedCandidateFiltersOutput.selectedAutoPopulateField,
      AIFilterParams: this._service.advSearchParam,
    };
    this._service.employerRootSideNavContent.next(this._service.nextPage);
    this.pagedEvent.emit(event);
    Logger.debug("handlePageEvent", e);
  }
  //#endregion Demo

  applyAIFilters(outPutlVal: advSearchParam) {
    this.aiSearchedJobTitle = this._service.advSearchParam?.jobOverview?.jobTitle;
    this.filterCandidatesParams.emit(outPutlVal);
    this.orderOfCategoriesEvent.emit(this.orderOfCategories);
  }

  userPriortizedJobMatches(allFields: FilterParameter[], job: iEmployerJobModel) {
    let priortization = { jobDescription: 64, title: 32, education: 0 };
    let selectedFields = allFields.filter((param) => param.selectedCount > 0);
    let totalSelectedFields = selectedFields.length;
    let totalScore = 0;
    for (let i = 0; i < totalSelectedFields; i++) {
      totalScore += i + 1;
    }

    let total = 70;
    if (job.educationDegree && job.educationDegree.length > 0) {
      priortization["education"] = 8;
      total -= 8;
    }
    priortization["skills"] = 0;
    priortization[eFilterCategoryTitle.Experience] = 0;
    priortization[eFilterCategoryTitle.Location] = 0;
    priortization[eFilterCategoryTitle.EmploymentType] = 0;
    priortization[eFilterCategoryTitle.Compensation] = 0;

    for (let item of selectedFields) {
      let val = (totalSelectedFields / totalScore) * total;
      if (item.category == eFilterCategoryTitle.JobCategory) {
        priortization["skills"] = val;
      } else {
        priortization[item.category] = val;
      }
      totalSelectedFields -= 1;
    }
    return priortization;
  }

  open() {
    this._service.getAllCategorySkills();
    const drawerRef = this.drawer.open(SideDrawerRootComponent, {
      width: "380px",
      data: {
        displayContentType: DisplayContentType.CandidateFilter,
        title: "Advance Search",
        selectedFields: this._service.advSearchParam.selectedFilters,
        isPriortized: this.isPriortized,
        categories: this.isPriortized ? this.allFields : this._service.getAllCategoryFields(),
        jobSelectedFields: this.getAllSelectedValuesPerJobs(),
        selectedAutoPopulateCategories: this.selectedAutoPopulateCategories,
        primaryBtn: { btnName: "Apply", eventName: sideDrawerEvents.applyCandidateFilter },
        secondaryBtn: { btnName: "Clear", eventName: sideDrawerEvents.clearCandidateFilter },
      },
    });

    drawerRef.afterDismissed().subscribe((result) => {
      if (result?.returnValue) {
        this._service.advSearchParam = this.setupAIFilterCandidateParams(result.returnValue);
        this._service.selectedCandidateFiltersOutput = result?.returnValue; // Defenitly remove

        // Update Filters:
        this.isPriortized = result?.returnValue.isPrioritized;
        this.allFields = result?.returnValue.allFields;
        this.orderOfCategories = result?.returnValue.allFields;
        this.selectedAutoPopulateCategories = result?.returnValue.selectedAutoPopulateField;
        this.applyAIFilters(this._service.advSearchParam);
        this.setFilterCount();
      }
    });
  }

  setupAIFilterCandidateParams(result: iFieldMultiSelectOutPut): advSearchParam {
    if (result?.selectedAutoPopulateField) {
      this.selectedJob = this._service._getAllJobs.find((job) => job.id == result?.selectedAutoPopulateField.id);
      var jobOverview: JobOverview = {
        customSkills: this.selectedJob.combinedSkills.customSkills.map((skill) => skill.display),
        certifications: this.selectedJob.certsList.items.map((item) => item.txt),
        education: this.selectedJob.educationDegree,
        jobTitle: this.selectedJob.title,
        jobDescription: this.selectedJob.description,
        bouloSkills: this._service.setupBouloSkills(this.selectedJob),
        industryFields: this._service.setupIndustryFields(this.selectedJob),
      };

      var param: advSearchParam = {
        keywords: "",
        selectedFilters: result?.selectedFields,
        jobOverview: jobOverview,
        start: 0,
        count: 20,
      };
      return param;
    } else {
      this.selectedJob = null;
      var param: advSearchParam = {
        keywords: "",
        selectedFilters: result?.selectedFields,
        jobOverview: null,
        start: 0,
        count: 20,
      };
      return param;
    }
  }

  viewCandidate(candidate: iCandidateIdSubmissionOptional) {
    const dialogRef = this.dialog.open(DialogApplicantDetailsComponent, {
      width: "800px",
      data: {
        candidateList: this._getListByTab,
        selectedId: candidate.candidateId,
        parentcomponent: this,
      },
    });
  }
}

export interface PagedEventCandidates {
  pageEvent: PageEvent;
  candidateFilters: iFieldMultiSelect[];
  selectedJob: FilterParameter;
  AIFilterParams: advSearchParam;
}

export enum eFilterCategoryTitle {
  JobCategory = "Job Category",
  Location = "Location",
  EmploymentType = "Employment Type",
  Experience = "Experience",
  Compensation = "Compensation",
}

export enum eJobSkillsCategoryFilters {
  JobSkillsCategory = "Job Skills Categories",
  CategorySkills = "Category Skills",
  CustomSkills = "Custom Skills",
}

export enum eLocationFilters {
  LocationPreference = "Location Preference",
  State = "State",
  City = "City",
  Zip = "Zip",
}

export enum eEmploymentTypeFilters {
  EmploymentType = "Employment Type",
  WorkingHours = "Working Hours",
}

export enum eExperienceFilters {
  Experience = "Experience",
}

export enum eCompensationFilters {
  CompensationType = "Compensation Type",
  Salary = "Salary",
  Hourly = "Hourly",
  Contract = "Contract",
}
