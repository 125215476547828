import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BouloPoll } from "src/app/services/send-bird.service";

@Component({
  selector: "app-poll-dialog",
  templateUrl: "./poll-dialog.component.html",
  styleUrls: ["./poll-dialog.component.scss"],
})
export class PollDialogComponent {
  selectedOptionIndex: number | null = null;
  title: string;

  constructor(
    public dialogRef: MatDialogRef<PollDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { poll: BouloPoll }
  ) {
    const selectedJob = data.poll.selectedJob !== "" ? `for ${data.poll.selectedJob}` : "";
    this.title = `${data.poll.title} ${selectedJob}`;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
