import { Component, OnInit, ViewEncapsulation, OnDestroy } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { SecurityService, Login } from "src/app/services/security.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: UntypedFormGroup;
  errorMessage: string = null;
  displayPassword: boolean;
  expirationModal: boolean;
  placeholderImg: string = 'url("../../../assets/img/login/login.jpg") no-repeat';

  constructor(
    private securityService: SecurityService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    document.getElementsByTagName("body")[0].classList.add("background-image");
    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
      password: new UntypedFormControl(null, [Validators.required]),
    });
    this.route.queryParams.subscribe((params) => {
      this.expirationModal = Boolean(params["expirate"]) || false;
    });
  }

  ngOnDestroy(): void {
    document.getElementsByTagName("body")[0].classList.remove("background-image");
  }

  onSubmit(): void {
    this.securityService
      .login(new Login(this.loginForm.controls.email.value, this.loginForm.controls.password.value))
      .subscribe(
        (Response) => {
          // Do nothing, all handled from the inside...
        },
        (errorMessage) => {
          if (errorMessage == "You have to confirm your email address before continuing.") {
            this.securityService.confirmationEmailSent = true;
            this.securityService.emailToSendConfirmation = this.loginForm.controls.email.value;
          }
          this.errorMessage = errorMessage;
        }
      );
  }

  toggleDisplayPassword() {
    this.displayPassword = !this.displayPassword;
  }

  onCloseDialogBox() {
    this.expirationModal = false;
  }
}
