<div class="container-fluid login-hero-image" id="mainScreen">
  <div id="login" class="col-lg-5 col-md-12 text-center">
    <div class="shadow rounded bg-white">
      <!-- <a routerLink="/"> -->
      <img class="logo" src="assets/svg/Logo.svg" />
      <!-- </a> -->
      <p class="title-9 dark-color">Sign In</p>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="w-100">
          <div class="row login-form w-100 px-5">
            <div class="form-group text-left w-100">
              <label for="email">Email</label>
              <input
                type="email"
                formControlName="email"
                class="form-control flex-grow-2"
                placeholder="Email Address"
                tabindex="1"
                [ngClass]="{ 'error-box': !loginForm.get('email').valid && loginForm.get('email').touched }"
              />
              <div
                class="text-danger help-block error-seccion form-text"
                *ngIf="!loginForm.get('email').valid && loginForm.get('email').touched"
              >
                <img src="assets/svg/error-icon.svg" />
                <span *ngIf="loginForm.get('email').errors.required" class="color-danger">Required</span>
                <span *ngIf="loginForm.get('email').errors.email" class="color-danger">Please enter a valid email</span>
              </div>
            </div>
            <div class="form-group text-left w-100">
              <label for="email">Password</label>
              <div
                class="input-group"
                [ngClass]="{ 'error-box': !loginForm.get('password').valid && loginForm.get('password').touched }"
              >
                <div class="input-group">
                  <input
                    [type]="displayPassword ? 'text' : 'password'"
                    formControlName="password"
                    class="form-control flex-grow-2"
                    placeholder="Password"
                    tabindex="2"
                  />
                  <div class="input-group-append cursor-pointer" (click)="toggleDisplayPassword()">
                    <span class="input-group-text no-border">
                      <i class="fa" [ngClass]="{ 'fa-eye-slash': !displayPassword, 'fa-eye': displayPassword }"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="text-danger help-block error-seccion form-text"
                *ngIf="!loginForm.get('password').valid && loginForm.get('password').touched"
              >
                <img src="assets/svg/error-icon.svg" />
                <span class="color-danger" *ngIf="loginForm.get('password').errors.required">Required</span>
              </div>
            </div>
            <div class="text-left forgot-password summary-9">
              <a routerLink="/forgotpassword" class="nav-link btn-link-primary" tabindex="4">Forgot Password?</a>
            </div>
          </div>
          <div class="py-1 px-5">
            <button type="submit" [disabled]="!loginForm.valid" class="btn btn-primary px-5 w-100" tabindex="3">
              Log In
            </button>
            <p class="text-center my-3 no-account">
              New to Boulo?
              <a class="btn btn-link btn-link-primary join-now padding-left-0" routerLink="/signup">Sign Up Here</a>.
            </p>
            <div class="text-center mt-5 terms-and-conditions">
              By signing up, I agree to the
              <a href="https://boulosolutions.com/privacy-policy/" target="_blank" rel="noopener noreferrer"
                >Privacy Policy</a
              >
              and
              <a href="https://boulosolutions.com/terms-of-service/" target="_blank" rel="noopener noreferrer"
                >Terms and Conditions</a
              >
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <app-hero-text></app-hero-text>
</div>
<app-expiration-modal *ngIf="expirationModal" (closeExpired)="onCloseDialogBox()"></app-expiration-modal>
<app-alert [message]="errorMessage" *ngIf="errorMessage !== null" (close)="errorMessage = null"></app-alert>
