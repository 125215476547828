import { Component, OnInit } from "@angular/core";
import { HomeService } from "../../services/home.service";
import { LoggerService as Logger } from "src/app/shared/services/logger.service";

@Component({
  selector: "app-press",
  templateUrl: "./press.component.html",
  styleUrls: ["./press.component.scss"],
})
// Route to this component was removed 01/2024
export class PressComponent implements OnInit {
  pressArticles: Array<any> = [];
  constructor(private homeService: HomeService) {}

  ngOnInit(): void {
    this.getPressReleases();
  }

  getPressReleases(): void {
    this.homeService.pressReleases().subscribe(
      (res: any) => {
        this.pressArticles = res.data;
      },
      (errorMessage) => {
        Logger.log(errorMessage);
      }
    );
  }
}
