import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { SecurityService } from "./security.service";

@Injectable({ providedIn: "root" })
export class SecurityGuard {
  constructor(
    private router: Router,
    private user: SecurityService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (!this.user.IsLoggedIn) {
      return this.router.navigate(["/login"], {
        queryParams: { expirate: true, returnUrl: state.url },
      });
    }

    return true;
  }
}
