import { eJobCardStatus } from "src/app/user/employer/dashboard/job-posting-card/job-posting-card.component";
import { iJobInfiniteScroll } from "src/app/user/jobs/search-jobs.service";
import { eJobCardBusyType, eJobCardViewer } from "../enums/shared.enums";
import { iAddress, iChipInfo, iClientCorporation, iCompanyLogo, iCustomSkill } from "./shared.models";

export interface JobModel {
  id: string;
  title: string;
  clientCorporation: iClientCorporation;
  //MISSING contact?
  address: iAddress | null;
  hoursPerWeek: number;

  description: string;
  publicDescription: string | null;
  employmentType: string;
  flexibility: string[]; //i.e. workingHours?
  skills: { data: iJobSkill[] };

  datePosted: Date | null;
  dateAdded: Date | null;

  hourlyRate: number | null; //payRate
  exactRate: string;
  compensationLow: number | null; //salary
  compensationHigh: number | null;
  benefits: string[];
  onsite: string;
  isOnSiteRequired: boolean;
  compensationType: string;
  arrangementType: string; //remote, onsite, hybrid
  arrangementSplit: string;
  yearsRequired: number;
  isExperienceRequired: number | null;
  isEducationRequired: number | null;
  educationDegree: string;
  certifications: string;
  workingLocationZip: string;
  combinedSkills: iCombinedSkills;

  appCreatedJob: boolean | null;
  status: eJobCardStatus | string; //jobStatus: i.e. Active,Draft,Closed,Archived/Deleted (Bullhorn: AcceptingCandidates == Active)

  dutiesList: iJob_DetailsList | null;
  certsList: iJob_DetailsList | null;
  idealFitList: iJob_DetailsList | null;

  //Fields below are calculated, not innately received
  saved: boolean | null;
  applied: boolean | null;
}

export interface iEmployerJobModel extends JobModel {
  locationZip: string; //address.zip
  screening: iJob_Screening;
  submissionIDs: number[];
  numViews: number | null;
  numSubmissions: number | null;
  newApplicant: boolean | null;
  submissions: any | null;
}

//Model for Employers on the List view of Applicants/Candidates
export interface iJobCardModel extends JobModel {
  highlightsChips: iChipInfo[] | null;
  requirementsChips: iChipInfo[] | null;
  skillChips: iChipInfo[] | null;
  keyTermsChips: iChipInfo[] | null;
  footerChips: iChipInfo[] | null;
  isBouloCreatedJob: boolean | null;
  hasDescriptionText: boolean | null;
  jobDescriptionText: string | null;
  hasDetailsSet: boolean | null;
  daysSincePosted: number | null;
  logo: iCompanyLogo | null;
  isFollowingCompany: boolean | null;

  extractedPublicDetails: ExtractedPublicDetails[] | null;
}

export interface iJobListToCardModel {
  job?: iJobCardModel;
  loadingStatus: eJobCardBusyType;
  listSelectedJobId: string;
  viewer: eJobCardViewer;
}

export interface iCombinedSkills {
  basicSkills: string[];
  categorySpecificSkills: { [skillKey: string]: string };
  skillCategories: string[];
  customSkills?: iCustomSkill[];
}
export interface ExtractedDetails {
  category: string;
  summary: string;
  highlightedData: string[];
  listItems: string[];
  hasList: boolean;
}

interface ExtractedPublicDetails {
  [key: string]: ExtractedDetails[];
}

export interface iJobSkill {
  id: string;
  name: string;
}

export interface iJob_DetailsList {
  summaryText: string | null;
  items: iJob_DetailsListItem[] | null;
}

export interface iJob_DetailsListItem {
  txt: string | null;
  isRequired: boolean | null;
}

export interface iJob_Education {
  educationLevel: string;
  isRequired: boolean;
  certificates: string;
}

export interface iJob_Experience {
  yearsExperience: number;
  isRequired: boolean;
}

export interface iJob_Screening {
  questions: iQuestion[];
  //what else can we put here?
}

export interface iAnswer {
  text: string;
  value: string;
  isCorrect?: boolean;
}

export interface iQuestion {
  category: string; //HR, Candidate, Custom
  title: string; //Background Check, Interview, Travel, etc.
  format: string; //free-form, date, yes/no
  isActive: boolean;
  question: string;
  answers?: iAnswer[];
}

export enum eJobLogoStatus {
  Unchecked = 0,
  HasLogo = 1,
  NoLogo = 2,
}

export enum eJobCardAction {
  Selected = 0,
  Apply = 1,
  // NavigateTo = 2,
  FollowCompany = 3,
  Save = 4,
  Unsave = 5,
  LoadMoreJobs = 6,
  ViewCompany = 7,
}

export interface iJobCardAction {
  action: eJobCardAction;
  job: iJobCardModel | null;
  scrollObj?: iJobInfiniteScroll;
}
