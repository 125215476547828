import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AlertComponent } from "./alert/alert.component";
import { PlaceholderDirective } from "./placeholder/placeholder.directive";
import { CarouselGoalsComponent } from "./carousel-goals/carousel-goals.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { httpCustomInterceptor } from "../../services/http-custom-interceptor.service";
import { OnboardingItemsContainerCheckComponent } from "./onboarding-items-container-check/onboarding-items-container-check.component";
import { SimpleItemCheckComponent } from "./simple-item-check/simple-item-check.component";
import { ProgressCircleComponent } from "./progress-circle/progress-circle.component";
import { NgCircleProgressModule } from "ng-circle-progress";
import { MomentPipe } from "../pipes/moment.pipe";
import { AlertCheckIconComponent } from "./alert-check-icon/alert-check-icon.component";
import { DefaultEmptyNAPipe } from "../pipes/default-empty-na.pipe";
import { SearchInputComponent } from "./search-input/search-input.component";
import { FormInputComponent } from "./form-input/form-input.component";
import { ReactiveFormsModule } from "@angular/forms";
import { SpinnerComponent } from "./spinner/spinner.component";
import { MultiselectorWidgetComponent } from "./multiselector-widget/multiselector-widget.component";
import { PopoverComponent } from "./popover/popover.component";
import { MaterialModule } from "../material.module";
// import { SortableListComponent } from "./sortable-list/sortable-list.component";
import { FormsModule } from "@angular/forms";
import { ChipMultiSelectionComponent } from "./chip-multi-selection/chip-multi-selection.component";

@NgModule({
  imports: [
    ReactiveFormsModule,
    CommonModule,
    NgCircleProgressModule.forRoot({
      radius: 60,
      space: -15,
      outerStrokeWidth: 15,
      innerStrokeWidth: 15,
      outerStrokeColor: "#7883E8",
      innerStrokeColor: "#e7e8ea",
      title: "UI",
      animateTitle: false,
      animationDuration: 1000,
      showUnits: false,
      showBackground: false,
      showTitle: false,
      showSubtitle: false,
      showImage: true,
      clockwise: true,
      startFromZero: false,
      outerStrokeLinecap: "butt",
      imageHeight: 80,
      imageWidth: 80,
    }),
    // MatFormFieldModule,
    // MatInputModule,
    MaterialModule,
    FormsModule,
  ],
  declarations: [
    AlertComponent,
    AlertCheckIconComponent,
    PlaceholderDirective,
    SearchInputComponent,
    CarouselGoalsComponent,
    OnboardingItemsContainerCheckComponent,
    SimpleItemCheckComponent,
    ProgressCircleComponent,
    FormInputComponent,
    MomentPipe,
    DefaultEmptyNAPipe,
    SpinnerComponent,
    MultiselectorWidgetComponent,
    PopoverComponent,
    ChipMultiSelectionComponent,
    AlertComponent,
    // SortableListComponent,
    // MatSelectModule,
    // MatIconModule,
    // MatChipsModule,
    // MatInputModule,
    // MatFormFieldModule
  ],
  exports: [
    AlertComponent,
    AlertCheckIconComponent,
    PlaceholderDirective,
    CarouselGoalsComponent,
    OnboardingItemsContainerCheckComponent,
    SearchInputComponent,
    SimpleItemCheckComponent,
    ProgressCircleComponent,
    MomentPipe,
    FormInputComponent,
    DefaultEmptyNAPipe,
    SpinnerComponent,
    MultiselectorWidgetComponent,
    PopoverComponent,
    MaterialModule,
    ChipMultiSelectionComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: httpCustomInterceptor,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
