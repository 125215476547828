<div class="container-fluid signup-hero-image" id="mainScreen">
  <div id="signup" class="col-lg-5 col-md-12 text-center">
    <div class="shadow rounded bg-white px-5">
      <img class="logo" src="assets/svg/Logo.svg" />
      <form [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
        <div class="row justify-content-center">
          <div class="form-group text-left w-100">
            <label for="role">Select Role</label>
            <select class="form-control" [ngClass]="setBoxBorder('role')" formControlName="role">
              <option value="user">Candidate</option>
              <option value="employer">Employer</option>
              <option value="both">Both</option>
            </select>
            <div
              class="text-danger help-block error-seccion"
              *ngIf="!signUpForm.get('role').valid && signUpForm.get('role').touched"
            >
              <img src="assets/svg/error-icon.svg" />

              <span class="color-danger">Required</span>
            </div>
          </div>
          <div class="form-group text-left w-100">
            <label for="first_name">First Name</label>
            <input
              type="text"
              formControlName="first_name"
              class="form-control"
              placeholder="First Name"
              [ngClass]="setBoxBorder('first_name')"
              (keyup)="passwordContainsPersonalInfo()"
            />
            <div
              class="text-danger help-block error-seccion form-text"
              *ngIf="!signUpForm.get('first_name').valid && signUpForm.get('first_name').touched"
            >
              <img src="assets/svg/error-icon.svg" />
              <span class="color-danger">Required</span>
            </div>
          </div>
          <div class="form-group text-left w-100">
            <label for="last_name">Last Name</label>
            <input
              type="text"
              formControlName="last_name"
              class="form-control"
              placeholder="Last Name"
              [ngClass]="setBoxBorder('last_name')"
              (keyup)="passwordContainsPersonalInfo()"
            />
            <div
              class="text-danger help-block error-seccion"
              *ngIf="!signUpForm.get('last_name').valid && signUpForm.get('last_name').touched"
            >
              <img src="assets/svg/error-icon.svg" />
              <span class="color-danger">Required</span>
            </div>
          </div>
          <div class="form-group text-left w-100" *ngIf="isEmployerRole">
            <label for="last_name">Company Name</label>
            <input
              type="text"
              formControlName="company_name"
              class="form-control"
              placeholder="Company Name"
              [ngClass]="setBoxBorder('company_name')"
            />
            <div
              class="text-danger help-block error-seccion"
              *ngIf="!signUpForm.get('company_name').valid && signUpForm.get('company_name').touched"
            >
              <img src="assets/svg/error-icon.svg" />
              <span class="color-danger">Required</span>
            </div>
          </div>
          <div class="form-group text-left w-100">
            <label for="email">Email</label>
            <input
              type="email"
              formControlName="email"
              class="form-control"
              placeholder="Email Address"
              [ngClass]="setBoxBorder('email')"
              (keyup)="passwordContainsPersonalInfo()"
            />
            <div
              class="text-danger help-block error-seccion"
              *ngIf="!signUpForm.get('email').valid && signUpForm.get('email').touched"
            >
              <img src="assets/svg/error-icon.svg" />
              <span *ngIf="signUpForm.get('email').errors.required" class="color-danger">Required</span>
              <span *ngIf="signUpForm.get('email').errors.email" class="color-danger">Please enter a valid email</span>
            </div>
          </div>
          <div class="form-group text-left w-100">
            <label for="emailConfirm">Confirm Email</label>
            <input
              type="email"
              formControlName="emailConfirm"
              class="form-control"
              [ngClass]="setBoxBorder('emailConfirm')"
              placeholder="Confirm Email Address"
            />
            <div
              class="text-danger help-block error-seccion"
              *ngIf="!signUpForm.get('emailConfirm').valid && signUpForm.get('emailConfirm').touched"
            >
              <img src="assets/svg/error-icon.svg" />
              <div class="error-section-detail">
                <span *ngIf="signUpForm.get('emailConfirm').errors.required" class="color-danger">Required</span>
                <span *ngIf="signUpForm.get('emailConfirm').errors.email" class="color-danger"
                  >Please enter a valid email</span
                >
                <span *ngIf="signUpForm.get('emailConfirm').errors.notSame" class="color-danger">
                  Email does not match
                </span>
              </div>
            </div>
          </div>
          <div class="form-group text-left w-100">
            <label for="password">Password</label>
            <input
              type="password"
              formControlName="password"
              class="form-control"
              placeholder="Set Password"
              [ngClass]="setBoxBorder('emailConfirm')"
              minlength="14"
              maxlength="20"
              (input)="eventOnChangePassword($event.target.value)"
            />
            <div
              class="text-danger help-block error-seccion"
              *ngIf="!signUpForm.get('password').valid && signUpForm.get('password').touched"
            >
              <img src="assets/svg/error-icon.svg" />
              <span class="color-danger" *ngIf="signUpForm.get('password').errors.required">Required</span>
              <span class="color-danger" *ngIf="signUpForm.get('password').errors.minlength"
                >Min length 14 characters</span
              >
              <span class="color-danger" *ngIf="signUpForm.get('password').errors.number"
                >Must contain at least one number</span
              >
              <span class="color-danger" *ngIf="signUpForm.get('password').errors.lowerLetter"
                >Must contain at least <br />
                one lowercas letter</span
              >
              <span class="color-danger" *ngIf="signUpForm.get('password').errors.capitalLetter"
                >Must contain at least <br />
                one capital letter</span
              >
              <span class="color-danger" *ngIf="signUpForm.get('password').errors.special"
                >Must contain at least
                <br />
                one of these special characters ! # @ $ % &</span
              >

              <span class="color-danger" *ngIf="signUpForm.get('password').errors.containsPersonalInfo"
                >Must NOT contain
                <br />
                personal Info: First name, Last name, Email</span
              >

            </div>
          </div>
          <div class="form-group text-left w-100">
            <label for="confirmPassword">Confirm Password</label>
            <input
              type="password"
              formControlName="confirmPassword"
              class="form-control"
              placeholder="Confirm Password"
              [ngClass]="setBoxBorder('confirmPassword')"
            />
            <div
              class="text-danger help-block error-seccion"
              *ngIf="
                (!signUpForm.get('confirmPassword').valid && signUpForm.get('confirmPassword').touched) ||
                (signUpForm.get('password').touched &&
                  signUpForm.get('confirmPassword').touched &&
                  signUpForm.get('password').value !== signUpForm.get('confirmPassword').value)
              "
            >
              <img src="assets/svg/error-icon.svg" />
              <span
                class="color-danger"
                *ngIf="!signUpForm.get('confirmPassword').valid && signUpForm.get('confirmPassword').errors.required"
                >Required</span
              >
              <span
                class="color-danger"
                *ngIf="
                  !(!signUpForm.get('confirmPassword').valid && signUpForm.get('confirmPassword').errors.required) &&
                  signUpForm.get('password').value !== signUpForm.get('confirmPassword').value
                "
                >Passwords don't match</span
              >
            </div>
          </div>
        </div>
        <div class="p-1">
          <button type="submit" class="btn btn-primary px-5 w-100" [disabled]="!canSubmit">
            <!-- [disabled]="!signUpForm.valid || signUpForm.get('password').value !== signUpForm.get('confirmPassword').value"> -->
            Create Account
          </button>
          <p class="text-center my-1 already-member">
            Already have an account?
            <button routerLink="/login" class="btn btn-link btn-link-primary padding-left-0 padding-right-0">
              Log In
            </button>
          </p>

          <div class="text-center mt-2 terms-and-conditions">
            By signing up, I agree to the
            <a href="https://boulosolutions.com/privacy-policy/" target="_blank" rel="noopener noreferrer"
              >Privacy Policy</a
            >
            and
            <a href="https://boulosolutions.com/terms-of-service/" target="_blank" rel="noopener noreferrer"
              >Terms and Conditions</a
            >
          </div>
        </div>
      </form>
    </div>
  </div>
  <app-hero-text></app-hero-text>
</div>
<app-alert [message]="errorMessage" *ngIf="errorMessage !== null" (close)="errorMessage = null"></app-alert>
