<section class="inbox-root-container">
  <mat-card class="inbox-card">
    <mat-card-header class="inbox-card-header">
      <mat-card-title class="inbox-card-title">Inbox</mat-card-title>

      <mat-checkbox
        *ngIf="isEmployer"
        class="checkbox-float-right"
        [(ngModel)]="messageDetails.sender.optInToEmail"
        (change)="optInChanged($event)"
      >
        Email Notifications
      </mat-checkbox>
      <app-mat-spinner-btn *ngIf="showDeleteButton">
        <button
          [disableRipple]="true"
          mat-raised-button
          (click)="deleteChannel(_currentChannel); $event.stopPropagation()"
          class="trash-button"
        >
          <mat-icon mat-button class="delete-icon">delete</mat-icon>
        </button>
      </app-mat-spinner-btn>
    </mat-card-header>
    <mat-card-content class="inbox-card-contents">
      <div class="channel-list-wrapper">
        <div class="channel-list">
          <div
            *ngFor="let channel of _channels"
            (click)="selectChannel(channel.name)"
            [class]="_isSelected(channel.channel_url) ? 'selected-channel-item' : 'channel-item'"
          >
            <span class="channel-label"
              >{{ isEmployer ? _getCandidateName(channel.name) : channel.created_by.nickname }}
            </span>
            <div class="unread-dot" *ngIf="channel.unread_message_count > 0"></div>
          </div>
        </div>
      </div>
      <div class="message-box-wrapper">
        <!-- isLoading -->
        <div *ngIf="isLoading" class="loading-wrapper">
          <app-mat-spinner-loading-screen
            [isLoading]="isLoading"
            [loadingMsg]="'Loading Chats...'"
          ></app-mat-spinner-loading-screen>
        </div>
        <div *ngIf="!isLoading && !_isChannelSelected && _hasChannels" class="empty-msg-wrapper">
          Select a chat to view messages.
        </div>
        <div *ngIf="!isLoading && !_hasChannels">
          <b>Your inbox is empty!</b> <br />
          {{ _noChatsMsg }}
        </div>
        <app-message-box
          *ngIf="!isLoading && _isChannelSelected"
          [receiver]="_deets.receiver"
          [sender]="_deets.sender"
          [senderName]="_deets.sender.name"
          [messages]="_deets.messages"
          [isEmployer]="isEmployer"
          [currentChannel]="_deets.selectedChannel"
          class="message-box-component"
        ></app-message-box>
      </div>
    </mat-card-content>
  </mat-card>
</section>
