<input
  [placeholder]="placeholder"
  class="share-search-input w-100 rounded-pill border"
  [value]="inputValue"
  [readonly]="isReadOnly"
  (input)="handleChange($event.target.value)"
/>
<div class="hourglass-container rounded-pill text-center pt-2">
  <img class="w-50 h-50" *ngIf="!inputValue" src="assets/svg/dashboard/Search_icon.svg" />
  <img class="w-50 h-50" *ngIf="inputValue" src="assets/svg/Close-default_icon.svg" (click)="handleChange('')" />
</div>
