<div class="alert-box text-center">
  <h2>Your session has expired</h2>
  <p class="message">Your ambition is waiting! Please Sign In again.</p>
  <p class="message"></p>
  <div class="alert-box-actions">
    <button class="btn btn-primary mt-4 px-4 py-2 rounded-pill font-weight-bold display-1" (click)="onClose()">
      Sign In
    </button>
  </div>
</div>
