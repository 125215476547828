export enum eCompensationTypes {
  Project = "Project",
  Salary = "Salary",
  Hourly = "Hourly",
  Unset = "",
}
export enum eApplicantRootTabs {
  ViewApplicants = 0,
  SearchCandidates = 1,
}
export enum eApplicantTabs {
  All = 0,
  Active = 1,
  Shortlisted = 2,
  Interviewing = 3,
  Hired = 4,
  Saved = 5,
}
export enum eFindCandidateTabs {
  Search = 0,
  Saved = 1,
  Followed = 2,
}
export enum eResponseMsg {
  Success = "Success",
  Error = "Error",
  InvalidParameters = "Invalid Parameters",
}
export enum eApplicantListType {
  AllApplicants = 0,
  SavedApplicants = 1,
  QueriedCandidates = 2,
  AllSavedCandidates = 3,
  FollowedByCandidates = 4,
}

export enum eEmployerTags {
  Paid_Member = "Paid Member",
  Company_Onboarding_Complete = "Company Onboarding Complete", //Company completed the company relevant onboarding
  FreeTrialSeed = "Seed Free trial", // Seed free trial Redeemed 
  FreeTrialGrow = "Grow Free trial", // Grow free trial Redeemed
}

export enum eEmployerContactTags {
  Contact_Onboarding_Complete = "Onboarding Complete", //user completed individual onboarding
}
