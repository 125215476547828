<app-header></app-header>
<div class="container-fluid" id="terms-conditions">
  <p class="title-3 text-center">Terms and Conditions</p>
  <div class="text">
    <p><strong>Boulo Terms of Use &#8211; Talent</strong></p>

    <p><strong>Introduction</strong></p>

    <p>
      Welcome to Boulo! We are excited to have you join us, but before we get started, we need you to read and agree to
      our Terms of Use below. Once you agree, we can get started on the quest for professional achievement without
      compromise<br />
    </p>

    <p>
      <strong
        >PLEASE READ THESE TERMS OF USE CAREFULLY, INCLUDING THE MANDATORY ARBITRATION PROVISION WHICH (IF YOU ARE
        RESIDENT IN THE USA OR ELSEWHERE IN THE WORLD) REQUIRES THAT DISPUTES ARE RESOLVED BY FINAL AND BINDING
        ARBITRATION ON AN INDIVIDUAL AND NOT A CLASS-WIDE OR CONSOLIDATED BASIS.</strong
      ><br />
    </p>

    <p>
      The Site located at www.boulosolutions.com (the “<strong>Site</strong>”) and all related services, software
      applications, and networks (together with the Site, the “<strong>Platform</strong>”) are provided by DCG Group,
      LLC (“<strong>BOULO</strong>”). The term <strong>&#8220;You&#8221; </strong>as used in this agreement will apply
      to the current visitor who browses, access or uses the Services of BOULO’s Platform on their own behalf or on
      behalf of an entity or organization. The term “<strong>User</strong>” refers to any other individual who accesses
      and uses the Services on their own behalf or on behalf of an entity or organization.
    </p>

    <p>By using the Platform you agree to the following:<br /></p>

    <ol>
      <li>
        <strong>Acceptance of the Terms of Use.</strong> &nbsp;These terms of use are between You and BOULO
        (collectively, the “<strong>Parties</strong>”). These terms of use contain the terms and conditions of the
        Platform and incorporate by reference the
        <a href="https://boulosolutions.com/privacy-policy/">Privacy Policy</a> and such other agreements and policies
        as BOULO may amend, revise, or post on the Site from time to time (collectively, these “<strong
          >Terms of Use</strong
        >”)
        <ol>
          <li>
            <strong>Who Can Use</strong>. This Platform is offered and available to Users who are 18 years of age or
            older and reside in the United States. By accessing, browsing or using the Platform, You or any User accepts
            all terms and conditions contained in the Terms of Use, as they create a binding contract between You and
            BOULO. If You are accessing any portion of the Platform for an organization, You agree to these Terms of Use
            on behalf of that organization.
          </li>
          <li>
            <strong>Use is Evidence of Acceptance.</strong> You should review the Platform periodically, as BOULO
            reserves the right to change, amend, or update the Site or the any portion of the Platform from time to
            time. Your continued use of the Platform following the posting of any revision to the Terms of Use means
            that You accept and agree to the changes. The Site and the Platform may only be accessed by Users who have
            accepted the Terms of Use.
          </li>
        </ol>
      </li>
      <li>
        <strong>The BOULO Platform.</strong> The mission of BOULO is to match professionals seeking work with companies
        looking for part-time, full-time and contract based workers to fill a specific need. In that regard, the
        Platform is where companies (each a “<strong>Company</strong>” and together, “<strong>Companies</strong>”)
        seeking Company-related services (the “<strong>Services</strong>”) connect with independent professional and
        consultant workers (the “<strong>Talent</strong>”) for placement services, including project-based staffing,
        interim and permanent staffing, and strategic advisory services. &nbsp;
        <ol>
          <li>
            <strong>Services.</strong> BOULO will perform for Company the Services in accordance with the these Terms of
            Use. If there is a conflict or ambiguity between any of the terms of these Terms of Use and any additional
            written agreements, the terms of the specific agreement will prevail for that instance only.
          </li>
          <li>
            <strong>BOULO Duties &amp; Responsibilities. &nbsp;</strong>During the term of these Terms of Use (the
            “<strong>Term</strong>”), BOULO will:
            <ol>
              <li>
                <strong>Background Checks</strong>. BOULO will procure background checks on Talent when matched with a
                Company from a third party of BOULO’s choice. Any additional verification of education, employment, or
                professional license is optional at the request of the Company and may be subject to additional fees;
              </li>
              <li>
                <strong>Payments</strong>. Subject to the payment terms set forth in Section 5 below and in the Fee
                Schedule, which is incorporated by reference, disburse payments between the Company and Talent;
              </li>
              <li>
                <strong>Compliance</strong>. Comply with applicable federal, state and local laws and regulations
                applicable to its performance of the Services as set forth in the Terms of Use and an applicable
                Purchase Order; and
              </li>
              <li>
                <strong>Election to file 1099K</strong>. If Company so elects, act as a third-party processor and file
                1099K forms to the IRS for Talent based in the United States.
              </li>
            </ol>
          </li>
          <li>
            <strong>Complete Scope of Services.</strong> Unless otherwise agreed to in writing by the Parties, the
            duties and responsibilities listed in this Section above describe BOULO’s complete scope of Services under
            these Terms of Use.<strong> </strong>
          </li>
        </ol>
      </li>
    </ol>

    <ol>
      <li>
        <strong>Responsibilities and Duties of the Talent. </strong>This Section outlines the responsibilities and
        duties of the Talent during the term of this agreement. (“You” refers to the Talent under this Section).
        <ol>
          <li>
            <strong>No Guarantee to be Listed.</strong> You Understand and agree that applying to be listed as Talent on
            BOULO’s platform does not guarantee that You will be permitted to use our Services. BOULO only selects
            Talent to be listed that meet our criteria and have paid all applicable fees. Such Fees are subject to
            change without notice. BOULO will make reasonable efforts to post those changes on the Platform.
          </li>
          <li>
            <strong>Selection.</strong> If You are selected to be placed as Talent on BOULO’s Platform and pay the
            applicable fee, You will be entitled to review Position Descriptions and will be provided with a means to
            contact Companies regarding those Position Descriptions.
            <ol>
              <li>
                <strong>Curation</strong>. BOULO provides curated matches for Talent and any Company and You understand
                that BOULO &nbsp;does not permit viewing of profiles and or Position Descriptions until a match has
                occured
              </li>
              <li>
                <strong>No Counseling Services.</strong> You understand and agree that BOULO does not provide job
                counseling or any other personal services.
              </li>
              <li>
                <strong>No Representation Made Regarding Position Descriptions. </strong>BOULO makes no representations
                as to the truth or accuracy of Position Descriptions and other information provided by our Users,
                including Companies, the existence of Position Descriptions for which You are qualified, that You will
                be considered for any Position by a Company, that You will receive an offer from any Company, the
                ability of Companies to pay You, the accuracy of any offer for a Position, or that a Company can or will
                actually complete a transaction.
              </li>
            </ol>
          </li>
          <li>
            <strong>You are not an Employee of BOULO.</strong> You further understand and agree that You are not an
            employee of BOULO and that BOULO does not, in any way, supervise, direct, or control your work. BOULO will
            not have any liability or obligations related to Position Descriptions. BOULO does not direct and has no
            control over Companies or Position Descriptions, and makes no representations as to the integrity,
            reliability, capability, or qualifications of any Company or the quality, security or legality of any
            Position.
          </li>
          <li>
            <strong>Insurance Requirements. </strong>Boulo encourages, but does not require Talent to carry a
            professional liability policy (E&amp;O). If Talent does carry such a policy, Talent should list BOULO as an
            additional insured. This section does not override any other that limits BOULO’s liability to the Talent or
            the Company.
          </li>
          <li>
            <strong>Failure to Deliver. </strong>In the event Talent fails to deliver any Project or perform Services,
            Talent agrees to the terms outlined in the Scope of Work. Talent’s ability to cure will be on a case by case
            basis in the Sole discretion of Boulo.
          </li>
        </ol>
      </li>
    </ol>

    <p></p>

    <p><strong>Terms of use </strong></p>

    <p><strong>For Every User</strong><br /></p>

    <ol>
      <li>
        <strong>Invoicing and Payment Terms. </strong>(“You” refers to the reader which may be the Company or Talent)
        <ol>
          <li>
            <strong>Billing and Payment. </strong>
            <ol>
              <li>
                We do not ask for any payment information today and while we don’t charge to be a Boulo member, today,
                we know we will be adding more and more valuable features that could require a membership. The following
                charge details pertain to future development. In addition, you will never be charged for a membership in
                the future unless you explicitly select a membership level and enter your payment information.
              </li>
              <li>
                <strong>Membership Charges</strong> If You choose to sign up for a BOULO membership, You agree to pay
                BOULO all membership charges associated with the plan You subscribe to as described in the Fee Schedule,
                or elsewhere on the Platform, at the time You submit your profile response and provide your payment
                information. You also authorize BOULO to charge your chosen payment Company according to the terms of
                the plan to which You subscribe. The membership period and the amount and frequency of the charges will
                vary depending on the membership plan You select.
              </li>
            </ol>
          </li>
          <li>
            <strong>Correction of Errors.</strong> BOULO reserves the right to correct any errors or mistakes that it
            makes even if it has already requested or received payment. To the extent You elect to purchase other
            Services BOULO may offer for a fee, You authorize BOULO to charge your chosen payment Company for the
            Services You purchase. You agree that if BOULO already has your credit card on file as a result of prior
            purchases You have made, BOULO may charge that credit card for the additional Services You purchase.
          </li>
          <li>
            <strong>Automatic Membership Renewal and Cancellation. </strong>BOULO paid memberships will continue
            indefinitely until cancelled by User. After your initial membership commitment period, and again after any
            subsequent membership period, your membership will automatically renew for an additional equivalent period
            as the membership term You originally selected and at the membership rate and frequency disclosed to You on
            the Site when You originally joined, unless otherwise provided at the time You joined. If You sign up for a
            payment plan that allows You to be charged monthly over the membership period and You decide to cancel your
            membership during the membership period, You acknowledge and agree that You will continue to be billed for
            the membership on a monthly basis until its originally scheduled expiration date. You may cancel your paid
            membership by adjusting your settings.
          </li>
          <li>
            <strong>Cancellation.</strong> If You cancel your membership, You will not be permitted to use your
            membership after such cancellation, effective immediately. BOULO expressly reserves the right to immediately
            deactivate your membership after You initiate a cancellation. You will not incur any fees associated with
            such cancellation, and BOULO will provide no refunds related to any cancellations.
          </li>
          <li>
            <strong>Refund Policy.</strong> All payments for Services are non-refundable, and there are no refunds or
            credits for unused or partially used Services or Service cancellations.
          </li>
          <li>
            <strong>Free Trial or Discounted Offers.</strong> BOULO may offer lifetime or limited-time free or
            discounted trial memberships to certain Users from time-to-time. If a User signs up for a free or discounted
            trial membership, after the expiration of the free trial or discounted period, the User will be charged the
            price then in effect for a membership to the Service, unless otherwise provided to him or her when he or she
            originally joined. If a User does not want to continue with the Service after the expiration of the free or
            discounted trial period, the User must cancel their membership prior to the conclusion of the free trial or
            discounted period.
          </li>
          <li>
            <strong>Third Party Processor.</strong> You hereby authorize BOULO to charge your credit card (or any other
            form of payment authorized by BOULO or mutually agreed to between You and BOULO) for the payments described
            in these Terms of Use. BOULO does not store your credit card information. Instead, it uses a secure and
            reputable payment processor to process credit card payments.
          </li>
        </ol>
      </li>
      <li>
        <strong>This Section outlines behavior that is prohibited by BOULO.</strong>
        <ol>
          <li>
            <strong>Non-solicitation and Non-circumvention. </strong>You agree not to use the Site or the Services for
            the purposes of circumventing or attempting to circumvent BOULO’s messaging tools or Services, including for
            the purpose of avoiding the obligation to pay any of BOULO’s fees related to its provision of the Service.
            You acknowledge and agree that a substantial portion of the compensation BOULO receives for making the
            Service available to You is collected as a percentage of the Talent’s payments from a Company as set forth
            in Section 5 above.
          </li>
          <li>
            <strong>Exclusive Use of Site for Restricted Period.</strong> For twelve (12) months from the first time You
            identify or are identified by any User or third party through the Site in connection with a Position, You
            must use the Site as your exclusive method to request and perform Project and to make and receive all
            payments for Project, directly or indirectly, with that User or third party or arising out of your
            relationship with that party. This restriction does not in any way limit Talent’s ability to provide
            services to any other businesses or entities that are not Platform Users. You agree not to circumvent the
            payment methods offered by the Site.
          </li>
          <li>
            <strong>Agreement to Notify BOULO</strong>. You will notify BOULO immediately if another party improperly
            contacts You or suggests making or receiving payments outside of the Site. You agree that You will not under
            any circumstances:
            <ol>
              <li>
                Accept proposals from, contact, deliver services to, invoice or receive payments from parties identified
                through the Service other than through the Site; or
              </li>
              <li>
                Invoice or report on the Site an invoice or payment amount lower than the actually agreed project price
                or salary between Company and Talent through the Site.
              </li>
            </ol>
          </li>
          <li>
            <strong>Breach of Obligations.</strong> Notwithstanding the foregoing, if a Company breaches its obligations
            under this Section, and such breach results, directly or indirectly, in Talent being hired or retained (as a
            full-time employee or independent contractor) by Company or any of its related entities (including but not
            limited to subsidiaries, parents, affiliates or entities otherwise related through common ownership or
            control), the parties must notify BOULO immediately upon Talent’s hiring or retention, and Company shall pay
            a fee to BOULO, in an amount equal to 30% of Talent’s annual compensation. The parties acknowledge that the
            harm caused to BOULO by a breach of this Section would be impossible or very difficult to accurately
            estimate and that the fee is a reasonable estimate of the anticipated or actual harm that might arise from
            such breach. Such a breach will also disqualify the Parties from using the Platform in the future.
          </li>
        </ol>
      </li>
      <li>
        <strong>Independent Contractor Relationship. </strong>
        <ol>
          <li>
            <strong>Talent is an Independent Contractor of BOULO. </strong>It is the intent of the Parties that Talent
            is classified as an independent contractor. BOULO shall not control the manner or means by which Talent
            performs the Project<strong>.</strong> Talent will determine the method, details, and means of performing
            the Services. Except as specifically set forth in this agreement, BOULO will not have any right or authority
            to direct or limit Talent&#8217;s activities with respect to Talent&#8217;s hours, services, time off,
            training, vacation or activities of Talent on behalf of other persons. BOULO will not offer Talent to
            participate in any vacation, group medical or life insurance, disability, profit sharing or retirement
            benefits, or any other fringe benefits or benefit plans offered by BOULO to its employees, and will not make
            any offer or promise relating to Talent&#8217;s compensation or benefits.
          </li>
          <li>
            <strong>Company’s Relationship with Talent.</strong> Company is solely responsible for determining whether a
            relationship between Company and Talent qualifies as an independent contractor relationship and whether a
            Talent qualifies as an independent contractor. &nbsp;BOULO shall not be responsible or liable to Company,
            Talent or any third party for the determination of independent contractor status or lack of such status.
          </li>
          <li>
            <strong>No Materials.</strong> BOULO is not responsible for procuring the equipment, supplies or other
            materials used by Talent for performance of the Project.
          </li>
          <li>
            <strong>No Relationship.</strong> Nothing in these Terms of Use shall be construed to create any
            association, partnership, joint venture, employee or agency relationship between the Company and BOULO or
            Talent and BOULO. BOULO does not in any way, directly or indirectly, control, supervise or control
            Talent&#8217;s work, Talent&#8217;s work hours or locations.
          </li>
          <li>
            <strong>Tax Reporting.</strong> Other than BOULO’s obligation to issue 1099K forms as set forth in Section
            2.2. above, BOULO has no responsibility for other tax related matters including, without limitation,
            determining the tax status of the relationship between Company and Talent or their tax filing obligations.
            BOULO is not responsible for withholding or paying any income, payroll, Social Security, or other federal,
            state, or local taxes, making any insurance contributions, including for unemployment or disability, or
            obtaining worker&#8217;s compensation insurance on Talent&#8217;s behalf.
          </li>
        </ol>
      </li>
    </ol>

    <ol>
      <li>
        <strong>Confidential Information. </strong>During the term of these Terms of Use, the Parties may have access to
        information that is treated as confidential and proprietary by the disclosing Party, including, without
        limitation, trade secrets, technology, and information pertaining to business operations and strategies,
        customers, pricing, marketing, finances, sourcing, and personnel, in each case whether spoken, written, printed,
        electronic, or in any other form or medium (collectively, “<strong>Confidential Information</strong>”).
        <ol>
          <li>
            <strong>Protection</strong>. The Parties agree to protect the Confidential Information in the same manner
            that it protects the confidentiality of its own proprietary and Confidential Information;
          </li>
          <li>
            <strong>Disclosure</strong>. The Parties agree not to disclose Confidential Information or permit it to be
            disclosed, in whole or part, to any third party without prior written consent of the disclosing Party in
            each instance; or
          </li>
          <li>
            <strong>Limited Use.</strong> The Parties agree not use any Confidential Information for any purpose except
            for performing each Party&#8217;s obligations under these Terms of Use or as required by law.
          </li>
          <li>
            <strong>No Imputation to BOULO.</strong> The Parties agree no knowledge, possession, or use of
            Company&#8217;s Confidential Information will be imputed to BOULO as a result of Talent&#8217;s access to
            such Confidential Information.
          </li>
          <li>
            <strong>No Access Provided.</strong> Except to the extent that Talent is given access by Company to
            Confidential Information as part of Talent&#8217;s performance of the Project, BOULO shall not provide
            access to Company&#8217;s Confidential Information to Talent.
          </li>
          <li>
            <strong>No Reproduction.</strong> Confidential Information may not be copied or reproduced without the
            disclosing Party&#8217;s prior written consent, except in connection with the performance of the Services.
          </li>
          <li>
            <strong>Return and Destruction.</strong> All Confidential Information made available under the terms of this
            agreement, including copies, shall be returned to the disclosing party or destroyed upon the latter to occur
            of (i) the need for access has been fulfilled, or (ii) completion of the Services or termination of the
            Agreement.
          </li>
          <li>
            <strong>Not included under Confidential Information.</strong> Confidential Information shall not include
            information that:
            <ol>
              <li>
                Is or becomes generally available to the public other than through a Party&#8217;s breach of these Terms
                of Use;
              </li>
              <li>
                Is communicated to a Party by a third party that had no confidentiality obligations with respect to such
                information;
              </li>
              <li>Was known to a Party prior to disclosure under these Terms of Use; or</li>
              <li>Is developed by a Party without use of Confidential Information.</li>
            </ol>
          </li>
          <li>
            <strong>Disclosure as Required by Law.</strong> Nothing in these Terms of Use shall be construed to prevent
            disclosure of Confidential Information as may be required by applicable law or regulation, or pursuant to
            the valid order of a court of competent jurisdiction or an authorized government agency, provided that the
            disclosure does not exceed the extent of disclosure required by such law, regulation, or order. A Party
            agrees to provide written notice of any such order to an authorized officer of the Company within 48 hours
            of receiving such order, but in any event sufficiently in advance of making any disclosure to permit the
            other Party to contest the order or seek confidentiality protections, as determined in that Party&#8217;s
            sole discretion.
          </li>
        </ol>
      </li>
      <li>
        <strong>Exclusivity. </strong>In addition to the provisions set forth in in these Terms of<strong> </strong>Use
        and in consideration of the Services provided to You by BOULO as Company or Talent, You agree, for a twelve (12)
        month period following the date You were introduced to the other party or identified as a potential match by
        BOULO, or through the Site or Platform (“<strong>Exclusivity Period</strong>”), to make exclusive use of the
        Site and Platform to retain services from or make payments to the other party. &nbsp;During the Exclusivity
        Period, You will not request work or make payments, directly or indirectly, in any manner or method that
        circumvents the use of BOULO, the Site or the Platform. Any change to this exclusivity requirement or one­time
        exception must be agreed to in advance by BOULO.
      </li>
      <li>
        <strong>Eligibility.</strong> This Site may only be accessed by entities and individuals who are capable of
        entering into a legally binding contract under applicable law and who are not prohibited by any law, decree,
        regulation, treaty or administrative act, national or international, from accessing the Site, utilizing the
        Services or participating in the Platform.
      </li>
      <li>
        <strong>Privacy.</strong> Your privacy is important to BOULO. This Site may be accessed and viewed by the
        general public without the User having to release any personal or personally identifiable information (“<strong
          >Personal Information</strong
        >”). &nbsp;If You are a User, You understand, acknowledge, and agree that the operation of certain Services
        through the Site, including without limitation, registering your account as either a Company or Talent and
        utilizing the Platform, requires the submission, use, and dissemination of various Personal Information.
        Accordingly, if You wish to access and use those Service, You acknowledge and agree that your use of the Site
        and the Platform will constitute acceptance of BOULO&#8217;s Personal Information collection and use practices.
        Please see our <a href="https://boulosolutions.com/privacy-policy/">Privacy<strong> </strong>Policy</a
        ><strong> </strong>that explains how we collect and use your information.
      </li>
      <li>
        <strong>Notice and Consent for Electronic Communication.</strong> You may deliver notice to BOULO at any time
        via email at support@boulosolutions.com. You consent to receive communications from BOULO in an electronic form
        via the email address on record in your User profile, by text or SMS via the phone number provided in your User
        profile, or by written communication delivered by first class US mail to your address on record in to your
        profile. You further agree that any agreements, notices, disclosures, and other communications that BOULO
        provides to You electronically satisfy any legal requirement that such communications would satisfy if they were
        in writing. BOULO may also use your email address to send You other messages, including information about BOULO
        and special offers. You may opt out of such email by changing your account settings or sending an email to
        support@boulosolutions.com. Opting out may prevent You from receiving messages regarding BOULO or special
        offers.
      </li>
      <li>
        <strong>Proprietary Rights. </strong>
        <ol>
          <li>
            <strong>Content. </strong>Unless otherwise noted, all of the content on the Site including but not limited
            to text, images, videos, graphics, sound, data, links and supporting systems and software, whether created,
            submitted or uploaded by BOULO, You or third parties for display on the Site or in connection with your use
            of the Platform, is owned by or licensed to BOULO (the “Content”). All such Content shall be protected by
            U.S. copyright and international treaties.
            <ol>
              <li>
                <strong>Unauthorized Use</strong>. Except as expressly provided in these Terms of Use, BOULO does not
                grant any express or implied right to You or any other person or entity under any intellectual or
                proprietary rights. Accordingly, your unauthorized use of the Site or the Platform may violate
                intellectual property or other proprietary rights laws as well as other laws, regulations, and statutes.
                Please be aware that BOULO does enforce its intellectual property rights to the fullest extent of the
                law and, in particular and without limitation, with respect to illegal use of terms confusingly similar
                to any of BOULO&#8217;s trademarks. [This Site is Copyright © 2018 DCG Group, LLC and/or its
                licensors.] All rights reserved.
              </li>
              <li>
                <strong>BOULO’s Copyright in the Platform.</strong> BOULO also owns a copyright in the contents of the
                Site and the Platform as collective work and/or compilation and in the selection, coordination,
                arrangement, and enhancement of the content of the Site. Any downloadable or printable programs,
                directories, databases, information, or materials available through the Site and Platform and all
                copyrights, trade secrets, and know-how related thereto, unless otherwise indicated, are owned by BOULO.
              </li>
              <li>
                <strong>Trademarks</strong>. BOULO, the BOULO logo, and all other names, logos, and icons identifying
                BOULO and its Services are proprietary trademarks of BOULO, and any use of such marks, including,
                without limitation, as domain names, without the express written permission of BOULO is strictly
                prohibited. Other service and entity names mentioned in these Terms of Use may be the trademarks and/or
                service marks of their respective owners.
              </li>
            </ol>
          </li>
          <li>
            <strong>Use of Content.</strong> Content may be viewed, downloaded, printed or copied for personal use or
            internal business use only and may not be used for any other purposes without consent of BOULO. Content may
            not be reproduced, modified, displayed, sold or distributed or used in any other way (including copying or
            adapting HTML code) for public or commercial purposes. You acknowledge and agree that:
            <ol>
              <li>Caching of, copies of or references to your Content may not be immediately removed;</li>
              <li>
                Removed Content may persist in backups (not available to others) for a reasonable period of time; and
              </li>
              <li>
                Removed Content may be available (and stored on our servers) through the accounts of other Users,
                because of re-posting.
              </li>
            </ol>
          </li>
          <li>
            <strong>License. </strong>Unless otherwise expressly stated in writing by BOULO, You are granted a limited,
            non-transferrable license (i.e. a personal and limited right) to access and use the Site or the Platform for
            your personal or internal business use only. By submitting or entering Content to the Site or Platform, You
            represent and warrant that You own all rights, title and interest to any Content You upload to the Site or
            the Platform and grant BOULO a perpetual, non-exclusive, irrevocable worldwide, royalty-free, fully paid
            transferrable, sub-licensable, license to copy, display, upload, perform, distribute, store, modify and
            otherwise use Content submitted by You by any means, forever and worldwide. BOULO reserves the right to use
            the Content in accordance with the Privacy Policy and to make changes to the Content from time to time and
            as it deems necessary.
          </li>
        </ol>
      </li>
      <li>
        <strong>Site and Platform Rules.</strong>
        <ol>
          <li>
            <strong>User Obligations. </strong>By downloading, accessing, or using the Site or the Platform to view
            BOULO&#8217;s information and materials or submit information of any kind, You represent that You are at
            least the legal age of majority and will, at all times, provide true, accurate, current, and complete
            information when submitting information or materials on the Site, including, without limitation, when You
            provide information via Site registration, profile or account creation or other submission form. If You
            provide any false, inaccurate, untrue, or incomplete information,
          </li>
          <li>
            <strong>Right to Terminate Access.</strong> BOULO reserves the right to terminate immediately your access to
            and use of the Site or the Platform. In addition, You agree to abide by all applicable local, state,
            national, and international laws and regulations with respect to your use of the Site or the Platform.
            Without limiting the generality of the foregoing,
          </li>
          <li>
            <strong>Disclosure</strong>. You agree that You shall not use nor disclose to any other party in a manner
            not permitted by these Terms of Use any User information or Confidential Information, which You receive or
            which is made available to You in connection with the Site or the Platform.
          </li>
          <li>
            <strong>Applicable Laws</strong>. These Terms of Use are also expressly made subject to any applicable
            export laws, orders, restrictions, or regulations. You shall not export the Site and/or Platform (or access
            thereto) without complying with such laws, orders, restrictions, or regulations.
          </li>
          <li>
            <strong>Use at your Own Risk.</strong> You also acknowledge and agree that use of the Internet and access to
            or transmissions or communications with the Site or the Platform is solely at your own risk. You agree to
            assume all responsibility concerning activities related to your use of the Site, including, providing any
            support or meeting any requirements of your contracts with third parties, obtaining and paying for all
            licenses and costs for third-party software and hardware necessary for your use of the Site or the Platform,
            and maintaining and backing up any data. &nbsp;While BOULO will make commercially reasonable efforts to
            create a secure and reliable Site and Platform, You should understand that the confidentiality of any
            communication or material transmitted to/from the Site and/or the Platform over the Internet or other form
            of global communication network cannot be guaranteed. Security breaches, such as hacking, may occur. You
            acknowledge that BOULO is not liable for any matter or claim that may arise from such a breach impacting the
            Site or the Platform.
          </li>
          <li>
            <strong>Posting Position Descriptions. </strong>A Position Description may not contain:
            <ol>
              <li>Inaccurate, false, or misleading information; and</li>
              <li>
                Material or links to material that exploits people in a sexual, violent or other manner, or solicits
                personal information from anyone under 18<strong>.</strong>
              </li>
              <li>
                <strong>Post must Comply with all Applicable Laws</strong>. Additionally, Companies may not post
                Position Descriptions that do not comply with applicable laws, including but not limited to laws
                relating to labor and employment, equal employment opportunity and employment eligibility requirements,
                data privacy, data access and use, and intellectual property;
              </li>
              <li>
                Post Position Descriptions that require citizenship or lawful permanent residence in any particular
                country as a condition of employment, unless otherwise required in order to comply with law,
                regulations, executive order, or federal, state or local government contract;
              </li>
              <li>
                Post Position Descriptions that include any screening criterion where such criterion is not an actual
                and legal requirement of the posted Position Description;
              </li>
              <li>
                Post jobs or advertisements for competitors of BOULO or post Position Descriptions that contain a link
                to any site competitive with BOULO;
              </li>
              <li>
                Sell, promote or advertise products or services; Post any franchise, pyramid scheme, “club membership”,
                distributorship, multi-level marketing opportunity, or sales representative agency arrangement;
              </li>
              <li>
                Post any Position that requires an up-front or periodic payment or requires recruitment of other
                members, sub-distributors or sub-agents;
              </li>
              <li>
                Post any Position that pays commission only, unless the posting clearly states that the available
                position pays commission only and clearly describes the product or service that the Company would be
                selling;
              </li>
              <li>Endorse a particular political party, political agenda, political position or issue;</li>
              <li>Promote a particular religion; or</li>
              <li>
                Except where allowed by applicable law, post Position Descriptions that require the Talent to provide
                information relating to her racial or ethnic origin, political beliefs, philosophical or religious
                beliefs, membership of a trade union, physical or mental health, sexual life, the commission of criminal
                offences or proceedings or age.
              </li>
            </ol>
          </li>
          <li>
            <strong>Right to Remove.</strong> BOULO reserves the right to remove any Position or content from our
            website, which in the reasonable exercise of BOULO’s discretion, does not comply with the above Terms, or if
            any content is posted that BOULO believes is not in the best interest of BOULO. If at any time during your
            use of our Services, You made a misrepresentation of fact to BOULO or otherwise misled BOULO in regards to
            the nature of your business activities, BOULO will have grounds to immediately terminate your use of the
            Services.
          </li>
          <li>
            <strong>Feedback.</strong> BOULO welcomes your input about BOULO&#8217;s Services, the Site or the Platform.
            If You provide any feedback, suggestions or comments or other information to BOULO regarding the Site, the
            Platform or the Services (collectively, “<strong>Feedback</strong>”), BOULO may use such Feedback for any
            purpose. To allow us to incorporate such Feedback into our Site, Platform or Services, any Feedback will be
            deemed to include a royalty free, perpetual, irrevocable, transferable, non­exclusive right and license for
            BOULO to adopt, publish, reproduce, disseminate, transmit, distribute, copy, use, create derivative works,
            and display (in whole or in part) worldwide, or act on such Feedback without additional approval or
            consideration, in any form, media, or technology now known or later developed for the full term of any
            rights that may exist in such content, and You hereby waive any claim to the contrary. By transmitting such
            Feedback, You represent and warrant that such Feedback does not infringe or violate the intellectual
            property or proprietary rights of any third party (including, without limitation, patents, copyrights, or
          </li>
        </ol>
      </li>
    </ol>

    <p>
      trademark rights) and that You have all rights necessary to convey to BOULO and enable BOULO to use such Feedback.
    </p>

    <ol>
      <li>
        <strong>Account Registration.</strong> If You are using or creating an account or profile with BOULO on behalf
        of a Company, entity or organization, then You represent and warrant that You are an authorized representative
        with the authority to bind such company, entity or organization to these Terms of Use and that You agree to
        these Terms of Use on behalf of such Company, entity or organization.
      </li>
      <li>
        <strong>Status of Site and Platform</strong>. The Site and/or Platform may on occasion be unavailable due to
        updates, maintenance or malfunction of computer equipment or for other reasons, or may from time to time, at
        BOULO&#8217;s sole discretion, be amended, modified, revised, altered, or taken down, including the Services
        available through the Platform, in each case without notice to You.
      </li>
      <li>
        <strong>Prohibited Uses</strong>. BY USING THE SITE AND THE PLATFORM, YOU AGREE NOT TO violate any law,
        contract, intellectual property or other third-party right or commit a tort in connection with BOULO, the
        Services or your use of the Site or the Platform. BOULO reserves the right to perform investigations and take
        appropriate legal action against anyone who violates this provision in BOULO&#8217;s sole discretion. Listed
        below are examples of the types of uses or actions that are illegal or prohibited by BOULO. This list is
        non-exclusive. You agree that You will comply with these Terms of Use and will not:
        <ol>
          <li>use the Site or Platform for any purposes except for its intended purposes;</li>
          <li>
            interfere with a business relationship formed through the Site or Platform including without limitation
            manipulating pricing for Services offered by BOULO, or use the Site or Platform for any illegal purpose, or
            in violation of any local, state, national, or international law, including, without limitation, laws
            governing intellectual property and other proprietary rights, and data protection and privacy;
          </li>
          <li>
            defame, harass, abuse, threaten or defraud Users, or collect, or attempt to collect, Personal Information or
            confidential information about users or third parties without their consent;
          </li>
          <li>
            post, upload, or distribute any defamatory, libelous, or inaccurate, or hateful or racially or ethnically
            objectionable Content;
          </li>
          <li>solicit Personal Information from anyone under the age of 18;</li>
          <li>
            impersonate any person or entity, falsely claim an affiliation with any person or entity, or access BOULO
            accounts of others without permission, forge another persons&#8217; digital signature, misrepresent the
            source, identity, or content of information transmitted via the Site or the Platform, or perform any other
            similar fraudulent activity, use or attempt to use another user&#8217;s account without authorization from
            that user;
          </li>
          <li>make unsolicited offers, advertisements, proposals, or send junk mail or spam to other Users;</li>
          <li>
            harvest or collect the email addresses or other contact information of other Users without their consent;
          </li>
          <li>
            remove, circumvent, disable, damage or otherwise interfere with security-related features of the Site, the
            Platform or the Content, features that prevent or restrict use or copying of any Content;
          </li>
          <li>
            reverse engineer, decompile, disassemble or otherwise attempt to discover the source code of the Site or the
            Platform or any part thereof, except and only to the extent that this activity is expressly permitted by the
            law of your country of residence;
          </li>
          <li>
            rent, lease, loan, sell, resell, sublicense, distribute or otherwise transfer the licenses granted in these
            Terms of Use;
          </li>
          <li>
            modify, adapt, translate or create derivative works based upon the Site or the Platform or any part thereof,
            except and only to the extent that such activity is expressly permitted by applicable law notwithstanding
            this limitation;
          </li>
          <li>
            intentionally interfere with or damage the operation of the Site or the Platform or any user&#8217;s
            enjoyment thereof, by any means, including uploading or otherwise disseminating viruses, adware, spyware,
            worms, or other malicious code;
          </li>
          <li>relay email from a third party&#8217;s mail servers without the permission of that third party;</li>
          <li>
            use any robot, spider, scraper, crawler or other automated means to access the Site or the Platform for any
            purpose;
          </li>
          <li>
            manipulate identifiers in order to disguise the origin of any Content transmitted through the Site or the
            Platform;
          </li>
          <li>
            interfere with or disrupt the Site or the Platform servers or networks connected therewith, or disobey any
            requirements, procedures, policies or regulations of networks connected to the Site or Platform or use the
            Site or Platform in any manner that could interfere with, disrupt, negatively affect or inhibit other users
            from fully enjoying the Site or Platform, or that could damage, disable, overburden or impair the
            functioning of thereof in any manner; or
          </li>
          <li>
            attempt to indicate in any manner that You have a relationship with BOULO or that the BOULO endorsed You or
            any products or services for any purpose.
          </li>
        </ol>
      </li>
      <li>
        <strong>Links to Other Sites.</strong> &nbsp;BOULO, in its sole discretion, may provide links to other websites
        (including links to social media or press sites) for your convenience in locating or accessing related
        information, products, and services. These websites have not necessarily been reviewed by BOULO and are
        maintained by third parties over which BOULO exercises no control. Accordingly, BOULO expressly disclaims any
        responsibility for the content, the materials, the accuracy of the information, and/or the quality of the
        products or services provided by, available through, or advertised on these third-party websites.
        &nbsp;Moreover, these links do not imply an endorsement with respect to any third party or any website or the
        products or services provided by any third party. BOULO encourages You to learn about the privacy practices and
        terms of those other third parties.
      </li>
      <li>
        <strong>Digital Millennium Copyright Act.</strong> &nbsp;If You believe that anything on the Platform or the
        Site infringes upon any copyright that You own or control, You may send a notification of such infringement to
        support@boulosolutions.com. as set forth below. Please see 17 U.S.C. §512(c)(3) for the requirements of a proper
        notification. Knowingly misrepresenting in your notification that the material or activity is infringing may
        result in your liability for damages, including costs and attorneys&#8217; fees, incurred by us or the alleged
        infringer as the result of our relying upon such misrepresentation in removing or disabling access to the
        material or activity claimed to be infringing.
      </li>
      <li>
        <strong>No Conflicts with Prior Agreements. </strong>You represent and warrant to BOULO that You are under no
        restriction or obligation that may affect your use of the Site, or the Platform or your ability to act as a
        Company or Talent, with regard to the Services.
      </li>
      <li>
        <strong>Release. </strong>Through the Site and the Platform, BOULO offers a venue for connecting Companies and
        Talent, and except to the extent, if any, that BOULO serves as a communications platform between Companies and
        Talent, suggests potential Talent to Companies in connection with the Services, and disburses payments between
        them, BOULO does not take part in the interaction between Companies and Talent. A Company&#8217;s decision to
        retain Talent for Project or confirm information provided to Company by Talent through the Platform, or
        otherwise, is in its sole discretion. As a result of BOULO&#8217;s limited involvement in the actual contact
        between Company and Talent, You hereby release BOULO, and BOULO&#8217;s officers, directors, agents, investors,
        subsidiaries, and employees from any and all claims, demands, or damages (actual or consequential) of every kind
        and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any
        way connected with a dispute You may have with a Company, Talent or other user. BOULO expressly disclaims any
        liability or claims that may arise between users of the Site or the Platform.
        <ol>
          <li>
            <strong>Waiver of § 1542</strong>. California residents waive any rights that they may have under California
            Civil Code Section 1542,which reads: “A general release does not extend to claims which the creditor does
            not know or suspect to exist in his favor at the time of executing the release, which, if known by him must
            have materially affected his settlement with the debtor.”
          </li>
        </ol>
      </li>
      <li>
        <strong>Geographic Restrictions.</strong> The owner of the Site is based in the state of Alabama in the United
        States. BOULO provide this Site for use only by persons located in the United States. BOULO make no claims that
        the Site or any of its content is accessible or appropriate outside of the United States. Access to the Site may
        not be legal by certain persons or in certain countries. If You access the Site from outside the United States,
        You do so on your own initiative and are responsible for compliance with local laws.
      </li>
    </ol>

    <p><strong>Disclaimers of Warranties</strong><br /></p>

    <ol>
      <li>
        <strong>Disclaimer of Warranties. </strong>
        <ol>
          <li>
            <strong>Integrity of Files</strong
            ><strong
              >. You understand that we cannot and do not guarantee or warrant that files available for downloading from
              the internet or the Site will be free of viruses or other destructive code. You are responsible for
              implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus
              protection and accuracy of data input and output, and for maintaining a means external to our site for any
              reconstruction of any lost data.
            </strong>
          </li>
          <li>
            <strong>DISTRIBUTED DENIAL OF SERVICE ATTACKS.</strong
            ><strong>
              WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR
              OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR
              OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SITE, THE PLATFORM, OR ANY SERVICES OR ITEMS OBTAINED
              THROUGH THE SITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.YOUR
              USE OF THE SITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE IS AT YOUR OWN RISK. THE
              SITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE ARE PROVIDED ON AN &#8220;AS
              IS&#8221; AND &#8220;AS AVAILABLE&#8221; BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR
              IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR
              REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY
              OF THE SITE. WITHOUT LIMITING THE FOREGOING, NEITHER BOULO NOR ANYONE ASSOCIATED WITH BOULO REPRESENTS OR
              WARRANTS THAT THE SITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL BE ACCURATE,
              RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT
              MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SITE OR ANY SERVICES OR
              ITEMS OBTAINED THROUGH THE SITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</strong
            ><strong> </strong>
          </li>
          <li>
            <strong>DISCLAIMER OF WARRANTIES</strong
            ><strong
              >. BOULO HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE,
              INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR
              PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER
              APPLICABLE LAW.</strong
            >
          </li>
        </ol>
      </li>
      <li>
        <strong>Limitation of Liability.</strong>
        <ol>
          <li>
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL BOULO BE LIABLE TO ANY USER OF THIS SITE OR THE
            PLATFORM OR ANY OTHER PERSON OR ENTITY FOR DIRECT, INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL OR INCIDENTAL
            DAMAGES WHICH MAY RESULT FROM THEIR USE, MISUSE, UNAUTHORIZED USE OR INABILITY TO USE THE SITE, THE PLATFORM
            OR THE SERVICES, WHETHER BASED UPON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, EVEN IF
            BOULO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES. YOU ACKNOWLEDGE AND AGREE THAT
            BOULO&#8217;S TOTAL CUMULATIVE LIABILITY IN CONNECTION WITH THESE TERMS OF USE, THE SITE, THE PLATFORM OR
            THE SERVICES, WHETHER ARISING FROM ANY CAUSE OF ACTION, WHETHER IN CONTRACT, TORT, (INCLUDING NEGLIGENCE) OR
            STRICT LIABILITY, WILL NOT EXCEED COMMISSIONS RECEIVED BY BOULO DURING THE LAST SIX MONTHS PRIOR TO THE DATE
            OF THE CLAIM WITH RESPECT TO CONTRACTS YOU WERE INVOLVED IN AS COMPANY OR TALENT.
          </li>
          <li>
            IN ADDITION TO THE LIMITATIONS OF BOULO’S LIABILITY SET FORTH IN SECTION ABOVE, BOULO SHALL NOT BE LIABLE
            FOR ANY LOSS OR DAMAGE ARISING OUT OF OR RELATING TO TALENT’S PERFORMANCE OF THE SCOPE OF WORK.
          </li>
        </ol>
      </li>
    </ol>

    <ol>
      <li>
        <strong>Indemnity.</strong> You agree to defend, indemnify, and hold harmless BOULO and its employees, agents,
        directors, officers, shareholders, attorneys, successors, and assigns from and against any and all claims,
        proceedings, damages, injuries, liabilities, including professional liabilities, losses, costs, and expenses
        (including reasonable attorneys&#8217; fees and litigation expenses) relating to or arising from your use of the
        Site, the Platform, any Services and any contracts regarding such Services entered into by You or any breach by
        You of these Terms of Use. The foregoing indemnity obligations include employment-related claims such as claims
        that an employee was misclassified as an independent contractor, joint employment claims and claims under
        employment-related laws.
      </li>
      <li>
        <strong>Mandatory Arbitration.</strong> You agree that all disputes between You and BOULO (whether or not such
        dispute involves a third party) arising out of or relating to the Site, the Platform, these Terms of Use, the
        Services, and/or the Privacy Policy shall be finally resolved by arbitration before a single arbitrator
        conducted in the English language in the State of Alabama, U.S.A. under the Commercial Arbitration Rules of the
        American Arbitration Association (AAA) and You and BOULO hereby expressly waive trial by jury. You and BOULO
        shall appoint as sole arbitrator a person mutually agreed by You and BOULO or, if You and BOULO cannot agree
        within thirty (30) days of either party&#8217;s request for arbitration, such single arbitrator shall be
        selected by the AAA upon the request of either party. The parties shall bear equally the cost of the arbitration
        (except that the prevailing party shall be entitled to an award of reasonable attorneys&#8217; fees incurred in
        connection with the arbitration in such an amount as may be determined by the arbitrator). &nbsp;All decisions
        of the arbitrator shall be final and binding on both parties and enforceable in any court of competent
        jurisdiction. Notwithstanding this, application may be made to any court for a judicial acceptance of the award
        or order of enforcement. Notwithstanding the foregoing, BOULO shall be entitled to seek injunctive relief,
        security or other equitable remedies from the United States District Court for a district in Jefferson County,
        Alabama, or any other court of competent jurisdiction. Under no circumstances shall the arbitrator be authorized
        to award damages, remedies or awards that conflict with these Terms of Use or to award punitive damages,
        including but not limited to pursuant to federal or state statutes permitting multiple or punitive awards. Any
        claims brought by a user or BOULO must be brought in the party&#8217;s individual capacity, and not as a
        plaintiff or class member in any purported class or representative proceeding. Neither You nor BOULO will
        participate in a class action or class-wide arbitration for any claims covered by these Terms of Use. You hereby
        waive any and all rights to bring any claims related to these Terms of Use and Privacy Policy as a plaintiff or
        class member in any purported class or representative proceeding. You may bring claims only on your own behalf.
        This arbitration provision will survive the termination of your relationship with BOULO.
      </li>
      <li>
        <strong>Termination.</strong> These Terms of Use and your right to use the Site and the Platform will take
        effect when You access or use the Site or the Platform and is effective until terminated under these Terms of
        Use. BOULO reserves the right to terminate your use of this Site or the Platform at any time, without notice. In
        addition, BOULO reserves the right at any time and on reasonable grounds, which shall include, without
        limitation, any reasonable belief of fraudulent or unlawful activity or actions or omissions that violate any
        term or condition of these Terms of Use, to deny your access to the Site, the Platform or to any portion thereof
        in order to protect its name and goodwill, its business, and/or other users, and these Terms of Use will also
        terminate automatically if You fail to comply with these Terms of Use, subject to the survival rights of certain
        provisions identified below. Termination will be effective without notice. You may also terminate these Terms of
        Use at any time by ceasing to use the Site or the Platform, but all applicable provisions of these Terms of Use
        will survive termination, as identified below. &nbsp;Upon termination, You must destroy all copies of any aspect
        of the Site or the Platform in your possession. In addition to the miscellaneous section below, the provisions
        concerning BOULO&#8217;s proprietary rights, Feedback, indemnity, disclaimers of warranty, limitation of
        liability, and governing law will survive the termination of these Terms of Use for any reason. All
        restrictions, rights granted by You and all disclaimers and limitations of liability by BOULO will survive
        termination. You agree to discontinue use of this Site or the Platform immediately upon your disagreement with
        or breach of any of these Terms of Use, as they may be amended from time to time.
      </li>
      <li>
        <strong>Governing Law.</strong> These Terms of Use have been made in and will be construed and enforced solely
        in accordance with the laws of the United States of America and the State of Alabama, U.S.A. as applied to
        agreements entered into and completely performed in the State of Alabama. You and BOULO each agree to submit to
        exclusive subject matter jurisdiction, personal jurisdiction, and venue of the courts in the State of Alabama
        for any disputes between us under or arising out of these Terms of Use. You also agree to waive any right to a
        jury trial in connection with any action or litigation in any way arising out of or related to these Terms of
        Use and acknowledge that either party may seek attorney&#8217;s fees in any proceeding. Any claim You might have
        against BOULO must be brought within two (2) years after the cause of action arises, or such claim or cause of
        action is barred. You also acknowledge and agree that any applicable state law implementation of the Uniform
        Computer Information Transactions Act (including any available remedies or laws) shall not apply to these Terms
        of Use and is hereby disclaimed. BOULO makes no representation that the Site is appropriate or available for use
        in other locations outside the State of Alabama and access to the Site from states, territories, or nations
        where any aspect of the Site is illegal is prohibited. You access the Site or the Platform on your own volition
        and are responsible for compliance with all applicable local laws with respect to your access and use of the
        Site.
      </li>
    </ol>

    <ol>
      <li>
        <strong>Waiver and Severability.</strong> No waiver by BOULO of any term or condition set forth in these Terms
        of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or
        condition, and any failure of BOULO to assert a right or provision under these Terms of Use shall not constitute
        a waiver of such right or provision.
      </li>
      <li>
        If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be
        invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum
        extent such that the remaining provisions of the Terms of Use will continue in full force and effect.
      </li>
      <li>
        <strong>Miscellaneous.</strong>
        <ol>
          <li>
            <strong>Breach of Terms.</strong> You acknowledge that any breach, threatened or actual, of these Terms of
            Use will cause irreparable injury to BOULO, such injury would not be quantifiable in monetary damages, and
            BOULO would not have an adequate remedy at law. You therefore agree that BOULO shall be entitled, in
            addition to other available remedies, to seek and be awarded an injunction or other appropriate equitable
            relief from a court of competent jurisdiction restraining any breach, threatened or actual, of your
            obligations under any provision of these Terms of Use. The parties agree that these Terms of Use are for the
            benefit of the parties hereto as well as BOULO&#8217;s licensors. Accordingly, these Terms of Use are
            personal to You, and You may not assign your rights or obligations to any other person or entity without
            BOULO&#8217;s prior written consent. Failure by BOULO to insist on strict performance of any of the terms
            and conditions of these Terms of Use will not operate as a waiver by BOULO of that or any subsequent default
            or failure of performance. If any provision (or part thereof) contained in these Terms of Use is determined
            to be void, invalid, or otherwise unenforceable by a court of competent jurisdiction or on account of a
            conflict with an applicable government regulation, such determination shall not affect the remaining
            provisions (or parts thereof) contained in these Terms of Use and the illegal, invalid, or unenforceable
            clause shall be modified in compliance with applicable law in a manner that most closely matches the intent
            of the original language. No joint venture, partnership, employment, or agency relationship exists between
            You and BOULO as result of these Terms of Use or your utilization of the Site. Headings in these Terms of
            Use are for convenience only. These Terms of Use, along with the BOULO&#8217;s
            <a href="https://boulosolutions.com/privacy-policy/">Privacy Policy</a>, represents the entire agreement
            between You and BOULO with respect to use of the Site, and it supersedes all prior or contemporaneous
            communications and proposals, whether electronic, oral, or written between You and BOULO with respect to the
            Site or the Platform.
          </li>
        </ol>
      </li>
    </ol>

    <p>Last updated on November, 2018. <br /></p>

    <p><em>If you have any questions about these Terms of Use, please contact us at: </em></p>

    <p>support@boulosolutions.com</p>
  </div>
</div>
<app-footer></app-footer>
