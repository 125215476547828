import { NgModule, Component } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SecurityGuard } from "./services/security-guard.service";
import { AdminSecurityGuard } from "./admin/admin-security-guard.service";
import { UserSecurityGuard } from "./user/user-security-guard.service";

const routes: Routes = [
  {
    path: "admin",
    canActivate: [SecurityGuard, AdminSecurityGuard],
    loadChildren: () => import("./admin/admin.module").then((mod) => mod.AdminModule),
  },
  {
    path: "user",
    canActivate: [SecurityGuard, UserSecurityGuard],
    loadChildren: () => import("./user/user-root/user-root.module").then((mod) => mod.UserModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
