import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormControl } from "@angular/forms";

/*
This widget is used to present a list of options where the user can select multiple options.
The options are presented as chips. The user can remove an option by clicking on the chip.
The user can add an option by selecting it from the dropdown list.
*/
@Component({
  selector: "app-chip-multi-selection",
  templateUrl: "./chip-multi-selection.component.html",
  styleUrls: ["./chip-multi-selection.component.scss"],
})
export class ChipMultiSelectionComponent {
  @Input() title = "";
  @Input() options = [];
  @Input() stringArrayOptions = [];
  @Input() selections = [];

  @Output() selections_out = new EventEmitter<string[]>();

  chipSelectInputControl = new FormControl([]);

  ngOnInit(): void {
    if (this.options.length === 0) {
      this.options = this.stringArrayOptions.map((option) => {
        return { value: option, label: option };
      });
    }
    this.chipSelectInputControl.setValue(this.selections);
    this.chipSelectInputControl.valueChanges.subscribe((value) => {
      this.selections_out.emit(value);
    });
  }

  getLabelFromValue(value: string): string {
    return this.options.find((option) => option.value === value)?.label;
  }

  onElementRemoved(element: string): void {
    const elements = this.chipSelectInputControl.value as string[];
    this.removeFirst(elements, element);
    this.chipSelectInputControl.setValue(elements);
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }
}
