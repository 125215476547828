<div id="change-password">
  <div class="col-lg-7 change-quote-section">
    <div class="change-quote-container">
      <div class="overlay"></div>
      <div class="content">
        <i class="fas fa-quote-left fa-4x"></i>
        <div class="text">
          <span class="quote">Stick to your true<br />north-build greatness<br />for the long term.</span>
          <i class="fas fa-quote-right fa-3x"></i>
          <div class="row author-row">
            <div class="mt-1" style="display: none">
              <span class="welcome">Welcome Again!</span>
            </div>
            <div class="ml-auto author text-right">
              <b>Ruth Porat</b><br />
              CFO Alphabet
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-5 col-md change-form-section {{ success }}">
    <a routerLink="/">
      <img class="logo" src="assets/svg/Logo.svg" />
    </a>
    <p class="subtitle-2">Change Password</p>
    <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()" class="forgot-password-form">
      <div class="container w-75">
        <div class="row">
          <ul class="list-group w-100">
            <div
              class="list-group-item"
              [ngClass]="{
                'error-box': !changePasswordForm.get('password').valid && changePasswordForm.get('password').touched
              }"
              (input)="eventOnChangePassword($event.target.value)"
            >
              <div class="input-group">
                <input
                  type="password"
                  formControlName="password"
                  class="form-control list-group-item"
                  style="border: 0"
                  placeholder="Enter a new password"
                  minlength="14"
                  maxlength="20"
                />
                <div
                  class="input-group-append text-danger ml-3 help-block error-seccion"
                  *ngIf="!changePasswordForm.get('password').valid && changePasswordForm.get('password').touched"
                >
                  <span class="color-danger" *ngIf="changePasswordForm.get('password').errors.required">required</span>
                  <span class="color-danger" *ngIf="changePasswordForm.get('password').errors.minlength"
                    >min length 14 characters</span
                  >
                  <span class="color-danger" *ngIf="changePasswordForm.get('password').errors.number"
                    >must contain at least one number</span
                  >
                  <span class="color-danger" *ngIf="changePasswordForm.get('password').errors.lowerLetter"
                    >must contain at least <br />
                    one lowercas letter</span
                  >
                  <span class="color-danger" *ngIf="changePasswordForm.get('password').errors.capitalLetter"
                    >must contain at least <br />
                    one capital letter</span
                  >
                  <span class="color-danger" *ngIf="changePasswordForm.get('password').errors.special"
                    >must contain at least <br />
                    one of these special characters ! # @ $ % &</span
                  >
                  <img src="assets/svg/error-icon.svg" />
                </div>
              </div>
            </div>
            <div
              class="list-group-item"
              [ngClass]="{
                'error-box':
                  (!changePasswordForm.get('confirmPassword').valid &&
                    changePasswordForm.get('confirmPassword').touched) ||
                  (changePasswordForm.get('password').touched &&
                    changePasswordForm.get('confirmPassword').touched &&
                    changePasswordForm.get('password').value !== changePasswordForm.get('confirmPassword').value)
              }"
            >
              <div class="input-group">
                <input
                  type="password"
                  formControlName="confirmPassword"
                  class="form-control list-group-item"
                  style="border: 0"
                  placeholder="Confirm Password"
                />
                <div
                  class="input-group-append text-danger ml-3 help-block error-seccion"
                  *ngIf="
                    (!changePasswordForm.get('confirmPassword').valid &&
                      changePasswordForm.get('confirmPassword').touched) ||
                    (changePasswordForm.get('password').touched &&
                      changePasswordForm.get('confirmPassword').touched &&
                      changePasswordForm.get('password').value !== changePasswordForm.get('confirmPassword').value)
                  "
                >
                  <span
                    class="color-danger"
                    *ngIf="
                      !changePasswordForm.get('confirmPassword').valid &&
                      changePasswordForm.get('confirmPassword').errors.required
                    "
                    >required</span
                  >
                  <span
                    class="color-danger"
                    *ngIf="
                      !(
                        !changePasswordForm.get('confirmPassword').valid &&
                        changePasswordForm.get('confirmPassword').errors.required
                      ) && changePasswordForm.get('password').value !== changePasswordForm.get('confirmPassword').value
                    "
                    >Passwords don't match</span
                  >
                  <img src="assets/svg/error-icon.svg" />
                </div>
              </div>
            </div>
          </ul>
        </div>
        <div class="submit-button">
          <button type="submit" [disabled]="!changePasswordForm.valid" class="btn btn-primary rounded-pill px-5">
            Change Password
          </button>
          <a class="cancel-button" routerLink="/login">Cancel</a>
        </div>
      </div>
    </form>
  </div>
  <div class="col-md-5 change-success-section {{ success }}">
    <a routerLink="/">
      <img class="logo" src="../../../assets/svg/Logo.svg" />
    </a>
    <img class="check" src="../../../assets/svg/Select-icon.svg" />
    <p class="subtitle-2">Your password has been reset.</p>
    <p class="forgot-text">You can sign in at Boulo now.</p>
    <div class="submit-button">
      <button routerLink="/login" class="btn btn-primary rounded-pill px-5">Sign In</button>
    </div>
  </div>
</div>
<app-alert [message]="errorMessage" *ngIf="errorMessage !== null" (close)="errorMessage = null"></app-alert>
