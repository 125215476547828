<div class="circle-progress">
  <div class="img-container">
    <img [src]="mainImage" />
    <span class="tooltiptext" *ngIf="title">{{ title }}</span>
  </div>
  <circle-progress
    [percent]="percent"
    [radius]="100"
    [outerStrokeColor]="color"
    [innerStrokeColor]="'#e7e8ea'"
    [animation]="true"
    [animationDuration]="300"
  >
  </circle-progress>
  <img class="icon" [src]="icon" />
</div>
