import { Component, Inject, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { iApplicantListModel } from "../../models/applicant-candidate.model";
import { PlacementRecord } from "../../models/placement-record.model";
import { EmployerOnboardingService } from "src/app/user/employer/employer-root/employer-root.service";
import { eJobCardStatus } from "src/app/user/employer/dashboard/job-posting-card/job-posting-card.component";
import { DialogConfirmDeleteComponent } from "../dialog-confirm-delete/dialog-confirm-delete.component";

export interface DialogData {
  selectedApplicant: iApplicantListModel;
}

export enum eEmploymentType {
  PartTime = "Part-Time",
  FullTime = "Full-Time",
  Contract = "Contract",
}

export enum eHiredCompensationType {
  Salary = "Per Year",
  Hourly = "Per Hour",
  FlatRate = "Per Project",
}

export enum ePlacementFee {
  Grow = "0.08",
}

export enum eHireApplicantResult {
  Hired = "Hired",
}

@Component({
  selector: "app-dialog-hire-applicant",
  templateUrl: "./dialog-hire-applicant.component.html",
  styleUrls: ["./dialog-hire-applicant.component.scss"],
})
export class DialogHireApplicantComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogHireApplicantComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private _service: EmployerOnboardingService,
    private dialog: MatDialog
  ) {}

  applicantHireForm: FormGroup;
  noEndDate: boolean = true;
  compensationTitle: string = "Salary";
  compensationPlaceHolder: string = "Enter Applicant Salary";
  startDateLimit: Date;
  employmentTypeList = [eEmploymentType.FullTime, eEmploymentType.PartTime, eEmploymentType.Contract];
  isCloseJob: boolean = false;
  isHiring: boolean = false;
  isWithDrawingApplicant: boolean = false;
  compensationType: string = "";
  isInitilizingForm: string = "INIT";
  isUpadingHireInfo: boolean = false;
  submitButtonName: string = "Hire";
  compensationTypes: string[] = [
    eHiredCompensationType.Salary,
    eHiredCompensationType.Hourly,
    eHiredCompensationType.FlatRate,
  ];
  placementRecord: PlacementRecord = {
    candidate: {
      id: "0",
    },
    billingClientContact: {
      id: "0",
    },
    jobOrder: {
      id: "0",
    },
    employmentType: "",
    salary: "",
    salaryUnit: "",
    dateBegin: "",
    correlatedCustomText2: "",
    fee: "0.8",
    dateEnd: "",
    // id: "0",
  };

  dateRequiredValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      // Check if the value is a valid date (not empty and is a date)
      const isValidDate = value && !isNaN(new Date(value).getTime());
      // Return null if the value is valid, or a validation error object if not
      return isValidDate ? null : { invalidDate: true };
    };
  }

  ngOnInit(): void {
    this.compensationType = eHiredCompensationType.Salary;
    this.setPaymentFee();
    this.updateHiredApplicantValues();
    this.startDateLimit =
      this.placementRecord.dateBegin?.length > 0 ? new Date(Number(this.placementRecord.dateBegin)) : new Date();
    this.applicantHireForm = this.fb.group({
      startDate: new FormControl(
        this.placementRecord.dateBegin != "" ? new Date(Number(this.placementRecord.dateBegin)) : "",
        this.dateRequiredValidator()
      ),
      endDate: new FormControl(this.settingEndDateValueForForm(), this.dateRequiredValidator()),
      employmentType: new FormControl(this.placementRecord.employmentType, [Validators.required]),
      hoursPerWeek: new FormControl(this.placementRecord.correlatedCustomText2, [
        Validators.required,
        Validators.minLength(1),
      ]),
      compensation: new FormControl(this.placementRecord.salary, [Validators.required, Validators.minLength(1)]),
    });
    this.placementRecord.billingClientContact.id = this._service.bullhornData.clientContact.id;
    this.placementRecord.candidate.id = this.data.selectedApplicant.id;
    this.placementRecord.jobOrder.id = this.data.selectedApplicant.latestJobApplied.jobOrder.id;

    // Subscribe to changes in employmentType control
    this.applicantHireForm.get("employmentType")?.valueChanges.subscribe((value) => {
      // Check if employmentType is equal to a certain value
      if (value === eEmploymentType.FullTime) {
        // Remove validators For Hours per week & endDate
        this.FullTimeSelected();
        // Add validators For Hours per week & Remove endDate
      } else if (value === eEmploymentType.PartTime) {
        this.PartTimeSelected();
      } else if (value === eEmploymentType.Contract) {
        // Add validators For Hours per week & endDate
        this.ContractSelected();
      }
    });

    this.setEmploymentType(this.placementRecord.employmentType);
  }

  currentPlacementHasEndDate(): boolean {
    if (this.placementRecord.dateEnd !== null && this.placementRecord.dateEnd !== "") {
      return true;
    }
    return false;
  }

  settingEndDateValueForForm() {
    if (this.currentPlacementHasEndDate()) {
      return new Date(Number(this.placementRecord.dateEnd));
    }
    return "";
  }

  updateEndDate() {
    this.applicantHireForm.controls.endDate.setValue("");
    this.noEndDate = !this.noEndDate;
    this.checkEndDateStatus();
  }

  checkEndDateStatus() {
    if (this.noEndDate) {
      this.applicantHireForm.controls.endDate.disable();
    } else {
      this.applicantHireForm.controls.endDate.enable();
    }
  }

  toggleEndDateStatusBetweenEmploymentTypes() {
    if (this.placementRecord.dateEnd?.length === 0 || this.placementRecord.dateEnd === null) {
      this.noEndDate = true;
    } else {
      this.noEndDate = false;
    }
    this.checkEndDateStatus();
  }

  setPaymentFee() {
    let subscription = this._service.bullhornData.stripeProduct;
    if (subscription === "Grow") {
      this.placementRecord.fee = ePlacementFee.Grow;
    }
  }

  setEmploymentType(employmentType: string) {
    if (employmentType === eEmploymentType.FullTime) {
      this.FullTimeSelected(this.isInitilizingForm);
    } else if (employmentType === eEmploymentType.PartTime) {
      this.PartTimeSelected(this.isInitilizingForm);
    } else if (employmentType === eEmploymentType.Contract) {
      this.ContractSelected(this.isInitilizingForm);
    }
  }

  updateHiredApplicantValues() {
    if (this._service.isApplicantHired(this.data.selectedApplicant)) {
      let placementRecord = this._service.bullhornData.placements?.filter(
        (record) =>
          record?.candidate?.id === this.data.selectedApplicant.id &&
          record?.jobOrder?.id === this.data.selectedApplicant.latestJobApplied.jobOrder.id
      )[0];
      this.placementRecord = placementRecord;
      this.compensationType = placementRecord.salaryUnit;
      this.isUpadingHireInfo = true;
      this.submitButtonName = "Update";
      this.selectCompensationType();
    }
  }

  openDeleteDialog() {
    const deletionRef = this.dialog.open(DialogConfirmDeleteComponent, {
      width: "600px",
      data: {
        deleteItemType: "Hire Applicant",
        deleteItemName: `${this.data.selectedApplicant.firstName} ${this.data.selectedApplicant.lastInitial}'s Hired Application`,
        description: "view this applicant hire documentation and this applicant will be given a status of rejected",
      },
    });
    deletionRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.isWithDrawingApplicant = true;
        await this._service.deletePlacementRecord(this.placementRecord.id.toString());
        this.removePlacementRecordFromBullhornData();
        await this._service.updateApplicantJobStatus("Internally Rejected", this.data.selectedApplicant, true);
        this.isWithDrawingApplicant = false;
        this.close();
      }
    });
  }

  // get methods:

  get _employmentType(): string {
    return this.applicantHireForm.value.employmentType;
  }

  get _compensation(): string {
    return this.applicantHireForm.value.compensation;
  }

  get _startDate(): string {
    return this.applicantHireForm.value.startDate;
  }

  // end get method region

  // Employment Type methods

  isContract(): boolean {
    return this._employmentType === eEmploymentType.Contract;
  }

  isPartTime(): boolean {
    return this._employmentType === eEmploymentType.PartTime;
  }

  isFullTime(): boolean {
    return this._employmentType === eEmploymentType.FullTime;
  }

  FullTimeSelected(isIntializing: string = null) {
    if (!isIntializing) {
      this.applicantHireForm.controls.hoursPerWeek.setValue("40");
      this.applicantHireForm.controls.endDate.setValue(this.settingEndDateValueForForm());
    }
    this.applicantHireForm.get("hoursPerWeek")?.clearValidators();
    this.applicantHireForm.get("hoursPerWeek")?.updateValueAndValidity();
    this.applicantHireForm.get("endDate")?.clearValidators();
    this.applicantHireForm.get("endDate")?.updateValueAndValidity();
    this.toggleEndDateStatusBetweenEmploymentTypes();
  }

  PartTimeSelected(isIntializing: string = null) {
    if (!isIntializing) {
      this.applicantHireForm.controls.hoursPerWeek.setValue(this.placementRecord.correlatedCustomText2);
      this.applicantHireForm.controls.endDate.setValue(this.settingEndDateValueForForm());
    }
    this.applicantHireForm.get("endDate")?.clearValidators();
    this.applicantHireForm.get("endDate")?.updateValueAndValidity();
    this.applicantHireForm.get("hoursPerWeek")?.setValidators([Validators.required, Validators.minLength(1)]);
    this.applicantHireForm.get("hoursPerWeek")?.updateValueAndValidity();
    this.toggleEndDateStatusBetweenEmploymentTypes();
  }

  ContractSelected(isIntializing: string = null) {
    if (!isIntializing) {
      this.applicantHireForm.controls.hoursPerWeek.setValue(this.placementRecord.correlatedCustomText2);
      this.applicantHireForm.controls.endDate.setValue(this.settingEndDateValueForForm());
    }
    this.applicantHireForm.get("hoursPerWeek")?.setValidators([Validators.required, Validators.minLength(1)]);
    this.applicantHireForm.get("hoursPerWeek")?.updateValueAndValidity();
    this.applicantHireForm.get("endDate")?.setValidators([Validators.required, Validators.minLength(1)]);
    this.applicantHireForm.get("endDate")?.updateValueAndValidity();
    this.toggleEndDateStatusBetweenEmploymentTypes();
  }

  // End of Employment Type methods

  // Compensation Type:

  selectCompensationType() {
    if (this.isSalary()) {
      this.setCompensationTypeToSalary(this.isInitilizingForm);
    } else if (this.isHourly()) {
      this.setCompensationTypeToHourly(this.isInitilizingForm);
    } else if (this.isFlatRate()) {
      this.setCompensationTypeToContract(this.isInitilizingForm);
    }
  }

  setCompensationTypeToHourly(isIntializingForm: string = null) {
    if (isIntializingForm == null) {
      this.applicantHireForm.get("compensation").setValue("");
    }
    this.compensationType = eHiredCompensationType.Hourly;
    this.compensationTitle = "Hourly";
    this.compensationPlaceHolder = "Enter Applicant Hourly Pay";
  }

  setCompensationTypeToSalary(isIntializingForm: string = null) {
    if (isIntializingForm == null) {
      this.applicantHireForm.get("compensation").setValue("");
    }
    this.compensationType = eHiredCompensationType.Salary;
    this.compensationTitle = "Salary";
    this.compensationPlaceHolder = "Enter Applicant Salary Pay";
  }

  setCompensationTypeToContract(isIntializingForm: string = null) {
    if (isIntializingForm == null) {
      this.applicantHireForm.get("compensation").setValue("");
    }
    this.compensationType = eHiredCompensationType.FlatRate;
    this.compensationTitle = "Flat Rate";
    this.compensationPlaceHolder = "Enter Applicant Flat Rate Pay";
  }

  isHourly(): boolean {
    return this.compensationType === eHiredCompensationType.Hourly;
  }

  isSalary(): boolean {
    return this.compensationType === eHiredCompensationType.Salary;
  }

  isFlatRate(): boolean {
    return this.compensationType === eHiredCompensationType.FlatRate;
  }

  // End Compensation Type:

  toggleCloseJob() {
    this.isCloseJob = !this.isCloseJob;
  }

  isFormValid(): boolean {
    return this.applicantHireForm.valid ? true : false;
  }

  async closeJob() {
    if (this.isCloseJob) {
      let selectedJob = this._service.bullhornData.jobs.filter(
        (job) => job.id == this.data.selectedApplicant.latestJobApplied.jobOrder.id
      )[0];
      selectedJob.status = eJobCardStatus.Closed;
      await this._service.upsertJob(selectedJob);
    }
  }

  async addPlacementRecord() {
    const addedRecord = await this._service.addPlacementRecordForApplicant(this.placementRecord);
    // let record = JSON.parse(addedRecord);
    let placementId = addedRecord.id;
    this.placementRecord.id = placementId;
    this.addPlacementRecordToBullhornData();
  }

  async updatePlacementRecord() {
    await this._service.updatePlacementRecord(this.placementRecord, this.placementRecord.id.toString());
    this.updatePlacementRecordToBullhornData();
  }

  addPlacementRecordToBullhornData() {
    if (this._service.bullhornData.placements) {
      this._service.bullhornData.placements.push(this.placementRecord);
    } else {
      this._service.bullhornData.placements = [this.placementRecord];
    }
  }

  updatePlacementRecordToBullhornData() {
    let placementRecord = this._service.bullhornData.placements?.filter(
      (record) =>
        record?.candidate?.id === this.data.selectedApplicant.id &&
        record?.jobOrder?.id === this.data.selectedApplicant.latestJobApplied.jobOrder.id
    )[0];
    let index = this._service.bullhornData.placements.indexOf(placementRecord);
    this._service.bullhornData.placements[index] = this.placementRecord;
  }

  removePlacementRecordFromBullhornData() {
    let placementRecord = this._service.bullhornData.placements?.filter(
      (record) =>
        record?.candidate?.id === this.data.selectedApplicant.id &&
        record?.jobOrder?.id === this.data.selectedApplicant.latestJobApplied.jobOrder.id
    )[0];
    let index = this._service.bullhornData.placements.indexOf(placementRecord);
    this._service.bullhornData.placements.splice(index, 1);
  }

  setPlacementRecordValues() {
    this.placementRecord.salaryUnit = this.compensationType;
    this.placementRecord.salary = this.applicantHireForm.value.compensation;
    this.placementRecord.dateBegin = new Date(this.applicantHireForm.value.startDate).getTime().toString();
    this.placementRecord.employmentType = this.applicantHireForm.value.employmentType;
    this.placementRecord.correlatedCustomText2 = "40";
    this.placementRecord.dateEnd = "";
    if (!this.isFullTime()) {
      this.placementRecord.correlatedCustomText2 = this.applicantHireForm.value.hoursPerWeek;
    }
    if (this.isContract()) {
      this.placementRecord.dateEnd =
        this.applicantHireForm.value.endDate && this.applicantHireForm.value.endDate != ""
          ? new Date(this.applicantHireForm.value.endDate).getTime().toString()
          : "";
    }
  }

  async hireApplicant() {
    this.isHiring = true;
    this.setPlacementRecordValues();
    await this.closeJob();
    if (!this._service.isApplicantHired(this.data.selectedApplicant)) {
      this.addPlacementRecord();
    } else {
      this.updatePlacementRecord();
    }
    this.isHiring = false;
    this.close(eHireApplicantResult.Hired);
  }

  close(hireStatus: string = null) {
    this.dialogRef.close(hireStatus);
  }
}
