import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "defaultEmptyNA",
})
export class DefaultEmptyNAPipe implements PipeTransform {
  transform(value: any, defaultFieldNameToDeliver?: string): string {
    const na = "N/A";

    // Not a value? returns default
    if (!value) return na;

    // Value can be an object, in that case check if an object's property wants to deliver instead...
    if (defaultFieldNameToDeliver) {
      if (!value[defaultFieldNameToDeliver]) {
        return na;
      }
      return value[defaultFieldNameToDeliver];
    }

    // Returns the value...
    return value;
  }
}
