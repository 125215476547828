import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChildren } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DialogConfirmCloseComponent } from "src/app/shared/components/dialog-confirm-close/dialog-confirm-close.component";
import { SnackbarData, SnackbarMsgComponent } from "src/app/shared/components/snackbar-msg/snackbar-msg.component";
import { EmployerOnboardingService } from "../../../../employer-root/employer-root.service";
import { UserService } from "src/app/user/user-root/user-root.service";
import { iApplicantListModel, iBullhornJobSubmission } from "src/app/shared/models/applicant-candidate.model";
import { BullhornApplicantModel } from "src/app/shared/models/bullhorn.model";
import { eResponseMsg } from "src/app/shared/enums/employer.enums";
import { iStatusDropdown } from "src/app/user/employer/employer-root/employer.models";
import { SendBirdService } from "src/app/services/send-bird.service";
import { ViewApplicantsPaneComponent } from "../../../list/view-applicants-pane/view-applicants-pane.component";
import { SearchCandidatesPaneComponent } from "../../../list/search-candidates-pane/search-candidates-pane.component";
import { Clipboard } from "@angular/cdk/clipboard";
import { environment } from "src/environments/environment";
import { MatMenuTrigger } from "@angular/material/menu";
import { OnboardingRootService } from "src/app/user/onboarding/onboarding-root/onboarding-root.service";
import { eCandidateTags } from "src/app/shared/enums/candidate.enums";
import { ApplicantStatusMenuEvent } from "src/app/shared/components/applicant-status-menu/applicant-status-menu.component";
import {
  DialogHireApplicantComponent,
  eHireApplicantResult,
} from "src/app/shared/components/dialog-hire-applicant/dialog-hire-applicant.component";
import { eCandidateStatus } from "src/app/shared/enums/candidate.enums";

export interface DialogData {
  parentcomponent: ViewApplicantsPaneComponent | SearchCandidatesPaneComponent;
  candidateList: iApplicantListModel[];
  selectedId: string;
  selectedJob: iBullhornJobSubmission;
}

@Component({
  selector: "app-dialog-applicant-details",
  templateUrl: "./dialog-applicant-details.component.html",
  styleUrls: ["./dialog-applicant-details.component.scss"],
})
export class DialogApplicantDetailsComponent implements AfterViewInit, OnInit {
  //#region Setup
  isLoading: boolean = true;
  isLoadingDetails: boolean = true;
  isLoadingJob: boolean = true;
  isUpdatingStatus: boolean = false;
  selectedMenuTab = new FormControl(0);
  firstFormGrp: FormGroup;
  messagesBadge: number = 0;
  selectedCandidate: iApplicantListModel;
  selectedBouloModel: BullhornApplicantModel;
  jobsAppliedList: iBullhornJobSubmission[];
  candidateIndex: number = null;
  selectedCandidateName: string;
  isApplicantSaved: boolean = false;
  saveToggleClicked: boolean = false;
  isBusy: boolean = false;
  emailClicked: boolean = false;
  linkClicked: boolean = false;
  url: String = "";
  durationInSeconds = 3;
  @ViewChildren(MatMenuTrigger) menuTriggers: MatMenuTrigger[];

  constructor(
    public applicantDialogRef: MatDialogRef<DialogApplicantDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public _service: EmployerOnboardingService,
    public _userService: UserService,
    private confirmationDialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _messageService: SendBirdService,
    private elementRef: ElementRef,
    public clipboard: Clipboard,
    private onboardingService: OnboardingRootService
  ) {
    //Increase Dialog size by percent (can only be set to px when called to open)
    applicantDialogRef.updateSize("98%", "95%");

    //prevent accidental closing of the dialog window
    applicantDialogRef.disableClose = true;
    applicantDialogRef.backdropClick().subscribe(() => {
      this.openConfirmationDialog();
    });
  }

  ngAfterViewInit() {
    // Calculate the maximum height based on screen size and dialog size
    // Get a reference to the content container
    // const contentContainer = this.elementRef.nativeElement.querySelector(".component-pane-wrapper");
    // this.setPaneHeight(contentContainer, false);
    // const contentDoubleMenuContainer = this.elementRef.nativeElement.querySelector(".component-double-menu-pane-wrapper");
    // Set the max-height of the content container
  }

  async loadCandidateDetails() {
    this.isLoading = true;
    this.isLoadingDetails = true;
    this.isLoadingJob = true;
    this.isUpdatingStatus = false;
    this.selectedCandidate = null;
    this.selectedBouloModel = null;
    this.selectedMenuTab.setValue(0);

    await this.loadProfile();
    await this.loadMessages(); //Currently don't display badge for notifications for app deets unread messages

    const contentDoubleMenuContainer = this.elementRef.nativeElement.querySelector(".component-double-menu-pane-wrapper");
    this.setPaneHeight(contentDoubleMenuContainer, true);

    const cont = this.elementRef.nativeElement.querySelector(".dialog-content");
    this.setPaneHeight(contentDoubleMenuContainer, false);

    this.isLoading = false;
  }

  async loadProfile(): Promise<void> {
    if (this.candidateIndex === null) {
      if (this.data.selectedJob != null) {
        this.candidateIndex = this.data.candidateList.findIndex((x) => x.latestJobApplied.id === this.data.selectedJob.id);
      } else {
        this.candidateIndex = this.data.candidateList.findIndex((x) => x.id === this.data.selectedId.toString());
      }
    }

    this.selectedCandidate = this.data.candidateList[this.candidateIndex];
    this.selectedCandidateName = this.selectedCandidate.firstName + " " + this.selectedCandidate.lastInitial;
    this.selectedBouloModel = await this._service.getApplicantBullhornDataByEmail(
      this.selectedCandidate.email,
      this.selectedCandidate.id
    );
    this.isLoadingDetails = false;

    this.isLoadingJob = false;
    this.jobsAppliedList = await this._service.getJobDetailsForCandidateDetails(
      this._service.bullhornData.id,
      (this.selectedCandidate.id)
    );

    const contentDoubleMenuContainer = this.elementRef.nativeElement.querySelector(".component-double-menu-pane-wrapper");
    this.setPaneHeight(contentDoubleMenuContainer, true);

    const cont = this.elementRef.nativeElement.querySelector(".dialog-content");
    this.setPaneHeight(contentDoubleMenuContainer, false);
    this.messagesBadge =
      Number(this.selectedBouloModel?.id) > 0
        ? await this._messageService.getUnreadMessageCountForUser(this.selectedBouloModel.email.toString())
        : 0;

    this.isLoading = false;
  }
  async loadMessages(): Promise<void> {
    this.messagesBadge =
      Number(this.selectedBouloModel?.id) > 0
        ? await this._messageService.getUnreadMessageCountForUser(this.selectedBouloModel.email.toString(), false)
        : 0;
  }

  setPaneHeight(container: any, hasDoubleMenus: boolean = false): void {
    const screenHeight = window.innerHeight;
    const dialogHeight = screenHeight * 0.96; // 96% of screen height

    const cardHeaderHeight = 78;
    const jobHistoryHeight = this._service.isMobileScreen ? 120 : 133;
    const tabHeights = hasDoubleMenus ? 115 : 30;
    const actionBarHeight = 78;
    const paneHeight = dialogHeight - cardHeaderHeight - jobHistoryHeight - tabHeights - actionBarHeight;
    if (container) {
      container.style.maxHeight = `${paneHeight}px`;
    }
  }

  async ngOnInit(): Promise<void> {
    await this.loadCandidateDetails();
    this.setIsSaved();
    this.url = `${environment.appUrl}/user/employer/candidates/${this.selectedCandidate.email}/${this.selectedCandidate.id}`;
  }

  //#endregion Setup

  //#region Gets
  get _verified(): boolean {
    return this.selectedCandidate?.bouloTags?.includes(eCandidateTags.Active_Verified);
  }

  get _getMobilePhone(): string {
    return this.selectedBouloModel?.mobile?.length > 0 ? this.selectedBouloModel.mobile : "";
  }
  get _getFavoriteIcon(): string {
    if (!this.selectedCandidate?.id || !this._service.bullhornData.savedCandidateIds?.includes((this.selectedCandidate?.id))) {
      return "star_outline";
    } else {
      return "star_filled";
    }
  }

  get _getFavoriteIconLabel(): string {
    if (!this.selectedCandidate?.id || !this._service.bullhornData.savedCandidateIds?.includes((this.selectedCandidate?.id))) {
      return "Add to Saved";
    } else {
      return "Remove from Saved";
    }
  }

  get isMobile() {
    return this._service.isMobileScreen;
  }

  get _getCandidateName() {
    let name = "Candidate Details";
    if (this.selectedCandidate?.firstName) {
      name = this.selectedCandidate.firstName.toLocaleLowerCase();
      name += this.selectedCandidate.lastInitial ? ` ${this.selectedCandidate.lastInitial.toLocaleUpperCase()}.` : "";
    }

    return name;
  }

  get jobAppliedDetails(): iBullhornJobSubmission {
    return this.selectedCandidate.latestJobApplied;
  }

  get _getJobTitle() {
    return this._hasJobAppliedTitle ? this.jobAppliedDetails?.jobOrder?.title : "No recent job applications";
  }

  get _hasJobAppliedTitle(): boolean {
    return this.jobAppliedDetails?.jobOrder?.title && this.jobAppliedDetails?.jobOrder?.title?.length > 0;
  }

  get _getJobAppliedDate() {
    if (this.jobAppliedDetails && (!this.selectedCandidate.dateApplied || this.selectedCandidate.dateApplied.length < 1)) {
      this.selectedCandidate.dateApplied = this._userService.ConvertEpochToDate(this.jobAppliedDetails.dateAdded);
    }
    return this.selectedCandidate.dateApplied ? this.selectedCandidate.dateApplied : "";
  }

  get _showAppliedDate() {
    return this.jobAppliedDetails && this.jobAppliedDetails?.dateAdded && this.jobAppliedDetails?.dateAdded.length > 0;
  }

  get _hasJobAppliedDetails(): boolean {
    return !!(this.jobAppliedDetails && this.jobAppliedDetails != null && this.jobAppliedDetails.jobOrder);
  }

  get _getApplicantStatus(): string {
    const jobStatus: string = this._hasJobAppliedDetails ? this.jobAppliedDetails.status : "No Status Set";
    return jobStatus;
  }

  get _applicantStatusDDL(): iStatusDropdown[] {
    return this._service.applicantStatusDropdown;
  }

  get _busyApplicantStatus(): boolean {
    return this.isLoading || this.isLoadingJob || this.isUpdatingStatus;
  }

  get _getSelectedCandidate(): iApplicantListModel {
    if (
      this.data?.candidateList?.length > 0 &&
      this.candidateIndex >= 0 &&
      this.candidateIndex < this.data.candidateList.length
    ) {
      return this.data.candidateList[this.candidateIndex];
    }
    return null;
  }
  get _getSelectedJob(): iBullhornJobSubmission {
    return this.data?.selectedJob;
  }

  //#endregion Gets

  displayLabel(tabIndex: number) {
    return !this._service.isMobileScreen;
  }

  isApplicantHired(): boolean {
    return (
      this.selectedCandidate.status === eCandidateStatus.Placed && this._service.isApplicantHired(this.selectedCandidate)
    );
  }

  async updateApplicantJobStatus(applicantEvent: ApplicantStatusMenuEvent) {
    this.isUpdatingStatus = true;
    if (
      applicantEvent.status === eCandidateStatus.Placed &&
      applicantEvent.hasJobAppliedDetails &&
      !this._service.isApplicantHired(applicantEvent.applicant)
    ) {
      this.hireApplicantDialog(applicantEvent);
    } else {
      const candidate = await this._service.updateApplicantJobStatus(
        applicantEvent.status,
        applicantEvent.applicant,
        applicantEvent.hasJobAppliedDetails
      );
      this.selectedCandidate = candidate ? candidate : this.selectedCandidate;
      this.isUpdatingStatus = false;
    }
  }

  hireApplicantDialog(applicantEvent: ApplicantStatusMenuEvent, index?: number) {
    const dialogRef = this.confirmationDialog.open(DialogHireApplicantComponent, {
      width: "500px",
      data: {
        selectedApplicant: applicantEvent.applicant,
      },
    });

    dialogRef.afterClosed().subscribe(async (hireStatus) => {
      if (eHireApplicantResult.Hired === hireStatus) {
        const applicant = await this._service.updateApplicantJobStatus(
          applicantEvent.status,
          applicantEvent.applicant,
          applicantEvent.hasJobAppliedDetails
        );
      }
      this.isUpdatingStatus = false;
    });
  }

  getParentStatus(childStatus: string): string {
    return this._service.applicantChildStatusMap.get(childStatus);
  }

  //#region Open, Close, & Save
  openConfirmationDialogWithPress($event: any) {
    event.stopPropagation();
    this.openConfirmationDialog();
  }
  openConfirmationDialog() {
    const confirmationRef = this.confirmationDialog.open(DialogConfirmCloseComponent, { width: "600px" });

    confirmationRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.applicantDialogRef.close();
      } else {
        // User cancelled the dialog closing
      }
    });
  }

  updateBullhornData() {}

  async nextCandidate() {
    if (this.candidateIndex + 1 < this.data.candidateList.length) {
      this.candidateIndex += 1;
    } else {
      this.candidateIndex = 0;
    }
    await this.loadCandidateDetails();
  }

  async previousCandidate() {
    if (this.candidateIndex - 1 < 0) {
      this.candidateIndex = this.data.candidateList.length - 1;
    } else {
      this.candidateIndex -= 1;
    }
    await this.loadCandidateDetails();
  }

  saveAll() {
    this.updateBullhornData();
    this.saveSuccessMsg();
  }

  saveSuccessMsg() {
    this._snackBar.openFromComponent(SnackbarMsgComponent, {
      duration: 5000,
      data: {
        boldMsg: "Changes have been saved!",
        detailsMsg: "",
      } as SnackbarData,
      panelClass: ["snackbar-success"], //applies success styling, remove this line for generic style
    });
  }
  //#endregion Open, Close, & Save

  // Icon Functionality:

  // Save Functionality:

  setIsSaved() {
    if (!this.selectedCandidate?.id || !this._service.bullhornData.savedCandidateIds?.includes((this.selectedCandidate.id))) {
      this.isApplicantSaved = false;
    } else {
      this.isApplicantSaved = true;
    }
  }

  toggleSaveCandidate() {
    if (!this.selectedCandidate?.id) {
      return;
    }

    this._service.isDirty = true;
    this.isApplicantSaved = !this.isApplicantSaved;
    this.saveToggleClicked = true;
    setTimeout(() => {
      this.saveToggleClicked = false;
    }, 2000);
    this.data.parentcomponent.toggleSaveCandidate(this.selectedCandidate.id);
  }

  // download 360 Profile Icon Functionality:

  snackMsg(bold: string) {
    this._service.generalMsg(bold, "", 3);
  }

  async downloadCandidateBoulo360() {
    this.isBusy = true;
    await this._userService.downloadCandidateBoulo360Profile(this.selectedCandidate);
    this.isBusy = false;
    this.snackMsg(`${this.selectedCandidate.firstName}'s Boulo360 Profile is downloaded!`);
  }

  mobileDownLoad360Profile(event: any) {
    event.stopPropagation();
    if (!this.isBusy) {
      this.downloadCandidateBoulo360();
    }
  }

  // share & email Icon Functionality:

  openSnackBar(msg: string) {
    this._service.generalMsg(msg, "", 3);
  }
}
