import { Component, Input, Output, EventEmitter, ViewChildren } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { iEmployerJobModel, iJobCardModel } from "src/app/shared/models/job.model";
import { environment } from "src/environments/environment";
import { EmployerOnboardingService } from "../../employer-root/employer-root.service";
import { Router } from "@angular/router";
import { LoggerService } from "src/app/shared/services/logger.service";
import { eValues } from "../dialog-employer-job-edit/dialog-employer-job-edit.component";

export enum eJobPostingCardEvent {
  Select = "Select",
  Clone = "Clone",
  Edit = "Edit",
}
export interface iJobPostingCardEvent {
  event: eJobPostingCardEvent;
  job: iEmployerJobModel;
}

@Component({
  selector: "app-job-posting-card",
  templateUrl: "./job-posting-card.component.html",
  styleUrls: ["./job-posting-card.component.scss"],
})
export class JobPostingCardComponent {
  @Input() jobs: iEmployerJobModel[];
  @Input() tab: string;
  @Input() isBusy: boolean;
  @Output() reactivateID = new EventEmitter<number>();
  @Output() updateJobStatus = new EventEmitter<iJobStatusUpdate>();
  @Output() cardEvent = new EventEmitter<iJobPostingCardEvent>();
  @Output() findCandidateEvent = new EventEmitter<iEmployerJobModel>();
  @ViewChildren(MatMenuTrigger) menuTriggers: MatMenuTrigger[];

  @Input() noJobsListedDesc: string;
  clickedJobID: number;
  url: String = `${environment.appUrl}/user/candidate/jobs/`;
  durationInSeconds = 3;

  constructor(
    public dialog: MatDialog,
    private employerService: EmployerOnboardingService,
    private router: Router
  ) {}

  openSnackBar() {
    let msg = "Copied the Job URL to Clipboard";
    this.employerService.generalMsg(msg, "", 3);
  }

  closeShareMenu() {
    this.menuTriggers["_results"].forEach((menuTrig) => menuTrig.closeMenu());
  }

  openShareMenu(event: any, jobId: number) {
    event.stopPropagation();
    let selectedJob = this.jobs.find((e) => e.id === jobId.toString());
    this.url = this.url = `${environment.appUrl}/user/candidate/jobs/${selectedJob.id}`;
  }

  get _isMobile(): boolean {
    return this.employerService.isMobileScreen;
  }

  cloneSelectedJob(selectedJob: iEmployerJobModel) {
    LoggerService.debug("Cloned Job ID: ", selectedJob.id);
    this.cardEvent.emit({ event: eJobPostingCardEvent.Clone, job: selectedJob });
    // this.cloneJob.emit(selectedJob);
  }

  editSelectedJob(selectedJob: iEmployerJobModel) {
    LoggerService.debug("Edit Job ID: ", selectedJob.id);
    this.cardEvent.emit({ event: eJobPostingCardEvent.Edit, job: selectedJob });
    // this.cloneJob.emit(selectedJob);
  }

  jobSelected(id: number) {
    this.clickedJobID = id;
    let selectedJob = this.jobs?.find((e) => e.id == id.toString());
    this.cardEvent.emit({ event: eJobPostingCardEvent.Select, job: selectedJob });
    // this.selectJob.emit(selectedJob);
  }

  verticalMenu(event: Event): void {
    event.stopPropagation();
  }

  displayNoJobListingDesc(): boolean {
    if (this.jobs?.length === 0) {
      return true;
    } else if (this.tab === "All") {
      return false;
    }
    for (let job of this.jobs) {
      if (job.status === this.tab) {
        return false;
      }
    }
    return true;
  }

  get _getJobs(): iEmployerJobModel[] {
    return this.jobs;
  }
  get _statusDraft(): string {
    return eJobCardStatus.Draft;
  }
  get _statusActive(): string {
    return eJobCardStatus.Active;
  }
  get _statusClosed(): string {
    return eJobCardStatus.Closed;
  }
  get _statusArchive(): string {
    return eJobCardStatus.Archive;
  }
  _getApplicantCount(job: iEmployerJobModel): number {
    return job?.numSubmissions ?? 0;
  }
  _getJobViews(job: iEmployerJobModel): number {
    return job?.numViews ?? 0;
  }

  _isRemote(job: iEmployerJobModel): boolean {
    return job?.arrangementType === eValues.Remote;
  }
  _showLocation(job: iEmployerJobModel): boolean {
    return job?.arrangementType === eValues.OnSite || job?.arrangementType === eValues.Hybrid;
  }
  _getLocation(job: iEmployerJobModel): string {
    if (job?.workingLocationZip?.trim()?.length > 4) {
      return job.workingLocationZip;
    }
    if (job?.address?.zip?.trim()?.length > 4) {
      return job.address.zip;
    }
    if (job?.locationZip?.trim()?.length > 4) {
      return job.locationZip;
    }

    return "";
  }
  _isStatusActive(job: iEmployerJobModel): boolean {
    return job?.status === eJobCardStatus.Active;
  }
  _isStatusDraft(job: iEmployerJobModel): boolean {
    return job?.status === eJobCardStatus.Draft;
  }
  _isStatusClosed(job: iEmployerJobModel): boolean {
    return job?.status === eJobCardStatus.Closed;
  }
  _isStatusArchived(job: iEmployerJobModel): boolean {
    return job?.status === eJobCardStatus.Archive;
  }
  // _showLocation(job: iJob): boolean {
  //   return job?.arrangementType ===
  // }

  reactivatePosting(event: any, id: number): void {
    event.stopPropagation();
    this.clickedJobID = id;
    this.reactivateID.emit(id);
  }

  splitButtonClick(event: any): void {
    event.stopPropagation();
  }

  findCandidate(event: any, job: iEmployerJobModel): void {
    event.stopPropagation();
    this.findCandidateEvent.emit(job);
  }

  getJobStatus(status: string): string {
    return status === eJobCardStatus.Archive ? "Deleted" : status;
  }

  renderCard(status: string): boolean {
    if (
      status === this.tab ||
      (status === eJobCardStatus.Archive && this.tab === eJobCardStatus.Closed) ||
      this.tab === eJobCardStatus.All
    ) {
      return true;
    }

    return false;
  }

  updateStatus(id: number, newStatus: string) {
    this.clickedJobID = id;
    this.updateJobStatus.emit({ id: id, status: newStatus });
  }

  _showSpinner(id: number): boolean {
    return this.isBusy && this.clickedJobID === id;
  }

  routeToFilteredJobApplicants(job: iJobCardModel) {
    this.employerService.applicantStatusChildTab = this.employerService.applicantTabs[0].index;
    this.router.navigate(["/user/employer/candidates", { selectJobId: job.id }]);
  }
}

export enum eJobCardStatus {
  Active = "Active",
  Draft = "Draft",
  Closed = "Closed",
  Archive = "Archive",
  Inactive = "Inactive",
  All = "All",
  AcceptingCandidates = "Accepting Candidates", //Bullhorn only status
  Covered = "Covered", //Bullhorn only status
  RestartSearch = "Restart Search", //Bullhorn only status
}

export interface iJobStatusUpdate {
  id: number;
  status: string;
}
