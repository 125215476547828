<div class="multiselector-area">
  <div
    [class.selected]="selectedOptions[option]"
    (click)="toggleOption(option)"
    class="multiselector-options"
    *ngFor="let option of options"
  >
    {{ option }}
  </div>
</div>
