//#region Imports
import { Injectable, EventEmitter, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import {
  State,
  PersonalInfo,
  Degree,
  Children,
  AgeRanges,
  Discipline,
  JobPositions,
  JobInfo,
  SizeCompany,
  MaritalState,
  Industries,
  CareerResourceApps,
  Goal,
  Skill,
  Strength,
  AverageDay,
  Weakness,
  AspirationInfo,
  Personality,
  CareerResourceAppsUser,
  PersonalInfoPOST,
  JobInfoPOST,
  AspirationInfoPOST,
  StrengthUser,
  WeaknessUser,
  SkillUserUpdate,
  City,
  Tables,
  iSkillCategory,
} from "./onboarding.models";
import { Image } from "../../../shared/models/commons.model";
import { SecurityService } from "src/app/services/security.service";
import { UserModel } from "src/app/shared/components/user.module";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { CustomizedStyleUpdate } from "src/app/shared/components/simple-item-check/simple-item-check.component";
import { ActivatedRoute, Router } from "@angular/router";
import { BullhornModel, ResumeModel } from "src/app/shared/models/bullhorn.model";
import { tap } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackbarData, SnackbarMsgComponent } from "src/app/shared/components/snackbar-msg/snackbar-msg.component";
// import { iLocation } from "../../employer/employer-root/employer.models";
import * as zipcodes from "zipcodes";
import { LoggerService as Logger } from "src/app/shared/services/logger.service";
import { eCandidateStatus, eCandidateTags } from "src/app/shared/enums/candidate.enums";
import { eResponseMsg } from "src/app/shared/enums/employer.enums";
import { EmployerOnboardingService } from "../../employer/employer-root/employer-root.service";
import { eSuperAdmin, eUserRole } from "src/app/shared/enums/user.enums";
//#endregion Imports
import { UserService } from "../../user-root/user-root.service";
import { eButtonLabels } from "src/app/shared/enums/shared.enums";

const API: string = environment.api;
const proxyApi: string = environment.proxyApi;

@Injectable({
  providedIn: "root",
})
export class OnboardingRootService implements OnInit {
  //#region Setup
  charSep: string = "|-=^=-|";
  currentData = new Subject<data>();
  skillsDataSet: iSkillCategory[] = [];
  tables: Tables = {
    childrens: [{ name: "0" }, { name: "1" }, { name: "2-3" }, { name: "over 4" }],
    ageRanges: [{ name: "18 to 25" }, { name: "26 to 35" }, { name: "36 to 55" }, { name: "over 55" }],
    sizeCompany: [
      { name: "self-employed" },
      { name: "1-10" },
      { name: "11-50" },
      { name: "51-200" },
      { name: "201-500" },
      { name: "501-1000" },
      { name: "1001-5000" },
      { name: "10001+" },
    ],
    maritalState: [
      { id: "married", name: "Married" },
      { id: "single", name: "Single" },
      { id: "other", name: "Other" },
    ],
    personalities: [
      {
        id: "1",
        description: "introvert",
        image: {
          name: "introvert",
          public_url: "assets/svg/introvert_image.svg",
        },
      },
      {
        id: "2",
        description: "in-between",
        image: {
          name: "in-between",
          public_url: "assets/svg/in-between_image.svg",
        },
      },
      {
        id: "3",
        description: "extrovert",
        image: {
          name: "extrovert",
          public_url: "assets/svg/Extrovert_image.svg",
        },
      },
    ],
  };
  user: UserModel;
  bullhornData: BullhornModel;
  lastRemoteBullhornData: string;
  subStepSelectedAspirations?: number;
  subStepSelectedPersonalInfo?: number;
  noGuardValidation = false;
  noConfirmModal = false;
  caller: string;
  fromProfile: boolean;

  simpleItemCheckShakeIt: EventEmitter<CustomizedStyleUpdate> = new EventEmitter<CustomizedStyleUpdate>();
  stopProfileEditing: EventEmitter<boolean> = new EventEmitter<boolean>();
  startProfileEditing: EventEmitter<string> = new EventEmitter<string>();
  aspirationsStepDefault: number;
  isLoaded = false;
  userHasSrc = false;
  isEditing: boolean = false;
  isBusy: boolean = false;
  isMobileScreen: boolean = false;
  screenWidth: number;
  isViewingProfile: boolean = false;
  isDirty = false;
  requestedPath: string = "";
  invalidAccessAttempted: string = "invalidAccessAttempted";

  constructor(
    private securityService: SecurityService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private service: EmployerOnboardingService,
    private _userService: UserService
  ) {
    this.user = securityService.UserRef;
  }

  ngOnInit() {
    this.route.url.subscribe((segments) => {
      Logger.debug("onboarding service route subscription!:", segments);
    });

    Logger.debug(`onboard init router: ${this.router.url}`);
  }

  public async initialize() {
    this.user = this.securityService.UserRef;
    if (this.user) {
      const adminStatus = await this.service.getUserAdminStatus(this.user.email);
      const email =
        adminStatus === eSuperAdmin.Admin && localStorage.getItem(eSuperAdmin.ImitatedEmailKey)
          ? localStorage.getItem(eSuperAdmin.ImitatedEmailKey)
          : this.user.email;
      await this.getBullhornDataByEmail(email)
        .catch(this.defaultBullhornData)
        .finally(async () => {
          await this.verifyCandidateStatus();
          this.isLoaded = true;
          this.userHasSrc = this.bullhornData.id && this.bullhornData?.source?.length > 0;
          this.getPath(this.router.url);
          this.navigateToCandidateDefaultPage();
        });
    } else {
      this.defaultBullhornData();
    }
  }

  defaultBullhornData = () => {
    this.bullhornData = {
      id: "0",
      firstName: this.user?.first_name,
      lastName: this.user?.last_name,
      email: this.user?.email,
      mobile: "",
      roles: [],
      experience: 0,
      degrees: [],
      certifications: [],
      excel: "",
      word: "",
      crm: "",
      presentationSoftware: "",
      teamCommuncationTools: "",
      accountingSoftware: "",
      design: "",
      emailMarketing: "",
      surveyCreation: "",
      projectManagement: "",
      otherSoftware: "",
      web: "",
      instagram: "",
      facebook: "",
      linkedIn: "",
      twitter: "",
      rolePreference: [],
      hoursPreference: [],
      locationPreference: [],
      compensationPreference: "",
      minimumHourlyRate: 0,
      minimumYearlyRate: 0,
      minimumProjectRate: 0,
      jobSpecificSkills: [],
      jobSpecificSkillsDetails: {},
      reportWriting: "",
      softSkills: [],
      resumes: [],
      status: eCandidateStatus.Prequalified,
      city: "",
      state: "",
      zip: "",
      elevatorPitch: "",
      desiredOccupation: [""],
      isSeeking: "",
      savedJobIDs: [],
      savedCompanyIDs: [],
      source: "",
      bouloTags: [],
      smsOptIn: null,
      jobNotificationOptIn: null,
      minimumSalary: 0,
      yearsOfExperience: 0,
    };
  };
  //#endregion Setup

  //#region Navigation
  public getPath(url: string): void {
    Logger.info(`Onboarding-root.service.ts getPath() url: ${url}`);
    if (!url || url?.length < 1 || url.includes("dual")) {
      return;
    }

    this.requestedPath = url;
  }

  public navigateToCandidateDefaultPage(): void {
    if (this.isRouteToSharedJobUrl()) {
      this.navigateToSharedJob();
    } else if (this.user.role === eUserRole.Employer) {
      this.routeEmployerBackToSharedJob();
    } else if (!this.userHasSrc) {
      this.router.navigate(["/user/candidate/onboarding/introduction"], { relativeTo: this.route.firstChild });
    } else if (this.userHasSrc && this.requestedPath?.length > 0) {
      this.router.navigate([this.requestedPath]);
    } else if (this.userHasSrc) {
      this.router.navigate(["/user/candidate/profile"], { relativeTo: this.route.firstChild });
    } else {
      this.router.navigate(["/user/candidate/onboarding/introduction"], { relativeTo: this.route.firstChild });
    }
  }

  navigateToSharedJob(): void {
    this.router.navigate([this.route.snapshot["_routerState"].url]);
    localStorage.setItem("sharedCandidate", this.route.snapshot["_routerState"].url);
  }

  routeEmployerBackToSharedJob(): void {
    let sharedCandidate = localStorage.getItem("sharedCandidate");
    localStorage.setItem(this.invalidAccessAttempted, "employer");
    this.router.navigate([sharedCandidate]);
  }

  private isRouteToSharedJobUrl(): boolean {
    var webPage: string = this.route.snapshot["_routerState"].url;
    let urlPrefix = "/user/candidate/jobs/";
    if (!webPage.startsWith(urlPrefix)) {
      return false;
    }

    const lastIndex = webPage.lastIndexOf("/");
    if (lastIndex > -1) {
      const jobId = webPage.substring(lastIndex + 1, webPage.length);
      if (jobId && this.isNumber(jobId)) {
        return true;
      }
    }

    return false;
  }
  //#endregion Navigation

  //#region Gets
  async getBullhornDataByEmail(email: string) {
    try {
      const bullhornData = await this.http.get<any>(`${proxyApi}users?email=${email}`).toPromise();

      bullhornData.jobSpecificSkillsDetails = bullhornData.jobSpecificSkillsDetails.reduce((prev, curr) => {
        const keyValue = curr.split(": ");
        prev[keyValue[0]] = keyValue[1];

        return prev;
      }, {});
      this.lastRemoteBullhornData = JSON.stringify(bullhornData);
      this.bullhornData = bullhornData;
    } catch (err) {
      Logger.error(`Couldn't pull candidate data from email. Error: ${err.message}`);
      this.defaultBullhornData();
    }
  }

  async getCandidateOptInStatus(candidateEmail: string): Promise<number> {
    try {
      const optInStatus = await this.http.get<number>(`${proxyApi}optin?candidateEmail=${candidateEmail}`).toPromise();
      return optInStatus;
    } catch (err) {
      Logger.error(`Couldn't pull candidate status from candidate id. Error: ${err.message}`);
    }
  }

  getJobPositions(discipline: Discipline): JobPositions[] {
    if (discipline && this.tables.jobPositions && this.tables.jobPositions.length > 0) {
      return this.tables.jobPositions.filter((n) => n.discipline.name === discipline.name);
    }

    return null;
  }

  getJobPositionsByJobId(id?: number): JobPositions[] {
    if (!(id && this.tables.jobPositions && this.tables.jobPositions.length > 0)) {
      return null;
    }

    const jobPositionItems = this.tables.jobPositions.filter((n1) => n1.id === id);
    if (jobPositionItems.length > 0) {
      return this.getJobPositions(jobPositionItems[0].discipline);
    }

    return null;
  }

  getDisciplineByJobId(id?: number): Discipline {
    if (!(id && this.tables.jobPositions && this.tables.jobPositions.length > 0)) {
      return null;
    }

    const jobPositionItems = this.tables.jobPositions.filter((n1) => n1.id === id);
    if (jobPositionItems.length > 0) {
      return jobPositionItems[0].discipline;
    }

    return null;
  }
  //#endregion Gets

  //#region Getters & Setters
  get GetButtonLabel() {
    if (this.noGuardValidation) {
      return eButtonLabels.Save_Changes;
    }

    return eButtonLabels.Continue;
  }
  //#endregion Getters & Setters

  //#region Check isBouloActiveMember

  saveTagUpdates: boolean; //isDirty for tags
  async verifyCandidateStatus(skipSave: boolean = false): Promise<void> {
    this.saveTagUpdates = false;
    const hasContactInfo = this.checkCandidateContactInfo(this.bullhornData);
    const hasWorkHistory = this.checkCandidateWorkHistroy(this.bullhornData);
    const hasAreasOfExpertise = this.checkCandidateAreasOfExpertise(this.bullhornData);
    if (hasContactInfo && hasWorkHistory && hasAreasOfExpertise) {
      this.bullhornData.status = eCandidateStatus.Active;
      this.addCandidateTag(eCandidateTags.Active_Verified);
      this.removeCandidateTag(eCandidateTags.Active_Unverified);
      this.removeCandidateTag(eCandidateTags.Preqqualified_Verified);
    } else if (this.bullhornData.status === eCandidateStatus.Active) {
      //Note: this check is strictly to flag people who shouldn't be Active, but whom we don't
      //want to remove from Active without warning & time to update their profiles to become
      //Boulo Verified.
      this.addCandidateTag(eCandidateTags.Active_Unverified);
      this.removeCandidateTag(eCandidateTags.Active_Verified);
    } else {
      this.bullhornData.status = eCandidateStatus.Prequalified;
      this.addCandidateTag(eCandidateTags.Preqqualified_Verified);
      this.removeCandidateTag(eCandidateTags.Active_Verified);
      this.removeCandidateTag(eCandidateTags.Active_Unverified);
    }

    if (this.saveTagUpdates && !skipSave) {
      this.saveTagUpdates = false;
      await this.updateCandidateTagsById(this.bullhornData.id, this.bullhornData.bouloTags);
    }
  }

  checkCandidateContactInfo(bullhornData: BullhornModel): boolean {
    if (
      this.hasValue(bullhornData.city) &&
      this.hasValue(bullhornData.email) &&
      this.hasValue(bullhornData.zip) &&
      this.hasValue(bullhornData.state) &&
      this.hasValue(bullhornData.mobile) &&
      this.hasValue(bullhornData.firstName) &&
      this.hasValue(bullhornData.lastName)
    ) {
      this.removeCandidateTag(eCandidateTags.Missing_ContactInfo);
      return true;
    }
    this.addCandidateTag(eCandidateTags.Missing_ContactInfo);
    return false;
  }

  // make enum so not hard coded string value
  checkIfCandidateHasUploadedResume(resume: ResumeModel[]): boolean {
    for (let item of resume) {
      if (item.name !== "BouloThreeSixtyProfile.pdf") {
        return true;
      }
    }
    return false;
  }

  checkCandidateWorkHistroy(bullhornData: BullhornModel): boolean {
    const hasUploadedResume = this.checkIfCandidateHasUploadedResume(bullhornData.resumes);
    if (hasUploadedResume || this.bullhornData.roles.length > 0) {
      this.removeCandidateTag(eCandidateTags.Missing_WorkHistory);
      return true;
    }
    this.addCandidateTag(eCandidateTags.Missing_WorkHistory);
    return false;
  }

  checkCandidateAreasOfExpertise(bullhornData: BullhornModel): boolean {
    const hasUniversalSkills = this.checkIfUniversalSkillsCompleted(bullhornData);
    if (
      bullhornData.hoursPreference.length > 0 &&
      bullhornData.locationPreference.length > 0 &&
      bullhornData.rolePreference.length > 0 &&
      bullhornData.jobSpecificSkills.length > 0 &&
      bullhornData.softSkills.length > 0 &&
      Object.entries(bullhornData.jobSpecificSkillsDetails).length > 0 &&
      hasUniversalSkills
    ) {
      this.removeCandidateTag(eCandidateTags.Missing_AreasOfExpertise);
      return true;
    }
    if (hasUniversalSkills) {
      this.addCandidateTag(eCandidateTags.Missing_AreasOfExpertise);
    }
    return false;
  }

  checkIfUniversalSkillsCompleted(bullhornData: BullhornModel): boolean {
    if (
      this.hasValue(bullhornData.accountingSoftware) ||
      this.hasValue(bullhornData.emailMarketing) ||
      this.hasValue(bullhornData.excel) ||
      this.hasValue(bullhornData.design) ||
      this.hasValue(bullhornData.presentationSoftware) ||
      this.hasValue(bullhornData.projectManagement) ||
      this.hasValue(bullhornData.reportWriting) ||
      this.hasValue(bullhornData.word) ||
      this.hasValue(bullhornData.teamCommuncationTools)
    ) {
      this.removeCandidateTag(eCandidateTags.Missing_UniversalSkills);
      return true;
    }
    this.addCandidateTag(eCandidateTags.Missing_UniversalSkills);
    return false;
  }
  //#endregion Check isBouloActiveMember

  //#region Tags
  hasCandidateTag(tag: eCandidateTags): boolean {
    if (!this.bullhornData.bouloTags) {
      this.bullhornData.bouloTags = [];
    }

    if (this.bullhornData.bouloTags?.length === 0) {
      return false;
    }

    const idx = this.bullhornData.bouloTags.findIndex((t) => t === tag);
    if (idx > -1) {
      return true;
    }

    return false;
  }
  addCandidateTag(tag: eCandidateTags): void {
    var hasTag = this.hasCandidateTag(tag);

    if (!hasTag) {
      this.bullhornData.bouloTags.push(tag);
      this.saveTagUpdates = true;
      Logger.debug(`Added boulo tag to candidate: ${tag}`);
    } else {
      Logger.debug(`Skipped adding boulo tag to candidate ${tag} since it already exists`);
    }
  }
  removeCandidateTag(tag: eCandidateTags): void {
    const idx = this.bullhornData.bouloTags?.findIndex((t) => t === tag);
    if (idx > -1) {
      this.bullhornData.bouloTags.splice(idx, 1);
      this.saveTagUpdates = true;
      Logger.debug(`Removed boulo tag to candidate: ${tag}`);
    } else {
      Logger.debug(`Skipped removing boulo tag ${tag} from candidate since it didn't exist.`);
    }
  }
  async updateCandidateTagsById(candidateId: number | string, tags: string[]): Promise<string> {
    try {
      if (!candidateId || !tags || tags.length < 1) {
        Logger.info(`Unable to update candidate boulo tags. Need valid tags and candidate id.`);
        return eResponseMsg.InvalidParameters;
      }

      const tagsString = tags.join(",");

      await this.http.patch<string>(`${proxyApi}candidate/tags?candidateId=${candidateId}`, { tagsString }).toPromise();
      return eResponseMsg.Success;
    } catch (err) {
      Logger.error(`Issue updating candidate's tags. Error: ${err.message}.`);
      return eResponseMsg.Error;
    }
  }
  //#endregion Tags

  //#region Saves/Updates
  async upsertUser() {
    try {
      const bullhornDataString = JSON.stringify(this.bullhornData);
      if (bullhornDataString === this.lastRemoteBullhornData) {
        return;
      }
      this.lastRemoteBullhornData = bullhornDataString;

      //Check the statuses & tags are updated after changes
      await this.verifyCandidateStatus(true);

      const body = { ...this.bullhornData };
      delete body.resumes;

      const jobSpecificSkillsDetailsKeys = Object.keys(this.bullhornData.jobSpecificSkillsDetails);

      const jobSpecificSkillsArray = jobSpecificSkillsDetailsKeys.map(
        (key) => `${key}: ${this.bullhornData.jobSpecificSkillsDetails[key]}`
      );

      body.jobSpecificSkillsDetails = jobSpecificSkillsArray as any;
      const bullhornData = await this.http.put<BullhornModel>(`${proxyApi}users`, body).toPromise();

      bullhornData.jobSpecificSkillsDetails = {};
      this.bullhornData.id = bullhornData.id;

      for (let i = 0; i < this.bullhornData.roles.length; i++) {
        this.bullhornData.roles[i].id = bullhornData.roles[i].id;
      }

      for (let i = 0; i < this.bullhornData.degrees.length; i++) {
        this.bullhornData.degrees[i].id = bullhornData.degrees[i].id;
      }
    } catch (err) {
      Logger.error(`Error saving user. Error: ${err.message}`);
    }
  }

  async updateCandidateStatusById(candidateId: number | string, status: string): Promise<string> {
    try {
      if (!candidateId || !status || status?.trim().length < 1) {
        Logger.info(`Unable to update candidate status. Need valid status and candidate id.`);
        return eResponseMsg.InvalidParameters;
      }

      await this.http.patch<string>(`${proxyApi}candidate/status?candidateId=${candidateId}`, { status }).toPromise();
      return eResponseMsg.Success;
    } catch (err) {
      Logger.error(`Issue updating candidate's status. Error: ${err.message}.`);
      return eResponseMsg.Error;
    }
  }

  //#endregion Saves/Updates

  //#region Boulo360 & Resume

  private toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async uploadResumeWithoutParse(resume: ResumeModel) {
    const fileUpload = {
      fileType: "RESUME",
      name: resume.file.name,
      fileContent: (await this.toBase64(resume.file)) as string,
    };

    fileUpload.fileContent = fileUpload.fileContent.replace(/^data:(.*,)?/, "");
    await this.http
      .post(`${proxyApi}parse-resume/${this.bullhornData.id}`, fileUpload, {
        headers: { "Content-Type": resume.file.type },
      })
      .pipe(
        tap((result: any) => {
          resume.id = result.id;
        })
      )
      .toPromise();
  }

  async uploadThreeSixty(resume: ResumeModel) {
    this.http.post(`${proxyApi}three-sixty-resume/${this.bullhornData.id}`, "").toPromise();
  }

  async postThreeSixty() {
    this.http.post(`${proxyApi}three-sixty-resume/${this.bullhornData.id}`, "").toPromise();
  }

  async downloadThreeSixty(): Promise<Blob> {
    await this.cleanUp();
    const response = await this.http
      .post(`${proxyApi}three-sixty-resume/${this.bullhornData.id}`, JSON.stringify({ download: true }), {
        responseType: "blob",
      })
      .toPromise();

    return response;
  }

  async cleanUp() {
    let arr = this.bullhornData.softSkills;
    arr = arr.filter((item, index) => arr.indexOf(item) === index);
    if (arr.length > 3) arr = arr.splice(0, 3);
    this.bullhornData.softSkills = arr;

    await this.upsertUser();
  }

  async parseResume(resume: ResumeModel) {
    const fileUpload = {
      fileType: "RESUME",
      name: resume.file.name,
      fileContent: (await this.toBase64(resume.file)) as string,
    };
    fileUpload.fileContent = fileUpload.fileContent.replace(/^data:(.*,)?/, "");
    await this.http
      .post(`${proxyApi}parse-resume/${this.bullhornData.id}`, fileUpload, {
        headers: { "Content-Type": resume.file.type },
      })
      .pipe(
        tap((result: any) => {
          if (!!result.candidateWorkHistory) {
            const sortedHistory = result.candidateWorkHistory.sort((a, b) => {
              if (a.startDate > b.startDate) {
                return 1;
              }

              if (a.startDate < b.startDate) {
                return -1;
              }

              return 0;
            });
            const timespan = sortedHistory.reduce(
              (acc, curr) => {
                if (acc.lastUsed && acc.lastUsed.endDate > curr.endDate) {
                  return acc;
                }

                if (acc.lastUsed?.endDate < curr.endDate && acc.lastUsed.endDate > curr.startDate) {
                  acc.totalTime += acc.lastUsed.endDate - curr.endDate;
                  acc.lastUsed = curr;

                  return acc;
                }

                acc.totalTime += curr.endDate - curr.startDate;
                acc.lastUsed = curr;

                return acc;
              },
              { totalTime: 0 }
            );
            this.bullhornData.experience = Math.floor(timespan.totalTime / (60 * 60 * 24 * 365 * 1000));
            const roles = result.candidateWorkHistory.map((workHistory) => {
              return {
                id: workHistory.id,
                position: workHistory.title,
                company: workHistory.companyName,
                startDate: new Date(workHistory.startDate).toDateString(),
                endDate: new Date(workHistory.endDate).toDateString(),
                details: workHistory.comments,
              };
            });
            this.bullhornData.roles.push(...roles);
          }

          if (!!result.candidateEducation) {
            const degrees = result.candidateEducation?.map((education) => {
              return {
                id: education.id,
                school: education.school,
                type: education.degree,
                major: education.major,
                startDate: new Date(education.startDate).toDateString(),
                endDate: new Date(education.endDate).toDateString(),
              };
            });
            this.bullhornData.degrees.push(...degrees);
          }
          resume.id = result.id;
        })
      )
      .toPromise();
  }

  async parseResumeNoReplace(resume: ResumeModel) {
    const fileUpload = {
      fileType: "RESUME",
      name: resume.file.name,
      fileContent: (await this.toBase64(resume.file)) as string,
    };

    fileUpload.fileContent = fileUpload.fileContent.replace(/^data:(.*,)?/, "");
    await this.http
      .post(`${proxyApi}parse-resume/${this.bullhornData.id}`, fileUpload, {
        headers: { "Content-Type": resume.file.type },
      })
      .pipe(
        tap((result: any) => {
          resume.id = result.id;
        })
      )
      .toPromise();
  }
  //#endregion Boulo360 & Resume

  //#region Delete/Clear/Remove
  async deleteResume(resumeId: number) {
    await this.http.delete(`${proxyApi}candidates/${this.bullhornData.id}/resumes/${resumeId}`).toPromise();
  }

  async deleteRole(roleId: number) {
    await this.http.delete(`${proxyApi}candidates/${this.bullhornData.id}/roles/${roleId}`).toPromise();
  }

  async deleteCertification(certificationId: number) {
    await this.http.delete(`${proxyApi}candidates/${this.bullhornData.id}/certifications/${certificationId}`).toPromise();
  }

  async deleteDegree(degreeId: number) {
    await this.http.delete(`${proxyApi}candidates/${this.bullhornData.id}/degrees/${degreeId}`).toPromise();
  }

  clearBullhornData() {
    this.isLoaded = false;
    this.defaultBullhornData();
  }
  //#endregion Delete/Clear/Remove

  //#region Reusable Functions

  checkNulleable(value: string) {
    if (!value || value.trim().length === 0) return null;
    return value;
  }

  getCitiesByState(stateId: string) {
    return this.http.get<{ data: City[] }>(API + "cities/filter_by_states", {
      params: new HttpParams().set("state_id", stateId),
    });
  }

  isNumber(str: string): boolean {
    return !isNaN(Number(str));
  }

  hasValue(data: String): boolean {
    return data && data.trim().length > 0;
  }

  async checkForAndCancelEditing() {
    //TODO: add cancel confirmation if there are changes
    if (this.isEditing) {
      this.stopProfileEditing.emit(false);
      this.isEditing = false;
      this.changesCancelledMsg();
    }
    if (this.isDirty) {
      await this.upsertUser();
      this.isDirty = false;
    }
  }

  saveSuccessMsg(): void {
    this._snackBar.openFromComponent(SnackbarMsgComponent, {
      duration: 4000,
      data: {
        boldMsg: "Changes saved!",
        detailsMsg: "",
      } as SnackbarData,
      panelClass: ["snackbar-success"], //applies success styling, remove this line for generic style
    });
  }

  successMsg(msg: string, details: string = ""): void {
    this._snackBar.openFromComponent(SnackbarMsgComponent, {
      duration: 4000,
      data: {
        boldMsg: msg,
        detailsMsg: details,
      } as SnackbarData,
      panelClass: ["snackbar-success"], //applies success styling, remove this line for generic style
    });
  }

  changesCancelledMsg(): void {
    this._snackBar.openFromComponent(SnackbarMsgComponent, {
      duration: 3000,
      data: {
        boldMsg: "Changes cancelled",
        detailsMsg: "",
      } as SnackbarData,
    });
  }
  genericMsg(msg: string, details: string = ""): void {
    this._snackBar.openFromComponent(SnackbarMsgComponent, {
      duration: 3000,
      data: {
        boldMsg: msg,
        detailsMsg: details,
      } as SnackbarData,
    });
  }
  //#endregion Reusable Functions

  //#region Ruby/Legacy
  navigateToStepAspirations(
    step: number | AspirationStep,
    caller?: string,
    noConfirmModal?: boolean,
    fromProfile?: boolean
  ) {
    this.noGuardValidation = caller != null;
    this.caller = caller;
    if (step > 0 && step < 9) {
      this.subStepSelectedAspirations = step;
    }

    if (noConfirmModal) this.noConfirmModal = noConfirmModal;
    if (fromProfile) this.fromProfile = fromProfile;

    this.router.navigate(["/user/candidate/onboarding/aspirations"]);
  }

  navigateToStepPersonalInfo(step?: number, caller?: string, fromProfile?: boolean) {
    this.noGuardValidation = caller !== null;
    this.caller = caller;
    if (step > 0 && step < 3) {
      this.subStepSelectedPersonalInfo = step;
    }
    if (fromProfile) this.fromProfile = fromProfile;
    this.router.navigate(["/user/candidate/onboarding/personal"]);
  }

  navigateToStepJobInfo(caller?: string, fromProfile?: boolean) {
    this.noGuardValidation = caller !== null;
    this.caller = caller;
    if (fromProfile) this.fromProfile = fromProfile;
    this.router.navigate(["/user/candidate/onboarding/job"]);
  }

  navigateToStepJobPosition(caller?: string) {
    this.noGuardValidation = caller !== null;
    this.caller = caller;
    this.router.navigate(["/user/candidate/onboarding/job"]);
  }

  navigateToStepInterests(caller?: string, step?: string) {
    this.noGuardValidation = caller !== null;
    this.caller = caller;
    this.router.navigate(["/user/candidate/onboarding/interest"], {
      queryParams: { step: step },
    });
  }

  navigateToStepMembership(caller?: string) {
    this.noGuardValidation = caller !== null;
    this.caller = caller;
    this.router.navigate(["/user/candidate/onboarding/membership"], {
      queryParams: { fromprofile: true },
    });
  }

  navigateToStepPayment(caller?: string, plan?: string) {
    this.noGuardValidation = caller !== null;
    this.caller = caller;
    this.router.navigate(["/user/candidate/onboarding/membership/payment"], {
      queryParams: { plan: plan },
    });
  }

  navigateBackToCaller() {
    this.securityService.keepAliveToken();
    if (this.noGuardValidation) {
      this.router.navigate([this.caller]);
      this.caller = null;
      setTimeout(() => {
        this.noGuardValidation = false;
      }, 1000);
      return true;
    }

    return false;
  }

  updatePersonalInfo(payload: PersonalInfoPOST, callbackSuccess) {
    if (!this.tables.personal) {
      this.http
        .post<PersonalInfo>(API + `users/${this.securityService.UserRef.id}/personal_infos`, {
          personal_info: payload,
        })
        .subscribe((result) => {
          this.tables.personal = payload;
          callbackSuccess();
        });
      return;
    }

    this.http
      .put<PersonalInfo>(API + `users/${this.securityService.UserRef.id}/personal_infos/${payload.id}`, {
        personal_info: payload,
      })
      .subscribe((result) => {
        this.tables.personal = payload;
        callbackSuccess();
      });
  }

  updateJobInfo(payload: JobInfoPOST, callbackSuccess) {
    if (!this.tables.job) {
      this.http
        .post<JobInfo>(API + `users/${this.securityService.UserRef.id}/job_infos`, {
          job_info: payload,
        })
        .subscribe((result) => {
          this.tables.job = payload;
          callbackSuccess();
        });
      return;
    }

    this.http
      .put<JobInfo>(API + `users/${this.securityService.UserRef.id}/job_infos/${payload.id}`, {
        job_info: payload,
      })
      .subscribe((result) => {
        this.tables.job = payload;
        callbackSuccess();
      });
  }

  updateAspirationsInfo(payload: AspirationInfoPOST, callbackSuccess) {
    if (!this.tables.aspiration) {
      this.http
        .post<AspirationInfo>(API + `users/${this.securityService.UserRef.id}/aspirations`, {
          aspiration: payload,
        })
        .subscribe((result) => {
          this.tables.aspiration = result["data"];
          callbackSuccess();
        });
      return;
    }

    this.http
      .put<AspirationInfo>(API + `users/${this.securityService.UserRef.id}/aspirations/${payload.id}`, {
        aspiration: payload,
      })
      .subscribe((result) => {
        callbackSuccess();
      });
  }

  updateStrengthInfo(payload: any, type: string, callbackSuccess) {
    this.http.post<StrengthUser>(API + `users/${this.securityService.UserRef.id}/${type}`, payload).subscribe((result) => {
      if (type === "weaknesses") {
        this.tables.weaknessUser = result["data"];
      } else {
        this.tables.strengthUser = result["data"];
      }
      callbackSuccess();
    });
  }

  updateSkillsInfo(payload: any, callbackSuccess) {
    this.http
      .post<SkillUserUpdate>(API + `users/${this.securityService.UserRef.id}/skill_learns`, {
        skill_learn: payload,
      })
      .subscribe((result) => {
        this.tables.skillsUser = result["data"];
        callbackSuccess();
      });
  }

  updateCareerResourcesAppInfo(payload: any, callbackSuccess) {
    this.http
      .post<CareerResourceAppsUser[]>(API + `users/${this.securityService.UserRef.id}/career_resource_apps`, {
        career_resource_app_user: payload,
      })
      .subscribe((result) => {
        this.tables.careerResourceAppsUser = result["data"];
        callbackSuccess();
      });
  }

  getDegree(id?: number): Degree {
    /** Helper - get item records based on id... */
    if (id && this.tables.degrees) {
      const x = this.tables.degrees.filter((n) => n.id === id);
      if (x.length > 0) return x[0];
    }

    return null;
  }

  getState(id?: number): State {
    /** Helper - get item records based on id... */
    if (id && this.tables.states) {
      const x = this.tables.states.filter((n) => n.id === id);
      if (x.length > 0) return x[0];
    }

    return null;
  }
  //#endregion Ruby/Legacy
}

//#region inferfaces and enums
export interface data {
  title: string;
  isPrivacyPolicy?: boolean;
}

export enum AspirationStep {
  longTermGoal = 1,
  shortTermGoal,
  whatDescribesYou,
  careerResourceApps,
  skills,
  strengths,
  weaknesses,
  averageDay,
}
//#endregion inferfaces and enums
