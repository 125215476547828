<mat-form-field>
  <mat-label>{{ title }}:</mat-label>
  <mat-select [formControl]="chipSelectInputControl" multiple>
    <mat-select-trigger>
      <mat-chip-list>
        <mat-chip
          *ngFor="let selection of chipSelectInputControl.value"
          [removable]="true"
          (removed)="onElementRemoved(selection)"
          color="accent"
          selected
        >
          {{ getLabelFromValue(selection) }}
          <mat-icon *ngIf="selection != ''" matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </mat-select-trigger>
    <mat-option *ngFor="let option of options" [value]="option.value">
      {{ option.label }}
    </mat-option>
  </mat-select>
</mat-form-field>
