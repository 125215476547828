<div class="w-100">
  <div class="hype-text-wrapper question-modal-titlebar">
    <h3 class="">Add Screening Questions</h3>
  </div>
</div>

<mat-dialog-content class="mat-typography question-modal-box">
  <div class="w-100">
    <div class="hype-text-wrapper w-100">
      <h4 class="">This can help to match you with the best candidates for your position.</h4>
    </div>
  </div>
  <mat-accordion class="accordion-headers-align" multi>
    <!-- Looping through categories to create expansion panel question sections -->
    <mat-expansion-panel
      *ngFor="let category of categories; let idx = index"
      [expanded]="currentStep === idx"
      (opened)="setStep(idx)"
    >
      <mat-expansion-panel-header class="question-headers-align">
        <mat-panel-title class="panel-title-text"> {{ category.name }} Questions </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="expansion-panel-contents">
        <!-- Looping through questions of each category to create question cards -->
        <mat-card
          *ngFor="let question of category.questions"
          [class]="question.isActive ? 'selected-question-card' : 'default-question-card'"
        >
          <mat-checkbox
            [(ngModel)]="question.isActive"
            matTooltip="Click to add/remove"
            matTooltipPosition="below"
            [matTooltipClass]="!!question.title ? 'checkbox-tooltip-below-withlabel' : 'checkbox-tooltip-below-nolabel'"
            matTooltipHideDelay="250"
            (click)="setFormIsDirty()"
            class="mt-2"
            aria-label="Button that shows a red tooltip"
          >
            <h3 *ngIf="!!question.title" class="question-title">{{ question.title }}</h3>
          </mat-checkbox>
          <h4 [style.maxWidth]="!question.title ? '75%' : '100%'" class="question-text">"{{ question.question }}"</h4>
        </mat-card>
        <p *ngIf="category.questions.length == 0">
          No custom questions have been created yet! Create your own questions first.
        </p>
      </div>
      <mat-action-row class="question-panel-actionrow">
        <button mat-button *ngIf="idx == 0" (click)="prevStep()">Jump to End</button>
        <button mat-button *ngIf="idx != 0" (click)="prevStep()">Previous</button>
        <button mat-button color="primary" (click)="nextStep()">Next</button>
      </mat-action-row>
    </mat-expansion-panel>

    <!-- Create Your Own Question -->
    <mat-expansion-panel [expanded]="currentStep === 3" (opened)="setStep(3)">
      <mat-expansion-panel-header class="question-headers-align">
        <mat-panel-title class="panel-title-text"> Create Your Own Question </mat-panel-title>
        <mat-panel-description>
          <mat-icon class="question-icon-btn">add_box</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="expansion-panel-contents">
        <form [formGroup]="form" class="w-100 m-0">
          <div class="content-section">
            <h3 class="pb-1">Choose a question format & type out the question to be asked</h3>
            <div class="custom-question-format-wrapper">
              <button
                mat-raised-button
                (click)="setCustomQuestionFormat('freeform')"
                [class]="
                  customQuestion.format == 'freeform'
                    ? 'selected-custom-question-format-btn'
                    : 'custom-question-format-btn'
                "
              >
                Free-Form Text
              </button>
              <!-- <button mat-raised-button 
                  (click)="setCustomQuestionFormat('multiple')" 
                  [class]="customQuestion.format == 'multiple' ? 'selected-custom-question-format-btn' : 'custom-question-format-btn'" 
                >Multiple Choice</button> -->
              <button
                mat-raised-button
                (click)="setCustomQuestionFormat('boolean')"
                [class]="
                  customQuestion.format == 'boolean'
                    ? 'selected-custom-question-format-btn'
                    : 'custom-question-format-btn'
                "
              >
                Yes/No
              </button>
              <button
                mat-raised-button
                (click)="setCustomQuestionFormat('date')"
                (click)="setCustomQuestionFormat('date')"
                [class]="isDateFormat ? 'selected-custom-question-format-btn' : 'custom-question-format-btn'"
              >
                Date
              </button>
            </div>
            <p class="question-format-desc">{{ questionFormatDetailsText }}</p>
          </div>
          <div class="custom-question-format-wrapper">
            <mat-form-field appearance="fill" class="w-100 pt-4">
              <mat-label>Question text</mat-label>
              <textarea
                matInput
                maxlength="250"
                formControlName="customQuestionCtrl"
                class="custom-question-textarea"
              ></textarea>
              <mat-hint align="end">{{ customQuestionCharLength }}/250</mat-hint>
            </mat-form-field>
          </div>
          <div class="custom-question-format-wrapper">
            <button
              mat-button
              color="primary"
              [disabled]="customQuestionCharLength < 12"
              (click)="addCustomQuestion()"
              class="custom-question-save-btn"
            >
              <span class="custom-question-save-btn-text"
                ><mat-icon>add</mat-icon>
                <p>Save & Add Question</p></span
              >
            </button>
          </div>
        </form>
      </div>
      <mat-action-row class="question-panel-actionrow">
        <button mat-button (click)="prevStep()">Previous</button>
        <button mat-button color="primary" (click)="nextStep()">Return to Start</button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
</mat-dialog-content>

<mat-dialog-actions class="question-modal-actionrow">
  <button mat-raised-button class="avoid question-btn-cancel" (click)="openConfirmationDialog()">Cancel</button>
  <button mat-raised-button class="avoid question-btn-save" color="primary" (click)="saveAll()">Save Changes</button>
</mat-dialog-actions>
