import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-alert-check-icon",
  templateUrl: "./alert-check-icon.component.html",
  styleUrls: ["./alert-check-icon.component.scss"],
})
export class AlertCheckIconComponent {
  @Input() message: string;
  @Input() backToLabel: string;
  @Output() close = new EventEmitter<void>();

  constructor() {}

  onClose() {
    this.close.emit();
  }
}
