<mat-h2 mat-dialog-title class="dialog-title w-100">{{ data.dialogTitleType }} Job Posting</mat-h2>

<mat-dialog-content class="stepper-dialog-content">
  <app-info-collapsible-section [displayText]="_labels.intro" [openByDefault]="true">
    <div [innerHTML]="_instructions.intro" class="intro-text"></div>
  </app-info-collapsible-section>

  <mat-vertical-stepper
    [linear]="false"
    #stepper
    [animationDuration]="animationDuration"
    (selectionChange)="onStepperSelectionChange($event)"
  >
    <!-- Step 1: Job Basics -->
    <mat-step [stepControl]="basicsFormGrp" (selectionChange)="scrollToHeader($event, 'step-job-basics')">
      <ng-template matStepLabel
        >Job Basics
        <span *ngIf="_isJobBasicInvalid" class="stepper-missing-fields-text">*</span>
        <span *ngIf="_isJobBasicInvalid" class="stepper-missing-fields-text">
          <i class="italic-warning">(missing required fields)</i>
        </span>
      </ng-template>

      <app-info-collapsible-section [displayText]="_labels.basics">
        <div [innerHTML]="_instructions.basics" class="info-collapse-html"></div>
      </app-info-collapsible-section>

      <ng-template matStepContent>
        <div class="stepper-contents">
          <app-step-job-basics [formGrp]="basicsFormGrp" [job]="_job" (updateCtrl)="updateBasicsCtrl($event)">
          </app-step-job-basics>
        </div>

        <div class="mat-stepper-button-group-employer-job-edit">
          <button mat-button matStepperNext class="not-allowed">Next</button>
        </div>
      </ng-template>
    </mat-step>

    <!-- Step 2: Job Basics: Work Arrangements -->
    <mat-step [stepControl]="basicsFormGrp">
      <ng-template matStepLabel
        >Job Basics: Location & Arrangements
        <span *ngIf="_isJobLocationInvalid" class="stepper-missing-fields-text">*</span>
        <span *ngIf="_isJobLocationInvalid" class="stepper-missing-fields-text">
          <i class="italic-warning">(missing required fields)</i>
        </span>
      </ng-template>

      <app-info-collapsible-section [displayText]="_labels.location">
        <div [innerHTML]="_instructions.location" class="info-collapse-html"></div>
      </app-info-collapsible-section>

      <ng-template matStepContent>
        <div class="stepper-contents">
          <app-step-job-location [formGrp]="basicsFormGrp" [job]="_job" (updateCtrl)="updateBasicsCtrl($event)">
          </app-step-job-location>
        </div>

        <div class="mat-stepper-button-group-employer-job-edit">
          <button mat-button matStepperPrevious class="avoid">Back</button>
          <button mat-button matStepperNext class="avoid ml-3">Next</button>
        </div>
      </ng-template>
    </mat-step>

    <!-- Step 3: Job Basics: Compensation -->
    <mat-step [stepControl]="compensationFormGrp">
      <ng-template matStepLabel>Job Basics: Compensation</ng-template>

      <app-info-collapsible-section [displayText]="_labels.compensation">
        <div [innerHTML]="_instructions.compensation" class="info-collapse-html"></div>
      </app-info-collapsible-section>

      <ng-template matStepContent>
        <form class="emp-dashboard" [formGroup]="compensationFormGrp">
          <div class="stepper-contents">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Compensation Type</mat-label>
              <mat-select formControlName="compensationTypeCtrl" name="compensationType">
                <mat-option
                  *ngFor="let option of optionsCompensationPreferences"
                  [value]="option.value"
                  (click)="updateRangeSliderConfig(option.value)"
                  >{{ option.label }}</mat-option
                >
              </mat-select>
              <mat-hint
                >You can choose to set the exact rate or range for the compensation below once an option is
                selected.</mat-hint
              >
            </mat-form-field>

            <div *ngIf="isCompensationPreferenceSet">
              <mat-divider></mat-divider>

              <input type="checkbox" id="switch" />
              <div class="toggle-wrapper">
                <div class="content">
                  <label for="switch">
                    <div class="toggle"></div>
                    <div class="names">
                      <p class="left">{{ _isMobile ? "Exact" : "Exact Rate" }}</p>
                      <p class="right">{{ _isMobile ? "Range" : "Compensation Range" }}</p>
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <!-- Range -->
            <div
              *ngIf="isCompensationPreferenceSet"
              [style.opacity]="isCompensationPreferenceSet ? 1 : 0.4"
              class="compensation-slider"
            >
              <div *ngIf="!_isExactRateCompensation" class="compensation-range-wrapper">
                <!-- Range: Slider -->
                <nouislider
                  [min]="min"
                  [max]="max"
                  [connect]="connect"
                  [step]="step"
                  [(ngModel)]="tempCompensationRange"
                  [ngModelOptions]="{ standalone: true }"
                  class="w-100"
                ></nouislider>
                <!-- Range: display -->
                <div *ngIf="!_isExactRateCompensation" class="form-control">
                  <p class="range">{{ formattedMinRange }} - {{ formattedMaxRange }}</p>
                  <div class="per" *ngIf="_compensationType === 'Salary'">per year</div>
                  <div class="per" *ngIf="_compensationType === 'Hourly'">per hour</div>
                  <div class="per" *ngIf="_compensationType === 'Project'">per project</div>
                </div>
              </div>
              <!-- Exact Rate -->
              <div *ngIf="_isExactRateCompensation" class="pt-3 mt-3 mx-2">
                <mat-form-field class="w-100" appearance="fill">
                  <mat-label>Exact Rate</mat-label>
                  <input matInput formControlName="exactRateCtrl" maxlength="8" name="exactRate" />
                  <mat-hint>If you have an exact rate, enter it here:</mat-hint>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
        <div class="mat-stepper-button-group-employer-job-edit">
          <button mat-button matStepperPrevious class="avoid">Back</button>
          <button mat-button matStepperNext class="avoid ml-3">Next</button>
        </div>
      </ng-template>
    </mat-step>

    <!-- Step 4: About the Role: Attract Talent (Summary)-->
    <mat-step [stepControl]="aboutRoleFormGrp" (selectionChange)="scrollToHeader($event, 'step-attract-talent')">
      <ng-template matStepLabel
        >Job Basics: Job Description & Benefits
        <span *ngIf="isJobDescriptionInvalid" class="stepper-missing-fields-text">*</span>
        <span *ngIf="isJobDescriptionInvalid" class="stepper-missing-fields-text">
          <i class="italic-warning">(missing required fields)</i>
        </span>
      </ng-template>

      <app-info-collapsible-section [displayText]="_labels.summary">
        <div [innerHTML]="_instructions.summary" class="info-collapse-html"></div>
      </app-info-collapsible-section>

      <ng-template matStepContent>
        <form class="emp-dashboard" [formGroup]="aboutRoleFormGrp">
          <div class="stepper-contents">
            <app-text-editor
              [notes]="jobDescription"
              [config]="config"
              (outputedText)="updateJobDescription($event)"
              textEditorHeight="300px"
              [isEditable]="true"
              placeHolderValue="Enter the job description"
            ></app-text-editor>

            <div class="pt-4">
              <label class="above-field-label chip-group-title-label">Benefits <i>(Choose up to 10)</i></label>
              <app-chip-group-selection
                [allChips]="allBenefitsChips"
                [selectedChips]="_selectedBenefitsChips"
                [maxAllowed]="maxBenefitsChipsAllowed"
                allowCustom="true"
                (unselectChip)="unselectBenefitsChip($event)"
                (selectChip)="selectBenefitsChip($event)"
                (addChip)="addBenefitsChip($event)"
              >
              </app-chip-group-selection>
              <div class="pl-2">
                <mat-hint>
                  If you offer another type that is not listed, type in the name and press 'Enter' to add it to the
                  list.
                </mat-hint>
              </div>
            </div>
          </div>
        </form>
        <div class="mat-stepper-button-group-employer-job-edit">
          <button mat-button matStepperPrevious class="avoid">Back</button>
          <button mat-button matStepperNext class="avoid ml-3">Next</button>
        </div>
      </ng-template>
    </mat-step>

    <!-- Step 6: Ideal Candidate -->
    <mat-step [stepControl]="aboutRoleFormGrp">
      <ng-template matStepLabel>Candidate: Personality & Culture Fit</ng-template>

      <app-info-collapsible-section [displayText]="_labels.softskills">
        <div [innerHTML]="_instructions.softskills" class="info-collapse-html"></div>
      </app-info-collapsible-section>

      <ng-template matStepContent>
        <div class="stepper-contents">
          <app-step-job-softskills
            [formGrp]="aboutRoleFormGrp"
            [job]="_job"
            [howToLbl]="_labels.howToConvert"
            [howToDesc]="_instructions.howToConvert"
            [traitsList]="_getTraitsList"
            (updateList)="updateTraitsList($event)"
          >
          </app-step-job-softskills>
        </div>
        <div class="mat-stepper-button-group-employer-job-edit">
          <button mat-button matStepperPrevious class="avoid">Back</button>
          <button mat-button matStepperNext class="avoid ml-3">Next</button>
        </div>
      </ng-template>
    </mat-step>

    <!-- Step 7: Candidate: Background & Experience -->
    <mat-step [stepControl]="candidateFormGrp">
      <ng-template matStepLabel>Candidate: Background & Experience</ng-template>

      <app-info-collapsible-section [displayText]="_labels.background">
        <div [innerHTML]="_instructions.background" class="info-collapse-html"></div>
      </app-info-collapsible-section>

      <ng-template matStepContent>
        <form class="emp-dashboard" [formGroup]="candidateFormGrp">
          <div class="stepper-contents">
            <div class="pt-2">
              <mat-form-field class="w-100" appearance="fill">
                <mat-label>Years of Experience</mat-label>
                <mat-select formControlName="yearsExperienceCtrl" name="yearsExperience">
                  <mat-option *ngFor="let option of optionsExp" color="primary" [value]="option.value"
                    >{{ option.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="preference pb-5">
              <label class="requiredQ">Is this experience level preferred or required?</label>
              <mat-radio-group
                aria-labelledby="Q"
                formControlName="expRequiredCtrl"
                class="inline-radio-btn-grp"
                name="experienceOption"
              >
                <mat-radio-button color="primary" value="0" class="radio-left">Preferred</mat-radio-button>
                <mat-radio-button color="primary" value="1" class="radio-right">Required</mat-radio-button>
              </mat-radio-group>
            </div>

            <div class="pt-2">
              <mat-form-field class="w-100" appearance="fill">
                <mat-label>Education</mat-label>
                <mat-select formControlName="educationLevelCtrl" name="educationLevel">
                  <mat-option *ngFor="let option of optionsEdu" [value]="option.value">{{ option.label }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="preference pb-5">
              <label class="requiredQ">Is this experience level preferred or required?</label>
              <mat-radio-group
                class="radio"
                aria-labelledby="Q"
                formControlName="eduRequiredCtrl"
                class="inline-radio-btn-grp"
                name="educationOption"
              >
                <mat-radio-button color="primary" value="0" class="radio-left">Preferred</mat-radio-button>
                <mat-radio-button color="primary" value="1" class="radio-right">Required</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </form>

        <div class="mat-stepper-button-group-employer-job-edit">
          <button mat-button matStepperPrevious class="avoid">Back</button>
          <button mat-button matStepperNext class="avoid ml-3">Next</button>
        </div>
      </ng-template>
    </mat-step>

    <!-- Step 8: Candidate: Certifications -->
    <mat-step [stepControl]="candidateFormGrp">
      <ng-template matStepLabel>Candidate: Certificates</ng-template>

      <app-info-collapsible-section [displayText]="_labels.certificates">
        <div [innerHTML]="_instructions.certificates" class="info-collapse-html"></div>
      </app-info-collapsible-section>

      <ng-template matStepContent>
        <div class="stepper-contents">
          <app-step-job-certs
            [formGrp]="candidateFormGrp"
            [job]="_job"
            [certsList]="_getCertsList"
            (updateList)="updateCertsList($event)"
          >
          </app-step-job-certs>
        </div>
        <div class="mat-stepper-button-group-employer-job-edit">
          <button mat-button matStepperPrevious class="avoid">Back</button>
          <button mat-button matStepperNext class="avoid ml-3">Next</button>
        </div>
      </ng-template>
    </mat-step>

    <!-- Step 9: Skill Categories -->
    <mat-step #step_nine optional>
      <ng-template matStepLabel>Boulo Guide: Skill Categories</ng-template>

      <app-info-collapsible-section [displayText]="_labels.skillCategories">
        <div [innerHTML]="_instructions.skillCategories" class="info-collapse-html"></div>
      </app-info-collapsible-section>

      <ng-template matStepContent>
        <div class="stepper-contents">
          <app-job-skill-categories [job]="_getJob"></app-job-skill-categories>
        </div>
        <div class="mat-stepper-button-group-employer-job-edit">
          <button mat-button matStepperPrevious class="avoid">Back</button>
          <button mat-button matStepperNext class="avoid ml-3">Next</button>
        </div>
      </ng-template>
    </mat-step>

    <!-- Step 9-A: Skill Details for SkillCat#1 -->
    <mat-step *ngIf="_showSkillDeets1" #step_ten optional>
      <ng-template matStepLabel>{{ _getSkillDetailsStepLabel(0) }} Skill Details</ng-template>

      <app-info-collapsible-section [displayText]="_labels.skillDetails">
        <div [innerHTML]="_instructions.skillDetails" class="info-collapse-html"></div>
      </app-info-collapsible-section>

      <ng-template matStepContent>
        <div class="stepper-contents">
          <app-job-skill-cat-details [job]="_getJob" [skillCategory]="_getSkillCategory(0)"></app-job-skill-cat-details>
        </div>
        <div class="mat-stepper-button-group-employer-job-edit">
          <button mat-button matStepperPrevious class="avoid">Back</button>
          <button mat-button matStepperNext class="avoid ml-3">Next</button>
        </div>
      </ng-template>
    </mat-step>

    <!-- Step 9-B: Skill Details for SkillCat#2 -->
    <mat-step *ngIf="_showSkillDeets2" #step_eleven optional>
      <ng-template matStepLabel>{{ _getSkillDetailsStepLabel(1) }} Skill Details</ng-template>

      <app-info-collapsible-section [displayText]="_labels.skillDetails">
        <div [innerHTML]="_instructions.skillDetails" class="info-collapse-html"></div>
      </app-info-collapsible-section>

      <ng-template matStepContent>
        <div class="stepper-contents">
          <app-job-skill-cat-details [job]="_getJob" [skillCategory]="_getSkillCategory(1)"></app-job-skill-cat-details>
        </div>
        <div class="mat-stepper-button-group-employer-job-edit">
          <button mat-button matStepperPrevious class="avoid">Back</button>
          <button mat-button matStepperNext class="avoid ml-3">Next</button>
        </div>
      </ng-template>
    </mat-step>

    <!-- Step 10: Role Skills -->
    <mat-step optional>
      <ng-template matStepLabel>Role Specific Skills</ng-template>

      <app-info-collapsible-section [displayText]="_labels.specificSkills">
        <div [innerHTML]="_instructions.specificSkills" class="info-collapse-html"></div>
      </app-info-collapsible-section>

      <ng-template matStepContent>
        <div class="stepper-contents">
          <mat-hint>Add up to 15 skills.</mat-hint>
          <app-chip-group-selection
            [allChips]="allBasicSkillsChips"
            [selectedChips]="selectedBasicSkillsChips"
            [maxAllowed]="maxBasicSkillsChipsAllowed"
            [allowCustom]="true"
            (unselectChip)="unselectBasicSkillsChip($event)"
            (selectChip)="selectBasicSkillsChip($event)"
            (addChip)="addedBasicSkillsChip($event)"
          >
          </app-chip-group-selection>
          <br />
          <mat-hint
            >Set the skill level, if they are required, and their priority of display by dragging & dropping them into
            the desired order!</mat-hint
          >
          <div
            cdkDropList
            [cdkDropListData]="_job.combinedSkills.customSkills"
            class="example-list"
            (cdkDropListDropped)="drop($event)"
          >
            <div class="example-box" *ngFor="let item of _job.combinedSkills.customSkills; let i = index" cdkDrag>
              <h3 class="skill-title">{{ item.display }}</h3>
              <mat-form-field class="level-select">
                <mat-label>Skill Level </mat-label>
                <mat-select [(value)]="item.level">
                  <mat-option
                    *ngFor="let option of optionsSkillLevel"
                    [value]="option.value"
                    (click)="setSkillLevel(option.value, i)"
                    >{{ option.label }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <div class="required-select">
                <mat-checkbox class="example-margin" [(ngModel)]="item.isRequired">Required?</mat-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="mat-stepper-button-group-employer-job-edit">
          <button mat-button matStepperPrevious class="avoid">Back</button>
          <button mat-button matStepperNext class="avoid ml-3">Next</button>
        </div>
      </ng-template>
    </mat-step>

    <!-- Step 11: Questions  -->
    <!-- <mat-step optional>
      <ng-template matStepLabel>Screening Questions</ng-template>

      <app-info-collapsible-section [displayText]="_labels.questions">
        <div [innerHTML]="_instructions.questions" class="info-collapse-html"></div>
      </app-info-collapsible-section>

      <ng-template matStepContent>
        <div class="stepper-contents">
          <div class="add-questions-wrapper">
            <div class="outer-box">
              <button
                mat-raised-button
                color="accent"
                class="add-questions-btn btn-dim"
                (click)="openAddQuestionDialog()"
              >
                <span class="add-questions-text">
                  Add Screening Questions
                  <mat-icon>add</mat-icon>
                </span>
              </button>
            </div>
          </div>

          <div *ngIf="_hasQuestions" class="screening-questions">
            <ng-container *ngFor="let question of _questions">
              <mat-card class="question-card">
                <mat-checkbox [(ngModel)]="question.isActive" class="mt-2" matTooltip="Click to add/remove">
                  <h3 class="question-title">{{ question.title }}</h3>
                </mat-checkbox>
                <h4 [style.maxWidth]="!question.title ? '75%' : '100%'" class="question-text">
                  "{{ question.question }}"
                </h4>
              </mat-card>
            </ng-container>
            <div *ngIf="!_hasQuestions" class="no-questions">No Screening Questions Have Been Selected.</div>
          </div>
        </div>
        <div class="mat-stepper-button-group-employer-job-edit">
          <button mat-button matStepperPrevious class="avoid">Back</button>
          <button mat-button matStepperNext class="avoid ml-3">Next</button>
        </div>
      </ng-template>
    </mat-step> -->

    <!-- Done! (to continue steps vertical line) -->
    <mat-step>
      <ng-template matStepLabel>Done!</ng-template>
      <!-- TODO: Add Job Card Preview Here -->
      <ng-template matStepContent>
        <!-- lazy loading -->
        <div class="stepper-contents pb-3">
          <div *ngIf="canSubmit">
            <span *ngIf="data.isCreateJob"
              >You have completed all the steps and are ready to create your new job! Click 'Create Job' Below!</span
            >
            <span *ngIf="!data.isCreateJob"
              >You have successfully edited all job data! Make sure to click save changes below.</span
            >
          </div>
          <span *ngIf="!canSubmit"> Be sure to fill in all required fields! </span>
        </div>
      </ng-template>
    </mat-step>
  </mat-vertical-stepper>
</mat-dialog-content>

<mat-dialog-actions class="edit-dialog-actionrow">
  <button mat-raised-button class="btn-dim edit-btn-cancel" (click)="openConfirmationDialog()">Cancel</button>

  <app-mat-spinner-btn [loading]="isBusy">
    <button
      mat-raised-button
      [style.cursor]="canSubmit && !isBusy ? 'default' : 'no-drop'"
      [disabled]="!canSubmit || isBusy"
      (click)="saveAll()"
      [style.opacity]="isBusy ? 0.3 : 0.95"
      [class]="canSubmit ? 'btn-dim edit-btn-save' : 'btn-dim edit-btn-cancel'"
    >
      {{ _submitBtnText }}
    </button>
  </app-mat-spinner-btn>
</mat-dialog-actions>
