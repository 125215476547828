import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Router } from "@angular/router";
import { NgModule, APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { NgxWebstorageModule } from "ngx-webstorage";
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from "@angular-material-components/datetime-picker";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { OpenModule } from "./open/open.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { DlDateTimeDateModule, DlDateTimePickerModule } from "angular-bootstrap-datetimepicker";
import { DateValueAccessorModule } from "angular-date-value-accessor";
import * as Sentry from "@sentry/angular";
// import { MaterialModule } from "./shared/material.module";
import { CustomDateModule } from "./shared/date-adapter.module";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    OpenModule,
    NgxWebstorageModule.forRoot(),
    NgbModule,
    AngularEditorModule,
    DlDateTimeDateModule,
    DlDateTimePickerModule,
    DateValueAccessorModule,
    BrowserAnimationsModule,
    // MaterialModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    CustomDateModule,
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
      useValue: undefined,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
