import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map, retry, shareReplay } from "rxjs/operators";
import { dataGET, SecurityService } from "../services/security.service";
import { AspirationInfo } from "./onboarding/onboarding-root/onboarding.models";
import { environment } from "src/environments/environment";
import { authenticationGET } from "../shared/components/user.module";
import { eSuperAdmin, eUserRole } from "../shared/enums/user.enums";

const API: string = environment.api;

@Injectable({ providedIn: "root" })
export class UserSecurityGuard {
  constructor(
    private router: Router,
    private user: SecurityService,
    private http: HttpClient
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (!this.user.IsUser && !this.user.IsEmployer) {
      return this.router.parseUrl("/");
    }

    return this.http.get<dataGET<authenticationGET>>(`${API}users/${this.user.UserRef.id}/info`).pipe(
      map<dataGET<authenticationGET>, boolean>((n) => {
        const u0 = this.user.UserRef;
        const u1 = n.data;

        const getProfileImageWithoutSignature = (image: string) => {
          if (!image) {
            return "";
          }
          if (image.indexOf("&X-Amz-Algorithm") < 0) {
            return image;
          }
          return image.substr(0, image.indexOf("&X-Amz-Algorithm"));
        };

        // TODO: ACS Error with email == null.
        // https://boulo-solutions.sentry.io/issues/3866408975/?project=4504453988745216&query=is%3Aunresolved&referrer=issue-stream&stream_index=2
        // if u0 or u1 are null, should we run the updateLocalStorage? Do we skip shareReplay?
        if (
          u0.email !== u1.email ||
          u0.first_name !== u1.first_name ||
          getProfileImageWithoutSignature(u0.profile_image) !== getProfileImageWithoutSignature(u1.profile_image) ||
          u0.total_points !== u1.total_points ||
          u0.last_name !== u1.last_name ||
          localStorage.getItem(eSuperAdmin.ImitatedEmailKey) ||
          u0.role !== u1.role
        ) {
          u1.token = u0.token;
          u1.role = localStorage.getItem(eSuperAdmin.ImitatedEmailKey) ? eUserRole.Both : u1.role;
          this.user.updateLocalStorage(u1);
        }

        return true;
      }),
      shareReplay()
    );
  }
}
