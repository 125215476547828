//Jobs
export enum eJobCardBusyType {
  NotBusy = 0,
  AppyingToJob = 1,
  NavigatingToJob = 2,
  LoadingMoreJobs = 3,
  IsBusy = 4,
}

export enum eJobCardViewer {
  Candidate = 0,
  Employer = 1,
  EmployerAsCandidate = 2,
}

export enum eSkillLevel {
  Unset = 0,
  Any = 1,
  Beginner = 2,
  Intermediate = 3,
  Advanced = 4,
}

export enum eDialogType {
  View = "View",
  Edit = "Edit",
  Create = "Create",
  Confirm = "Confirm",
  Delete_Confirm = "Delete",
}

export enum eButtonLabels {
  Cancel = "Cancel",
  Continue = "Continue",
  Create = "Create",
  Delete = "Delete",
  Edit = "Edit",
  Save = "Save",
  Save_Changes = "Save Changes",
  View = "View",
}
