import { Component, Input, Output, EventEmitter, OnDestroy } from "@angular/core";
import { SecurityService } from "../../../services/security.service";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

@Component({
  selector: "app-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"],
})
export class AlertComponent implements OnDestroy {
  @Input() message: string;
  @Output() close = new EventEmitter<void>();
  confirmationEmail: boolean;
  constructor(
    private securityService: SecurityService,
    private router: Router
  ) {
    this.confirmationEmail = securityService.confirmationEmailSent;
  }

  onClose() {
    this.close.emit();
  }

  resentConfirmationEmail(): void {
    this.securityService.resentConfirmationEmail(this.securityService.emailToSendConfirmation).subscribe((res) => {
      this.router.navigate(["signup/confirmationmessage"], {
        queryParams: { email: this.securityService.emailToSendConfirmation, resent: true },
      });
    });
  }

  ngOnDestroy() {
    this.securityService.confirmationEmailSent = false;
    this.securityService.emailToSendConfirmation = null;
  }
}
