<div *ngIf="!!jobs" class="job-postings">
  <ng-container *ngFor="let job of _getJobs" class="all-jobs">
    <mat-card
      (click)="jobSelected(job.id)"
      *ngIf="renderCard(job.status)"
      class="jobs-content-card"
      [ngClass]="{ 'card-clicked': clickedJobID === job.id }"
    >
      <div class="top-row">
        <div class="title-menu-wrapper">
          <div class="job-title">{{ job.title }}</div>
          <div class="share-btn-wrapper">
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="verticalMenu($event)" class="btn-dim">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="cloneSelectedJob(job)">
                <mat-icon>content_copy</mat-icon>
                <span>Duplicate Job</span>
              </button>
              <button mat-menu-item (click)="editSelectedJob(job)">
                <mat-icon>edit</mat-icon>
                <span>Edit Job</span>
              </button>
              <button mat-menu-item *ngIf="_isMobile" class="share-btn-menu">
                <app-share-button
                  [shareTitle]="'Share this job via:'"
                  [snackMsg]="'Copied the Job URL to Clipboard'"
                  [timeOfMsg]="3"
                  [url]="url + job.id"
                  [color]="'#555555'"
                  [displayShareText]="true"
                ></app-share-button>
                <span>Share</span>
              </button>
            </mat-menu>
            <app-share-button
              *ngIf="!_isMobile"
              class="share-btn"
              [shareTitle]="'Share this job via:'"
              [snackMsg]="'Copied the Job URL to Clipboard'"
              [timeOfMsg]="3"
              [url]="url + job.id"
              [color]="'#555555'"
            ></app-share-button>
          </div>
        </div>
        <div class="location-status-wrapper">
          <div class="job-location-wrapper">
            <span id="job-location-icon" class="material-icons" [style.color]="'#858E93'">location_on</span>
            <div *ngIf="_isRemote(job)" class="job-location">Remote</div>
            <div *ngIf="_showLocation(job)" class="job-location">
              {{ _getLocation(job) }}
            </div>
          </div>
        </div>
        <div class="bottom-row">
          <div class="apps">
            <span class="material-icons job-icon">people</span>
            <div class="job-text" (click)="routeToFilteredJobApplicants(job)">
              <b>{{ _getApplicantCount(job) }}</b> {{ _getApplicantCount(job) === 1 ? " Applicant" : " Applicants" }}
            </div>
          </div>
          <!-- TODO: add back in views once we are getting that data -->
          <div class="views">
            <!-- <span class="material-icons-outlined job-icon">remove_red_eye</span>
                    <div class="job-text"><b>{{job.numViews}}</b> Views</div> -->
          </div>
          <!-- TODO: add in 
                <div class="newapp" *ngIf="job.newApplicant && job.status === 'Active'">New Applicant!</div>
                <div class="empty-box" *ngIf="!job.newApplicant && job.status === 'Active'"> </div>
                <button class="reactivate" *ngIf="!(job.status === 'Active')" (click)="reactivatePosting($event, job.id)">Publish Job</button> -->
        </div>

        <div class="job-action-buttons">
          <button (click)="findCandidate($event, job)" mat-raised-button class="find-candidate-btn" color="accent">
            Find Matching Candidates
          </button>
          <div class="icons">
            <app-mat-spinner-btn *ngIf="job.appCreatedJob" [loading]="_showSpinner(job.id)">
              <div class="split-button-container">
                <button
                  mat-raised-button
                  [disabled]="isBusy"
                  (click)="splitButtonClick($event)"
                  color="primary"
                  class="split-button-left"
                  [matMenuTriggerFor]="menu"
                  aria-label="Change job status"
                >
                  {{ getJobStatus(job.status) }}
                </button>
                <button
                  mat-raised-button
                  [disabled]="isBusy"
                  (click)="splitButtonClick($event)"
                  color="primary"
                  class="split-button-right"
                  [matMenuTriggerFor]="menu"
                  aria-label="Change job status"
                >
                  <mat-icon aria-hidden="false" aria-label="Change job status">expand_more</mat-icon>
                </button>
                <mat-menu #menu="matMenu" xPosition="before">
                  <button mat-menu-item [disabled]="_isStatusDraft(job)" (click)="updateStatus(job.id, _statusDraft)">
                    Set to Draft
                  </button>
                  <button mat-menu-item [disabled]="_isStatusActive(job)" (click)="updateStatus(job.id, _statusActive)">
                    Publish Job
                  </button>
                  <button mat-menu-item [disabled]="_isStatusClosed(job)" (click)="updateStatus(job.id, _statusClosed)">
                    Close Job
                  </button>
                  <button
                    mat-menu-item
                    [disabled]="_isStatusActive(job)"
                    (click)="updateStatus(job.id, _statusArchive)"
                  >
                    Delete Job
                  </button>
                </mat-menu>
              </div>
            </app-mat-spinner-btn>

            <mat-chip *ngIf="!job.appCreatedJob">Recruiter Managed</mat-chip>
          </div>
        </div>
      </div>
    </mat-card>
  </ng-container>
</div>

<div *ngIf="displayNoJobListingDesc()" class="noJobsListed">{{ noJobsListedDesc }}</div>
