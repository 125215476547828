import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { SecurityService } from "src/app/services/security.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: UntypedFormGroup;
  success: string = "pending";
  token: string;
  errorMessage: string = null;

  constructor(
    private securityService: SecurityService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      this.token = params["reset_password_token"];
    });
  }

  ngOnInit(): void {
    this.changePasswordForm = new UntypedFormGroup({
      password: new UntypedFormControl(null, [Validators.required, Validators.minLength(5)]),
      confirmPassword: new UntypedFormControl(null, [Validators.required]),
    });
  }

  eventOnChangePassword(value): void {
    if (value) {
      if (!/\d/.test(value)) {
        this.changePasswordForm.controls.password.setErrors({ number: true });
      }
      if (!/[a-z]/g.test(value)) {
        this.changePasswordForm.controls.password.setErrors({ lowerLetter: true });
      }
      if (!/[A-Z]/g.test(value)) {
        this.changePasswordForm.controls.password.setErrors({ capitalLetter: true });
      }
      if (!/[!#@$%&]/g.test(value)) {
        this.changePasswordForm.controls.password.setErrors({ special: true });
      }
    }
  }

  onSubmit(): void {
    this.securityService.changePassword(this.token, this.changePasswordForm.controls.password.value).subscribe(
      (res) => {
        this.success = "success";
      },
      (errorMessage) => {
        this.errorMessage = errorMessage;
      }
    );
  }
}
