import { HttpClient } from "@angular/common/http";
import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input } from "@angular/core";
import { OnboardingRootService } from "src/app/user/onboarding/onboarding-root/onboarding-root.service";
import { Goal } from "src/app/user/onboarding/onboarding-root/onboarding.models";
import { environment } from "src/environments/environment";
declare var jQuery: any;

const API: string = environment.api;

interface Icon {
  url: string;
  id: number;
}

@Component({
  selector: "app-carousel-goals",
  templateUrl: "./carousel-goals.component.html",
  styleUrls: ["./carousel-goals.component.scss"],
})
export class CarouselGoalsComponent implements OnInit {
  @Output() goalSelected = new EventEmitter<number>();
  @Input() initialGoalId: number;
  @ViewChild("goalCarouselAnimationOnProgress", { read: ElementRef }) animationOnProgress: ElementRef;
  extraItemsToCreate: number;

  icons: Icon[];
  iconsSource = {
    27: "assets/svg/long-term/Boulo_CCO.svg",
    22: "assets/svg/long-term/Boulo_CEO.svg",
    23: "assets/svg/long-term/Boulo_CFO.svg",
    29: "assets/svg/long-term/Boulo_CHRO.svg",
    5: "assets/svg/long-term/Boulo_CIO.svg",
    26: "assets/svg/long-term/Boulo_CMO.svg",
    24: "assets/svg/long-term/Boulo_COO.svg",
    28: "assets/svg/long-term/Boulo_CRO.svg",
    25: "assets/svg/long-term/Boulo_CTO.svg",
  };

  constructor(
    private http: HttpClient,
    private onboardingService: OnboardingRootService
  ) {}

  ngOnInit(): void {
    const endProcess = () => {
      setTimeout(() => {
        this.navClicked(0);
      }, 200);

      var extraItemsToCreate = 0;
      var totalItems = this.icons.length;
      var currentPosition = 0;

      var idSelected = !this.initialGoalId ? 22 : this.initialGoalId;
      var indexSwapped = 0;
      const swapIcons = (currentPosition) => {
        if (this.icons[currentPosition].id === idSelected) {
          return;
        }
        this.icons.forEach((icon, index) => {
          if (icon.id === idSelected) {
            const icon0 = this.icons[currentPosition];
            this.icons[currentPosition] = this.icons[index];
            this.icons[index] = icon0;

            if (index === 0) {
              indexSwapped = currentPosition;
            }
          }
        });
      };

      (function ($) {
        $(document).ready(function () {
          var itemsMainDiv = ".MultiCarousel";
          var itemsDiv = ".MultiCarousel-inner";
          var itemClass = ".item";
          var itemWidth = 0;
          var animationInProgress = false;
          var customTimeOut = function (callback) {
            setTimeout(() => {
              callback();
            }, 900);
          };

          $(".leftLst, .rightLst").click(function () {
            $(".check").css("visibility", "hidden");
            var condition = $(this).hasClass("leftLst");
            if (condition) click(0, this);
            else click(1, this);
          });

          ResCarouselSize();

          $(window).resize(function () {
            ResCarouselSize();
          });

          $(itemsDiv)
            .find(itemClass)
            .click(function () {
              if (animationInProgress) {
                return;
              }
              animationInProgress = true;
              $("[goalCarouselAnimationOnProgress]").attr("goalCarouselAnimationOnProgress", "1");
              $(".check").css("visibility", "hidden");

              const newPosition = parseInt($(this).attr("data-position"));
              const e = newPosition > currentPosition ? 1 : 0;
              const s = Math.abs(currentPosition - newPosition);
              currentPosition = newPosition;
              ResCarousel(e, "#MultiCarousel1", s);
            });

          //this function define the size of the items
          function ResCarouselSize() {
            $(".check").css("visibility", "hidden");

            var incno = 0;
            var dataItems = "data-items";
            var id = 0;
            var btnParentSb = "";
            var itemsSplit: string[];
            var sampwidth = $(itemsMainDiv).width();
            var bodyWidth = $("#MultiCarousel").width(); // Get the first container width
            $(".item.cgSelected").removeClass("cgSelected");

            $(itemsDiv).each(function () {
              id = id + 1;
              var itemNumbers = $(this).find(itemClass).length;
              btnParentSb = $(this).parent().attr(dataItems);
              itemsSplit = btnParentSb.split(",");
              $(this)
                .parent()
                .attr("id", "MultiCarousel" + id);
              bodyWidth = $(`#MultiCarousel${id}`).width(); // Get the second+ container width
              let extraCSSToCheck = {};
              if (bodyWidth >= 1200) {
                incno = parseInt(itemsSplit[3]);
                itemWidth = sampwidth / incno;
                extraCSSToCheck = {
                  left: itemWidth / 2 + "px",
                  top: "-5px",
                  // 'border': '1px solid red'
                };
              } else if (bodyWidth >= 992) {
                incno = parseInt(itemsSplit[2]);
                itemWidth = sampwidth / incno;
                extraCSSToCheck = {
                  left: (itemWidth * 0.32 + 40).toString() + "px",
                  top: "-4px",
                  // 'border': '1px solid blue'
                };
              } else if (bodyWidth >= 768) {
                incno = parseInt(itemsSplit[1]);
                itemWidth = sampwidth / incno;
                extraCSSToCheck = {
                  left: "83px",
                  top: "6px",
                  // 'border': '1px solid yellow'
                };
              } else {
                incno = parseInt(itemsSplit[0]);
                itemWidth = sampwidth / incno;
                extraCSSToCheck = {
                  left: (itemWidth * 0.35 + 15).toString() + "px",
                  top: "11px",
                };
              }

              $(".check").css({
                width: (itemWidth * 0.12).toString() + "px",
                position: "relative",
                ...extraCSSToCheck,
              });
              currentPosition = Math.ceil(incno / 2);
              extraItemsToCreate = Math.floor(incno / 2);
              const initialMarginLeft = itemWidth * extraItemsToCreate;

              $(this).css("height", (itemWidth + itemWidth * 0.9).toString() + "px");

              $(this).css({
                transform: "translateX(" + (initialMarginLeft > 0 ? -initialMarginLeft : 0).toString() + "px)",
                width: itemWidth * (itemNumbers + extraItemsToCreate * 2),
              });

              if (swapIcons) {
                swapIcons(currentPosition - 1);
              }
              $(this)
                .find(itemClass)
                .each(function (i, v) {
                  if (swapIcons) {
                    if ($(this).attr("id") == idSelected) {
                      $(this).addClass("cgSelected");
                    }
                  } else if (i + 1 == currentPosition) {
                    $(this).addClass("cgSelected");
                  }

                  $(this).css({
                    "margin-left":
                      (i == indexSwapped && initialMarginLeft > 0 ? initialMarginLeft + 20 : 35).toString() + "px",
                    "margin-right": "15px",
                    width: itemWidth - 50,
                  });
                });

              customTimeOut(() => {
                $(".check").css("visibility", "");
              });
              $(".leftLst").removeClass("over");
              $(".rightLst").removeClass("over");
            });
          }

          //this function used to move the items
          function ResCarousel(e, el, s) {
            var leftBtn = ".leftLst";
            var rightBtn = ".rightLst";
            var translateXval = 0;
            var divStyle = $(el + " " + itemsDiv).css("transform");
            var values = divStyle.match(/-?[\d\.]+/g);
            var xds = Math.abs(parseInt(values[4]));
            if (e == 0) {
              translateXval = xds - itemWidth * s;
              $(el + " " + rightBtn).removeClass("over");

              if (translateXval <= itemWidth / 2) {
                translateXval = 0;
                $(el + " " + leftBtn).addClass("over");
              }
            } else if (e == 1) {
              var itemsCondition = $(el).find(itemsDiv).width() - $(el).width();
              translateXval = xds + itemWidth * s;
              $(el + " " + leftBtn).removeClass("over");

              if (translateXval >= itemsCondition - itemWidth / 2) {
                translateXval = itemsCondition;
                $(el + " " + rightBtn).addClass("over");
              }
            }
            $(el + " " + itemsDiv).css("transform", "translateX(" + -translateXval + "px)");

            $(".item.cgSelected").removeClass("cgSelected");
            $('[data-position="' + currentPosition + '"]').addClass("cgSelected");
            customTimeOut(() => {
              animationInProgress = false;
              $(".check").css("visibility", "");
              $("[goalCarouselAnimationOnProgress]").attr("goalCarouselAnimationOnProgress", "0");
            });
          }

          //It is used to get some elements from btn
          function click(ell, ee) {
            if (animationInProgress) {
              return;
            }
            animationInProgress = true;
            $("[goalCarouselAnimationOnProgress]").attr("goalCarouselAnimationOnProgress", "1");
            var Parent = "#" + $(ee).parent().attr("id");
            var slide = parseInt($(Parent).attr("data-slide")) + extraItemsToCreate;
            if (ell > 0) {
              if (currentPosition + extraItemsToCreate + 1 > totalItems) {
                slide = totalItems - currentPosition;
                currentPosition = totalItems;
              } else {
                currentPosition = currentPosition + extraItemsToCreate + 1;
              }
            } else {
              if (currentPosition - extraItemsToCreate - 1 <= 0) {
                slide = 0 + (totalItems - currentPosition);
                currentPosition = 1;
              } else {
                currentPosition = currentPosition - extraItemsToCreate - 1;
              }
            }

            ResCarousel(ell, Parent, slide);
          }
        });
      })(jQuery);
    };
    this.http.get<{ data: Goal[] }>(API + "goals").subscribe((n) => {
      this.icons = n.data
        .filter((n) => n.goal_type === "long_term" && this.iconsSource[n.id])
        .map<Icon>((n) => {
          return {
            url: this.iconsSource[n.id],
            id: n.id,
          };
        });
      this.icons = [
        ...this.icons,
        { url: "assets/svg/long-term/Boulo_Entrepreneur.svg", id: 30 },
        { url: "assets/svg/long-term/Boulo_Founder.svg", id: 30 },
      ];
      endProcess();
    });
  }

  navClicked(increment: number): void {
    if (this.animationOnProgress.nativeElement.attributes[1].value == "0") {
      const el = document.getElementsByClassName("cgSelected")[0];

      if (!el) {
        return;
      }

      const position = parseInt(el.attributes["data-position"].value) + increment * 2;
      if (position > this.icons.length) {
        this.goalSelected.emit(this.icons[this.icons.length - 1].id);
      } else if (position <= 0) {
        this.goalSelected.emit(this.icons[0].id);
      } else {
        this.goalSelected.emit(this.icons[position - 1].id);
      }
    }
  }

  onResize(): void {
    setTimeout(() => {
      this.navClicked(0);
    }, 200);
  }

  goToNav(position: number) {
    this.goalSelected.emit(this.icons[position - 1].id);
  }
}
