<app-header></app-header>
<div class="navBarPadding">
  <section id="invest" class="container-xpadding" [ngClass]="{ 'pt-4': showBanner }">
    <div class="container-fluid">
      <div id="invest-row" class="row justify-content-stretch">
        <div
          class="col-lg-8 d-lg-flex flex-lg-column justify-content-lg-start pt-lg-5 text-center text-sm-left block-title"
        >
          <h1 class="mb-1 title-2">Build your best career game plan to achieve your ambition</h1>
          <h3 class="subtitle display-4">
            5 minutes can <br class="split-subtitle" />
            change your life!
          </h3>
          <h4 class="summary">
            Boulo is the first personalized online career trainer <br class="d-none d-md-block" />built by women for
            women that helps you develop skills<br class="d-none d-md-block" />
            and knowledge to advance professionally.
          </h4>
          <div class="mt-3 mb-4">
            <div class="pt-lg-5">
              <button
                id="startYourGamePlan"
                type="button"
                class="btn btn-primary mt-2 mb-5 rounded-pill font-weight-bold display-1"
                (click)="common.goToSignup()"
              >
                Start Your Free Trial, Cancel Anytime
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-4 d-flex flex-column justify-content-end align-items-end">
          <div id="quote">
            <div class="overlay"></div>
            <div class="content">
              <i class="fas fa-quote-left fa-3x"></i>
              <div class="text">
                <span class="quote"
                  >Focus on the <br />
                  step in front <br />
                  of you, not <br />
                  the whole <br />
                  staircase.</span
                >
                <i class="fas fa-quote-right fa-2x"></i>
                <div class="author pt-4 text-right mr-3 mr-md-5">
                  <!-- <b>Melinda Gates</b><br />
                                    Co-Chair and Founder<br />
                                    Bill & Melinda Gates Foundation -->
                  <b>Anonymous</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="careerPath" class="py-3 container-xpadding">
    <div class="text-center pt-4">
      <p class="title-12 titlex mb-5">Create Your Career Path for Success</p>
      <p class="summary-12 summaryx px-4 px-md-0">
        Identify new capabilities to develop for your career advancement that are proven successful by C-suite
        executives. Your game plan will reflect customized content and priorities based on executive insights and your
        profile and goals.
      </p>
      <h2 class="goal pt-5 font-weight-bold subtitle-12">What is your long-term goal?</h2>
      <app-carousel-goals (goalSelected)="goalSelected($event)"></app-carousel-goals>
      <p>
        <button type="button" class="btn btn-primary mt-3 rounded-pill" (click)="common.goToSignup()">
          Let's Start Now
        </button>
      </p>
    </div>
  </section>

  <section id="AI" class="py-5">
    <div>
      <div class="row mx-0">
        <div class="col-md">
          <div class="row align-items-end">
            <div class="col-lg-6 px-0">
              <div class="container-fluid container-xpadding">
                <div class="title-3 text-center text-lg-left custom-width">Artificial Intelligence + Human Element</div>
                <div class="subtitle-3 text-center text-lg-left">Driving Results</div>
                <div class="text-center text-lg-left pt-3 summary-3-bold mt-3 mt-lg-0 mb-4 mb-lg-0">
                  <b>Big Dreams Meet Big Data</b>
                </div>
                <div class="summary-3 our-alg-paragraph text-center text-lg-left px-4 px-md-0">
                  Our algorithms compare your unique profile to our proprietary C-suite insights to identify skills,
                  behaviors and innovations to build your capabilities for advancement.
                </div>
                <div class="d-lg-none d-block text-center pt-2 pb-3 mb-5">
                  <img class="img-fluid w-75" src="../../../assets/img/Machine-learning.png" />
                </div>
              </div>
              <div class="pt-0 summary-3">
                <div class="container-fluid d-flex flex-column hcg-container px-1 pb-5">
                  <div class="col-12 summary-3-bold text-center py-4 d-block d-lg-none">
                    <b>5 minutes can change your life!<br />Get started with 3 steps:</b>
                  </div>
                  <div class="row mb-4 ml-4 pink-title d-none d-lg-block">
                    <div class="col-12 summary-3-bold">
                      <b>5 minutes can change your life! Get started with 3 steps:</b>
                    </div>
                  </div>
                  <div class="row justify-content-around pb-3 mx-0">
                    <div class="col-3 five-min-icons">
                      <img src="../../../assets/svg/begin-onboarding.svg" alt="" />
                    </div>
                    <div class="col-9">
                      Begin the onboarding process with a quick questionnaire so we can customize a game plan for you.
                    </div>
                  </div>
                  <div class="row justify-content-around p-2 mx-0">
                    <div class="col-3 five-min-icons">
                      <img src="../../../assets/svg/choose-membership.svg" alt="" />
                    </div>
                    <div class="col-9">
                      Choose the membership plan that works best for you, we always give you an opportunity to try it
                      for free first.
                    </div>
                  </div>
                  <div class="row justify-content-around pt-3 mx-0">
                    <div class="col-3 five-min-icons">
                      <img src="../../../assets/svg/Confirm-email.svg" alt="" />
                    </div>
                    <div class="col-9">Confirm your email address and then sign in to your career game plan.</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="d-none d-lg-block">
                <img class="img-fluid" src="../../../assets/img/Machine-learning.png" />
              </div>
              <div class="text-center pt-5">
                <button class="btn btn-outline-dark rounded-pill mr-4" (click)="common.goToHowItWorks()">
                  Learn More
                </button>
                <button class="btn btn-primary rounded-pill" (click)="common.goToSignup()">Start Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="customizeLearning" class="pb-5 container-xpadding">
    <div class="container-fluid">
      <div class="row align-items-start align-items-end align-items-lg-start">
        <div class="col-lg-6 px-0">
          <p class="title-3 text-center text-md-left pb-0 mb-0">Customized Learning</p>
          <div class="d-block d-lg-none pt-4">
            <img src="../../../assets/img/Customized-Learning.png" class="img-fluid" />
          </div>
          <div class="text-center text-md-left mt-4 mt-lg-0 pt-lg-0">
            <p class="subtitle-10">Resources At Your Fingertips</p>
            <p class="summary-10 mx-4 mx-md-0">
              Save time with our proprietary platform which curates the perfect content to match your game plan,
              including articles, podcasts, videos and events.
            </p>
            <button class="btn btn-outline-dark rounded-pill mt-5" (click)="common.goToMerbership()">
              See Our Plans
            </button>
          </div>
        </div>
        <div class="col-lg-6">
          <img src="../../../assets/img/Customized-Learning.png" class="img-fluid d-none d-lg-block" />
        </div>
      </div>
    </div>
  </section>

  <div id="stories" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li
        *ngFor="let item of carouselItems; let i = index"
        data-target="#stories"
        attr.data-slide-to="{{ i }}"
        [ngClass]="{ active: i == 0 }"
      ></li>
    </ol>
    <div class="carousel-inner">
      <div
        class="carousel-item text-center"
        *ngFor="let item of carouselItems; let i = index"
        [ngClass]="{ active: i == 0 }"
      >
        <div class="carousel-header">
          <div class="title">Success Stories</div>
          <div class="quote">
            <i class="fas fa-quote-left"></i>
            {{ item.description }}
            <i class="fas fa-quote-right"></i>
          </div>
          <div class="author-container">
            <div class="author">{{ item.name }}</div>
            <div class="author-position">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#stories" role="button" data-slide="prev">
      <i class="fas fa-angle-double-left"></i>
    </a>
    <a class="carousel-control-next" href="#stories" role="button" data-slide="next">
      <i class="fas fa-angle-double-right"></i>
    </a>
  </div>
</div>
<app-footer></app-footer>
