import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-onboarding-items-container-check",
  templateUrl: "./onboarding-items-container-check.component.html",
  styleUrls: ["./onboarding-items-container-check.component.scss"],
})
export class OnboardingItemsContainerCheckComponent implements OnInit {
  @Output() checkedItems: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();
  @Input() items: Object;
  @Input() customStyleObject: string;
  selectedItems: Array<string> = [];

  constructor() {}

  ngOnInit(): void {}

  eventCheck(event): void {
    this.selectedItems = [];
    this.selectedItems.push(event);
    this.checkedItems.emit(this.selectedItems);
  }
}
