import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-mat-spinner-loading-screen",
  templateUrl: "./mat-spinner-loading-screen.component.html",
  styleUrls: ["./mat-spinner-loading-screen.component.scss"],
})
export class MatSpinnerLoadingScreenComponent implements OnInit {
  @Input() isLoading: boolean = true;
  @Input() loadingMsg: string = "Loading...";

  ngOnInit(): void {}
}
