import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import SendbirdChat, { MetaData, User, UserUpdateParams } from "@sendbird/chat";
import { GroupChannel, GroupChannelCreateParams, GroupChannelModule } from "@sendbird/chat/groupChannel";
import {
  BaseMessage,
  FileMessage,
  FileMessageCreateParams,
  MessageListParams,
  MessageType,
  UserMessage,
  UserMessageCreateParams,
} from "@sendbird/chat/message";
import { environment } from "src/environments/environment";
import { LoggerService as Logger } from "src/app/shared/services/logger.service";
import { SecurityService } from "./security.service";
import { BehaviorSubject } from "rxjs";
import { Poll, PollOption, PollStatus } from "@sendbird/chat/poll";
import { SentMessageResponse } from "../user/employer/message-box/message-box.component";

const sendBird = SendbirdChat.init({
  appId: environment.sendBirdAppId,
  modules: [new GroupChannelModule()],
});

export enum BouloMessageType {
  UserMessage,
  FileMessage,
  PollMessage
}

export interface BouloPoll {
  id: number;
  title: string;
  options: PollOption[];
  status: PollStatus;
  selectedJob: string;
}

export interface SendTextMessageRequest {
  To: string;
  Body: string;
}

export interface SendMessageResponse {
  url: string | null; 
  fileType: string; 
  fileName: string;
}

export interface SendMessageRequest {
  channelUrl: string,
  messageText: string,
  file: File | null,
  messageType: BouloMessageType,
  pollId: number | null,
}

export interface GetOrCreateChannelRequest {
  employerContactId: string,
  candidateId: string,
  employerContactName: string,
  candidateName: string
}

export interface GetMessagesResponse {
    sender: string;
    text: string;
    timestamp: number;
    url: string;
    type: BouloMessageType;
    fileType: string;
    poll: BouloPoll | null;
}

export interface PollCreateRequest {
  title: string,
  options: string[],
  allow_user_suggestion: boolean,
  allow_multiple_votes: boolean,
  close_at: number,
  created_by: string,
  data: object
}

export interface EmailNotificationRequest {
  toEmail: string;
  template: string;
  subject: string;
  template_content: { name: string; content: string }[];
}

export interface MessageNotificationRequest {
  senderName: string;
  email: string;
  messageText: string;
}

export interface CreatePollRequest {
  pollOptions: string[], selectedJob: string, userId: string
}

export interface InterviewConfirmationRequest {
  email: string;
  interviewDate: string;
  companyName: string;
  roleName: string;
}

@Injectable({
  providedIn: "root",
})
export class SendBirdService {
  private apiUrl: string = `https://api-${environment.sendBirdAppId}.sendbird.com/v3`;
  public messagesBadge = new BehaviorSubject<number>(0);
  constructor(
    private _http: HttpClient,
    private _securityService: SecurityService
  ) {}

  replaceSpecialCharacters(userId: string): string {
    return userId?.replace(/[@.]/g, '_');
  }

  async connectOrCreate(userNickName: string, userEmail: string, phone: string): Promise<User> {
    // were are now using email as sendbird userId so userId argument given must be user email.
    const userId = this.replaceSpecialCharacters(userEmail);
    const user = await sendBird.connect(userId, environment.sendBirdApiKey);
    const noNickName = user.nickname == null;
    const noEmail = user.metaData["email"] === undefined;
    const noPhone = user.metaData["phoneNumber"] === undefined;
    const noOptIn = user.metaData["optInToEmail"] === undefined;

    if (noNickName || user.nickname !== userNickName) {
      var nickNameData: UserUpdateParams = {
        nickname: userNickName,
      };
      await sendBird.updateCurrentUserInfo(nickNameData);
    }

    if (noEmail && noPhone && noOptIn) {
      const data: MetaData = {
        email: userEmail,
        optInToEmail: "true",
        phoneNumber: phone?.length > 0 ? phone : "",
      };
      await sendBird.currentUser.createMetaData(data);
      return user;
    }

    
    if(phone?.length > 0) {
      const phoneData: MetaData = {
        phoneNumber: phone?.length > 0 ? phone : ""
      };
      if (noPhone) {
        await sendBird.currentUser.createMetaData(phoneData); //TODO: currently no way to go back and update the phone number
      } else if (phoneData.phoneNumber !== user.metaData["phoneNumber"]) {
        await sendBird.currentUser.updateMetaData(phoneData,true);
      }
    }

    if (noEmail) {
      const emailData: MetaData = {
        email: userEmail?.length > 0 ? userEmail : ""
      };
      await sendBird.currentUser.createMetaData(emailData);
    }

    return user;
  }

  async connect(userId: string): Promise<User> {
    userId = this.replaceSpecialCharacters(userId);
    const user = await sendBird.connect(userId, environment.sendBirdApiKey);

    return Promise.resolve(user);
  }

  async disconnect() {
    await sendBird.disconnect();
  }

  async getGroupChannelsForUser(userId: string): Promise<{ channels: GroupChannel[] }> {
    userId = this.replaceSpecialCharacters(userId);
    const url = `${this.apiUrl}/users/${userId}/my_group_channels`;

    const groupChannels = this._http.get<{ channels: GroupChannel[] }>(url).toPromise();

    return groupChannels;
  }

  async createOrFetchGroupChannel(request: GetOrCreateChannelRequest): Promise<GroupChannel> {
    const urlEnvironment = environment.production ? "production" : "staging";
    request.employerContactId = this.replaceSpecialCharacters(request.employerContactId);
    request.candidateId = this.replaceSpecialCharacters(request.candidateId);
    const params: GroupChannelCreateParams = {
      isDistinct: true,
      invitedUserIds: [request.employerContactId, request.candidateId],
      name: `${request.employerContactId}-${request.candidateId}-${request.employerContactName}-${request.candidateName}`,
      channelUrl: `${urlEnvironment}-${request.employerContactId}-${request.candidateId}-channel`,
    };

    const channel = await sendBird.groupChannel.createChannel(params);
    return channel;
  }

  async getSendBirdMessages(channelUrl: string): Promise<GetMessagesResponse[]> {
    const params: MessageListParams = {
      prevResultSize: 50,
      nextResultSize: 50,
    };
    const channel: GroupChannel = await sendBird.groupChannel.getChannel(channelUrl);
    const messageList: BaseMessage[] = await channel.getMessagesByTimestamp(100, params);

    const transformedMessages = messageList.map((message) => {
      if (message.messageType === MessageType.FILE) {
        const fileMessage = message as FileMessage;
        return {
          sender: fileMessage.sender.nickname,
          text: fileMessage.name,
          timestamp: fileMessage.createdAt,
          url: fileMessage.url,
          type: BouloMessageType.FileMessage,
          fileType: fileMessage.type,
          poll: null
        };
      } else if (message.messageType === MessageType.USER) {
        const userMessage = message as UserMessage;
        if(userMessage.poll) {
          const selectedJob = userMessage.poll.data !== null ? userMessage.poll.data['selectedJob'] : '';
          return {
            sender: userMessage.sender.nickname,
            text: userMessage.poll.title,
            timestamp: userMessage.createdAt,
            url: "",
            type: BouloMessageType.PollMessage,
            fileType: "",
            poll: {
              options: userMessage.poll.options,
              title: userMessage.poll.title,
              id: userMessage.poll.id,
              status: userMessage.poll.status,
              selectedJob: selectedJob,
            }
          };
        } else {
          return {
            sender: userMessage.sender.nickname,
            text: userMessage.message,
            timestamp: userMessage.createdAt,
            url: "",
            type: BouloMessageType.UserMessage,
            fileType: "",
            poll: null
          };
        }
      }
    });

    return transformedMessages;
  }

  async sendMessage(
    request: SendMessageRequest
  ): Promise<SentMessageResponse> {
    const channel: GroupChannel = await sendBird.groupChannel.getChannel(request.channelUrl);

    if(request.messageType === BouloMessageType.PollMessage) {
        const messageParams: UserMessageCreateParams = {
          message: request.messageText,
          pollId: request.pollId,
        };
  
        channel.sendUserMessage(messageParams).onSucceeded(() => {
          Logger.log("Message sent successfully")
        }).onFailed((e) => {
          Logger.warn(e);
        });
    } else {
      try {
        if (request.messageText !== "") {
          const messageParams: UserMessageCreateParams = {
            message: request.messageText,
          };
          channel.sendUserMessage(messageParams);
        }
  
        if (request.file) {
          const fileParams: FileMessageCreateParams = {
            file: request.file,
          };
  
          return new Promise((resolve) => {
            channel.sendFileMessage(fileParams).onSucceeded((message: FileMessage) => {
              resolve({
                fileType: message.type,
                url: message.url,
                fileName: message.name,
              });
            });
          });
        }
  
        return { url: null, fileType: "", fileName: "" };
      } catch (error) {
        Logger.error("Error in sendMessage:", error);
        return { url: null, fileType: "", fileName: "" };
      }
    }
    
  }

  async deleteChannel(channelUrl: string): Promise<void> {
    this._http.delete(`${this.apiUrl}/group_channels/${channelUrl}`).subscribe(
      (response) => {
        Logger.log(response);
        return Promise.resolve();
      },
      (error) => {
        Logger.error("Error deleting channel", error);
      }
    );
  }

  updateEmailOptIn(userId: string, optIn: boolean) {
    userId = this.replaceSpecialCharacters(userId);
    const metadataKey = "optInToEmail";
    const metadataValue = optIn.toString();
    const metadata = {
      [metadataKey]: metadataValue,
    };

    this._http.put(`${this.apiUrl}/users/${userId}/metadata`, { metadata }).subscribe(
      () => {},
      (error) => {
        Logger.error("Failed to update metadata:", error);
      }
    );
  }

  async sendInterviewConfirmation(interviewRequest: InterviewConfirmationRequest): Promise<void> {
    const request: EmailNotificationRequest = {
      toEmail: interviewRequest.email,
      template: "interview-message",
      subject: `Interview Confirmation for ${interviewRequest.roleName} with ${interviewRequest.companyName}`,
      template_content: [
        {
          name: "MEETING_DATE",
          content: interviewRequest.interviewDate.substring(0,10),
        },
        {
          name: "MEETING_TIME",
          content: interviewRequest.interviewDate.substring(11),
        },
        {
          name: "ROLE_NAME",
          content: interviewRequest.roleName,
        },
        {
          name: "COMPANY_NAME",
          content: interviewRequest.companyName,
        },
      ],
    };
    this._http.post(`${environment.proxyApi}/send-email`, request).subscribe(
      () => {
        return Promise.resolve();
      },
      (error) => {
        Logger.error("Error:", error);
        return Promise.reject();
      }
    );
  }

  async sendEmailNotification(messageNotificationRequest: MessageNotificationRequest): Promise<void> {
    const isEmployer = this._securityService.IsEmployer;
    const inboxLink = !isEmployer
      ? `${environment.appUrl}/user/employer/message-inbox`
      : `${environment.appUrl}/user/candidate/messages`;

    const request: EmailNotificationRequest = {
      toEmail: messageNotificationRequest.email,
      template: "chat-message",
      subject: "You've got a message in Boulo",
      template_content: [
        {
          name: "SENDER_NAME",
          content: messageNotificationRequest.senderName,
        },
        {
          name: "INBOX_LINK",
          content: inboxLink,
        },
        {
          name: "RECEIVER_TYPE",
          content: isEmployer ? "candidate" : "employer",
        },
        {
          name: "MESSAGE_CONTENT",
          content: messageNotificationRequest.messageText.length > 200 ? messageNotificationRequest.messageText.substring(0, 200) + "..." : messageNotificationRequest.messageText
        },
      ],
    };

    this._http.post(`${environment.proxyApi}/send-email`, request).subscribe(
      () => {
        return Promise.resolve();
      },
      (error) => {
        Logger.error("Error:", error);
        return Promise.reject();
      }
    );
  }

  async sendTextMessageNotification(request: SendTextMessageRequest) {
    const url = `${environment.twilioUrl}/${environment.twilioSID}/Messages.json`;

    // Twilio params are url-encoded
    const params = new URLSearchParams();
    params.set('From', environment.bouloNumber);
    params.set('To', request.To);
    params.set('Body', request.Body);
    // TODO: Move this to backend
    // this._http.post(url, params.toString()).subscribe(
    //     () => {
    //         return Promise.resolve();
    //     },
    //     (error) => {
    //         Logger.error("Error:", error);
    //         return Promise.reject();
    //     }
    // );
}

  async getUnreadMessageCountForUser(userId: string, updateBadge: boolean = true): Promise<number> {
    try {
      userId  = this.replaceSpecialCharacters(userId);
      const count = await this._http
        .get<number>(`${this.apiUrl}/users/${userId}/unread_message_count`)
        .toPromise();
      
      if (updateBadge) {
        this.messagesBadge.next(count["unread_count"]);
      }

      return count["unread_count"];
    } catch (error) {
      Logger.warn("Failed to retrieve unread message count:", error);
      return 0;
    }
  }

  async markMessagesAsReadForChannel(userId: string, channelUrl: string): Promise<void> {
    try {
      userId = this.replaceSpecialCharacters(userId);
      await this._http
        .put(`${this.apiUrl}/users/${userId}/mark_as_read_all`, {
          "channel_url": channelUrl
        }).toPromise();

      const updatedCount = await this.getUnreadMessageCountForUser(userId);
      this.messagesBadge.next(updatedCount);

    } catch (error) {
      Logger.error("Failed to mark messages as read or update badge:", error);
    }
  }

  async createPoll(request: CreatePollRequest): Promise<Poll> {
    const poll: PollCreateRequest = {
      title: "Interview Times",
      options: request.pollOptions,
      allow_user_suggestion: false,
      allow_multiple_votes: false,
      created_by: this.replaceSpecialCharacters(request.userId),
      close_at: -1,
      data: {
        'selectedJob': request.selectedJob
      }
    }

    try {
      const newPoll: Poll = await this._http.post<Poll>(`${this.apiUrl}/polls`, poll ).toPromise();

      return newPoll;
    } catch (error) {
      Logger.error("Failed to create poll", error);
    }
  }

  async closePoll(pollId: number): Promise<void> {
    try {
      await this._http.put(`${this.apiUrl}/polls/${pollId}/close`, {}).toPromise();
    } catch (error) {
      Logger.error("Falled to close poll: ", error)
    }
  }

  
  async voteForPollOption(pollId: number, optionId: number, userId: string): Promise<void> {
    try {
      userId = this.replaceSpecialCharacters(userId);
      const vote = {
        user_id: userId,
        option_ids: [optionId]
      };
      
      await this._http.put(`${this.apiUrl}/polls/${pollId}/vote`, vote).toPromise();
    } catch (error) {
      Logger.error("Failed to vote for poll option: ", error);
    }
  }
}
