import { Component, OnInit } from "@angular/core";
import { OpenCommonService } from "../open-common.service";

@Component({
  selector: "app-membership",
  templateUrl: "./membership.component.html",
  styleUrls: ["./membership.component.scss"],
})
// Route to this component was removed 01/2024
export class MembershipComponent implements OnInit {
  showBanner: boolean;
  membershipComparison: {
    description: string;
    basic: boolean;
    advanced: boolean;
    buyOne: boolean;
  }[] = [
    {
      description: "First month free",
      basic: true,
      advanced: true,
      buyOne: true,
    },
    {
      description: "6 month membership",
      basic: true,
      advanced: false,
      buyOne: true,
    },
    {
      description: "12 month membership",
      basic: false,
      advanced: true,
      buyOne: false,
    },
    {
      description: "Curated content for your career game plan",
      basic: true,
      advanced: true,
      buyOne: true,
    },
    {
      description: "Exclusive career tips from C-suite executives",
      basic: true,
      advanced: true,
      buyOne: true,
    },
    {
      description: "Online events with industry leaders and career experts",
      basic: true,
      advanced: true,
      buyOne: true,
    },
    {
      description: "Success tracker to highlight accomplishments",
      basic: true,
      advanced: true,
      buyOne: true,
    },
    {
      description: "Superstar Loyalty Program to reward personal growth",
      basic: true,
      advanced: true,
      buyOne: true,
    },
    {
      description: "Available on desktop and mobile",
      basic: true,
      advanced: true,
      buyOne: true,
    },
    {
      description: "Invitations to exclusive Boulo events",
      basic: false,
      advanced: true,
      buyOne: false,
    },
    {
      description: "Gift of 6 month membership with a note acknowledging who sent the gift",
      basic: false,
      advanced: false,
      buyOne: true,
    },
  ];
  constructor(public common: OpenCommonService) {}

  ngOnInit(): void {
    this.common.showBannerObservable.subscribe((val) => {
      this.showBanner = val;
    });
  }
}
