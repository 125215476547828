import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { forkJoin } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MainService } from "./main-service";

const API: string = environment.api;

@Injectable({ providedIn: "root" })
export class MainResolverService {
  constructor(
    private mainServices: MainService,
    private http: HttpClient
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any | import("rxjs").Observable<any> | Promise<any> {
    return forkJoin([this.http.get(API + "success_histories")]).pipe<any>(
      tap((results) => {
        this.mainServices.successStories = results[0].data;
      })
    );
  }
}
