<mat-dialog-content class="applicant-hire-dialog-wrapper">
  <!-- Title -->
  <mat-h2 mat-dialog-title class="dialog-title w-100">
    <div class="applicant-title">Hire Applicant for {{ data.selectedApplicant.latestJobApplied.jobOrder.title }}</div>
    <mat-icon [mat-dialog-close] *ngIf="isUpadingHireInfo">close</mat-icon>
  </mat-h2>

  <!-- Body -->
  <mat-dialog-content class="dialog-content">
    <div class="stretch-div">
      <form class="applicant-hire-form" [formGroup]="applicantHireForm">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Employment Type</mat-label>
          <mat-select formControlName="employmentType">
            <mat-option *ngFor="let type of employmentTypeList" [value]="type">
              {{ type }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div [ngClass]="{ 'applicant-hire-split-wrapper': isContract() }">
          <div class="applicant-hire-split-col">
            <mat-form-field class="w-100 datePicker" appearance="fill">
              <mat-label>Start Date</mat-label>
              <input
                [matDatepicker]="startDate"
                [min]="startDateLimit"
                matInput
                formControlName="startDate"
                placeholder="Start Date"
              />
              <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
              <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="applicant-hire-split-col" *ngIf="isContract()">
            <mat-form-field *ngIf="isContract()" class="w-100 datePicker" appearance="fill">
              <mat-label>End Date</mat-label>
              <input
                [matDatepicker]="endDate"
                [min]="startDateLimit"
                matInput
                formControlName="endDate"
                placeholder="End Date"
              />
              <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
              <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>

            <mat-checkbox
              [checked]="noEndDate"
              *ngIf="isContract()"
              (change)="updateEndDate()"
              class="example-margin checkboxEnrolled"
            >
              No End Date
            </mat-checkbox>
          </div>
        </div>

        <mat-form-field *ngIf="isContract() || isPartTime()" class="w-100" appearance="fill">
          <mat-label>Hours Per Week</mat-label>
          <input matInput formControlName="hoursPerWeek" placeholder="Enter the number of hours per week" />
        </mat-form-field>

        <mat-form-field class="w-100" appearance="fill">
          <mat-label>{{ compensationTitle }}</mat-label>
          <input matInput formControlName="compensation" [placeholder]="compensationPlaceHolder" />
        </mat-form-field>
        <mat-chip-list #chipList aria-label="filter selection">
          <mat-chip
            [ngClass]="{ 'selected-compensation': isSalary() }"
            (click)="setCompensationTypeToSalary()"
            class="compensation-type"
            >Salary</mat-chip
          >
          <mat-chip
            [ngClass]="{ 'selected-compensation': isHourly() }"
            (click)="setCompensationTypeToHourly()"
            class="compensation-type"
            >Hourly</mat-chip
          >
          <mat-chip
            [ngClass]="{ 'selected-compensation': isFlatRate() }"
            (click)="setCompensationTypeToContract()"
            *ngIf="isContract()"
            class="compensation-type"
            >Flat Rate</mat-chip
          >
        </mat-chip-list>
      </form>

      <mat-checkbox (change)="toggleCloseJob()" class="close-job-check"> Check to close job after hire </mat-checkbox>
    </div>
  </mat-dialog-content>

  <!-- navigation-footer -->
  <mat-dialog-actions class="applicant-hire-modal-actionrow">
    <div class="btns">
      <button *ngIf="!isUpadingHireInfo" mat-button type="button" (click)="close()">Cancel</button>
      <app-mat-spinner-btn [loading]="isWithDrawingApplicant" *ngIf="isUpadingHireInfo">
        <button mat-button type="button" (click)="openDeleteDialog()">Withdraw Hire</button>
      </app-mat-spinner-btn>
      <app-mat-spinner-btn [loading]="isHiring">
        <button [disabled]="!isFormValid()" (click)="hireApplicant()" mat-raised-button color="primary" type="submit">
          {{ submitButtonName }}
        </button>
      </app-mat-spinner-btn>
    </div>
  </mat-dialog-actions>
</mat-dialog-content>
