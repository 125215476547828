import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface DialogData {
  deleteItemType: string;
  deleteItemName: string;
  description: string; // "This is irreversible and you will no longer be able to....""
}

@Component({
  selector: "app-dialog-confirm-delete",
  templateUrl: "./dialog-confirm-delete.component.html",
  styleUrls: ["./dialog-confirm-delete.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogConfirmDeleteComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogConfirmDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  get _hasDeleteType(): boolean {
    return this.data?.deleteItemType?.length > 0;
  }
  get _getType(): string {
    return this._hasDeleteType ? ` ${this.data.deleteItemType}` : "";
  }

  get _hasDeleteName(): boolean {
    return this.data?.deleteItemName?.length > 0;
  }
  get _getName(): string {
    return this._hasDeleteName
      ? ` ${this.data.deleteItemName}`
      : ` this ${this._hasDeleteType ? this.data.deleteItemType.toLowerCase() : "item"}`;
  }

  get _hasDescription(): boolean {
    return this.data?.description?.length > 0;
  }
  get _getDescription(): string {
    return this._hasDescription
      ? `This is irreversible and you will no longer be able to ${this.data.description}.`
      : "This is action is irreversible and you will no longer be able to view deleted items.";
  }
}
