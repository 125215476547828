import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { SecurityService } from "src/app/services/security.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: UntypedFormGroup;
  success: string;
  errorMessage: string = null;

  constructor(private securityService: SecurityService) {}

  ngOnInit(): void {
    this.success = "pending";

    this.forgotPasswordForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
    });
  }

  onSubmit(): void {
    this.securityService.forgotPassword(this.forgotPasswordForm.controls.email.value).subscribe(
      (res) => {
        this.success = "success";
      },
      (errorMessage) => {
        this.errorMessage = errorMessage;
      }
    );
  }

  sendEmail(): void {
    this.securityService.forgotPassword(this.forgotPasswordForm.controls.email.value).subscribe(
      (res) => {
        this.success = "success";
      },
      (errorMessage) => {
        this.errorMessage = errorMessage;
      }
    );
  }
}
