<div class="container-fluid pt-5">
  <div class="row mr-2">
    <div class="col-md-2 col-lg-3 d-flex flex-column justify-content-between">
      <a routerLink="/">
        <img class="header-logo mb-2" src="assets/svg/Logo.svg" />
      </a>
      <div class="mb-2 copyright hide-mobile">© 2020 Boulo</div>
    </div>
    <div class="row pt-2 show-mobile">
      <div class="col" id="footer-logos">
        <ng-container *ngFor="let red of socialMedia; let i = index">
          <a [href]="red.link" class="btn btn-link" id="social-{{ i }}" [target]="red.target">
            <img [src]="red.svg" [class]="red.extraClass" />
          </a>
        </ng-container>
      </div>
    </div>
    <div class="col-md col-lg col-sm-6 col-6 p-2">
      <ul class="nav flex-column">
        <li class="nav-item">
          <a routerLink="/howitworks" class="nav-link"> How It Works </a>
        </li>
        <li class="nav-item">
          <a routerLink="/membership" class="nav-link"> Membership </a>
        </li>
        <li class="nav-item">
          <a routerLink="/rewards" class="nav-link"> Rewards </a>
        </li>
        <li class="nav-item">
          <a routerLink="/login" class="nav-link"> Sign In </a>
        </li>
        <li class="nav-item">
          <a routerLink="/signup" class="nav-link"> Get Started </a>
        </li>
      </ul>
    </div>
    <div class="col-6 p-2 d-lg-none d-block">
      <ul class="nav flex-column">
        <li class="nav-item">
          <a
            href="https://legacy-hellocareerguru.squarespace.com/guru-salon-podcast"
            target="_blank"
            class="nav-link bold"
          >
            Salon Podcast
          </a>
        </li>
        <li class="nav-item">
          <a href="https://legacy-hellocareerguru.squarespace.com/coaching-overview" class="nav-link" target="_blank">
            Coaching
          </a>
        </li>
        <li class="nav-item">
          <a href="https://legacy-hellocareerguru.squarespace.com/the-guru-view" class="nav-link" target="_blank">
            View
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/faq" class="nav-link"> FAQ </a>
        </li>
        <li class="nav-item">
          <a routerLink="/press" class="nav-link"> Press </a>
        </li>
      </ul>
    </div>
    <div class="col-md col-lg p-2 d-lg-block d-none">
      <ul class="nav flex-column">
        <li class="nav-item">
          <a
            href="https://legacy-hellocareerguru.squarespace.com/guru-salon-podcast"
            target="_blank"
            class="nav-link bold"
          >
            Salon Podcast
          </a>
        </li>
        <li class="nav-item">
          <a href="https://legacy-hellocareerguru.squarespace.com/coaching-overview" class="nav-link" target="_blank">
            Coaching
          </a>
        </li>
        <li class="nav-item">
          <a href="https://legacy-hellocareerguru.squarespace.com/the-guru-view" target="_blank" class="nav-link">
            View
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/faq" class="nav-link"> FAQ </a>
        </li>
        <li class="nav-item">
          <a routerLink="/press" class="nav-link"> Press </a>
        </li>
      </ul>
    </div>
    <div class="col-md-3 col-lg-3 p-2 newsletter-margin-left">
      <ul class="nav flex-column">
        <!-- <li class="nav-item">
                    <a class="nav-link mail-link" href="mailto: @hellocareerguru.com">
                         @hellocareerguru.com
                    </a>
                </li> -->
        <li class="nav-item">
          <a routerLink="/team" class="nav-link"> The Team </a>
        </li>
        <li class="nav-item">
          <div class="container-fluid">
            <label for="newsletterEmail">Newsletter Signup</label>
            <form [formGroup]="newsletterForm" (ngSubmit)="onNewsletterSignup()">
              <div class="input-group input-group-sm rounded-pill newsletter">
                <input
                  type="email"
                  id="newsletterEmail"
                  placeholder="Email address"
                  (keydown)="resetNewsletterSignupStatus()"
                  class="form-control join-input"
                  formControlName="email"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-primary btn-sm avoid join-button"
                    [disabled]="!newsletterForm.valid || newsletterSubmitting"
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </form>
            <span
              [ngClass]="{ 'text-danger': newsletterMessageError, 'text-primary': !newsletterMessageError }"
              class="newsletter-sent-message"
              *ngIf="newsletterMessage"
            >
              <b>{{ newsletterMessage }}</b>
            </span>
          </div>
        </li>
        <li class="nav-item">
          <a routerLink="/contactus" class="nav-link bold"> Contact Us </a>
        </li>
        <li class="nav-item">
          <a routerLink="/termsandconditions" class="nav-link"> Terms and Conditions </a>
        </li>
        <li class="nav-item">
          <a routerLink="/privacy" class="nav-link"> Privacy Policy </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="row pt-2 hide-mobile padding-footer">
    <div class="col text-center" id="footer-logos">
      <ng-container *ngFor="let red of socialMedia; let i = index">
        <a [ngClass]="red.cssClass" [href]="red.link" class="btn btn-link" id="social-{{ i }}" [target]="red.target">
          <img [src]="red.svg" [class]="red.extraClass" />
        </a>
      </ng-container>
    </div>
  </div>
  <div class="mb-2 copyright show-mobile text-center">© 2020 Boulo</div>
</div>
