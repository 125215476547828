import { Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-schedule-interview-dialog",
  templateUrl: "./schedule-interview-dialog.component.html",
  styleUrls: ["./schedule-interview-dialog.component.scss"],
})
export class ScheduleInterviewDialogComponent implements OnInit {
  timeSlots: string[] = ["", "", ""];
  employerJobs: string[] = [];
  currentDate: string = this.roundToNearestHalfHour(new Date());
  selectedJob: string | null = null;
  form: FormGroup;
  defaultTime = ["9", "0", "0"];
  minDate: Date;

  constructor(
    public dialogRef: MatDialogRef<ScheduleInterviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.employerJobs = data.employerJobs;
  }
  ngOnInit(): void {
    this.form = this.fb.group({
      slots: this.fb.array([]),
    });
    this.initTimeSlots(2);

    const today = new Date();
    this.minDate = new Date();
    this.minDate.setDate(today.getDate());
  }

  disableAddTimeSlotButton() {
    if (this.slots.length >= 5) {
      return true;
    } else {
      return false;
    }
  }

  initTimeSlots(numberOfSlots: number) {
    for (let i = 0; i < numberOfSlots; i++) {
      this.addTimeSlot();
    }
  }

  get slots() {
    return this.form.controls["slots"] as FormArray;
  }

  get isAnyTimeSelected(): boolean {
    return this.timeSlots.some((slot) => !!slot) && this.selectedJob != null;
  }

  // get _minDate() {
  //   return new Date().getDate();
  // }
  // private _setMinDate() {
  //   const now = new Date();
  //   this.minDate = new Date();
  //   this.minDate.setDate(now.getDate() - 1);
  // }

  disableProposeBtn() {
    if (this.slots.length === 0 || !this.form.valid) {
      return true;
    }
    return false;
  }

  addTimeSlot() {
    if (this.slots.length >= 5) {
      return;
    }
    const timeSlotForm = this.fb.group({
      time: [null, [Validators.required]] as [Date | string | null, Validators[]],
      // time: [{ value: null, disabled: false }, [Validators.required, Validators.minLength(1)]],
    });

    this.slots.push(timeSlotForm);
  }

  deleteTimeSlot(lessonIndex: number) {
    this.slots.removeAt(lessonIndex);
  }

  proposeTimes(): void {
    let lst = [];
    for (let item of this.slots.value) {
      let itemDate = new Date(item.time);
      let itemDateString = itemDate?.toISOString()?.slice(0, 16);
      lst.push(itemDateString);
    }

    const dialogData = {
      timeSlots: [...lst], //, "9999-12-31T00:00"],
      selectedJob: this.selectedJob,
    };

    this.dialogRef.close(dialogData);
  }

  roundToNearestHalfHour(date: Date): string {
    date.setDate(date.getDate() + 1);

    const minutes = date.getMinutes();
    const adjustment = minutes < 30 ? 30 - minutes : 60 - minutes;
    date.setMinutes(minutes + adjustment);
    date.setSeconds(0);
    return date.toISOString().split(".")[0];
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
