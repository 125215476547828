import { NgModule, Injectable } from "@angular/core";
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { MainComponent } from "./main/main.component";
import { LoginComponent } from "./login/login.component";
import { OpenComponent } from "./open.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { AdminNotAuthorizedComponent } from "./admin-not-authorized/admin-not-authorized.component";
import { SignupComponent } from "./signup/signup.component";
import { ConfirmationMessageComponent } from "./signup/confirmation-message/confirmation-message.component";
import { TermsConditionsComponent } from "./terms-conditions/terms-conditions.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { MainResolverService } from "./main/main-resolver.service";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { MessageInboxComponent } from "../user/employer/messaging/inbox/message-inbox.component";

@Injectable()
class PodcastLink {
  resolve() {
    window.location.href = "https://legacy-hellocareerguru.squarespace.com/guru-salon-podcast";
  }
}

const routes: Routes = [
  {
    path: "",
    component: OpenComponent,
    children: [
      { path: "", component: MainComponent, resolve: [MainResolverService] },
      { path: "login", component: LoginComponent },
      { path: "adminnotauthorized", component: AdminNotAuthorizedComponent },
      { path: "signup", component: SignupComponent },
      {
        path: "signup/confirmationmessage",
        component: ConfirmationMessageComponent,
      },
      { path: "termsandconditions", component: TermsConditionsComponent },
      { path: "privacy", component: PrivacyPolicyComponent },
      { path: "forgotpassword", component: ForgotPasswordComponent },
      { path: "changepassword", component: ChangePasswordComponent },
      { path: "message-inbox", component: MessageInboxComponent },
      { path: "**", component: PageNotFoundComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [PodcastLink],
})
export class OpenRoutingModule {}
