import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
})
export class SpinnerComponent {
  @Input() active: boolean;
  @Input() dark = false;
}
