import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
  name: "moment",
})
export class MomentPipe implements PipeTransform {
  transform(value: string, ...args: string[]): unknown {
    if (!value) return null;
    if (!args) return value;
    if (!moment(value).isValid) return value;

    return moment(value).format(args[0]);
  }
}
