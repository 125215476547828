import {
  HttpClient,
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpParams,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, timer, throwError, of } from "rxjs";
import { retryWhen, tap, mergeMap } from "rxjs/operators";
import { SecurityService } from "./security.service";
import { environment } from "src/environments/environment";
import { LoggerService as Logger } from "src/app/shared/services/logger.service";

const API: string = environment.api;

@Injectable({ providedIn: "root" })
export class httpCustomInterceptor implements HttpInterceptor {
  private readonly publicUrls: string[] = [
    "users/resend_confirmation",
    "users/login",
    "users/resend_confirmation",
    "users/password",
  ];

  retryDelay = 2000;
  retryMaxAttempts = 1;

  constructor(private securityService: SecurityService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqUrl = req.url;
    const token = this.securityService.getToken();
    const isPublic = this.publicUrls.filter((url) => reqUrl.startsWith(API + url)).length > 0;
    if (reqUrl.includes("sendbird.com")) {
      const apiRequest = req.clone({
        headers: req.headers.set("Api-Token", environment.sendBirdApiToken),
      });
      return next.handle(apiRequest);
    }

    if (reqUrl.includes("twilio.com")) {
      const basicAuth = "Basic " + btoa(environment.twilioSID + ":" + environment.twilioAuthToken);
      const apiRequest = req.clone({
        headers: req.headers.set("Authorization", basicAuth).set("Content-Type", "application/x-www-form-urlencoded"),
      });
      return next.handle(apiRequest);
    }

    const headers = new HttpHeaders({
      Authorization: token,
    });

    if (!isPublic && token) {
      const modifiedReq = req.clone({
        headers: headers,
      });

      if (!modifiedReq.headers.has("Content-Type")) {
        modifiedReq.headers.append("Content-Type", "application/json");
      }

      return next.handle(modifiedReq).pipe(this.retryAfterDelay());
    }

    return next.handle(req).pipe(this.retryAfterDelay());
  }

  retryAfterDelay(): any {
    return retryWhen((errors) => {
      return errors.pipe(
        tap((error) => {
          if (error.status <= 499) {
            throw error;
          }
        }),
        mergeMap((err, count) => {
          // throw error when we've retried ${retryMaxAttempts} number of times and still get an error
          if (count === this.retryMaxAttempts) {
            return throwError(err);
          }
          return of(err).pipe(
            tap((error) => Logger.log(`Retrying ${error.url}. Retry count ${count + 1}`)),
            mergeMap(() => timer(this.retryDelay))
          );
        })
      );
    });
  }
}
