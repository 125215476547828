<div class="sign-up-modal email-hero-image" id="sign-up-modal">
  <div class="side-by-side">
    <div class="hype-text column">
      <h1>Welcome!</h1>
      <h2>We're so glad you're here.</h2>
    </div>
    <div class="modal-dialog" role="document">
      <div class="modal-content row column">
        <div class="modal-text">
          <h2>PLEASE VERIFY YOUR EMAIL ADDRESS</h2>
          <p class="info-text confirmation-text">
            This way we can be sure our recruiters can reach you when we find the perfect opportunities!
          </p>
          <p class="info-text confirmation-text">(Don't worry, we wont spam you with unnecessary emails)</p>
          <button class="continue-btn avoid" (click)="onClickSendAgain()">Resend Verification</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="corner-text">
  Boulo is the first personalized online career trainer that is focused on helping women <b>succeed</b> in the
  workforce.
</div>
