<mat-tab-group
  mat-stretch-tabs
  [selectedIndex]="selectedMenuTab.value"
  (selectedIndexChange)="_setCandidateSearchMenuTabIndex($event)"
  class="find-candidates-pane"
>
  <!-- SEARCH -->
  <mat-tab>
    <!-- tab -->
    <ng-template mat-tab-label>
      <span class="label-contents-wrapper">
        <span class="label-text"> Search </span>
      </span>
    </ng-template>

    <app-search-applicant-bar
      [hasFilterBtn]="true"
      [filterBtnName]="'Advance Search'"
      [filterCount]="filterCount"
      (searchEvent)="clickSearchBtn($event)"
      (isSearchDeletedEvent)="deleteKeyWords($event)"
      (clickedFilterBtn)="open()"
    ></app-search-applicant-bar>

    <div class="ai-search-indicator" *ngIf="isUsingAISearch && !_isSearching">
      <span class="icon-container">
        <img src="../../../../../../assets/svg/artifical_intelligence.svg" alt="" />
      </span>
      <p class="ai-result-desc">
        <span class="text"> Search Results Include AI-matching for </span>
        <span class="ai-selected-job-title">{{ _selectedAIJobMatch }}</span>
      </p>
    </div>

    <!-- Loading View -->
    <app-mat-spinner-loading-screen
      [isLoading]="_isSearching"
      [loadingMsg]="'Loading Searched Candidates...'"
    ></app-mat-spinner-loading-screen>
    <!-- pane -->
    <div *ngIf="!_isSearching && _isViewingSearchTab">
      <div *ngIf="_hasQueriedResults">
        <div class="search-pane">
          <div class="list-container">
            <app-applicant-list
              [candidateList]="_getListByTab"
              (selectedCandidate)="viewCandidate($event)"
              (saveCandidateId)="toggleSaveCandidate($event)"
              [tab]="'Search'"
            >
            </app-applicant-list>
          </div>
          <!--   https://v15.material.angular.io/components/paginator/examples   -->
          <div class="paginator-container">
            <mat-paginator
              [length]="_getTotalSize"
              [pageSizeOptions]="_pageSizeOptions"
              [pageSize]="_getPageSize"
              [hidePageSize]="_hidePageSize"
              [pageIndex]="_getPageIndex"
              [showFirstLastButtons]="_showFirstLastButtons"
              (page)="handlePageEvent($event)"
              class="pager"
              aria-label="Select page"
            >
            </mat-paginator>
          </div>
        </div>
      </div>
      <div *ngIf="!_hasQueriedResults" class="mx-auto py-4 px-5">
        <div class="no-candidate-results-search">
          <!-- THERE ARE NO RESULTS! TRY USING THE SEARCH FEATURE ABOVE! -->
          <div class="left-text-block">
            <p class="big-text-search">Find your perfect candidate.</p>
            <p class="regular-text-search">Looking for a candidate?</p>
            <p class="regular-text-search">You can use the search bar to narrow down the list of candidates.</p>
          </div>
          <div class="right-image-block">
            <img src="assets/img/employer/find_candidate_tiny.png" />
          </div>
        </div>
      </div>
    </div>
  </mat-tab>

  <!-- SAVED -->
  <mat-tab>
    <!-- tab -->
    <ng-template mat-tab-label>
      <span class="label-contents-wrapper">
        <span class="label-text"> Saved </span>
      </span>
    </ng-template>
    <!-- Loading View -->
    <app-mat-spinner-loading-screen
      [isLoading]="isLoading"
      [loadingMsg]="'Loading Saved Candidates...'"
    ></app-mat-spinner-loading-screen>
    <!-- pane -->
    <div *ngIf="!isLoading && _isViewingSavedTab">
      <div *ngIf="_hasSavedCandidates" class="saved-pane">
        <app-applicant-list
          [candidateList]="_getListByTab"
          (selectedCandidate)="viewCandidate($event)"
          (saveCandidateId)="toggleSaveCandidate($event)"
          [tab]="'Saved'"
        >
        </app-applicant-list>
      </div>
      <div *ngIf="!_hasSavedCandidates" class="mx-auto py-4 px-5">
        <div class="no-candidate-results-saved flipped-img-saved">
          <!-- THERE ARE NO SAVED CANDIDATES! TRY USING THE SEARCH FEATURE ABOVE & FAVORITING STAND-OUT CANDIDATES! -->
          <div class="left-text-block flipped-img-saved">
            <p class="big-text-saved">Save your perfect candidates.</p>
            <p class="regular-text-saved">Click the 'star' on a stand-out candidates.</p>
            <p class="regular-text-saved">You can use the search bar above to find candidates.</p>
          </div>
          <div class="right-image-block">
            <img src="assets/img/employer/find_candidate_tiny.png" />
          </div>
        </div>
      </div>
    </div>
  </mat-tab>

  <!-- FOLLOWED -->
  <mat-tab>
    <!-- tab -->
    <ng-template mat-tab-label>
      <span class="label-contents-wrapper">
        <span class="label-text"> Followed By </span>
      </span>
    </ng-template>
    <!-- Loading View -->
    <app-mat-spinner-loading-screen
      [isLoading]="isLoading"
      [loadingMsg]="'Loading Candidates Following Your Company...'"
    ></app-mat-spinner-loading-screen>
    <!-- pane -->
    <div *ngIf="!isLoading && _isViewingFollowTab" class="w-100">
      <div *ngIf="_hasFollowingCandidates" class="search-pane" class="w-100">
        <app-applicant-list
          [candidateList]="_getListByTab"
          (selectedCandidate)="viewCandidate($event)"
          (saveCandidateId)="toggleSaveCandidate($event)"
          [tab]="'Followed'"
          class="w-100"
        >
        </app-applicant-list>
      </div>
      <div *ngIf="!_hasFollowingCandidates" class="mx-auto py-4 px-5">
        <div class="no-candidate-results-saved">
          <!-- THERE ARE NO SAVED CANDIDATES! TRY USING THE SEARCH FEATURE ABOVE & FAVORITING STAND-OUT CANDIDATES! -->
          <div class="left-text-block">
            <p class="big-text-saved">No following candidates.</p>
            <p class="regular-text-saved">Candidates who are following your company will show up here.</p>
            <p class="regular-text-saved">Posting more jobs increases your visibility to candidates!</p>
          </div>
          <div class="right-image-block">
            <img src="assets/img/employer/find_candidate_tiny.png" />
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
