import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { GroupChannel } from "@sendbird/chat/groupChannel";
import { SendBirdService } from "src/app/services/send-bird.service";
import { iMessageInbox } from "src/app/user/user-root/user-root.service";
import { EmployerOnboardingService } from "../../employer-root/employer-root.service";
import { iMessageReciever, iMessageSender } from "../../message-box/message-box.component";
import { LoggerService as Logger } from "src/app/shared/services/logger.service";
import { MessageDisplayText } from "../messageDisplayText.constants";
import { MatDialog } from "@angular/material/dialog";
import { DialogConfirmDeleteComponent } from "src/app/shared/components/dialog-confirm-delete/dialog-confirm-delete.component";
import { OnboardingRootService } from "src/app/user/onboarding/onboarding-root/onboarding-root.service";

@Component({
  selector: "app-message-inbox",
  templateUrl: "./message-inbox.component.html",
  styleUrls: ["./message-inbox.component.scss"],
})
export class MessageInboxComponent implements OnInit, OnDestroy {
  public channels: GroupChannel[] = [];
  @Input() senderId: string;
  @Input() senderName: string;
  public currentChannel: GroupChannel = null;
  public selectedChannel: string | null = null;
  public sender: iMessageSender = {
    email: "",
    name: "",
    optInToEmail: false,
    phone: "",
  };
  public receiver: iMessageReciever = {
    email: "",
    name: "",
    optInToEmail: false,
    phone: "",
  };
  public messages: { sender: string; text: string; timestamp: number }[] = [];
  public channelToDelete: GroupChannel | null = null;
  public isLoading: boolean = true;
  public showDelete: boolean = false;

  public messageInboxDetails: iMessageInbox;

  constructor(
    private messageService: SendBirdService,
    private cdr: ChangeDetectorRef,
    public onboardingService: EmployerOnboardingService,
    public dialog: MatDialog,
    public _service: OnboardingRootService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.onboardingService.bullhornData.clientContact?.id.length > 1 ) {
      this.sender.email = this.onboardingService.bullhornData.clientContact.email.toString();

      const user = await this.messageService.connect(this.sender.email);
      this.sender.email = user.userId;
      this.sender.name = user.nickname;
      this.sender.email = user.metaData["email"];
      this.sender.optInToEmail = user.metaData["optInToEmail"] === "true" ? true : false;

      const getChannelsResponse = await this.messageService.getGroupChannelsForUser(this.sender.email);
      this.channels = getChannelsResponse.channels;
      this.cdr.detectChanges();
      this.isLoading = false;
    } else {
      Logger.error(`Unable to connect to Sendbird with an invalid contact id`);
      this.onboardingService.generalMsg(
        MessageDisplayText.Error.LoadingInboxChatsIssue,
        MessageDisplayText.Error.LoadingInboxChatsResolution
      );
    }
  }

  get _getMessageDetails(): iMessageInbox {
    return {
      channels: this.channels,
      currentChannel: this.currentChannel,
      selectedChannel: this.selectedChannel,
      receiver: this.receiver,
      sender: this.sender,
      messages: this.messages,
    };
  }

  ngOnDestroy(): void {
    this.messageService.disconnect();
  }

  async selectChannel(channelName: string): Promise<void> {
    this.showDelete = true;
    this.messages = [];
    this.receiver.name = "";
    const channelInfo = this.channels.find((channel) => channel.name === channelName);
    this.isLoading = true;
    if (!channelInfo) {
      this.onboardingService.generalMsg(`Unable to load chat.`, "If problem persists, reach out to support");
      return;
    }

    this.selectedChannel = channelInfo["channel_url"];

    const parsedChannelInfo = channelInfo.name.split("-");

    // Candidate Id from Channel Name
    this.receiver.email = parsedChannelInfo[1];
    this.currentChannel = channelInfo;

    const recUser = await this.messageService.connect(this.receiver.email);

    this.receiver.name = recUser.nickname;
    this.receiver.email = recUser.metaData["email"];
    this.receiver.optInToEmail = recUser.metaData["optInToEmail"] === "true" ? true : false;
    this.receiver.phone = recUser.metaData["phoneNumber"];
    await this.messageService.connect(this.sender.email);

    this.messages = [];
    this.messages = await this.messageService.getSendBirdMessages(this.currentChannel["channel_url"]);
    await this.messageService.markMessagesAsReadForChannel(this.sender.email, this.currentChannel["channel_url"]);
    this.currentChannel["unread_message_count"] = 0;

    this.cdr.detectChanges();
    this.isLoading = false;
  }

  toggleChannel(): void {
    this.currentChannel = null;
  }

  prepareDeleteChannel(channel: GroupChannel): void {
    this.channelToDelete = channel;
    const deletionRef = this.dialog.open(DialogConfirmDeleteComponent, {
      width: "600px",
      data: {
        deleteItemType: "conversation",
        deleteItemName: `your conversation with ${this.receiver.name}`,
        description: "retrieve message history",
      },
    });

    deletionRef.afterClosed().subscribe(async (result) => {
      if (result === true) {
        deletionRef.close();
        await this.messageService.deleteChannel(this.channelToDelete["channel_url"]);

        this.onboardingService.generalMsg(`Coversation Deleted!`, "");

        this.channels = this.channels.filter((channel) => channel["channel_url"] !== this.channelToDelete["channel_url"]);
        this.channelToDelete = null;
        this.selectedChannel = null;

        this.cdr.detectChanges();
      } else {
        // User cancelled the deletion
        this.channelToDelete = null;
      }
    });
  }

  updateEmailOptIn(optIn: boolean): void {
    this.messageService.updateEmailOptIn(this.sender.email, optIn);
  }

  defaultReceiver = (): iMessageReciever => {
    return {
      email: "",
      name: "",
      optInToEmail: false,
      phone: "",
    };
  };

  async confirmDelete(): Promise<void> {
    if (this.channelToDelete) {
      await this.messageService.deleteChannel(this.channelToDelete["channel_url"]);

      this.onboardingService.generalMsg(`Coversation Deleted!`, "");

      this.channels = this.channels.filter((channel) => channel["channel_url"] !== this.channelToDelete["channel_url"]);
      this.channelToDelete = null;
      this.selectedChannel = null;
      this.showDelete = false;

      this.cdr.detectChanges();
    }
  }
}
