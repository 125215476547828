import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-the-team",
  templateUrl: "./the-team.component.html",
  styleUrls: ["./the-team.component.scss"],
})
// Route to this component was removed 01/2024
export class TheTeamComponent implements OnInit {
  teamData: { id: string; name: string; position: string; picture: string; prevPicture: string; description: string }[] = [
    {
      id: "suzanna-keith",
      name: "Suzanna Keith",
      position: "Founder & CEO",
      picture: "../../../assets/img/the-team/suzanna-keith.png",
      prevPicture: "../../../assets/img/the-team/preview-Suzanna.png",
      description:
        "Responsible for overall management, fundraising, interface with investors, and advisory board. Suzanna has over 25 years of experience as senior marketing, sales, strategy, not-for-profit founder, social media, and innovation executive. She is author of TechandTravelmom.com Blog (‘10) and Techandtravl moments on Youtube. Also, Founder of Women In Innovation.  NYU Stern M.B.A. ",
    },
    {
      id: "tom-beauchamp",
      name: "Tom Beauchamp",
      position: "Co-Founder & CTO",
      picture: "../../../assets/img/the-team/tom-beuchamp.png",
      prevPicture: "../../../assets/img/the-team/preview-Tom.png",
      description:
        "Responsible for technology strategy, product design, and management and interface with developers on key elements of the product. His background includes CIO roles at numerous multi-channel retail organizations including David Yurman, Hot Topic, Foot Locker, and Limited Stores. In addition, Tom has been an investor in numerous successful startup organizations with two successful exits. CA State M.B.A. ",
    },
    {
      id: "sonal-rinello",
      name: "Sonal Rinello",
      position: "Co-Founder & CMO",
      picture: "../../../assets/img/the-team/sonal-rinello.png",
      prevPicture: "../../../assets/img/the-team/preview-Sonal.png",
      description:
        "Is a C-suite executive with 20+ years of experience including Marketing leadership positions at L’Occitane en Provence and P&G.  Her skill set encompasses strategic planning, branding, and marketing execution for retail and consumer brands.  Sonal is also the author of jobgenda, a job search planner-organizer. Northwestern Kellogg M.B.A",
    },
    {
      id: "karen-keane",
      name: "Karen Keane",
      position: "Co-Founder & CFO",
      picture: "../../../assets/img/the-team/karen-keane.png",
      prevPicture: "../../../assets/img/the-team/preview-Karen.png",
      description:
        "Responsible for financial operations. Karen is a senior finance professional with over 15 years of banking, non-profit, and public accounting experience. Karen’s background includes structuring and executing domestic and international finance transactions at Deutsche Bank Securities and educational program development at Center for Continuing Education. NYU Stern M.B.A",
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
