import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular";
import { SignUp } from "./security.service";

@Injectable({ providedIn: "root" })
export class SentryService {
  startStopwatchTimestamp = Date.now();

  public startStopwatch() {
    this.startStopwatchTimestamp = Date.now();
  }

  private caclulateTimespanInMinutes() {
    const t = Date.now() - this.startStopwatchTimestamp;

    return t / 1000;
  }

  public logOnboardingStepCompleted() {
    Sentry.captureMessage("User completed onboarding step", {
      level: "log",
      extra: {
        timeOnScreen: { value: this.caclulateTimespanInMinutes(), unit: "seconds" },
      },
    });
  }

  public logSignup(userInfo: SignUp) {
    const e = { ...userInfo };
    delete e.password;
    Sentry.captureMessage("User completed signup", {
      level: "log",
      extra: e,
    });
  }

  public setUser(userId: string, email: string) {
    Sentry.setUser({ id: userId, email: email });
  }

  public clearUser() {
    Sentry.setUser({});
  }
}
