import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subject, BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError, tap } from "rxjs/operators";
import { HandleHttpErrors } from "../services/handle-http-errors.function";
import { SecurityService } from "src/app/services/security.service";

const API: string = environment.api;

@Injectable()
export class OpenCommonService {
  private _showBannerObservable: Subject<boolean> = new BehaviorSubject<boolean>(true);
  public readonly showBannerObservable: Observable<boolean> = this._showBannerObservable.asObservable();
  private _showTutorialObservable: Subject<string> = new BehaviorSubject<string>("false");
  public readonly showTutorialObservable: Observable<string> = this._showTutorialObservable.asObservable();

  constructor(
    private router: Router,
    private http: HttpClient,
    private user: SecurityService
  ) {}

  goToSignup(): void {
    this.router.navigate(["/signup"]);
  }

  goToHowItWorks(): void {
    this.router.navigate(["/howitworks"]);
  }

  goToMerbership(): void {
    this.router.navigate(["/membership"]);
  }

  goToRewardsHelping(): void {
    this.router.navigate(["/rewards"]).then(() => {
      const helping = document.getElementById("helping");
      helping.scrollIntoView(false);
    });
  }

  updateShowBanner(show): void {
    this._showBannerObservable.next(show);
  }

  updateShowTutorial(show) {
    this._showTutorialObservable.next(show);
    return this.http.put(API + `users/${this.user.UserRef.id}`, { skip_tutorial: show }, { observe: "response" }).pipe(
      catchError(HandleHttpErrors),
      tap((response) => {
        this.user.skipTutorial(show == "true" ? true : false);
        return response;
      })
    );
  }

  signupNewsletter(email: string) {
    return this.http.post(API + "newsletters/signup", {
      member: {
        email_address: email,
      },
    });
  }
}
