import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { OpenCommonService } from "../open-common.service";
import { getErrorMessage } from "src/app/services/handle-http-errors.function";
import { Meta } from "@angular/platform-browser";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  defaultSvgAddress: string = "assets/svg/";
  socialMedia = [
    {
      svg: this.defaultSvgAddress + "Linkedin.svg",
      name: "LinkedIn",
      link: "https://www.linkedin.com/company/hello-career-guru",
      target: "_blank",
    },
    {
      svg: this.defaultSvgAddress + "Facebook.svg",
      name: "Facebook",
      extraClass: "facebook",
      link: "https://www.facebook.com/hellocareerguru/",
      target: "_blank",
    },
    {
      svg: this.defaultSvgAddress + "Twitter.svg",
      name: "Twitter",
      link: "https://twitter.com/hellocareerguru",
      target: "_blank",
    },
    {
      svg: this.defaultSvgAddress + "Instagram.svg",
      name: "Instagram",
      link: "https://www.instagram.com/hellocareerguru/",
      target: "_blank",
      cssClass: "just-mobile",
    },
    {
      svg: this.defaultSvgAddress + "Spotify_icon.svg",
      name: "Spotify",
      link: "https://open.spotify.com/show/3nSC4rVhsxFNk5upYDXIhT",
      target: "_blank",
    },
    // {
    //   svg: this.defaultSvgAddress + 'Youtube.svg',
    //   name: 'YouTube',
    //   link: 'https://www.youtube.com/channel/UCpIQmH4d-ooDXOomIEdeNrw',
    //   target: '_blank'
    // },
  ];
  newsletterForm: UntypedFormGroup;
  newsletterSubmitting: boolean;
  newsletterMessage: string;
  newsletterMessageError: boolean;

  constructor(
    private commonService: OpenCommonService,
    private metaService: Meta
  ) {}

  ngOnInit(): void {
    this.newsletterForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
    });

    this.metaService.addTags([
      {
        name: "keywords",
        content: "Career Development, Career Advancement, Executives, Advice",
      },
      {
        name: "description",
        content:
          "Subscribe to the Boulo Newsletter to receive career insights and advice based on data and feedback from C-suite women.",
      },
    ]);
  }

  onNewsletterSignup() {
    const email = this.newsletterForm.get("email").value;
    if (!email || this.newsletterSubmitting) {
      return;
    }

    this.newsletterSubmitting = true;
    this.newsletterMessageError = false;
    this.commonService.signupNewsletter(email).subscribe(
      () => {
        this.newsletterSubmitting = false;
        this.newsletterMessage = "Thank you for subscribing to our newsletter";
      },
      (err) => {
        const errorMessage = getErrorMessage(err);
        this.newsletterSubmitting = false;
        this.newsletterMessageError = true;
        this.newsletterMessage = errorMessage !== "Unknown Error" ? errorMessage : errorMessage;
      }
    );
  }

  resetNewsletterSignupStatus() {
    this.newsletterMessage = null;
  }
}
