import {
  NgxMatMomentModule,
  NgxMatMomentAdapter,
  NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular-material-components/moment-adapter";
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  NGX_MAT_DATE_FORMATS,
  NgxMatDateAdapter,
  NgxMatNativeDateModule,
} from "@angular-material-components/datetime-picker";
import { NgModule } from "@angular/core";
import { MAT_DATE_LOCALE } from "@angular/material/core";

export const CUSTOM_MOMENT_FORMATS = {
  parse: {
    dateInput: "l, LT",
  },
  display: {
    dateInput: "l, LT",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
@NgModule({
  imports: [NgxMatNativeDateModule, NgxMatMomentModule, NgxMatDatetimePickerModule, NgxMatTimepickerModule],
  providers: [
    { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS },
    { provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter },
  ],
})
export class CustomDateModule {}
