import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { OpenCommonService } from "../open-common.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild("navButton") navButton: ElementRef<HTMLButtonElement>;
  // showBanner: boolean = true;
  showBanner$: Observable<boolean>;

  constructor(
    public common: OpenCommonService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    document.getElementsByTagName("body")[0].classList.remove("hide-contents");
  }

  ngOnInit(): void {
    this.showBanner$ = this.common.showBannerObservable;
  }

  hideShowBody() {
    if (this.navButton.nativeElement.getAttribute("aria-expanded") === "true") {
      document.getElementsByTagName("body")[0].classList.remove("hide-contents");
      this.common.updateShowBanner(true);
    } else {
      document.getElementsByTagName("body")[0].classList.add("hide-contents");
      this.common.updateShowBanner(false);
    }
  }

  goToPodcast(): void {
    this.common.updateShowBanner(false);
    this.router.navigate([""]).then((result) => {
      window.open("https://www.youtube.com/watch?v=ly6fpBLhcWA", "_blank");
    });
  }

  closeBanner(): void {
    this.common.updateShowBanner(false);
  }
}
