import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { GroupChannel } from "@sendbird/chat/groupChannel";
import { iMessageInbox } from "src/app/user/user-root/user-root.service";
import { MatCheckboxChange } from "@angular/material/checkbox";

@Component({
  selector: "app-inbox-root",
  templateUrl: "./inbox-root.component.html",
  styleUrls: ["./inbox-root.component.scss"],
})
export class InboxRootComponent implements OnInit {
  @Input() messageDetails: iMessageInbox;
  @Input() isLoading: boolean;
  @Input() isEmployer: boolean = false;
  @Input() showDeleteButton: boolean;
  @Output() channelSelect: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleteChannelSelect: EventEmitter<GroupChannel> = new EventEmitter<GroupChannel>();
  @Output() optInForEmailChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit(): void {}

  get _hasCurrentChannel(): boolean {
    return this.messageDetails.currentChannel !== null;
  }
  get _currentChannel(): GroupChannel {
    return this.messageDetails?.currentChannel;
  }
  get _channels(): GroupChannel[] {
    return this.messageDetails?.channels;
  }

  selectChannel(name: string): void {
    this.channelSelect.emit(name);
  }

  deleteChannel(channel: GroupChannel): void {
    this.deleteChannelSelect.emit(channel);
  }

  get _deets(): iMessageInbox {
    return this.messageDetails;
  }

  _getCandidateName(longName: string): string {
    const idx = longName.lastIndexOf("-");
    if (idx !== -1) {
      return longName.substring(idx + 1, longName.length);
    }

    return this.isEmployer ? "Candidate" : "Employer";
  }

  get _hasChannels(): boolean {
    return this.messageDetails?.channels?.length > 0;
  }

  get _noChatsMsg(): string {
    if (this.isEmployer) {
      return "Start a conversation with a candidate when you are viewing their details!";
    }

    return "You will receive messages from Employers here.";
  }

  get _isChannelSelected(): boolean {
    return this.messageDetails?.selectedChannel?.length > 0;
  }

  _isSelected(channelUrl: string): boolean {
    if (channelUrl === this.messageDetails.selectedChannel) {
      return true;
    }

    return false;
  }

  optInChanged(event: MatCheckboxChange) {
    this.optInForEmailChanged.emit(event.checked);
  }
}
