<nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top container-xpadding">
  <div class="justify-content-start">
    <a class="navbar-brand" routerLink="/">
      <img class="header-logo version0 d-none d-sm-block" src="assets/svg/dashboard/Logo_dashboard_icon.svg" />
      <img class="header-logo version1 d-block d-sm-none" src="assets/svg/dashboard/Logo_dashboard_icon.svg" />
    </a>
  </div>
  <button
    #navButton
    class="navbar-toggler ml-3"
    (click)="hideShowBody()"
    type="button"
    data-toggle="collapse"
    data-target="#navbarNav"
    aria-controls="navbarNav"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
    <ul class="navbar-nav">
      <li class="nav-item" routerLinkActive="selected text">
        <a class="nav-link" routerLink="/howitworks">How It Works</a>
      </li>
      <li class="nav-item" routerLinkActive="selected text">
        <a class="nav-link" routerLink="/membership">Membership</a>
      </li>
      <li class="nav-item" routerLinkActive="selected text">
        <a class="nav-link" href="https://legacy-hellocareerguru.squarespace.com/guru-salon-podcast" target="_blank">
          Salon Podcast</a
        >
      </li>
      <li class="nav-item" routerLinkActive="selected text">
        <a class="nav-link" href="https://legacy-hellocareerguru.squarespace.com/the-guru-view" target="_blank"
          >The View</a
        >
      </li>
      <li class="nav-item" routerLinkActive="selected text">
        <a class="nav-link" routerLink="/rewards">Rewards</a>
      </li>
      <li class="nav-item hidden-item" routerLinkActive="text">
        <a class="nav-link">Company</a>
      </li>
      <li class="nav-item hidden-item" routerLinkActive="selected text">
        <a class="nav-link margin-left" routerLink="/team">Team</a>
      </li>
      <li class="nav-item hidden-item" routerLinkActive="selected text">
        <a class="nav-link margin-left" routerLink="/press">Press</a>
      </li>
      <li class="nav-item hidden-item" routerLinkActive="selected text">
        <a class="nav-link margin-left" routerLink="/insights">Insights & Resources</a>
      </li>
      <li class="nav-item hidden-item" routerLinkActive="selected text">
        <a class="nav-link margin-left" routerLink="/faq">FAQ</a>
      </li>
      <li class="nav-item hidden-item" routerLinkActive="selected text">
        <a
          class="nav-link margin-left"
          href="https://legacy-hellocareerguru.squarespace.com/the-guru-view"
          target="_blank"
        >
          View</a
        >
      </li>
      <li class="nav-item hidden-item" routerLinkActive="text">
        <a class="nav-link" routerLink="/contactus">Contact us</a>
      </li>
      <li class="nav-item hidden-item" routerLinkActive="selected text">
        <a class="nav-link margin-left" routerLink="/newsletter">Newletter signup</a>
      </li>
      <li class="nav-item hidden-item" routerLinkActive="selected text">
        <a class="nav-link margin-left" routerLink="/termsandconditions">Terms and Conditions</a>
      </li>
      <li class="nav-item hidden-item" routerLinkActive="selected text">
        <a class="nav-link margin-left" routerLink="/privacy">Privacy Policy</a>
      </li>
      <li class="nav-item d-none d-lg-block elements-in-xl" routerLinkActive="selected text">
        <a class="nav-link" routerLink="/login">Sign In</a>
      </li>
      <li class="d-none d-lg-block">
        <button class="btn btn-outline-dark rounded-pill avoid font-weight-bold" (click)="common.goToSignup()">
          Sign Up Now
        </button>
      </li>
      <li class="d-block d-lg-none px-3">
        <hr />
        <div class="container-fluid mt-2 mb-1 d-flex justify-content-between">
          <div class="nav-item mr-2">
            <button class="btn btn-outline-dark rounded-pill avoid font-weight-bold" (click)="common.goToSignup()">
              Sign Up Now
            </button>
          </div>
          <div class="nav-item ml-2" routerLinkActive="selected text">
            <a class="nav-link" routerLink="/login">Sign In</a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</nav>
<ng-container *ngIf="showBanner$ | async as showBanner">
  <section class="banner w-100 d-flex justify-content-center navbar fixed-top" *ngIf="showBanner">
    <h4 class="col-md-11 text-center">
      "Boulo helped me advance in my career and helped me obtain a C-suite role." Nicole, Not for Profit
    </h4>
    <a (click)="closeBanner()" class="close-icon"><img src="assets/svg/Close-default_icon.svg" /></a>
  </section>
</ng-container>
