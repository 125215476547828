import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-user-not-authorized",
  templateUrl: "./user-not-authorized.component.html",
  styleUrls: ["./user-not-authorized.component.scss"],
})
// Route to this component was removed 01/2024
export class UserNotAuthorizedComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
