// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// ----------------------------------------------------------------------------------
// -- Update this field only, set it equal to the environment you need to set it to -- 
// ----------------------------------------------------------------------------------
const isProduction: boolean = true; 
const isDev: boolean = false;



//#region Environments Setups
// Dev 
const devEnvironment = {
  appUrl: "http://localhost:4200",
};

// Staging
const stagingEnvironment = {
  production: false,
  LOG_LEVEL: 'DEBUG',
  api: "http://ec2-54-226-72-204.compute-1.amazonaws.com/api/v1/",
  proxyApi: "https://ax7fe4utl0.execute-api.us-east-1.amazonaws.com/",
  communityLink: "https://boulo.circle.so/join?invitation_token=5f25099dc9813dd476ca93bde6b8e3f55dde8c14-bcb112d9-2732-40d7-ad5c-ed5ed88bea99",
  stripePublicKey: 'pk_test_51Knq0NJdCABnraOoyChGBMVBdXkVpGk6X5IrSzw1oFP7Gf0oNdI0P3XJhzyeCRgfMCNMe2BcCHhEQNwTgxONj4YD00yOJVDf6v',
  stripeSeedPriceId: 'price_1POK59JdCABnraOoH69gkpHf',
  stripeGrowPriceId: 'price_1NhzBuJdCABnraOoNZAnexuc',
  stripeGrowMonthlyPriceId : 'price_1POK48JdCABnraOoCwFvCXIa',
  stripeGrowYearlyPriceId : 'price_1POK2zJdCABnraOogx3i3iJv',
  sendBirdAppId: '17526762-0A81-4C57-9CA7-B578EB42440E',
  sendBirdApiKey: '9bce6f7bd6cb4cc9becfa176',
  sendBirdApiToken: '3aab3d4c792c7f67c8c2f49c8b02413c5261d98e',
  emailLambdaUrl: 'https://ax7fe4utl0.execute-api.us-east-1.amazonaws.com/send-email',
  appUrl: isDev ? devEnvironment.appUrl :  "http://ec2-18-205-109-234.compute-1.amazonaws.com",
  twilioUrl: "https://api.twilio.com/2010-04-01/Accounts",
  twilioAuthToken: "1c312abcba53bbe12cb35c11ef2c1f23",
  twilioSID: "AC70b47a3061ca42bf64c2b70feecd0a7e",
  bouloNumber: "+14049007748"
};

// Production
const prodEnvironment = {
  production: true,
  LOG_LEVEL: "ERROR",
  api: "https://59grqph3i1.execute-api.us-east-1.amazonaws.com/api/v1/",
  proxyApi: "https://pfhe02apw1.execute-api.us-east-1.amazonaws.com/",
  communityLink:
    "https://boulo.circle.so/join?invitation_token=5f25099dc9813dd476ca93bde6b8e3f55dde8c14-bcb112d9-2732-40d7-ad5c-ed5ed88bea99",
  stripePublicKey:
    "pk_live_51Knq0NJdCABnraOo3LOxaJ7XxtNHMawAdiA6JZuqmhmLBoMPUD6txSZeVdm6DDO1OI8ffKuVe5vHnnHiIWPxtf2f00EdGwZEEg",
  stripeSeedPriceId: "price_1PO35RJdCABnraOo1AF8WVOY",
  stripeGrowPriceId: "price_1NnobRJdCABnraOoMPwd0iEA",
  stripeGrowMonthlyPriceId : 'price_1PO35xJdCABnraOoIZrlfQEw',
  stripeGrowYearlyPriceId : 'price_1PO4OQJdCABnraOoD6zDdDt8',
  sendBirdAppId: "17526762-0A81-4C57-9CA7-B578EB42440E",
  sendBirdApiKey: "9bce6f7bd6cb4cc9becfa176",
  sendBirdApiToken: "3aab3d4c792c7f67c8c2f49c8b02413c5261d98e",
  emailLambdaUrl: "https://ax7fe4utl0.execute-api.us-east-1.amazonaws.com/send-email",
  appUrl: "app.boulosolutions.com",
  twilioUrl: "https://api.twilio.com/2010-04-01/Accounts",
  twilioAuthToken: "1c312abcba53bbe12cb35c11ef2c1f23",
  twilioSID: "AC70b47a3061ca42bf64c2b70feecd0a7e",
  bouloNumber: "+14049007748"
};
//#endregion Environments Setups


export const environment = {
  production: isProduction,
  LOG_LEVEL: isProduction ? prodEnvironment.LOG_LEVEL : stagingEnvironment.LOG_LEVEL,
  api: isProduction ? prodEnvironment.api : stagingEnvironment.api,
  proxyApi: isProduction ? prodEnvironment.proxyApi : stagingEnvironment.proxyApi,
  communityLink: isProduction ? prodEnvironment.communityLink : stagingEnvironment.communityLink,
  stripePublicKey: isProduction ? prodEnvironment.stripePublicKey : stagingEnvironment.stripePublicKey,
  stripeSeedPriceId: isProduction ? prodEnvironment.stripeSeedPriceId : stagingEnvironment.stripeSeedPriceId,
  stripeGrowPriceId: isProduction ? prodEnvironment.stripeGrowPriceId : stagingEnvironment.stripeGrowPriceId,
  stripeGrowMonthlyPriceId: isProduction ? prodEnvironment.stripeGrowMonthlyPriceId : stagingEnvironment.stripeGrowMonthlyPriceId,
  stripeGrowYearlyPriceId: isProduction ? prodEnvironment.stripeGrowYearlyPriceId : stagingEnvironment.stripeGrowYearlyPriceId,
  sendBirdAppId: isProduction ? prodEnvironment.sendBirdAppId : stagingEnvironment.sendBirdAppId,
  sendBirdApiKey: isProduction ? prodEnvironment.sendBirdApiKey : stagingEnvironment.sendBirdApiKey,
  sendBirdApiToken: isProduction ? prodEnvironment.sendBirdApiToken : stagingEnvironment.sendBirdApiToken,
  emailLambdaUrl: isProduction ? prodEnvironment.emailLambdaUrl : stagingEnvironment.emailLambdaUrl,
  appUrl: isProduction ? prodEnvironment.appUrl : stagingEnvironment.appUrl,
  twilioUrl: isProduction ? prodEnvironment.twilioUrl : stagingEnvironment.twilioUrl,
  twilioAuthToken: isProduction ? prodEnvironment.twilioAuthToken : stagingEnvironment.twilioAuthToken,
  twilioSID: isProduction ? prodEnvironment.twilioSID : stagingEnvironment.twilioSID,
  bouloNumber: isProduction ? prodEnvironment.bouloNumber : stagingEnvironment.bouloNumber,
}


//Production
// export const environment = {
//   production: true,
//   LOG_LEVEL: "ERROR",
//   api: "https://59grqph3i1.execute-api.us-east-1.amazonaws.com/api/v1/",
//   proxyApi: "https://pfhe02apw1.execute-api.us-east-1.amazonaws.com/",
//   communityLink:
//     "https://boulo.circle.so/join?invitation_token=5f25099dc9813dd476ca93bde6b8e3f55dde8c14-bcb112d9-2732-40d7-ad5c-ed5ed88bea99",
//   stripePublicKey:
//     "pk_live_51Knq0NJdCABnraOo3LOxaJ7XxtNHMawAdiA6JZuqmhmLBoMPUD6txSZeVdm6DDO1OI8ffKuVe5vHnnHiIWPxtf2f00EdGwZEEg",
//   stripeSeedPriceId: "price_1NnobRJdCABnraOo2dlYk0aW",
//   stripeGrowPriceId: "price_1NnobRJdCABnraOoMPwd0iEA",
//   stripeGrowMonthlyPriceId : 'price_1ODDNpJdCABnraOoTpBbzxls',
//   stripeGrowYearlyPriceId : 'price_1ODDOUJdCABnraOo7sILUcki',
//   sendBirdAppId: "17526762-0A81-4C57-9CA7-B578EB42440E",
//   sendBirdApiKey: "9bce6f7bd6cb4cc9becfa176",
//   sendBirdApiToken: "3aab3d4c792c7f67c8c2f49c8b02413c5261d98e",
//   emailLambdaUrl: "https://ax7fe4utl0.execute-api.us-east-1.amazonaws.com/send-email",
//   appUrl: "app.boulosolutions.com",
//   twilioUrl: "https://api.twilio.com/2010-04-01/Accounts",
//   twilioAuthToken: "f3cce10aaab3ade0f9bdee5f84600020",
//   twilioSID: "AC70b47a3061ca42bf64c2b70feecd0a7e",
//   bouloNumber: "+14049007748"
// };

// Staging
// export const environment = {
//   production: true,
//   LOG_LEVEL: "ERROR",
//   api: "https://59grqph3i1.execute-api.us-east-1.amazonaws.com/api/v1/",
//   proxyApi: "https://pfhe02apw1.execute-api.us-east-1.amazonaws.com/",
//   communityLink:
//     "https://boulo.circle.so/join?invitation_token=5f25099dc9813dd476ca93bde6b8e3f55dde8c14-bcb112d9-2732-40d7-ad5c-ed5ed88bea99",
//   stripePublicKey:
//     "pk_live_51Knq0NJdCABnraOo3LOxaJ7XxtNHMawAdiA6JZuqmhmLBoMPUD6txSZeVdm6DDO1OI8ffKuVe5vHnnHiIWPxtf2f00EdGwZEEg",
//   stripeSeedPriceId: "price_1NnobRJdCABnraOo2dlYk0aW",
//   stripeGrowPriceId: "price_1NnobRJdCABnraOoMPwd0iEA",
//   stripeGrowMonthlyPriceId : 'price_1ODDNpJdCABnraOoTpBbzxls',
//   stripeGrowYearlyPriceId : 'price_1ODDOUJdCABnraOo7sILUcki',
//   sendBirdAppId: "17526762-0A81-4C57-9CA7-B578EB42440E",
//   sendBirdApiKey: "9bce6f7bd6cb4cc9becfa176",
//   sendBirdApiToken: "3aab3d4c792c7f67c8c2f49c8b02413c5261d98e",
//   emailLambdaUrl: "https://ax7fe4utl0.execute-api.us-east-1.amazonaws.com/send-email",
//   appUrl: "app.boulosolutions.com",
//   twilioUrl: "https://api.twilio.com/2010-04-01/Accounts",
//   twilioAuthToken: "f3cce10aaab3ade0f9bdee5f84600020",
//   twilioSID: "AC70b47a3061ca42bf64c2b70feecd0a7e",
//   bouloNumber: "+14049007748"
// };

// Staging
// export const environment = {
//   production: false,
//   LOG_LEVEL: 'DEBUG',
//   api: "http://ec2-54-226-72-204.compute-1.amazonaws.com/api/v1/",
//   proxyApi: "https://ax7fe4utl0.execute-api.us-east-1.amazonaws.com/",
//   communityLink: "https://boulo.circle.so/join?invitation_token=5f25099dc9813dd476ca93bde6b8e3f55dde8c14-bcb112d9-2732-40d7-ad5c-ed5ed88bea99",
//   stripePublicKey: 'pk_test_51Knq0NJdCABnraOoyChGBMVBdXkVpGk6X5IrSzw1oFP7Gf0oNdI0P3XJhzyeCRgfMCNMe2BcCHhEQNwTgxONj4YD00yOJVDf6v',
//   stripeSeedPriceId: 'price_1Nha5wJdCABnraOoOIL1XLNg',
//   stripeGrowPriceId: 'price_1NhzBuJdCABnraOoNZAnexuc',
//   stripeGrowMonthlyPriceId : 'price_1OHp55JdCABnraOoNLP0Ms5G',
//   stripeGrowYearlyPriceId : 'price_1OHp4XJdCABnraOoGhRzqYHG',
//   sendBirdAppId: '17526762-0A81-4C57-9CA7-B578EB42440E',
//   sendBirdApiKey: '9bce6f7bd6cb4cc9becfa176',
//   sendBirdApiToken: '3aab3d4c792c7f67c8c2f49c8b02413c5261d98e',
//   emailLambdaUrl: 'https://ax7fe4utl0.execute-api.us-east-1.amazonaws.com/send-email',
//   appUrl: "http://ec2-18-205-109-234.compute-1.amazonaws.com",
//   // appUrl: "http://localhost:4200",
//   twilioUrl: "https://api.twilio.com/2010-04-01/Accounts",
//   twilioAuthToken: "f3cce10aaab3ade0f9bdee5f84600020",
//   twilioSID: "AC70b47a3061ca42bf64c2b70feecd0a7e",
//   bouloNumber: "+14049007748"
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
