import { Component, OnInit, OnDestroy, ViewEncapsulation } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators, ValidationErrors, ValidatorFn } from "@angular/forms";
import { SecurityService, SignUp } from "src/app/services/security.service";
import { Router } from "@angular/router";
import { OpenCommonService } from "../open-common.service";
import { eRoleType, eUserRole } from "src/app/shared/enums/user.enums";
import { error } from "console";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SignupComponent implements OnInit, OnDestroy {
  showBanner: boolean;
  errorMessage: string = null;
  success: string = "pending";
  signUpForm: UntypedFormGroup;
  selectedRole: eRoleType = null;
  membershipComparison: {
    description: string;
    basic: boolean;
    advanced: boolean;
    buyOne: boolean;
  }[] = [
    {
      description: "First month free",
      basic: true,
      advanced: true,
      buyOne: true,
    },
    {
      description: "6 month membership",
      basic: true,
      advanced: false,
      buyOne: true,
    },
    {
      description: "12 month membership",
      basic: false,
      advanced: true,
      buyOne: false,
    },
    {
      description: "Curated content for your career game plan",
      basic: true,
      advanced: true,
      buyOne: true,
    },
    {
      description: "Exclusive career tips from C-suite executives",
      basic: true,
      advanced: true,
      buyOne: true,
    },
    {
      description: "Online events with industry leaders and career experts",
      basic: true,
      advanced: true,
      buyOne: true,
    },
    {
      description: "Innovative training and online networking events to achieve goals",
      basic: true,
      advanced: true,
      buyOne: true,
    },
    {
      description: "Success tracker to highlight accomplishments",
      basic: true,
      advanced: true,
      buyOne: true,
    },
    {
      description: "Superstar Loyalty Program to reward personal growth",
      basic: true,
      advanced: true,
      buyOne: true,
    },
    {
      description: "Invitations to exclusive Boulo events",
      basic: false,
      advanced: true,
      buyOne: false,
    },
    {
      description: "Gift of 6 month membership with a note acknowledging who sent the gift",
      basic: false,
      advanced: false,
      buyOne: true,
    },
  ];
  placeholderImg: string = 'url("../../../assets/img/login/signup-1.png") no-repeat';

  constructor(
    private securityService: SecurityService,
    private router: Router,
    public common: OpenCommonService
  ) {}

  ngOnInit(): void {
    this.common.showBannerObservable.subscribe((val) => {
      this.showBanner = val;
    });
    document.getElementsByTagName("body")[0].classList.add("background-image");
    const _this = this;

    this.signUpForm = new UntypedFormGroup({
      first_name: new UntypedFormControl(null, [Validators.required]),
      last_name: new UntypedFormControl(null, [Validators.required]),
      company_name: new UntypedFormControl(null, [Validators.required]),
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
      emailConfirm: new UntypedFormControl(null, [
        Validators.required,
        Validators.email,
        this.confirmEmailValidate.bind(this),
      ]),
      password: new UntypedFormControl(null, [Validators.required, Validators.minLength(14)]),
      confirmPassword: new UntypedFormControl(null, [Validators.required]),
      role: new UntypedFormControl(null, [Validators.required]),
    });
  }

  get _role() {
    return this.signUpForm.get("role");
  }
  get _companyName() {
    return this.signUpForm.get("company_name");
  }
  get _firstName() {
    return this.signUpForm.get("first_name");
  }
  get _lastName() {
    return this.signUpForm.get("last_name");
  }
  get _password() {
    return this.signUpForm.get("password");
  }
  get _confirmPassword() {
    return this.signUpForm.get("confirm_password");
  }

  get isUserRole(): boolean {
    return this._role?.value === eUserRole.Candidate || this._role?.value === eUserRole.Both;
  }
  get isEmployerRole(): boolean {
    return this._role?.value === eUserRole.Employer || this._role?.value === eUserRole.Both;
  }

  get canSubmit() {
    switch (this._role.value?.toLowerCase()) {
      case eUserRole.Candidate:
        if (!this._companyName?.value) {
          this._companyName.setValue(" ");
        }
        break;
      case eUserRole.Employer:
        if (this._companyName?.value == " ") {
          this._companyName.setValue(null);
        }
        break;
      case eUserRole.Both:
        if (this._companyName?.value == " ") {
          this._companyName.setValue(null);
        }
        break;
    }

    const disableBtn =
      !this.signUpForm.valid || this.signUpForm.get("confirmPassword").value !== this.signUpForm.get("password").value;
    return !disableBtn;
  }

  setBoxBorder(name: string): string {
    if (this.signUpForm.get(name).touched && !this.signUpForm.get(name).valid) {
      return "error-box";
    }
    return "";
  }

  ngOnDestroy(): void {
    document.getElementsByTagName("body")[0].classList.remove("background-image");
  }

  confirmEmailValidate(emailConfirm) {
    if (!this.signUpForm?.controls) return null;

    const email = this.signUpForm.controls.email.value;
    if (!!email && email.toLowerCase() === emailConfirm.value.toLowerCase()) {
      return null;
    } else {
      return { notSame: true };
    }
  }

  getEmailParts(email: string): string[] {
    // Split the email address by "@"
    const parts = email.split("@");

    // Check if the email address is valid
    if (parts.length !== 2) {
        return [email];
    }

    // Split the username and domain parts by "."
    const usernameParts = parts[0].split(".");

    // Combine username and domain parts
    const result = [...usernameParts];

    return result;
}

  passwordContainsPersonalInfo(): void {
    const password = this.signUpForm.controls.password.value?.toLowerCase();
    const firstName = this.signUpForm.controls.first_name.value?.toLowerCase();
    const lastName = this.signUpForm.controls.last_name.value?.toLowerCase();
    const email = this.signUpForm.controls.email.value?.toLowerCase();
    let lst = this.getEmailParts(email);
    let containsEmail = false;
    if (lst.length > 0) {
      lst.forEach((x) => {
        if (password?.includes(x)) {
          containsEmail = true;
        }
      });
    }
    if (password?.includes(firstName) || password?.includes(lastName) || containsEmail) {
      this.signUpForm.controls.password.setErrors({ containsPersonalInfo: true });
    } else {
      const errors = { ...this.signUpForm.controls.password.errors }; // Make a copy of current errors
      delete errors['containsPersonalInfo']; // Remove containsPersonalInfo error
      let allErrors = Object.keys(errors).length === 0 ? null : errors;
      this.signUpForm.controls.password.setErrors(allErrors); // Update the errors object
    }
  }

  eventOnChangePassword(value): void {
    if (value) {
      if (!/\d/.test(value)) {
        this.signUpForm.controls.password.setErrors({ number: true });
      }
      if (!/[a-z]/g.test(value)) {
        this.signUpForm.controls.password.setErrors({ lowerLetter: true });
      }
      if (!/[A-Z]/g.test(value)) {
        this.signUpForm.controls.password.setErrors({ capitalLetter: true });
      }
      if (!/[!#@$%&]/g.test(value)) {
        this.signUpForm.controls.password.setErrors({ special: true });
      }
      this.passwordContainsPersonalInfo();
    }
  }

  onSubmit(): void {
    if (!this.canSubmit) {
      return;
    }

    this.securityService
      .signUp(
        new SignUp(
          this.signUpForm.controls.email.value,
          this.signUpForm.controls.password.value,
          this.signUpForm.controls.first_name.value,
          this.signUpForm.controls.last_name.value,
          this.signUpForm.controls.company_name.value,
          this.signUpForm.controls.role.value
        )
      )
      .subscribe(
        (res) => {
          this.router.navigate(["signup/confirmationmessage"], {
            queryParams: { email: this.signUpForm.controls.email.value },
          });
        },
        (errorMessage) => {
          this.errorMessage = errorMessage;
        }
      );
  }
}
