import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-search-input",
  templateUrl: "./search-input.component.html",
  styleUrls: ["./search-input.component.scss"],
})
export class SearchInputComponent {
  @Input() isReadOnly: boolean;
  @Input() placeholder: string = "";
  @Output() inputSearch = new EventEmitter<string>();
  inputValue: string = "";

  handleChange(searchInput: string): void {
    this.inputValue = searchInput;
    this.inputSearch.emit(searchInput);
  }
}
