import { HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";

export function HandleHttpErrors(responseError: HttpErrorResponse): Observable<never> {
  let errorMessage: string = "Unknown Error";

  if (responseError && responseError.status) {
    switch (responseError.status) {
      case 401:
        errorMessage = getErrorMessage(responseError);
        break;

      case 403:
        // TODO:
        break;

      case 404:
        // TODO:
        break;

      case 422:
        errorMessage = getErrorMessage(responseError);
        break;

      case 500:
        // TODO:
        break;
    }
  }

  // TODO:
  return throwError(errorMessage);
}

export function getErrorMessage(responseError: HttpErrorResponse) {
  let errorMessage: string = "Unknown Error";
  if (responseError.status == 500) {
    return errorMessage;
  }

  if (responseError && responseError.error && responseError.error["errors"]) {
    if (Array.isArray(responseError.error["errors"])) {
      return responseError.error["errors"][0];
    } else if (typeof responseError.error["errors"] == "object") {
      for (let p in responseError.error["errors"]) {
        errorMessage = responseError.error["errors"][p][0];
        break;
      }
    }
  } else if (responseError.error && responseError.error["error"]) {
    if (Array.isArray(responseError.error["error"])) {
      return responseError.error["error"][0];
    }
  }

  return errorMessage;
}
