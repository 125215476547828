import { Component, OnInit, Input, TemplateRef, Output, EventEmitter } from "@angular/core";
import { OnboardingRootService } from "src/app/user/onboarding/onboarding-root/onboarding-root.service";
import { ItemsList } from "@ng-select/ng-select/lib/items-list";

export interface SimpleItemCheckItem {
  index: number;
  selected: boolean;
  itemRef?: any;
}

export interface CustomizedStyleUpdate {
  name: string;
  index: number;
}

@Component({
  selector: "app-simple-item-check",
  templateUrl: "./simple-item-check.component.html",
  styleUrls: ["./simple-item-check.component.scss"],
})
export class SimpleItemCheckComponent implements OnInit {
  @Input() contentTemplate: TemplateRef<any>;
  @Input() index: number;
  @Input() selected: boolean;
  @Input() customizedStyle: string = null;
  @Input() allowDefaultClick: boolean = false;
  @Input() itemRef: any;
  @Output() elementClicked: EventEmitter<SimpleItemCheckItem> = new EventEmitter<SimpleItemCheckItem>();
  @Input() iconType: "check" | "radio" = "radio";
  @Input() disabled?: boolean;
  constructor(private onboardingService: OnboardingRootService) {}

  ngOnInit(): void {
    this.onboardingService.simpleItemCheckShakeIt.subscribe((item: CustomizedStyleUpdate) => {
      if (this.customizedStyle == item.name && this.index === item.index) {
        if (this.selected) {
          this.selected = !this.selected;
          this.elementClicked.emit({
            index: this.index,
            selected: this.selected,
          });
        }
      }
    });
  }

  onClicked() {
    if (this.disabled) {
      return;
    }

    if (this.customizedStyle && !this.allowDefaultClick) {
      if (this.selected) return;
    }

    this.selected = !this.selected;
    this.elementClicked.emit({
      index: this.index,
      selected: this.selected,
      itemRef: this.itemRef,
    });
  }
}
