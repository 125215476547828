import { Injectable, HostListener, OnDestroy, OnInit, NgZone } from "@angular/core";
import { MediaMatcher } from "@angular/cdk/layout";
import { BehaviorSubject, Observable } from "rxjs";
import { GroupChannel } from "@sendbird/chat/groupChannel";
import { LoggerService as Logger } from "src/app/shared/services/logger.service";
import { iMessageReciever, iMessageSender } from "../employer/message-box/message-box.component";
import { environment } from "src/environments/environment";
import { iApplicantListModel } from "src/app/shared/models/applicant-candidate.model";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { iSkillCategory } from "../onboarding/onboarding-root/onboarding.models";
import startCase from "lodash.startcase";
import { saveAs } from "file-saver";
import * as JSZip from "jszip";
import * as zipcodes from "zipcodes";
import { HttpClient } from "@angular/common/http";

const proxyApi: string = environment.proxyApi;
import { iIndustryGroup, iIndustrySelected } from "src/app/shared/components/industry/industry.component";
import { eIcons, eLabels, eValues } from "../employer/dashboard/dialog-employer-job-edit/dialog-employer-job-edit.component";
import { eJobLogoStatus, iEmployerJobModel, iJobCardModel, JobModel } from "src/app/shared/models/job.model";
import { iChipInfo, iCompanyLogo } from "src/app/shared/models/shared.models";
import { eSkillLevel } from "src/app/shared/enums/shared.enums";
import { eCompensationTypes } from "src/app/shared/enums/employer.enums";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class UserService implements OnInit, OnDestroy {
  //#region Display
  screenHeight: number;
  screenWidth: number;
  mobileWidthThresholdPx = 700;
  isMobileScreen: boolean = false;
  matcher!: MediaQueryList;
  //#endregion Display

  private isMobileViewSubject = new BehaviorSubject<boolean>(false);
  public isMobileView$: Observable<boolean> = this.isMobileViewSubject.asObservable();
  updateMobileViewStatus(isMobile: boolean): void {
    this.isMobileViewSubject.next(isMobile);
  }

  skillsDataSet: iSkillCategory[] = [];
  allIndustries: iIndustryGroup[] = [];

  // cachedCompanyLogos: Map<string, any> = new Map<string, any>(); //Note: this doesn't actually cache the data, but a quick way to prevent extra data pulling & quick loads

  constructor(
    mediaMatcher: MediaMatcher,
    private ngZone: NgZone,
    private http: HttpClient
  ) {
    this.matcher = mediaMatcher.matchMedia(`(max-width: ${this.mobileWidthThresholdPx}px)`);
    // this.getScreenSize();
    const isMobile = window.innerWidth < this.mobileWidthThresholdPx; // determine if mobile view based on event or MediaQueryList
    this.updateMobileViewStatus(isMobile);
    this.skillsDataSet = this.setSkillData();
    this.allIndustries = this.setIndustryList();
  }

  ngOnInit(): void {
    // Logger.log('user.service init')
    this.matcher.addEventListener("change", this.onResize);
  }

  ngOnDestroy(): void {
    // Logger.log('user.service destroy')
    this.matcher.removeEventListener("change", this.onResize);
  }

  public async initialize() {}

  //#region Location
  //https://www.npmjs.com/package/zipcodes/v/8.0.0?activeTab=readme
  private returnNotFoundLocation(): iLocation {
    return { isValid: false };
  }
  getLocationByZipCode(zip: number | string): iLocation {
    const result = zipcodes.lookup(zip);

    if (result) {
      result.isValid = true;
      return result;
    } else {
      return this.returnNotFoundLocation();
    }
  }

  states = {
    Arizona: "AZ",
    Alabama: "AL",
    Alaska: "AK",
    Arkansas: "AR",
    California: "CA",
    Colorado: "CO",
    Connecticut: "CT",
    Delaware: "DE",
    Florida: "FL",
    Georgia: "GA",
    Hawaii: "HI",
    Idaho: "ID",
    Illinois: "IL",
    Indiana: "IN",
    Iowa: "IA",
    Kansas: "KS",
    Kentucky: "KY",
    Louisiana: "LA",
    Maine: "ME",
    Maryland: "MD",
    Massachusetts: "MA",
    Michigan: "MI",
    Minnesota: "MN",
    Mississippi: "MS",
    Missouri: "MO",
    Montana: "MT",
    Nebraska: "NE",
    Nevada: "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    Ohio: "OH",
    Oklahoma: "OK",
    Oregon: "OR",
    Pennsylvania: "PA",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    Tennessee: "TN",
    Texas: "TX",
    Utah: "UT",
    Vermont: "VT",
    Virginia: "VA",
    Washington: "WA",
    "West Virginia": "WV",
    Wisconsin: "WI",
    Wyoming: "WY",
  };

  abbrState(input: string, format = "abbreviation"): string {
    // Invert the states object to map abbreviations back to full names
    const abbrevs = Object.entries(this.states).reduce((acc, [state, abbr]) => {
      acc[abbr] = state;
      return acc;
    }, {});

    if (format === "abbreviation") {
      const pattern = new RegExp(`\\b(${Object.keys(this.states).join("|")})\\b`, "gi");
      return input.replace(pattern, (match) => this.states[match]);
    } else if (format === "search-query-abbreviation") {
      const pattern = new RegExp(
        `\\b(${Object.keys(this.states)
          .map((key) => key.toLowerCase())
          .join("|")})\\b`,
        "gi"
      );
      return input.replace(pattern, (match) => this.states[match.toLowerCase()]);
    } else if (format === "full") {
      const pattern = new RegExp(`\\b(${Object.keys(abbrevs).join("|")})\\b`, "g");
      return input.replace(pattern, (match) => abbrevs[match.toUpperCase()]);
    } else {
      return input; // If the format is not recognized, return the input unchanged.
    }
  }

  getAllStateNames(): string[] {
    return Object.keys(this.states); //full names
  }

  getAllStateAbbreviations(): string[] {
    return Object.values(this.states);
  }
  //#endregion Location

  //#region setupJobDataAfterImport/
  cachedCompanyLogos: Map<string, any> = new Map<string, any>(); //Note: this doesn't actually cache the data, but a quick way to prevent extra data pulling & quick loads
  regex = /[A-Za-z]/g;

  public setupJobDataAfterImport(
    jobsList: iJobCardModel[],
    companyInitial: string = "",
    isApplied: boolean = false,
    isSaved: boolean = false
  ): iJobCardModel[] {
    let newList: iJobCardModel[] = [];
    jobsList.forEach((job) => {
      let initial = companyInitial;
      if (initial.length < 1) {
        job.clientCorporation?.name?.length > 0 ? job.clientCorporation.name[0] : "B";
      } //default to 'B' for Boulo

      job.isBouloCreatedJob = job.appCreatedJob || job.hoursPerWeek === 0; //TODO need a better check. Publically posted jobs seem to always have 40 hours

      this.setupJobPostingChips(job);

      job.hasDetailsSet = true;
      (job.logo = { logoStatus: eJobLogoStatus.Unchecked, companyInitial: initial }), // await this.lookupCompanyLogo(job.clientCorporation.id, initial);
        (job.applied = isApplied);
      job.saved = isSaved;

      newList.push(job);
    });
    return newList;
  }

  public setupJobPostingData(selectedJob: iEmployerJobModel): iJobCardModel {
    const initial = selectedJob.clientCorporation?.name?.length > 0 ? selectedJob.clientCorporation.name[0] : "B"; //default to 'B' for Boulo
    let jobCard: iJobCardModel = {
      ...selectedJob,
      isBouloCreatedJob: selectedJob.appCreatedJob || selectedJob.hoursPerWeek === 0, //TODO need a better check. Publically posted jobs seem to always have 40 hours
      jobDescriptionText: "",
      highlightsChips: [],
      requirementsChips: [],
      footerChips: [],
      skillChips: [],
      keyTermsChips: [],
      hasDetailsSet: true,
      logo: {
        logoStatus: eJobLogoStatus.Unchecked,
        companyInitial: selectedJob?.clientCorporation?.name?.length > 0 ? selectedJob.clientCorporation.name[0] : "B",
      }, //await this.lookupCompanyLogo(selectedJob.clientCorporation.id, initial),
      daysSincePosted: this.daysSince(selectedJob.datePosted) ?? 0,
      hasDescriptionText: false,
      applied: false,
      saved: false,
      extractedPublicDetails: null,
      isFollowingCompany: false,
      // address: { city: "", state: "", zip: selectedJob.locationZip },
    };
    if (selectedJob.locationZip?.length > 0) {
      const loc = this.getLocationByZipCode(selectedJob.locationZip);
      if (loc.isValid) {
        jobCard.address = { city: loc.city, state: loc.state, zip: selectedJob.locationZip };
      }
    }
    this.setupJobPostingChips(jobCard);

    return jobCard;
  }

  public setupJobPostingChips(job: iJobCardModel): void {
    job.jobDescriptionText = this.getJobDescriptionText(job);
    job.highlightsChips = this.getHighlightsChipsForJob(job);
    job.requirementsChips = this.getRequirementChipsForJob(job);
    job.footerChips = [];
    job.skillChips = this.getSkillChipsForJob(job);
    job.keyTermsChips = this.getKeyTermsChipsForJob(job);
    job.daysSincePosted = this.daysSince(job.datePosted) ?? 0;
    // return job;
  }

  async getCompanyLogo(companyId: string, allowCachedResults: boolean = true): Promise<any> {
    if (companyId === null) {
      return;
    }

    const cachedLogo = this.cachedCompanyLogos.get(companyId);
    if (cachedLogo && allowCachedResults) {
      // Logger.debug(`returned cached logo for company id ${companyId}. Result: ${cachedLogo}`);
      return cachedLogo;
    }
    try {
        await this.http
        .get(proxyApi + `users/${companyId}/image?isEmployer=true`, { responseType: "blob" })
        .pipe(
          tap((response) => {
            this.blobToBase64(response).then((data) => {
              if (data == "data:application/json;base64,IiI=") {
                this.cachedCompanyLogos.set(companyId, "");
                Logger.debug("Empty img to cachedCompanyLogos", companyId);
                return "";
              } else {
                this.cachedCompanyLogos.set(companyId, data);
                Logger.debug("Added img to cachedCompanyLogos", companyId);
                return data;
              }
              ``;
            });
          })
        )
        .toPromise();
    }  catch(err) {
      Logger.error("getCompanyLogo", err);
      return "";
    }
   
  }
  public async lookupCompanyLogo(companyId: string, companyInitial: string): Promise<iCompanyLogo> {
    const isCached = this.hasCachedCompanyLogo(companyId);
    let logo: iCompanyLogo = { companyInitial: companyInitial, logoStatus: eJobLogoStatus.Unchecked };

    if (isCached) {
      logo.companyImg = this.getCachedCompanyLogo(companyId);
      logo.logoStatus = eJobLogoStatus.HasLogo;
    } else {
      const getLogo = await this.getCompanyLogo(companyId);
      if (getLogo) {
        logo.companyImg = getLogo;
        logo.logoStatus = eJobLogoStatus.HasLogo;
      } else {
        logo.logoStatus = eJobLogoStatus.NoLogo;
      }
    }

    return logo;
  }
  hasCachedCompanyLogo(companyId: string): boolean {
    return this.cachedCompanyLogos.has(companyId);
  }
  getCachedCompanyLogo(companyId: string) {
    const cachedLogo = this.cachedCompanyLogos.get(companyId);
    if (cachedLogo) {
      // Logger.debug(`returned cached logo for company id ${companyId}. Result: ${cachedLogo}`);
      return cachedLogo;
    }

    return null;
  }
  private daysSince(date: Date): number | null {
    if (!date) {
      return null;
    }
    let dateFormat = new Date(date);
    let differenceInTime = Date.now() - dateFormat.getTime(); // Ensure we're using the epoch time of the date

    let differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); // Convert the time difference from milliseconds to days

    return differenceInDays;
  }

  private getHighlightsChipsForJob(job: iJobCardModel): iChipInfo[] {
    //Arrangment Type
    var chips: iChipInfo[] = [];
    const arrangment = this._arrangementType(job);
    if (arrangment && arrangment?.length > 0) {
      var icon = "";

      if (arrangment === eLabels.OnSite) {
        icon = eIcons.OnSite;
      } else if (arrangment === eLabels.Hybrid) {
        icon = eIcons.Hybrid;
      } else if (arrangment === eLabels.Remote) {
        icon = eIcons.Remote;
      }

      chips.push({ label: arrangment, value: arrangment, hasIcon: true, iconName: icon, isRequired: false });
    }

    //Location
    if (this._hasLocation(job)) {
      const location = this._getLocation(job);
      if (location?.length > 0) {
        chips.push({ label: location, value: location, hasIcon: true, iconName: eIcons.Location, isRequired: false });
      }
    }

    //Employment Type (e.g. PartTime, FullTime, Contract)
    if (job?.employmentType?.length > 0) {
      let employmentType = job?.employmentType?.match(this.regex)?.join("")?.toLowerCase();
      if (employmentType === eValues.PartTime) {
        chips.push({
          label: eLabels.PartTime,
          value: eValues.PartTime,
          hasIcon: true,
          iconName: eIcons.EmploymentType,
          isRequired: false,
        });
      } else if (employmentType === eValues.FullTime) {
        chips.push({
          label: eLabels.FullTime,
          value: eValues.FullTime,
          hasIcon: true,
          iconName: eIcons.EmploymentType,
          isRequired: false,
        });
      } else if (employmentType === eValues.Contract) {
        chips.push({
          label: eLabels.Contract,
          value: eValues.Contract,
          hasIcon: true,
          iconName: eIcons.EmploymentType,
          isRequired: false,
        });
      }
    }

    //Compensation
    const jobPay = this.getPay(job);
    if (jobPay.length > 0 && job.compensationType && job.compensationType != eCompensationTypes.Unset) {
      var chipTitle = "";
      switch (job.compensationType) {
        case eCompensationTypes.Hourly:
          chipTitle = `${jobPay} /hr`;
          break;
        case eCompensationTypes.Project:
          chipTitle = `${jobPay} /project`;
          break;
        case eCompensationTypes.Salary:
          chipTitle = `${jobPay} /year`;
          break;
      }
      chips.push({ label: chipTitle, value: chipTitle, hasIcon: true, iconName: eIcons.Compensation, isRequired: false });
    }

    return chips;
  }

  private getRequirementChipsForJob(job: iJobCardModel): iChipInfo[] {
    var chips: iChipInfo[] = [];
    //Education
    if (job.isEducationRequired === 1) {
      const chipTitle = `${job.educationDegree}`;
      if (chipTitle?.length > 0) {
        chips.push({
          label: chipTitle,
          value: chipTitle,
          hasIcon: true,
          iconName: eIcons.Education,
          isRequired: job.isEducationRequired === 1,
        });
      }
    }

    //Years Experience
    if (job.isExperienceRequired === 1) {
      const chipTitle = `${job.yearsRequired} Years of Experience`;
      if (chipTitle?.length > 0) {
        chips.push({
          label: chipTitle,
          value: chipTitle,
          hasIcon: true,
          iconName: eIcons.YearsExperience,
          isRequired: job.isExperienceRequired === 1,
        });
      }
    }

    //CertsList
    var certChips: iChipInfo[] = [];
    if (job?.certsList !== null && job.certsList.items?.length > 0) {
      job.certsList.items.forEach((cert) => {
        certChips.push({
          label: cert.txt,
          value: cert.txt,
          hasIcon: true,
          iconName: eIcons.Certifications,
          isRequired: cert.isRequired,
        });
      });
    } else if (job.certifications?.length > 0) {
      //Certifications (jobs created before CertsList was implemented)
      const certs = job.certifications.split(",");
      certs.forEach((cert) => {
        certChips.push({ label: cert, value: cert, hasIcon: true, iconName: eIcons.Certifications, isRequired: false });
      });
    }
    if (certChips.length > 0) {
      chips = chips.concat(certChips.filter((x) => x.isRequired === true));
      chips = chips.concat(certChips.filter((x) => x.isRequired !== true));
    }

    return chips;
  }

  private getSkillChipsForJob(job: iJobCardModel): iChipInfo[] {
    var chips: iChipInfo[] = [];

    //Custom Skills
    if (job?.combinedSkills?.customSkills?.length > 0) {
      job.combinedSkills.customSkills.forEach((skill) => {
        let displayIcon = true;
        let icon = eIcons.Beginner;
        if (skill.level === eSkillLevel.Advanced) {
          icon = eIcons.Advanced;
        } else if (skill.level === eSkillLevel.Intermediate) {
          icon = eIcons.Intermediate;
        } else if (skill.level !== eSkillLevel.Beginner) {
          displayIcon = false;
        }
        if (skill && skill.display?.length > 0) {
          chips.push({
            label: skill.display,
            value: skill.level,
            hasIcon: displayIcon,
            iconName: icon,
            isRequired: skill.isRequired,
          });
        }
      });
    }

    //categorySpecificSkills
    if (job?.combinedSkills?.categorySpecificSkills) {
      for (const skillKey in job.combinedSkills.categorySpecificSkills) {
        if (job.combinedSkills.categorySpecificSkills.hasOwnProperty(skillKey)) {
          // Access the key and corresponding value
          const levelRequired = job.combinedSkills.categorySpecificSkills[skillKey];
          let displayIcon = true;
          let icon = eIcons.Beginner;
          if (levelRequired.includes("dvance")) {
            icon = eIcons.Advanced;
          } else if (levelRequired.includes("ntermediate")) {
            icon = eIcons.Intermediate;
          } else if (!levelRequired.includes("eginner")) {
            displayIcon = false;
          }

          if (skillKey?.length > 0 && levelRequired?.length > 0) {
            //Lookup the displayName of the skillKey that is set to the dataName
            var displayName = this.getDisplayNameByDataName(skillKey);
            if (displayName === undefined) {
              displayName = skillKey;
            }
            chips.push({
              label: displayName,
              value: levelRequired,
              hasIcon: displayIcon,
              iconName: icon,
              isRequired: false,
            });
          }
        }
      }
    }

    // if (chips.length > 0) {
    //   chips.sort((a, b) => {
    //     // Convert isRequired to a boolean (treating null as false)
    //     let aIsRequired = !!a.isRequired;
    //     let bIsRequired = !!b.isRequired;

    //     if (aIsRequired === bIsRequired) return 0;  // same status, no need to change their order

    //     if (aIsRequired && !bIsRequired) return -1; // a should come before b

    //     if (!aIsRequired && bIsRequired) return 1; //b should come before a
    //   });
    // }

    var sortedSkills: iChipInfo[] = [];
    if (chips.length > 0) {
      sortedSkills = sortedSkills.concat(chips.filter((x) => x.isRequired === true && x.iconName === eIcons.Advanced));
      sortedSkills = sortedSkills.concat(chips.filter((x) => x.isRequired === true && x.iconName === eIcons.Intermediate));
      sortedSkills = sortedSkills.concat(chips.filter((x) => x.isRequired === true && x.iconName === eIcons.Beginner));
      sortedSkills = sortedSkills.concat(
        chips.filter(
          (x) =>
            x.isRequired === true &&
            x.iconName !== eIcons.Advanced &&
            x.iconName !== eIcons.Intermediate &&
            x.iconName !== eIcons.Beginner
        )
      );
      sortedSkills = sortedSkills.concat(chips.filter((x) => x.isRequired !== true && x.iconName === eIcons.Advanced));
      sortedSkills = sortedSkills.concat(chips.filter((x) => x.isRequired !== true && x.iconName === eIcons.Intermediate));
      sortedSkills = sortedSkills.concat(chips.filter((x) => x.isRequired !== true && x.iconName === eIcons.Beginner));
      sortedSkills = sortedSkills.concat(
        chips.filter(
          (x) =>
            x.isRequired !== true &&
            x.iconName !== eIcons.Advanced &&
            x.iconName !== eIcons.Intermediate &&
            x.iconName !== eIcons.Beginner
        )
      );
    }
    return sortedSkills;
  }

  private getKeyTermsChipsForJob(job: iJobCardModel): iChipInfo[] {
    var chips: iChipInfo[] = [];

    //skillCategories
    if (job?.combinedSkills?.skillCategories?.length > 0) {
      job.combinedSkills.skillCategories.forEach((skill) => {
        chips.push({ label: skill, value: skill, hasIcon: false, iconName: "", isRequired: false });
      });
    }

    //TODO: Add Industry

    return chips;
  }

  getPay(job: iJobCardModel): string {
    //Check ExactRate First
    if (job.exactRate?.length > 0) {
      // const numericValue: number = +job.exactRate;
      const numericValue: number = this.extractNumberFromString(job.exactRate);
      if (!isNaN(numericValue) && numericValue > 0) {
        return this.formatCompensationForDisplay(numericValue);
      }
    }

    //Otherwise Show Compensation Range
    var price: string = "";
    var compLow: number = job.compensationLow ?? 0;
    var compHigh: number = job.compensationHigh ?? 0;

    price = compLow > 0 ? `${this.formatCompensationForDisplay(compLow)}` : "";
    price += compLow > 0 && compHigh > 0 ? `-` : "";
    price += compHigh > 0 ? `${this.formatCompensationForDisplay(compHigh)}` : "";

    return price;
  }

  formatCompensationForDisplay(number: number): string {
    return number.toLocaleString("en-US", {
      minimumFractionDigits: 0, // Do not show decimal part if the number is a whole number
      maximumFractionDigits: 2, // Allow up to two digits in the decimal part
    });
  }

  extractNumberFromString(input: string): number {
    // This regex matches all digits and decimal points
    const regex = /\d+\.?\d*/g;

    // Extract all matches into an array
    const matches = input.match(regex);

    // Join all extracted numbers (if you expect multiple numbers and want them concatenated)
    // If you expect a single number spread across the string, just join without separator
    const joinedNumbers = matches ? matches.join("") : "";

    // Convert the joined string to a number
    const number = parseFloat(joinedNumbers);

    // Handle the case where the result is NaN (e.g., no numbers found in input)
    if (isNaN(number)) {
      return 0; // or any other fallback value you prefer
    }

    return number;
  }

  private getJobDescriptionText(job: iJobCardModel): string {
    //Check for Description
    if (job.description || this._hasDescription(job.description)) {
      job.hasDescriptionText = true;
      return job.description;
    }
    //Check for Public Description
    if (job.publicDescription || this._hasDescription(job.publicDescription)) {
      job.hasDescriptionText = true;
      return job.publicDescription;
    }

    //If no description or public description, return a default message
    Logger.debug("getJobDescriptionText", {
      publicDescription: job.publicDescription,
      description: job.description,
      jobTitle: job.title,
      jobId: job.id,
    });
    job.hasDescriptionText = false;
    return "No description details to display.";
  }
  private _hasDescription(description: string): boolean {
    return description && description !== null && description?.length > 0;
  }
  private _hasLocation(job: iJobCardModel): boolean {
    return (
      (job?.address?.zip?.length > 0 && job?.address?.zip !== eValues.EmptyZip) ||
      (job?.address?.zip?.length > 0 && job?.address?.zip !== eValues.EmptyZip) ||
      (job?.address?.city?.length > 0 && job?.address?.state?.length > 0)
    );
  }
  private _getLocation(job: iJobCardModel): string {
    if (job?.address?.city?.length > 0 && job?.address?.state?.length > 0) {
      let state = job.address.state;
      if (state.length > 2) {
        state = this.abbrState(state);
      }
      return `${job.address.city}, ${state}`;
    }
    if (job?.address?.zip?.length > 0) {
      const loc = this.getLocationByZipCode(job.address.zip);
      if (loc && loc.city?.length > 0 && loc.state?.length > 0) {
        return `${loc.city}, ${loc.state}`;
      }
    }

    return "";
  }

  private _arrangementType(job: iJobCardModel): string {
    let arrangementType = job?.arrangementType?.match(this.regex)?.join("")?.toLowerCase();

    if (eValues.Remote === arrangementType) {
      return eLabels.Remote;
    }

    if (eValues.OnSite === arrangementType) {
      return eLabels.OnSite;
    }

    if (eValues.Hybrid === arrangementType) {
      return eLabels.Hybrid;
    }

    if (job?.address?.zip && job?.address?.zip === eValues.EmptyZip) {
      return eLabels.Remote;
    }
    if (this._isOnSite(job)) {
      return eLabels.OnSite;
    }
    if (this._isHybrid(job)) {
      return eLabels.Hybrid;
    }
    if (this._isRemote(job)) {
      return eLabels.Remote;
    }

    return "";
  }
  private _isHybrid(job: iJobCardModel): boolean {
    var isHybrid = false;
    if (job?.address?.zip && job?.address?.zip === eValues.EmptyZip) {
      return false;
    }

    if (job?.flexibility && job?.flexibility?.length > 0) {
      job.flexibility.forEach((ct1) => {
        if (ct1?.trim()?.toLowerCase().includes(eValues.Hybrid)) {
          return true;
        }
      });
    }

    const description = job?.publicDescription?.trim();
    if (description?.length > 0) {
      if (description?.toLowerCase().includes(eValues.Hybrid)) {
        return true;
      }
    }

    return isHybrid;
  }
  private _isRemote(job: iJobCardModel): boolean {
    var isRemote = false;
    if (job?.address?.zip && job?.address?.zip === eValues.EmptyZip) {
      return true;
    }

    if (job?.flexibility && job?.flexibility?.length > 0) {
      job.flexibility.forEach((ct1) => {
        if (ct1?.trim()?.toLowerCase().includes(eValues.Remote)) {
          return true;
        }
      });
    }

    const description = job?.publicDescription?.trim();
    if (description?.length > 0) {
      if (description?.toLowerCase().includes(eValues.Remote)) {
        return true;
      }
    }

    return isRemote;
  }
  private _isOnSite(job: iJobCardModel): boolean {
    var onsite = false;
    if (job?.address?.zip && job?.address?.zip === eValues.EmptyZip) {
      return false;
    }

    if (job?.flexibility && job?.flexibility?.length > 0) {
      job.flexibility.forEach((ct1) => {
        if (ct1?.trim()?.toLowerCase().includes(eValues.Hybrid)) {
          return true;
        }
      });
    }

    const description = job?.publicDescription?.trim();
    if (description?.length > 0) {
      if (
        description?.toLowerCase().includes("in-office") ||
        description?.toLowerCase().includes("inoffice") ||
        description?.toLowerCase().includes("on-site") ||
        description?.toLowerCase().includes("onsite")
      ) {
        return true;
      }
    }

    return onsite;
  }

  //#endregion setupJobDataAfterImport

  //#region Screen detection (not implemented here yet, but would like to)
  @HostListener("window:resize", ["$event"])
  onResize(event: { matches: any }): void {
    // this.ngZone.run(() => {
    const isMobile = window.innerWidth < this.mobileWidthThresholdPx; // determine if mobile view based on event or MediaQueryList
    Logger.info("user-root.service.ts onResize", {
      isMobile,
      innerWidth: window.innerWidth,
      mobileThreshold: this.mobileWidthThresholdPx,
    });
    this.updateMobileViewStatus(isMobile);
    // });
  }

  CheckIsMobileScreen(): boolean {
    return this.screenWidth < this.mobileWidthThresholdPx;
  }
  //#endregion Screen detection (not implemented here yet, but would like to)

  getCtrlName(formGrp: FormGroup, ctrl: AbstractControl | FormControl): string {
    const formGroup: FormGroup = formGrp;
    return Object.keys(formGroup.controls).find((name) => ctrl === formGroup.get(name)) || "";
  }

  //formatted to MM/dd/yyyy
  ConvertEpochToDate(epochTimeString: string): string {
    const epochSeconds = parseInt(epochTimeString) / 1000; // Convert to seconds
    if (!epochSeconds) {
      return null;
    }

    var currentDate = new Date(0); // The 0 there is the key, which sets the date to the epoch
    currentDate.setUTCSeconds(epochSeconds);

    const formattedDate = currentDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    return formattedDate;
  }

  //#region Industries
  setIndustryList(): iIndustryGroup[] {
    return [
      {
        displayName: "Aerospace & Defense",
        dataName: "aerospace&defense",
        industries: [
          {
            displayName: "Aerospace",
            dataName: "aerospace",
          },
          {
            displayName: "Defense",
            dataName: "defense",
          },
        ],
      },
      {
        displayName: "Automotive", //"Automobiles",
        dataName: "automotive", //"automobiles",
        industries: [
          {
            displayName: "Automobiles",
            dataName: "automobiles",
          },
          {
            displayName: "Auto Parts",
            dataName: "autoparts",
          },
          {
            displayName: "Tires & Rubber",
            dataName: "tires&rubber",
          },
        ],
      },
      {
        displayName: "Banks",
        dataName: "banks",
        industries: [
          {
            displayName: "Banks",
            dataName: "banks",
          },
        ],
      },
      {
        displayName: "Beverages",
        dataName: "beverages",
        industries: [
          {
            displayName: "Brewers",
            dataName: "brewers",
          },
          {
            displayName: "Distillers & Vintners",
            dataName: "distillers&vintners",
          },
          {
            displayName: "Soft Drinks",
            dataName: "softdrinks",
          },
        ],
      },
      {
        displayName: "Chemicals",
        dataName: "chemicals",
        industries: [
          {
            displayName: "Commodity",
            dataName: "commodity",
          },
          {
            displayName: "Advanced Materials",
            dataName: "advancedmaterials",
          },
          {
            displayName: "Speciality",
            dataName: "speciality",
          },
        ],
      },
      {
        displayName: "Construction & Building", // Mats.",
        dataName: "construction&building",
        industries: [
          {
            displayName: "Builders Merchants",
            dataName: "buildersmerchants",
          },
          {
            displayName: "Building & Construction Materials",
            dataName: "building&constructionmaterials",
          },
          {
            displayName: "House Building",
            dataName: "housebuilding",
          },
          {
            displayName: "Other Construction",
            dataName: "otherconstruction",
          },
        ],
      },
      {
        displayName: "Distributors",
        dataName: "distributors",
        industries: [
          {
            displayName: "Industrial Components & Equipment", //"Distributors of Industrial Components & Equipment",
            dataName: "industrialcomponents&equipment",
          },
          {
            displayName: "Vehicle", //"Vehicle Distribution",
            dataName: "vehicles",
          },
          {
            displayName: "Other",
            dataName: "other",
          },
        ],
      },
      {
        displayName: "General Retailers",
        dataName: "generalretailers",
        industries: [
          {
            displayName: "Discount & Super Stores and Warehouses",
            dataName: "discount&superstoresandwarehouses",
          },
          {
            displayName: "Hardlines",
            dataName: "hardlines",
          },
          {
            displayName: "Multi Department",
            dataName: "multidepartment",
          },
          {
            displayName: "Soft Goods",
            dataName: "softgoods",
          },
        ],
      },
      {
        displayName: "Diversified Industrials",
        dataName: "Diversifiedindustrials",
        industries: [
          {
            displayName: "General", //"Diversified Industrials",
            dataName: "general", //"diversifiedindustrials",
          },
        ],
      },
      {
        displayName: "Electronic & Electrical Eqptmt",
        dataName: "electronic&electricaleqptmt",
        industries: [
          {
            displayName: "Electrical Equipment",
            dataName: "electricalequipment",
          },
          {
            displayName: "Electronic Equipment",
            dataName: "electronicequipment",
          },
        ],
      },
      {
        displayName: "Engineering & Machinery",
        dataName: "engineering&machinery",
        industries: [
          {
            displayName: "Commercial Vehicles & Trucks",
            dataName: "commercialvehicles&trucks",
          },
          {
            displayName: "Contractors",
            dataName: "contractors",
          },
          {
            displayName: "Engineering Fabricators",
            dataName: "engineeringfabricators",
          },
          {
            displayName: "General",
            dataName: "general",
          },
        ],
      },
      {
        displayName: "Food & Drug Retailers",
        dataName: "food&drugretailers",
        industries: [
          {
            displayName: "Food & Drug Retailers",
            dataName: "food&drugretailers",
          },
        ],
      },
      {
        displayName: "Telecommunications Services",
        dataName: "telecommunicationsservices",
        industries: [
          {
            displayName: "Fixed-Line Telecommunication Services",
            dataName: "fixed-linetelecommunicationservices",
          },
          {
            displayName: "Wireless Telecommunication Services",
            dataName: "wirelesstelecommunicationservices",
          },
        ],
      },
      {
        displayName: "Food Producers & Processors",
        dataName: "foodproducers&processors",
        industries: [
          {
            displayName: "Farming & Fishing",
            dataName: "farming&fishing",
          },
          {
            displayName: "Food Processors",
            dataName: "foodprocessors",
          },
        ],
      },
      {
        displayName: "Forestry & Paper",
        dataName: "forestry&paper",
        industries: [
          {
            displayName: "Forestry",
            dataName: "forestry",
          },
          {
            displayName: "Paper",
            dataName: "paper",
          },
        ],
      },
      {
        displayName: "Health",
        dataName: "health",
        industries: [
          {
            displayName: "Health Maintenance Organisations",
            dataName: "healthmaintenanceorganisations",
          },
          {
            displayName: "Hospital Management & Long Term Care",
            dataName: "hospitalmanagement&longtermcare",
          },
          {
            displayName: "Medical Equipment & Supplies",
            dataName: "medicalequipment&supplies",
          },
          {
            displayName: "Other Health Care",
            dataName: "otherhealthcare",
          },
        ],
      },
      {
        displayName: "Household Goods & Textiles",
        dataName: "householdgoods&textiles",
        industries: [
          {
            displayName: "Clothing & Footwear",
            dataName: "clothing&footwear",
          },
          {
            displayName: "Furnishings & Floor Coverings",
            dataName: "furnishings&floorcoverings",
          },
          {
            displayName: "Household Appliances & Housewares",
            dataName: "householdappliances&housewares",
          },
          {
            displayName: "Leisure Equipment",
            dataName: "leisureequipment",
          },
          {
            displayName: "Other Textiles & Leather Goods",
            dataName: "othertextiles&leathergoods",
          },
        ],
      },
      {
        displayName: "Information Technology",
        dataName: "informationtechnology",
        industries: [
          {
            displayName: "Computer Hardware",
            dataName: "computerhardware",
          },
          {
            displayName: "Semiconductors",
            dataName: "semiconductors",
          },
          {
            displayName: "Telecommunications Equipment",
            dataName: "telecommunicationsequipment",
          },
          {
            displayName: "Computer Services",
            dataName: "computerservices",
          },
          {
            displayName: "Internet",
            dataName: "internet",
          },
          {
            displayName: "Software",
            dataName: "software",
          },
        ],
      },
      {
        displayName: "Insurance",
        dataName: "insurance",
        industries: [
          {
            displayName: "Insurance Brokers",
            dataName: "insurancebrokers",
          },
          {
            displayName: "Non-Life",
            dataName: "non-life",
          },
          {
            displayName: "Lloyd's Funds",
            dataName: "lloyd'sfunds",
          },
          {
            displayName: "Re-insurance",
            dataName: "re-insurance",
          },
          {
            displayName: "Other Insurance",
            dataName: "otherinsurance",
          },
        ],
      },
      {
        displayName: "Investments", //"Investment Co.'S
        dataName: "investments",
        industries: [
          {
            displayName: "Investment Companies",
            dataName: "investmentcompanies",
          },
          {
            displayName: "International Investment Trusts",
            dataName: "internationalinvestmenttrusts",
          },
          {
            displayName: "UK Investment Trusts",
            dataName: "ukinvestmenttrusts",
          },
          {
            displayName: "European Investment Trusts",
            dataName: "europeaninvestmenttrusts",
          },
          {
            displayName: "Venture & Development Capital",
            dataName: "venture&developmentcapital",
          },
        ],
      },
      {
        displayName: "Lesiure / Entertainment / Hotels",
        dataName: "lesiure/entertainment/hotels",
        industries: [
          {
            displayName: "Gaming",
            dataName: "gaming",
          },
          {
            displayName: "Home Entertainment",
            dataName: "homeentertainment",
          },
          {
            displayName: "Hotels",
            dataName: "hotels",
          },
          {
            displayName: "Leisure Facilities",
            dataName: "leisurefacilities",
          },
        ],
      },
      {
        displayName: "Life Assurance",
        dataName: "lifeassurance",
        industries: [
          {
            displayName: "Life Assurance",
            dataName: "lifeassurance",
          },
        ],
      },
      {
        displayName: "Media & Photography",
        dataName: "media&photography",
        industries: [
          {
            displayName: "Broadcasting Contractors",
            dataName: "broadcastingcontractors",
          },
          {
            displayName: "Cable & Satellite",
            dataName: "cable&satellite",
          },
          {
            displayName: "Media Agencies",
            dataName: "mediaagencies",
          },
          {
            displayName: "Photography",
            dataName: "photography",
          },
          {
            displayName: "Publishing & Printing",
            dataName: "publishing&printing",
          },
        ],
      },
      {
        displayName: "Mining",
        dataName: "mining",
        industries: [
          {
            displayName: "Gold Mining",
            dataName: "goldmining",
          },
          {
            displayName: "Mining Finance",
            dataName: "miningfinance",
          },
          {
            displayName: "Other Mineral Extractors & Mines",
            dataName: "othermineralextractors&mines",
          },
        ],
      },
      {
        displayName: "Oil & Gas",
        dataName: "oil&gas",
        industries: [
          {
            displayName: "Exploration & Production",
            dataName: "exploration&production",
          },
          {
            displayName: "Services",
            dataName: "services",
          },
          {
            displayName: "Integrated",
            dataName: "integrated",
          },
        ],
      },
      {
        displayName: "Packaging",
        dataName: "packaging",
        industries: [
          {
            displayName: "Packaging",
            dataName: "packaging",
          },
        ],
      },
      {
        displayName: "Nonprofit",
        dataName: "nonprofit",
        industries: [
          {
            displayName: "Nonprofit",
            dataName: "nonprofit",
          },
        ],
      },
      {
        displayName: "Personal Care & Household Products",
        dataName: "personalcare&householdproducts",
        industries: [
          {
            displayName: "Household Products",
            dataName: "householdproducts",
          },
          {
            displayName: "Personal Products",
            dataName: "personalproducts",
          },
        ],
      },
      {
        displayName: "Pharmaceuticals",
        dataName: "pharmaceuticals",
        industries: [
          {
            displayName: "Pharmaceuticals",
            dataName: "pharmaceuticals",
          },
        ],
      },
      {
        displayName: "Real Estate",
        dataName: "realestate",
        industries: [
          {
            displayName: "Real Estate Holding & Development",
            dataName: "realestateholding&development",
          },
          {
            displayName: "Property Agencies",
            dataName: "propertyagencies",
          },
          {
            displayName: "Real Estate Investment Trusts",
            dataName: "realestateinvestmenttrusts",
          },
        ],
      },
      {
        displayName: "Service Industry", //"Restaurants / Pubs / Breweries",
        dataName: "serviceindustry", //"restaurants/pubs/breweries",
        industries: [
          {
            displayName: "Restaurants / Pubs / Breweries",
            dataName: "restaurants/pubs/breweries",
          },
        ],
      },
      {
        displayName: "Speciality & Other Finance",
        dataName: "speciality&otherfinance",
        industries: [
          {
            displayName: "Asset Managers",
            dataName: "assetmanagers",
          },
          {
            displayName: "Consumer Finance",
            dataName: "consumerfinance",
          },
          {
            displayName: "Investment Banks",
            dataName: "investmentbanks",
          },
          {
            displayName: "Mortgage Finance",
            dataName: "mortgagefinance",
          },
          {
            displayName: "Other Financial",
            dataName: "otherfinancial",
          },
          {
            displayName: "Housing Income Investment Trusts",
            dataName: "housingincomeinvestmenttrusts",
          },
          {
            displayName: "Open-Ended Investment Companies",
            dataName: "open-endedinvestmentcompanies",
          },
          {
            displayName: "Off-Shore Investment Companies & Funds",
            dataName: "off-shoreinvestmentcompanies&funds",
          },
          {
            displayName: "Venture Capital Trusts",
            dataName: "venturecapitaltrusts",
          },
          {
            displayName: "Currency Funds",
            dataName: "currencyfunds",
          },
          {
            displayName: "Other S.842 Investment Trusts",
            dataName: "other_s.842investmenttrusts",
          },
          {
            displayName: "Split Capital Investment Trusts",
            dataName: "splitcapitalinvestmenttrusts",
          },
        ],
      },
      {
        displayName: "Steel & Other Metals",
        dataName: "steel&othermetals",
        industries: [
          {
            displayName: "Non-Ferrous Metals",
            dataName: "non-ferrousmetals",
          },
          {
            displayName: "Steel",
            dataName: "steel",
          },
        ],
      },
      {
        displayName: "Support Services",
        dataName: "supportservices",
        industries: [
          {
            displayName: "Business Support Services",
            dataName: "businesssupportservices",
          },
          {
            displayName: "Education / Business Training / Employment Agencies",
            dataName: "education/businesstraining/employmentagencies",
          },
          {
            displayName: "Environmental Control",
            dataName: "environmentalcontrol",
          },
          {
            displayName: "Funerals & Cemeteries",
            dataName: "funerals&cemeteries",
          },
          {
            displayName: "Laundries & Cleaners",
            dataName: "laundries&cleaners",
          },
          {
            displayName: "Security & Alarm Services",
            dataName: "security&alarmservices",
          },
        ],
      },
      {
        displayName: "Tobacco",
        dataName: "tobacco",
        industries: [
          {
            displayName: "Tobacco",
            dataName: "tobacco",
          },
        ],
      },
      {
        displayName: "Transport",
        dataName: "transport",
        industries: [
          {
            displayName: "Airlines & Airports",
            dataName: "airlines&airports",
          },
          {
            displayName: "Rail / Road / Freight",
            dataName: "rail/road/freight",
          },
          {
            displayName: "Shipping & Ports",
            dataName: "shipping&ports",
          },
        ],
      },
      {
        displayName: "Utilities",
        dataName: "utilities",
        industries: [
          {
            displayName: "Electricity",
            dataName: "electricity",
          },
          {
            displayName: "Gas Distribution",
            dataName: "gasdistribution",
          },
          {
            displayName: "Water",
            dataName: "water",
          },
        ],
      },
    ];
  }
  convertSelectedIndustriesToString(selected: iIndustrySelected[]): string[] {
    let strSelected: string[] = [];
    selected.forEach((item) => {
      // const industryGroup = this.allIndustries.find(group => group.dataName === item.groupDataName);
      // const industry = industryGroup.industries.find(ind => ind.dataName === item.industryDataName);

      // strSelected.push(`${industryGroup.displayName} > ${industry.displayName}`);
      strSelected.push(`${item.groupDataName} > ${item.industryDataName}`);
    });

    return strSelected;
  }
  convertStringToSelectedIndustries(strSelected: string[]): iIndustrySelected[] {
    let selected: iIndustrySelected[] = [];

    strSelected.forEach((str) => {
      let split = str.split(">").map((str) => str.trim());
      selected.push({ groupDataName: split[0], industryDataName: split[1] });
    });

    return selected;
  }
  //#endregion Industries

  //#region 360 Profile

  async downloadCandidateBoulo360Profile(candidate: iApplicantListModel) {
    var a = document.createElement("a");
    a.target = "_self";
    let candidateName = `${candidate.firstName?.toLowerCase()}`;
    candidateName +=
      candidate.lastInitial?.length > 0
        ? `-${candidate.lastInitial}`
        : candidate.lastName?.length > 0
        ? `-${candidate.lastName[0].toLowerCase()}`
        : "";
    const fileName = `${candidateName}_BouloThreeSixtyProfile.pdf`;
    const file = await this.downloadThreeSixtyByApplicantId(candidate.id);
    const data = window.URL.createObjectURL(file);
    a.href = data;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  async downloadThreeSixtyByApplicantId(candidateId: string): Promise<Blob> {
    try {
      return await this.http
        .post(`${proxyApi}three-sixty-resume/${candidateId}`, JSON.stringify({ download: true }), { responseType: "blob" })
        .toPromise();
    } catch (err) {
      Logger.warn(`Unable to download Boulo 360 Profile. Error: ${err.message}`);
    }
  }

  getIndivdualCategorySkill(categorySkill: string) {
    let allSkills = this.setSkillData();
    for(let skills of allSkills) {
      for (let skill of skills.skills) {
        if (skill.dataName === categorySkill) {
          return skill.displayName;
        }
      }
    }

  }

  setSkillData() {
    return [
      {
        displayName: "Administrative Skills Focus",
        keyName: "Administrative",
        icon: "task_alt",
        skills: [
          { dataName: "appointmentSetting", displayName: "Appointment Setting" },
          { dataName: "billing", displayName: "Billing" },
          { dataName: "businessCorrespondence", displayName: "Business Correspondence" },
          { dataName: "clientRelations", displayName: "Client Relations" },
          { dataName: "customerService", displayName: "Customer Service" },
          { dataName: "documentManagement", displayName: "Document Management" },
          { dataName: "filing", displayName: "Filing" },
          { dataName: "inventoryCoordination", displayName: "Inventory Coordination" },
          { dataName: "maintainingOfficeRecords", displayName: "Maintaining Office Records" },
          { dataName: "officeManagement", displayName: "Office Management" },
          { dataName: "orderProcessing", displayName: "Order Processing" },
          { dataName: "proofReading", displayName: "Proof Reading" },
          { dataName: "reception", displayName: "Reception" },
          { dataName: "recordKeeping", displayName: "Record Keeping" },
          { dataName: "timeAndBilling", displayName: "Time And Billing" },
          { dataName: "transcription", displayName: "Transcription" },
          { dataName: "travelArrangements", displayName: "Travel Arrangements" },
          { dataName: "typing", displayName: "Typing" },
        ],
      },
      {
        displayName: "Business Skills Focus",
        keyName: "Business",
        icon: "business",
        skills: [
          { dataName: "businessAnalysis", displayName: "Business analysis" },
          { dataName: "businessGrowth", displayName: "Business Growth" },
          { dataName: "businessIntelligence", displayName: "Business Intelligence" },
          { dataName: "businessPlanning", displayName: "Business Planning" },
          { dataName: "businessProcessPlanning", displayName: "Business Process Planning" },
          { dataName: "businessRescue", displayName: "Business Rescue" },
          { dataName: "businessValuations", displayName: "Business Valuations" },
          { dataName: "companyDesign", displayName: "Company Design" },
          { dataName: "insurance", displayName: "Insurance" },
          { dataName: "managementConsulting", displayName: "Management Consulting" },
          { dataName: "research", displayName: "Research" }, //not in specific skills?
          { dataName: "startupManagement", displayName: "Startup Management" },
          { dataName: "strategicManagement", displayName: "Strategic Management" },
          { dataName: "reconciliations", displayName: "Reconciliations" },
        ],
      },
      {
        displayName: "Creative Skills Focus",
        keyName: "Creative",
        icon: "brush",
        skills: [
          { dataName: "animation", displayName: "Animation" },
          { dataName: "creativeWriting", displayName: "Creative Writing" },
          { dataName: "directing", displayName: "Directing" },
          { dataName: "editing", displayName: "Editing" },
          { dataName: "filmAndMedia", displayName: "Film And Media" },
          { dataName: "graphicDesign", displayName: "Graphic Design" },
          { dataName: "journalism", displayName: "Journalism" },
          { dataName: "production", displayName: "Production" },
          { dataName: "videoProduction", displayName: "Video Production" },
          { dataName: "voiceovers", displayName: "Voiceovers" },
        ],
      },
      {
        displayName: "Financial Skills Focus",
        keyName: "Financial",
        icon: "paid",
        skills: [
          { dataName: "accounting", displayName: "Accounting" },
          { dataName: "actuarial", displayName: "Actuarial" },
          { dataName: "bookkeeping", displayName: "Bookkeeping" },
          {
            dataName: "cashFlowManagement",
            displayName: "Cash Flow Management",
            catSpecificName: "Budgeting / Cash Flow Management",
          },
          { dataName: "costAccounting", displayName: "Cost Accounting" },
          { dataName: "costManagement", displayName: "Cost Management" },
          { dataName: "dataProcessing", displayName: "Data Processing" },
          { dataName: "debtorsAndCreditors", displayName: "Debtors And Creditors" },
          { dataName: "financialCompliance", displayName: "Financial Compliance" },
          { dataName: "financialManagement", displayName: "Financial Management" },
          { dataName: "financialPackages", displayName: "Financial Packages" },
          { dataName: "financialReporting", displayName: "Financial Reporting" },
          { dataName: "gaap", displayName: "GAAP" },
          { dataName: "generalLedger", displayName: "General Ledger" },
          { dataName: "mergersAcquisitions", displayName: "Mergers & Acquisitions" },
          { dataName: "publicAccounting", displayName: "Public Accounting" },
          { dataName: "reconciliations", displayName: "Reconciliations" },
          { dataName: "riskManagement", displayName: "Risk Management" },
          { dataName: "securities", displayName: "Securities" },
          { dataName: "sorting", displayName: "Sorting" },
          { dataName: "statistical", displayName: "Statistical" },
          { dataName: "taxes", displayName: "Taxes", catSpecificName: "Tax Filing / Planning / Reporting" },
        ],
      },
      {
        displayName: "Legal Skills Focus",
        keyName: "Legal",
        icon: "gavel",
        skills: [
          { dataName: "contractDevelopmentAndManagement", displayName: "Contract Development and Management" },
          { dataName: "knowledgeAndApplicationOfLegalActs", displayName: "Knowledge and Application of Legal Acts" },
          { dataName: "lawCompilation", displayName: "Law Compilation" },
          { dataName: "legalGuidance", displayName: "Legal Guidance" },
          { dataName: "legalLetters", displayName: "Legal Letters" },
          { dataName: "legalSecretarial", displayName: "Legal Secretarial" },
          { dataName: "legalSupport", displayName: "Legal Support" },
          { dataName: "policyDevelopment", displayName: "Policy Development" },
          { dataName: "leagalRealEstate", displayName: "Legal Real Estate", catSpecificName: "Real Estate Transactions" },
          { dataName: "research", displayName: "Research" }, //not in specific skills?
        ],
      },
      {
        displayName: "Marketing Skills Focus",
        keyName: "Marketing",
        icon: "store",
        skills: [
          { dataName: "blogging", displayName: "Blogging" },
          { dataName: "brandManagement", displayName: "Brand Management" },
          { dataName: "mediaBuying", displayName: "Media Buying" },
          {
            dataName: "campaignPromotions",
            displayName: "Campaign Promotions",
            catSpecificName: "Campaign Promotions / Management",
          },
          {
            dataName: "communications",
            displayName: "Communications",
            catSpecificName: "Communications (Internal / External)",
          },
          { dataName: "competitorAnalysis", displayName: "Competitor Analysis" },
          { dataName: "consumerBehaviorAnalysis", displayName: "Consumer Behavior Analysis" },
          {
            dataName: "contentManagementSystems",
            displayName: "Content Management Systems",
            catSpecificName: "Content Management Systems (CMS)",
          },
          {
            dataName: "customerRelationshipManagement",
            displayName: "Customer Relationship Management",
            catSpecificName: "Customer Relationship Management (CRM)",
          },
          { dataName: "digitalMarketing", displayName: "Digital Marketing" },
          { dataName: "marketResearch", displayName: "Market Research" },
          { dataName: "marketingPitches", displayName: "Marketing Pitches" },
          {
            dataName: "marketingStrategy",
            displayName: "Marketing Strategy",
            catSpecificName: "Marketing Strategy / Plan Development",
          },
          { dataName: "productManagement", displayName: "Product Management" },
          { dataName: "publicRelations", displayName: "Public Relations" },
          { dataName: "socialMediaManagement", displayName: "Social Media Management" },
          { dataName: "websiteContentManagement", displayName: "Website Content Management" },
        ],
      },
      {
        displayName: "Operations Skills Focus",
        keyName: "Operations",
        icon: "assignment",
        skills: [
          { dataName: "callCenterManagement", displayName: "Call Center Management" },
          { dataName: "customerService", displayName: "Customer Service" },
          {
            dataName: "distributionManagement",
            displayName: "Distribution Management",
            catSpecificName: "Distribution Management / Support",
          },
          {
            dataName: "environmentOptimizationThroughAutomation",
            displayName: "Environment Optimization Through Automation",
          },
          { dataName: "fieldServiceOperationalManagement", displayName: "Field Service Operational Management" },
          {
            dataName: "logisticsManagement",
            displayName: "Logistics Management",
            catSpecificName: "Logistics Management / Support",
          },
          { dataName: "operationalDataManagementAndAnalytics", displayName: "Operational Data Management and Analytics" },
          { dataName: "qualityAssurance", displayName: "Quality Assurance" },
          {
            dataName: "optimizationOfTeams",
            displayName: "Optimization of Teams",
            catSpecificName: "Resourcing / Development / Optimization of Teams",
          },
          { dataName: "serviceDeliveryManagement", displayName: "Service Delivery Management" },
          { dataName: "supplierAndVendorManagement", displayName: "Supplier and Vendor Management" },
          {
            dataName: "workProcessManagement",
            displayName: "Work Process Management",
            catSpecificName: "Work Process Management / Support",
          },
        ],
      },
      {
        displayName: "People Skills Focus",
        keyName: "People",
        icon: "group",
        skills: [
          { dataName: "assessments", displayName: "Assessments" },
          { dataName: "benefitsAdministration", displayName: "Benefits Administration" },
          { dataName: "changeManagement", displayName: "Change Management" },
          { dataName: "voluntaryWork", displayName: "Voluntary Work", catSpecificName: "Charity / Voluntary Work" },
          { dataName: "compensationManagement", displayName: "Compensation Management" },
          {
            dataName: "conflictResolution",
            displayName: "Conflict Resolution",
            catSpecificName: "Conflict Resolution / Negotiation",
          },
          { dataName: "counseling", displayName: "Counseling" },
          { dataName: "hrSystems", displayName: "HR Systems" },
          { dataName: "industrialRelations", displayName: "Industrial Relations" },
          { dataName: "internalAndExternalCommunications", displayName: "Internal and External Communications" },
          { dataName: "mediation", displayName: "Mediation" },
          {
            dataName: "organizationalDesign",
            displayName: "Organizational Design",
            catSpecificName: "Organizational Design / Development",
          },
          { dataName: "payroll", displayName: "Payroll" },
          { dataName: "performanceManagement", displayName: "Performance Management" },
          { dataName: "policiesAndProcedures", displayName: "Policies and Procedures" },
          { dataName: "recruitmentAndSelection", displayName: "Recruitment and Selection" },
          { dataName: "socialWork", displayName: "Social Work" },
          { dataName: "teamBuilding", displayName: "Team Building" },
          { dataName: "trainingAndDevelopment", displayName: "Training and Development" },
        ],
      },
      {
        displayName: "Project Management Skills Focus",
        keyName: "Project Management",
        icon: "checklist",
        skills: [
          { dataName: "budgeting", displayName: "Budgeting" },
          { dataName: "contractManagement", displayName: "Contract Management" },
          { dataName: "projectCharterPreparation", displayName: "Project Charter Preparation" },
          { dataName: "projectQualityManagement", displayName: "Project Quality Management" },
          { dataName: "projectSoftware", displayName: "Project Software" },
          { dataName: "projectStakeholderManagement", displayName: "Project Stakeholder Management" },
          { dataName: "riskManagement", displayName: "Risk Management" },
          { dataName: "scheduling", displayName: "Scheduling" },
          { dataName: "workBreakdown", displayName: "Work Breakdown" },
        ],
      },
      {
        displayName: "Sales Skills Focus",
        keyName: "Sales",
        icon: "point_of_sale",
        skills: [
          { dataName: "b2b", displayName: "B2B" },
          { dataName: "b2c", displayName: "B2C" },
          { dataName: "insideSales", displayName: "Inside Sales" },
          { dataName: "negotiating", displayName: "Negotiating" },
          { dataName: "outsideSales", displayName: "Outside Sales" },
          { dataName: "presentationCreation", displayName: "Presentation Creation" },
          { dataName: "salesManagement", displayName: "Sales Management" },
          { dataName: "salesDemoing", displayName: "Sales Demoing" },
        ],
      },
      {
        displayName: "Technical Skills Focus",
        keyName: "Technical",
        icon: "memory",
        skills: [
          { dataName: "artificialIntelligence", displayName: "Artificial Intelligence" },
          { dataName: "computerProgramming", displayName: "Computer Programming" },
          { dataName: "dataAnalysis", displayName: "Data Analysis" },
          {
            dataName: "databaseManagement",
            displayName: "Database Management",
            catSpecificName: "Database Design / Management",
          },
          { dataName: "desktopManagement", displayName: "Desktop Management" },
          {
            dataName: "digitalPlatformManagement",
            displayName: "Digital Platform Management",
            catSpecificName: "Digital / Social Platform Management",
          },
          { dataName: "engineering", displayName: "Engineering" },
          { dataName: "hardwareDesign", displayName: "Hardware Design" },
          { dataName: "networkManagement", displayName: "Network Management" },
          { dataName: "qualityAssurance", displayName: "Quality Assurance", catSpecificName: "Quality Assurance / Control" },
          { dataName: "softwareEngineering", displayName: "Software Engineering" },
          { dataName: "specificationsGathering", displayName: "Specifications Gathering" },
          { dataName: "statisticalModeling", displayName: "Statistical Modeling" },
          { dataName: "systemsSecurity", displayName: "Systems Security" },
          {
            dataName: "systemsChangeManagement",
            displayName: "Systems Change Management",
            catSpecificName: "Systems / Process Change Management",
          },
          { dataName: "technicalProjectManagement", displayName: "Technical Project Management" },
          { dataName: "technicalReportWriting", displayName: "Technical Report Writing" },
          { dataName: "virtualReality", displayName: "Virtual Reality" },
          { dataName: "webAnalytics", displayName: "Web Analytics" },
        ],
      },
    ];
  }
  getDisplayNameByDataName(dataName: string): string | undefined {
    const allSkills = this.skillsDataSet.reduce((acc, category) => acc.concat(category.skills), []);
    const skill = allSkills.find((skill) => skill.dataName === dataName);
    return skill ? skill.displayName : undefined;
  }

  SetFullCustomTextEditorConfig(toolBarIconList: Object[]) {
    return {
      "emoji-toolbar": true,
      "emoji-textarea": false,
      "emoji-shortname": true,
      toolbar: {
        container: toolBarIconList,
      },
      keyboard: {
        bindings: {
          enter: {
            key: 13,
            handler: (range, context) => {
              return true;
            },
          },
        },
      },
    };
  }

  jobSpecificSkillsDetails: { [skillKey: string]: string };
  getSkillFocusLevel(skillName: string, jobSpecificSkillsDetails: { [skillKey: string]: string }): string {
    let totalPts: number = 0;
    let skillsWithInput: number = 0;
    let dataMatch = this.skillsDataSet.find((x) => x.displayName == skillName);
    if (!dataMatch) {
      return eSkillLevelName.Intermediate;
    }

    this.jobSpecificSkillsDetails = jobSpecificSkillsDetails;
    dataMatch.skills.forEach((skill) => {
      let skillLvl: number = this.skillLevelToNumber(this.getSkillDetail(skill.dataName));
      if (skillLvl > 0) {
        totalPts += skillLvl;
        skillsWithInput++;
      }
    });
    Logger.debug(`getSkillFocusLevel - dataMatch`);
    let value = totalPts / skillsWithInput;

    Logger.debug(
      `value = total / length: ${value} = ${totalPts} / ${skillsWithInput} ...(instead of dataMatch...:) ${dataMatch.skills.length}`
    );
    if (value >= 2) {
      return eSkillLevelName.Advanced;
    } else if (value >= 1.2) {
      return eSkillLevelName.Intermediate;
    } else {
      return eSkillLevelName.Beginner;
    }
  }

  skillLevelToNumber(level: string): number {
    switch (level.toLowerCase()) {
      case eSkillLevelName.Beginner:
        return 1;
      case eSkillLevelName.Intermediate:
        return 2;
      case eSkillLevelName.Advanced:
        return 3;
      default:
        return 0;
    }
  }
  getSkillDetail(skillDetail: string) {
    const key = startCase(skillDetail);

    return this.jobSpecificSkillsDetails[key] || "";
  }
  //#endregion 360 Profile

  //#region DownLoad Files

  downLoadSingleFile(fileContent: Buffer, contentType: string, fileName: string) {
    const blobData = new Blob([fileContent], { type: contentType });
    saveAs(blobData, fileName);
  }

  createAndDownloadZip(files: { name: string; content: Blob }[], zipName: string): void {
    const zip = new JSZip();

    files.forEach((file) => {
      zip.file(file.name, file.content);
    });

    zip.generateAsync({ type: "blob" }).then((zipContent: Blob) => {
      saveAs(zipContent, `${zipName}.zip`);
    });
  }

  //#endregion DownLoad Files
}

export enum eSkillLevelName {
  Beginner = "beginner",
  Intermediate = "intermediate",
  Advanced = "advanced",
  Unset = "unset",
}

export interface iMessageInbox {
  sender: iMessageSender;
  channels?: GroupChannel[];
  currentChannel?: GroupChannel;
  selectedChannel?: string;
  receiver?: iMessageReciever;
  messages?: iMessage[];
}

export interface iMessage {
  sender: string;
  text: string;
  timestamp: number;
}

export interface iLocation {
  isValid: boolean;
  zip?: string;
  latitude?: number;
  longitude?: number;
  city?: string;
  state?: string; //state abbreviation
  country?: string; //'US' for UnitedStates
}
