import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { environment } from "src/environments/environment";
import { OpenCommonService } from "../open-common.service";

const API: string = environment.api;

interface Way_ {
  ways: {
    svg: string;
    name: string;
    details: string;
    link?: string;
    extraClass?: string;
    target?: string;
  }[];
}

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.scss"],
})
// Route to this component was removed 01/2024
export class ContactUsComponent implements OnInit {
  messageSent = false;
  showBanner: boolean;
  defaultSvgAddress: string = "assets/svg/";
  ways: Way_[] = [
    {
      ways: [
        // {
        //   svg: this.defaultSvgAddress + 'Phone-icon.svg',
        //   name: 'Phone',
        //   details: '(914) 787 85 96'
        // },
        {
          svg: this.defaultSvgAddress + "Email-icon.svg",
          name: "Email",
          details: "Guru@hellocareerguru.com",
          link: "mailto:'Guru@hellocareerguru.com'",
        },
        // {
        //   svg: this.defaultSvgAddress + 'Location-icon.svg',
        //   name: 'US Address',
        //   details: '401 Park Ave South, New York, NY 10016'
        // },
      ],
    },
    {
      ways: [
        {
          svg: this.defaultSvgAddress + "Instagram.svg",
          name: "Instagram",
          details: "@hellocareerguru",
          link: "https://www.instagram.com/hellocareerguru/",
          target: "_blank",
        },
        {
          svg: this.defaultSvgAddress + "Twitter.svg",
          name: "Twitter",
          details: "@hellocareerguru",
          link: "https://twitter.com/hellocareerguru",
          target: "_blank",
        },
      ],
    },
    {
      ways: [
        {
          svg: this.defaultSvgAddress + "Linkedin.svg",
          name: "LinkedIn",
          details: "Boulo",
          link: "https://www.linkedin.com/company/hello-career-guru",
          target: "_blank",
        },
        {
          svg: this.defaultSvgAddress + "Facebook.svg",
          name: "Facebook",
          details: "Boulo",
          extraClass: "facebook",
          link: "https://www.facebook.com/hellocareerguru/",
          target: "_blank",
        },
      ],
    },
    // {
    //   ways: [
    //     {
    //       svg: this.defaultSvgAddress + 'Youtube.svg',
    //       name: 'YouTube',
    //       details: 'Boulo',
    //       link: 'https://www.youtube.com/channel/UCpIQmH4d-ooDXOomIEdeNrw',
    //       target: '_blank'
    //     },
    //   ]
    // },
  ];
  contactUsForm: UntypedFormGroup;

  constructor(
    private http: HttpClient,
    public common: OpenCommonService
  ) {}

  ngOnInit(): void {
    this.common.showBannerObservable.subscribe((val) => {
      this.showBanner = val;
    });
    this.contactUsForm = new UntypedFormGroup({
      firstName: new UntypedFormControl(null, Validators.required),
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
      message: new UntypedFormControl(null),
    });
  }

  onSubmit(): void {
    this.messageSent = true;
    this.http
      .post(API + "customer_contacts", {
        customer_contact: {
          first_name: this.contactUsForm.get("firstName").value,
          email: this.contactUsForm.get("email").value,
          message: !this.contactUsForm.get("message").value ? "Request more info" : this.contactUsForm.get("message").value,
        },
      })
      .subscribe((n) => {
        this.contactUsForm.reset();
      });
  }

  sendANewMessage() {
    this.messageSent = false;
    setTimeout(() => {
      document.getElementById("firstName").focus();
    }, 200);
  }
}
