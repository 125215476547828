<div class="dialog-applicant-details-container">
  <!-- header/toolbar -->
  <div class="mw-static-div-header">
    <!-- TODO: verified logo -->
    <div class="candidate-verified-logo">
      <div class="display-logo" *ngIf="_verified">
        <div class="logo-position">
          <span _ngcontent-avc-c200="" matprefix="" class="prefix-dark-bg logo-container"
            ><img
              _ngcontent-avc-c200=""
              src="../../../../assets/svg/Boulo-position-white-version.svg"
              alt="a"
              class="prefix-icon-img logo-img"
          /></span>
        </div>
        <div class="text">Boulo Verified</div>
      </div>
    </div>

    <mat-h2 mat-dialog-title class="dialog-title">{{ _getCandidateName }}</mat-h2>

    <!-- Mobile view - Icons -->
    <div class="top-right" class="mobile-icons">
      <div class="top-right-icons-mobile">
        <!-- Mobile Dropdown Menu -->
        <div>
          <button mat-icon-button [disableRipple]="true" [matMenuTriggerFor]="menu">
            <mat-icon class="threedotMenu">more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu" [disableRipple]="true">
            <button mat-menu-item id="favorite-candidate" class="btn-dim" (click)="toggleSaveCandidate()">
              <mat-icon>{{ _getFavoriteIcon }}</mat-icon>
              <span>{{ _getFavoriteIconLabel }}</span>
            </button>
            <div *ngIf="linkClicked" class="isClicked">Link Copied!</div>

            <app-mat-spinner-btn [loading]="isBusy">
              <button mat-menu-item (click)="mobileDownLoad360Profile($event)" class="btn-dim">
                <mat-icon id="download-candidate">download</mat-icon>
                <span>Download Boulo360 Profile</span>
              </button>
            </app-mat-spinner-btn>

            <button
              mat-menu-item
              class="btn-dim"
              [cdkCopyToClipboard]="selectedCandidate?.email"
              (click)="openSnackBar('Copied the Email to Clipboard')"
            >
              <mat-icon id="message-candidate">email</mat-icon>
              <span>Copy Email</span>
            </button>
            <div *ngIf="emailClicked" class="isClicked">Email Copied!</div>
          </mat-menu>
        </div>

        <app-share-button
          hoverMsg="Share Candidate"
          class="share-btn"
          [shareTitle]="'Share this Candidate via:'"
          [snackMsg]="'Copied the Candidate URL to Clipboard'"
          [timeOfMsg]="3"
          [url]="url"
          [color]="'white'"
        ></app-share-button>
      </div>
    </div>

    <!-- Desktop view - Icons: -->
    <div class="icon-container">
      <button
        mat-icon-button
        id="save-candidate"
        (click)="toggleSaveCandidate()"
        class="full-button-candidate btn-dim"
        [matTooltip]="_getFavoriteIconLabel"
      >
        <mat-icon>{{ _getFavoriteIcon }}</mat-icon>
      </button>

      <app-share-button
        hoverMsg="Share Candidate"
        class="share-btn full-button-candidate"
        [shareTitle]="'Share this Candidate via:'"
        [snackMsg]="'Copied the Candidate URL to Clipboard'"
        [timeOfMsg]="3"
        [url]="url"
        [color]="'white'"
      ></app-share-button>

      <app-mat-spinner-btn [loading]="isBusy">
        <button
          mat-icon-button
          id="download-candidate"
          (click)="downloadCandidateBoulo360()"
          class="full-button-candidate btn-dim"
          matTooltip="Download Boulo360 Profile"
        >
          <mat-icon>download</mat-icon>
        </button>
      </app-mat-spinner-btn>

      <button
        mat-icon-button
        id="message-candidate"
        class="full-button-candidate btn-dim"
        [matMenuTriggerFor]="shareEmail"
        matTooltip="View Candidate Email"
      >
        <mat-icon>email</mat-icon>
      </button>
      <mat-menu #shareEmail="matMenu">
        <div class="copied-container">
          <div class="top-copied">
            <input class="actual-email avoid" matInput readonly value="{{ selectedCandidate.email }}" />
            <mat-icon
              class="content-copy"
              id="content-copy"
              matTooltip="Copy to Clipboard"
              [cdkCopyToClipboard]="selectedCandidate.email"
              (click)="openSnackBar('Copied the Email to Clipboard')"
              >content_copy</mat-icon
            >
          </div>
          <div *ngIf="emailClicked" class="isClicked">Copied!</div>
        </div>
      </mat-menu>
    </div>

    <div class="title-icon-row-wrapper">
      <button *ngIf="!isLoadingDetails" mat-icon-button color="primary" [mat-dialog-close] class="close-btn btn-dim">
        X
      </button>
    </div>
  </div>

  <!-- job progress/status banner -->
  <div *ngIf="isLoadingJob" class="mw-static-div applicant-status-loading-container">
    <div class="loading-jobs-wrapper">
      <mat-spinner class="smol-spinner"></mat-spinner>
      <mat-h3 mat-dialog-title class="dialog-subtitle">Loading applied jobs...</mat-h3>
    </div>
  </div>
  <div *ngIf="!isLoadingJob" class="mw-static-div applicant-status-container">
    <!-- Job Details -->
    <!-- Job Title and AppliedOn date -->
    <!-- <div [class]="_hasJobAppliedDetails ? 'job-applied-wrapper' : ''"> -->
    <div [class]="'job-applied-wrapper'">
      <mat-h2 mat-dialog-title class="dialog-title">{{ _getJobTitle }}</mat-h2>
      <mat-h3 *ngIf="_showAppliedDate" mat-dialog-title class="dialog-subtitle"
        >Applied On: {{ _getJobAppliedDate }}</mat-h3
      >
    </div>

    <app-applicant-status-menu
      [isDisabled]="isApplicantHired()"
      [applicant]="selectedCandidate"
      backgroundColor="#45A1B2"
      textColor="white"
      [isLoading]="isUpdatingStatus"
      (applicantOutputEvent)="updateApplicantJobStatus($event)"
    ></app-applicant-status-menu>
  </div>
  <!-- TODO: icons row -->

  <div class="body-container">
    <!-- contents/body - start -->
    <mat-dialog-content class="dialog-content">
      <mat-drawer-container class="candidate-details-drawer-container" autosize>
        <div class="stretch-div">
          <!-- menu tabs - start -->
          <mat-tab-group
            mat-stretch-tabs
            [selectedIndex]="selectedMenuTab.value"
            (selectedIndexChange)="selectedMenuTab.setValue($event)"
            class="w-100 parent-tab-menu-group"
          >
            <!-- Profile Tab -->
            <mat-tab>
              <ng-template mat-tab-label>
                <span class="label-contents-wrapper">
                  <mat-icon>person_outline</mat-icon>
                  <span *ngIf="displayLabel(0)" class="label-text">Profile</span>
                </span>
              </ng-template>
              <app-applicant-details-profile-root
                [isLoading]="isLoadingDetails"
                [candidate]="selectedBouloModel"
                class="component-pane-wrapper"
              ></app-applicant-details-profile-root>
            </mat-tab>

            <!-- Messages Tab -->
            <mat-tab [disabled]="false">
              <ng-template mat-tab-label>
                <span class="label-contents-wrapper">
                  <mat-icon [style.opacity]="0.7">email</mat-icon>
                  <span *ngIf="displayLabel(1)" [style.opacity]="0.7" class="label-text">Messages</span>
                </span>
              </ng-template>
              <ng-template matTabContent>
                <div class="msg-content-wrapper">
                  <app-applicant-details-messages-root
                    [CandidateId]="selectedCandidate.id.toString()"
                    [CandidateName]="selectedCandidateName"
                    [CandidateEmail]="selectedCandidate.email"
                    [CandidatePhone]="_getMobilePhone"
                    class="component-pane-wrapper"
                  ></app-applicant-details-messages-root>
                </div>
              </ng-template>
            </mat-tab>

            <!-- Notes Tab -->
            <mat-tab class="notes-wrapper-pane">
              <ng-template mat-tab-label>
                <span class="label-contents-wrapper" [style.opacity]="0.7">
                  <mat-icon>edit_note</mat-icon>
                  <span *ngIf="displayLabel(2)" class="label-text">Notes</span>
                </span>
              </ng-template>
              <ng-template matTabContent>
                <app-applicant-details-notes-root
                  [selectedCandidate]="_getSelectedCandidate"
                  [selectedJob]="_getSelectedJob"
                ></app-applicant-details-notes-root>
              </ng-template>
            </mat-tab>

            <!-- Documents Tab -->
            <mat-tab [disabled]="false">
              <ng-template mat-tab-label>
                <span class="label-contents-wrapper" [style.opacity]="0.7">
                  <mat-icon>folder</mat-icon>
                  <span *ngIf="displayLabel(3)" class="label-text">Documents</span>
                </span>
              </ng-template>

              <ng-template matTabContent>
                <app-applicant-details-documents-root
                  [candidateObj]="{
                    name: _getCandidateName,
                    id: selectedCandidate.id
                  }"
                  class="component-pane-wrapper documents"
                ></app-applicant-details-documents-root>
              </ng-template>
              <button type="button" mat-raised-button (click)="drawer.toggle()">Toggle sidenav</button>
            </mat-tab>
          </mat-tab-group>

          <!-- menu tabs - end -->
        </div>

        <!-- Quick-Add/Quick-View right-drawer -->
        <mat-drawer #drawer mode="over" position="end">
          <p>Auto-resizing sidenav</p>
          <p>Lorem, ipsum dolor sit amet consectetur.</p>
        </mat-drawer>
      </mat-drawer-container>
    </mat-dialog-content>
    <!-- contents/body - end -->
  </div>

  <!-- navigation-footer -->
  <mat-dialog-actions class="candidate-dialog-actionrow">
    <div class="mw-static-footer">
      <div class="candidate-navigation-footer-wrapper">
        <div class="left-half-container">
          <div class="prev-candidate-btn-container">
            <button
              [disabled]="isLoading"
              mat-raised-button
              color="primary"
              (click)="previousCandidate()"
              class="split-button-icon-left"
            >
              <mat-icon aria-hidden="false" aria-label="Split button icon">navigate_before</mat-icon>
            </button>
            <app-mat-spinner-btn [loading]="isLoading">
              <button mat-raised-button color="primary" (click)="previousCandidate()" class="split-button-text-right">
                Prev. Candidate
              </button>
            </app-mat-spinner-btn>
          </div>
        </div>
        <div class="right-half-container">
          <div class="next-candidate-btn-container">
            <app-mat-spinner-btn [loading]="isLoading">
              <button mat-raised-button color="primary" (click)="nextCandidate()" class="split-button-text-left">
                Next Candidate
              </button>
            </app-mat-spinner-btn>
            <button
              [disabled]="isLoading"
              mat-raised-button
              color="primary"
              (click)="nextCandidate()"
              class="split-button-icon-right"
            >
              <mat-icon aria-hidden="false" aria-label="Split button icon">navigate_next</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-actions>
</div>
