import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DialogConfirmCloseComponent } from "src/app/shared/components/dialog-confirm-close/dialog-confirm-close.component";
import { SnackbarData, SnackbarMsgComponent } from "src/app/shared/components/snackbar-msg/snackbar-msg.component";
import { EmployerOnboardingService } from "../../employer-root/employer-root.service";

interface iAnswer {
  text: string;
  value: string;
  isCorrect?: boolean;
}
interface iQuestion {
  category: string; //HR, Candidate, Custom
  title: string; //Background Check, Interview, Travel, etc.
  format: string; //free-form, multiple-choice, yes/no
  isActive: boolean;
  question: string;
  answers?: iAnswer[];
}
interface iCategory {
  name: string;
  questions: iQuestion[];
}

export interface DialogData {
  selectedQuestions: iQuestion[];
}

@Component({
  selector: "app-modal-add-screening-question",
  templateUrl: "./modal-add-screening-question.component.html",
  styleUrls: ["./modal-add-screening-question.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalAddScreeningQuestionComponent implements OnInit {
  form: FormGroup;
  customQuestion: iQuestion;
  questionFormatDetails: string;
  currentStep: number = 3;
  maxSteps: number;
  categories: iCategory[] = [];
  questions: iQuestion[] = [];
  // selectedQuestions: iQuestion[] = [];
  yesnoDefaultAnswers: iAnswer[] = [
    { text: "Yes", value: "true", isCorrect: null },
    { text: "No", value: "false", isCorrect: null },
  ];

  constructor(
    public questionsDialogRef: MatDialogRef<ModalAddScreeningQuestionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private confirmationDialog: MatDialog,
    private _snackBar: MatSnackBar,
    public onboardingService: EmployerOnboardingService
  ) {
    questionsDialogRef.updateSize("94%");
    questionsDialogRef.disableClose = true;

    questionsDialogRef.backdropClick().subscribe(() => {
      this.openConfirmationDialog();
    });
  }

  async ngOnInit(): Promise<void> {
    this.setupQuestionArrays();
    this.setupCategoryArrays();
    this.setCustomQuestionDefaults();
    this.maxSteps = this.categories.length;
    this.form = new FormGroup({
      customQuestionCtrl: new FormControl(""),
    });
  }

  setStep(index: number) {
    this.currentStep = index;
  }

  nextStep() {
    this.currentStep++;
    if (this.currentStep > this.maxSteps) this.currentStep = 0;
  }

  prevStep() {
    this.currentStep--;
    if (this.currentStep < 0) this.currentStep = this.maxSteps;
  }

  setupQuestionArrays() {
    // if (this.onboardingService.bullhornData.jobs[0].screening.questions.length === 1) {
    this.questions = [];
    this.questions = [
      {
        category: "HR",
        title: "Work Authorization",
        format: "boolean",
        isActive: false,
        question: "Are you authorized to work in the US?",
        answers: this.yesnoDefaultAnswers,
      },
      {
        category: "HR",
        title: "Background Check",
        format: "boolean",
        isActive: false,
        question: "Are you able and willing to undergo a background check?",
        answers: this.yesnoDefaultAnswers,
      },
      {
        category: "HR",
        title: "Interview",
        format: "boolean",
        isActive: false,
        question: "Are you able to come to our physical location for an interview?",
        answers: this.yesnoDefaultAnswers,
      },
      {
        category: "HR",
        title: "Travel",
        format: "boolean",
        isActive: false,
        question: "Are you willing to travel if required for the position?",
        answers: this.yesnoDefaultAnswers,
      },
      {
        category: "HR",
        title: "Relocation",
        format: "boolean",
        isActive: false,
        question: "Can you relocate to our location if necessary?",
        answers: this.yesnoDefaultAnswers,
      },
      {
        category: "HR",
        title: "References",
        format: "boolean",
        isActive: false,
        question: "Are you able to provide professional references?",
        answers: this.yesnoDefaultAnswers,
      },
      {
        category: "HR",
        title: "Languages",
        format: "boolean, freeform-true",
        isActive: false,
        question: "Do you speak any languages other than English? If yes, what other languages do you speak?",
        answers: this.yesnoDefaultAnswers,
      },
      {
        category: "HR",
        title: "Salary",
        format: "",
        isActive: false,
        question: "What are your salary expectations?",
        answers: this.yesnoDefaultAnswers,
      },
      {
        category: "HR",
        title: "Timing",
        format: "",
        isActive: false,
        question: "Are you able to start immediately?",
        answers: this.yesnoDefaultAnswers,
      },

      {
        category: "Candidate",
        title: "",
        format: "freeform",
        isActive: false,
        question: "What excites you about this position?",
        answers: [],
      },
      {
        category: "Candidate",
        title: "",
        format: "freeform",
        isActive: false,
        question: "Why do you want to work for our company?",
        answers: [],
      },
      {
        category: "Candidate",
        title: "",
        format: "freeform",
        isActive: false,
        question: "What motivates you?",
        answers: [],
      },
      {
        category: "Candidate",
        title: "",
        format: "freeform",
        isActive: false,
        question: "How would you describe your ideal work environment?",
        answers: [],
      },
      {
        category: "Candidate",
        title: "",
        format: "freeform",
        isActive: false,
        question: "What are you looking for in your next job?",
        answers: [],
      },
      {
        category: "Candidate",
        title: "",
        format: "freeform",
        isActive: false,
        question: "Describe one of your proudest moments.",
        answers: [],
      },
      {
        category: "Candidate",
        title: "",
        format: "freeform",
        isActive: false,
        question: "Describe one of your most significant career accomplishments.",
        answers: [],
      },
      {
        category: "Candidate",
        title: "",
        format: "freeform",
        isActive: false,
        question: "What is most important to you when considering your next position?",
        answers: [],
      },
    ];
    // } else {
    //   this.questions = this.onboardingService.bullhornData.jobs[0].screening.questions;
    // }

    this.data.selectedQuestions.forEach((selected) => {
      let q = this.questions.find((x) => x.question === selected.question);

      if (q) {
        q.isActive = true;
      } else {
        this.questions.unshift(selected);
      }
    });
  }

  setupCategoryArrays() {
    this.questions.forEach((question) => {
      // Check if the category already exists in the categories array
      const categoryIndex = this.categories.findIndex((c) => c.name === question.category);

      // If the category doesn't exist, create a new category object
      if (categoryIndex === -1) {
        const newCategory: iCategory = {
          name: question.category,
          questions: [question],
        };
        this.categories.push(newCategory);
      } else {
        this.categories[categoryIndex].questions.push(question);
      }
    });

    //Setup the 'Custom' category if there are no custom questions added yet
    const categoryIndex = this.categories.findIndex((c) => c.name === "Your Custom");
    if (categoryIndex === -1) {
      this.categories.push({ name: "Your Custom", questions: [] });
    }
  }

  get isDateFormat() {
    //short-date (MM/YYYY) or date (MM/dd/YYYY)
    return this.customQuestion.format.includes("date");
  }

  get customQuestionCharLength() {
    return this.form.get("customQuestionCtrl").value.length;
  }

  get questionFormatDetailsText() {
    switch (this.customQuestion.format) {
      case "boolean":
        this.questionFormatDetails = `A Yes/No question will provide the applicants with two radio-buttons:
        'Yes' & 'No' where they may only select one of the provided options.`;
        break;
      case "date":
        this.questionFormatDetails = `A date question style will provide an applicant with a 
        date selector that will allow them to answer a date-related question where you'd like a more
        concise answer than what a free-form textbox provides`;
        break;
      case "freeform":
        this.questionFormatDetails = `A free-form question style will provide an applicant with a 
          textbox that will allow them to type an answer up to 250 characters.`;
        break;
    }

    return this.questionFormatDetails;
  }

  get displayCustomAnswers() {
    return !!(this.customQuestion.format == "multiple" || this.customQuestion.format == "boolean");
  }

  setFormIsDirty() {
    this.form.markAsDirty();
  }

  setCustomQuestionDefaults() {
    this.customQuestion = {
      category: "Your Custom",
      title: "",
      format: "freeform",
      isActive: true,
      question: "",
      answers: [],
    };
  }

  setCustomQuestionFormat(format: string) {
    this.customQuestion.format = format;
    this.setFormIsDirty();
  }

  addCustomQuestion() {
    //Verify Custom Category exists, otherwise add it
    let categoryIndex = this.categories.findIndex((c) => c.name === "Your Custom");
    if (categoryIndex === -1) {
      this.categories.push({ name: "Your Custom", questions: [] });
      categoryIndex = this.categories.findIndex((c) => c.name === "Your Custom");
    }

    //Add answers for boolean format
    if (
      this.customQuestion.format.toLowerCase() === "boolean" ||
      this.customQuestion.format.toLowerCase().includes("boolean")
    ) {
      this.customQuestion.answers = this.yesnoDefaultAnswers;
    }

    //Set Question text to textarea control & add question
    this.customQuestion.question = this.form.get("customQuestionCtrl").value;
    this.categories[categoryIndex].questions.push(this.customQuestion);
    this.questions.push(this.customQuestion);

    //Reset values & post success message
    this.setCustomQuestionDefaults();
    this.form.get("customQuestionCtrl").setValue("");
    this.customQuestionAddSuccessMsg();
  }

  customQuestionAddSuccessMsg() {
    this._snackBar.openFromComponent(SnackbarMsgComponent, {
      duration: 5000,
      data: {
        boldMsg: "Custom Question Saved & Added!",
        detailsMsg: 'It has been added to the "Your Custom Questions" section.',
      } as SnackbarData,
      panelClass: ["snackbar-success"],
    });
  }

  // updateBullhornData() {
  //   // this.onboardingService.bullhornData.jobs[0].screening.questions = this.questions;
  // }

  get _getSelectedQuestions(): iQuestion[] {
    const selectedQuestions = this.questions.filter((x) => x.isActive);
    return selectedQuestions?.length > 0 ? selectedQuestions : [];
  }

  saveAll() {
    //TODO: Check for unsaved question?

    // this.updateBullhornData();
    this.questionsDialogRef.close(this._getSelectedQuestions);

    this._snackBar.openFromComponent(SnackbarMsgComponent, {
      duration: 3000,
      data: {
        boldMsg: "Changes have been saved.",
        detailsMsg: "",
      } as SnackbarData,
      panelClass: ["snackbar-success"],
    });
  }

  openConfirmationDialog() {
    const confirmationRef = this.confirmationDialog.open(DialogConfirmCloseComponent, { width: "600px" });

    confirmationRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.questionsDialogRef.close();
      } else {
        // User cancelled the dialog closing
      }
    });
  }
}
