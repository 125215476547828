<section id="items-container" class="row">
  <div class="col-md-3 item-component" *ngFor="let item of items; let i = index">
    <div [ngClass]="selectedItems.includes(item.id.toString()) ? 'item-checkbox checked' : 'item-checkbox no-checked'">
      <div class="checkbox-row">
        <div class="regular-14 custom-control custom-checkbox">
          <input
            type="radio"
            id="customCheck{{ i }}"
            name="checkedItems"
            class="primary custom-control-input"
            [value]="item.id"
            (change)="eventCheck($event.target.value)"
          /><span class="custom-control-indicator"></span
          ><label class="custom-control-label" for="customCheck{{ i }}"></label>
        </div>
      </div>
      <div class="image-row">
        <img [style]="customStyleObject" src="{{ item.image.public_url }}" />
      </div>
      <div>
        <p class="subtitle-2">{{ item.description }}</p>
      </div>
    </div>
  </div>
</section>
