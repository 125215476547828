import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";

import { OpenRoutingModule } from "./open-routing.module";
import { OpenComponent } from "./open.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { HowItWorksComponent } from "./how-it-works/how-it-works.component";
import { LoginComponent } from "./login/login.component";
import { MainComponent } from "./main/main.component";
import { MembershipComponent } from "./membership/membership.component";
import { RewardsComponent } from "./rewards/rewards.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { AdminNotAuthorizedComponent } from "./admin-not-authorized/admin-not-authorized.component";
import { UserNotAuthorizedComponent } from "./user-not-authorized/user-not-authorized.component";
import { OpenCommonService } from "./open-common.service";
import { SignupComponent } from "./signup/signup.component";
import { ComponentsModule } from "../shared/components/components.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { TermsConditionsComponent } from "./terms-conditions/terms-conditions.component";
import { FaqComponent } from "./faq/faq.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { TheTeamComponent } from "./the-team/the-team.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { ConfirmationMessageComponent } from "./signup/confirmation-message/confirmation-message.component";
import { PressComponent } from "./press/press.component";
import { ExpirationModalComponent } from "./login/expiration-modal/expiration-modal.component";
import { HeroTextComponent } from "./hero-text/hero-text.component";

@NgModule({
  imports: [
    CommonModule,
    OpenRoutingModule,
    RouterModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  declarations: [
    OpenComponent,
    HeaderComponent,
    FooterComponent,
    HowItWorksComponent,
    HeroTextComponent,
    LoginComponent,
    MainComponent,
    MembershipComponent,
    RewardsComponent,
    PageNotFoundComponent,
    AdminNotAuthorizedComponent,
    UserNotAuthorizedComponent,
    SignupComponent,
    TermsConditionsComponent,
    FaqComponent,
    PrivacyPolicyComponent,
    TheTeamComponent,
    ForgotPasswordComponent,
    ContactUsComponent,
    ChangePasswordComponent,
    ConfirmationMessageComponent,
    PressComponent,
    ExpirationModalComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [OpenCommonService],
})
export class OpenModule {}
