import { Component, OnInit } from "@angular/core";
import { OpenCommonService } from "../open-common.service";

@Component({
  selector: "app-how-it-works",
  templateUrl: "./how-it-works.component.html",
  styleUrls: ["./how-it-works.component.scss"],
})

// Route to this component was removed 01/2024
export class HowItWorksComponent implements OnInit {
  items: { title: string; subtitle: string; img: string }[] = [
    {
      title: "ebook",
      subtitle: "how to encourage your career?",
      img: "Learn1.png",
    },
    {
      title: "whitepaper",
      subtitle: "Our Approach to Transformation",
      img: "Learn2.png",
    },
    {
      title: "webinar",
      subtitle: "Career advice coaching",
      img: "Learn3.png",
    },
  ];

  constructor(public common: OpenCommonService) {}

  ngOnInit(): void {}
}
