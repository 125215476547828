import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  BouloPoll,
  BouloMessageType,
  MessageNotificationRequest,
  SendBirdService,
  SendMessageRequest,
  SendMessageResponse,
  CreatePollRequest,
  InterviewConfirmationRequest,
  SendTextMessageRequest,
} from "src/app/services/send-bird.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { EmployerOnboardingService } from "../employer-root/employer-root.service";
import { ScheduleInterviewDialogComponent } from "../messaging/inbox/schedule-interview/schedule-interview-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { Poll, PollOption, PollStatus } from "@sendbird/chat/poll";
import { PollDialogComponent } from "../messaging/polls/poll-dialog.component";
import { MessageDisplayText } from "../messaging/messageDisplayText.constants";
import { OnboardingRootService } from "../../onboarding/onboarding-root/onboarding-root.service";

export interface iMessageReciever {
  name: string;
  email: string;
  optInToEmail: boolean;
  phone: string;
}

export interface iMessageSender {
  name: string;
  email: string;
  optInToEmail: boolean;
  phone: string;
}

export interface BouloMessage {
  sender: string;
  text: string;
  timestamp: number;
  url: string;
  type: BouloMessageType;
  fileType: string;
  poll: BouloPoll;
}

export interface BouloMessageOrderByDate {
  date: string;
  time: string;
  message: BouloMessage;
}

export interface SentMessageResponse {
  url: string | null;
  fileType: string;
  fileName: string;
}

@Component({
  selector: "app-message-box",
  templateUrl: "./message-box.component.html",
  styleUrls: ["./message-box.component.scss"],
})
export class MessageBoxComponent implements OnChanges {
  @Input() sender: iMessageSender;
  @Input() receiver: iMessageReciever;
  @Input() messages: BouloMessage[] = [];
  @Input() currentChannel: string;
  @Input() isEmployer: boolean = false;
  @ViewChild("fileInput") fileInput: ElementRef;
  @ViewChild("messageList", { read: ElementRef })
  private messageList: ElementRef;
  orderedMsgs = [];

  public newMessage: string = "";
  sendMsgGrp: FormGroup;
  newMsgCharMax: number = 2000;
  selectedFile: File | null = null;
  isLoading: boolean = false;
  interviewDate: string;
  private readonly cancelledPollDate = "9999-12-31T00:00";

  constructor(
    private messageService: SendBirdService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    public onboardingService: EmployerOnboardingService,
    private dialog: MatDialog,
    private _service: OnboardingRootService
  ) {
    this.setFormDefaults();
  }

  ngAfterViewChecked(): void {
    //this.scrollToBottom();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.messageDetails && changes.messageDetails.currentValue) {
      this.receiver = changes.messageDetails.currentValue.receiver;
    }
    if (changes.messages) {
      this.orderMessagesByDate();
      this.cdr.detectChanges();
      this.scrollToBottom();
    }
  }

  displayMessageHeader(currentIndex, list) {
    let previousIndex = currentIndex - 1;
    if (currentIndex === 0) {
      return true;
    }
    let currentSender = list[currentIndex].message.sender;
    let previousSender = list[previousIndex].message.sender;

    let currentDate = list[currentIndex].date;
    let previousDate = list[previousIndex].date;

    let currentTime = list[currentIndex].time;
    let previousTime = list[previousIndex].time;

    if (currentSender === previousSender && currentDate === previousDate && currentTime === previousTime) {
      return false;
    }
    return true;
  }

  orderMessagesByDate() {
    if (!this.messages) return;
    let obj = {};
    for (let item of this.messages) {
      const dateObject = new Date(item.timestamp);
      const formattedDate = dateObject.toDateString();
      const formattedTime = dateObject.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
      let msg: BouloMessageOrderByDate = { message: item, date: formattedDate, time: formattedTime };
      if (obj.hasOwnProperty(formattedDate)) {
        obj[formattedDate].push(msg);
      } else {
        obj[formattedDate] = [msg];
      }
    }

    this.orderedMsgs = Object.entries(obj).sort((a: any, b: any) => {
      let date1 = new Date(a[0]);
      let date2 = new Date(b[0]);
      return date1.getTime() - date2.getTime();
    });
  }

  showNoInterviewTimesSelected(message: BouloMessage): boolean {
    return (
      message.type === BouloMessageType.PollMessage &&
      message.poll.status !== PollStatus.CLOSED &&
      this._isSender(message.sender)
    );
  }

  showPollMessage(message: BouloMessage): boolean {
    return (
      message.type === BouloMessageType.PollMessage &&
      message.poll.status !== PollStatus.CLOSED &&
      !this._isSender(message.sender)
    );
  }

  isImageAttachment(message: BouloMessage): boolean {
    return message.type === BouloMessageType.FileMessage && message.fileType.includes("image");
  }

  isFileAttachment(message: BouloMessage): boolean {
    return message.type === BouloMessageType.FileMessage && !message.fileType.includes("image");
  }

  isUserMessage(message: BouloMessage): boolean {
    return message.type === BouloMessageType.UserMessage;
  }

  pollIsClosed(message: BouloMessage) {
    return message.type === BouloMessageType.PollMessage && message.poll.status === PollStatus.CLOSED;
  }

  getInterviewDescription(message: BouloMessage): string {
    return `Interview for ${this.sender.name} scheduled with ${this.receiver.name} for ${message.poll.selectedJob ?? ""}.`;
  }

  getInterviewTitle(message: BouloMessage): string {
    return `Inteview Confirmation - ${this.sender.name} : ${this.receiver.name}. ${message.poll.selectedJob ?? ""}`;
  }

  private scrollToBottom(): void {
    try {
      this.messageList.nativeElement.scrollTop = this.messageList.nativeElement.scrollHeight;
      this.cdr.detectChanges();
    } catch (err) {}
  }

  setFormDefaults() {
    this.sendMsgGrp = this.formBuilder.group({
      newMsg: new FormControl("", [Validators.maxLength(this.newMsgCharMax), Validators.minLength(1)]),
    });
  }

  get _characterCount(): number {
    const msg = this.sendMsgGrp.get("newMsg").value;
    return msg?.length > 0 ? msg.length : 0;
  }

  _isSender(sender: string): boolean {
    return sender === this.sender.name;
  }

  _msgHeaderPosition(messageItem: BouloMessageOrderByDate): String {
    return messageItem.message.sender === this.sender.name ? "sender-header-position" : "receiver-header-position";
  }

  _msgDesign(messageItem: BouloMessageOrderByDate): String {
    return messageItem.message.sender === this.sender.name ? "sender-tip sender-base" : "receiver-tip receiver-base";
  }

  msgFileAttachmentDesign(messageItem: BouloMessageOrderByDate): String {
    return messageItem.message.sender === this.sender.name ? "download-attachment-sender" : "download-attachment-receiver";
  }

  get _isMsgValid(): boolean {
    return (
      (this.sendMsgGrp.valid && this.sendMsgGrp.get("newMsg").valid && this.sendMsgGrp.get("newMsg").value?.length > 0) ||
      this.selectedFile !== null
    );
  }

  clearNewMsg(): void {
    this.sendMsgGrp.get("newMsg").setValue("");
  }

  onFileSelected(event: Event): void {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      this.selectedFile = fileInput.files[0];
      if (
        ![
          "image/png",
          "image/jpeg",
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "text/plain",
        ].includes(this.selectedFile.type)
      ) {
        this.selectedFile = null;
        this.onboardingService.generalMsg(MessageDisplayText.Error.InvalidFileType, MessageDisplayText.Error.SelectMessage);
      }
    }
  }

  removeFile(): void {
    this.selectedFile = null;
    this.fileInput.nativeElement.value = null;
  }

  resetMessage(): void {
    this.sendMsgGrp.get("newMsg").setValue("");
    this.selectedFile = null;
    this.scrollToBottom();
    this.cdr.detectChanges();
  }

  addMessage(msg: BouloMessage) {
    const dateObject = new Date();
    const formattedDate = dateObject.toDateString();
    const formattedTime = dateObject.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    const msgObj = { message: msg, date: formattedDate, time: formattedTime };
    if (this.orderedMsgs.length > 0 && this.orderedMsgs[this.orderedMsgs.length - 1][0] === formattedDate) {
      this.orderedMsgs[this.orderedMsgs.length - 1][1].push(msgObj);
    } else {
      const newObj = [formattedDate, [msgObj]];
      this.orderedMsgs.push(newObj);
    }
  }

  async sendMessageToFrontEnd(textMessage: string, file: SentMessageResponse): Promise<void> {
    const date = new Date();
    if (textMessage && textMessage !== "") {
      const msg = {
        sender: this.sender.name,
        text: textMessage,
        timestamp: date.getTime(),
        url: "",
        type: BouloMessageType.UserMessage,
        fileType: "",
        poll: null,
      };
      this.addMessage(msg);
    }

    if (file && file.url) {
      const msg = {
        sender: this.sender.name,
        text: file.fileName,
        timestamp: date.getTime(),
        url: file.url,
        type: BouloMessageType.FileMessage,
        fileType: file.fileType,
        poll: null,
      };
      this.addMessage(msg);
      this.isLoading = false;
      this.fileInput.nativeElement.value = null;
    }
  }

  async sendEmailNotification(textMessage: string): Promise<void> {
    const messageNotificationRequest: MessageNotificationRequest = {
      email: this.receiver.email,
      messageText: textMessage,
      senderName: this.sender.name,
    };

    if (this.receiver.optInToEmail) {
      await this.messageService.sendEmailNotification(messageNotificationRequest);
    }
  }

  async openPollModal(): Promise<void> {
    const employerJobs = this.onboardingService.bullhornData.jobs.map((job) => job.title);

    const dialogRef = this.dialog.open(ScheduleInterviewDialogComponent, {
      width: "400px",
      data: { employerJobs: employerJobs },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result?.timeSlots !== undefined) {
        const createPollRequest: CreatePollRequest = {
          pollOptions: result.timeSlots,
          userId: this.sender.email,
          selectedJob: result.selectedJob,
        };

        const poll: Poll = await this.messageService.createPoll(createPollRequest);

        const date = new Date();
        const messageRequest: SendMessageRequest = {
          channelUrl: this.currentChannel,
          messageText: MessageDisplayText.Interview.ProposedTimes,
          file: null,
          messageType: BouloMessageType.PollMessage,
          pollId: poll?.id,
        };

        await this.messageService.sendMessage(messageRequest);

        const msg = {
          sender: this.sender.name,
          text: MessageDisplayText.Interview.TimesSent,
          timestamp: date.getTime(),
          url: "",
          type: BouloMessageType.UserMessage,
          fileType: "",
          poll: null,
        };
        this.addMessage(msg);
        this.scrollToBottom();
        this.cdr.detectChanges();
      }
    });
  }

  async openPollDialog(poll: BouloPoll): Promise<void> {
    const dialogRef = this.dialog.open(PollDialogComponent, {
      width: "500px",
      data: { poll: poll },
    });

    dialogRef.afterClosed().subscribe(async (result: PollOption) => {
      if (result) {
        await this.generatePoll(poll, result);
      }
    });
  }

  async generatePoll(poll: BouloPoll, result: PollOption) {
    if (result.text === this.cancelledPollDate) {
      await this.messageService.closePoll(result.pollId);
      poll.status = PollStatus.CLOSED;
      this.cdr.detectChanges();
    } else {
      await this.messageService.voteForPollOption(result.pollId, result.id, this.sender.email);
      await this.messageService.closePoll(result.pollId);

      this.interviewDate = result.text;
      poll.status = PollStatus.CLOSED;

      if (this.receiver.optInToEmail) {
        const request: InterviewConfirmationRequest = {
          email: this.receiver.email,
          interviewDate: this.interviewDate,
          companyName: this.receiver.name,
          roleName: poll.selectedJob,
        };

        await this.messageService.sendInterviewConfirmation(request);
      }

      const senderRequest: InterviewConfirmationRequest = {
        email: this.sender.email,
        interviewDate: this.interviewDate,
        companyName: this.receiver.name,
        roleName: poll.selectedJob,
      };

      await this.messageService.sendInterviewConfirmation(senderRequest);

      this.cdr.detectChanges();
    }
  }

  getChosenDate(options: PollOption[]): string {
    const chosenOption = options.find((option) => option.voteCount > 0);

    if (chosenOption?.text === this.cancelledPollDate) {
      return MessageDisplayText.Interview.NoneOfTheAbove;
    }
    return chosenOption ? chosenOption.text : this.interviewDate;
  }

  async sendInitalOptOutMsg(isFirstMsg: boolean) {
    if (isFirstMsg) {
      const textRequest: SendTextMessageRequest = {
        To: this.receiver.phone,
        Body: `If you do not want to receive notifications directly from Employers text REMOVE`,
      };
      await this.messageService.sendTextMessageNotification(textRequest);
    }
  }

  async sendMessage(): Promise<void> {
    let isFirstMsg = this.messages && this.messages?.length === 0 ? true : false;
    this.isLoading = true;

    if (this._isMsgValid) {
      const date = new Date();
      const textMessage = this.sendMsgGrp.get("newMsg").value;
      const messageRequest: SendMessageRequest = {
        channelUrl: this.currentChannel,
        messageText: textMessage,
        file: this.selectedFile,
        messageType: BouloMessageType.UserMessage,
        pollId: null,
      };

      const file: SendMessageResponse = await this.messageService.sendMessage(messageRequest);

      if (textMessage && textMessage !== "") {
        this.messages.push({
          sender: this.sender.name,
          text: textMessage,
          timestamp: date.getTime(),
          url: "",
          type: BouloMessageType.UserMessage,
          fileType: "",
          poll: null,
        });
      }

      if (file && file.url) {
        this.messages.push({
          sender: this.sender.name,
          text: file.fileName,
          timestamp: date.getTime(),
          url: file.url,
          type: BouloMessageType.FileMessage,
          fileType: file.fileType,
          poll: null,
        });
      }

      if (this.receiver.optInToEmail) {
        const msgNotificationReq: MessageNotificationRequest = {
          email: this.receiver.email,
          messageText: messageRequest.messageText,
          senderName: this.sender.name,
        };
        await this.messageService.sendEmailNotification(msgNotificationReq);
      }

      // Employers will not receive texts because they don't have a Contact Number yet
      const optInStatus = await this._service.getCandidateOptInStatus(this.receiver.email);
      if (this.receiver.phone !== "" && optInStatus === 1) {
        const textRequest: SendTextMessageRequest = {
          To: this.receiver.phone,
          Body: `Hello from Boulo! You've received a message from ${this.sender.name} regarding an open position. To view the message log into your Boulo dashboard (https://app.boulosolutions.com/login). Boulo: Showcase your skills. Find your dream job. `
        };
        await this.messageService.sendTextMessageNotification(textRequest);
        setTimeout(async () => {
          await this.sendInitalOptOutMsg(isFirstMsg);
        }, 4000);
      }

      this.sendMessageToFrontEnd(textMessage, file);

      this.sendMsgGrp.get("newMsg").setValue("");
      this.selectedFile = null;
      this.cdr.detectChanges();
      this.scrollToBottom();
      this.isLoading = false;
    }
  }
}
