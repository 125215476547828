import { Injectable } from "@angular/core";
import { BullhornCompanyModel } from "../../../shared/models/company.model";
import { SecurityService } from "src/app/services/security.service";
import { UserModel } from "src/app/shared/components/user.module";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackbarData, SnackbarMsgComponent } from "src/app/shared/components/snackbar-msg/snackbar-msg.component";
import {
  iCandidateFindModel,
  iApplicantListModel,
  iBullhornJobSubmission,
  iApplicantModel,
} from "src/app/shared/models/applicant-candidate.model";
import { BehaviorSubject, interval, Subscription } from "rxjs";
import { BullhornApplicantModel, BullhornModel } from "src/app/shared/models/bullhorn.model";
import { LoggerService as Logger } from "src/app/shared/services/logger.service";
import { eLabels } from "../dashboard/dialog-employer-job-edit/dialog-employer-job-edit.component";
import {
  iApplicantCounts,
  iCompensationRange,
  iPagedApplicantResponse,
  iStatusDropdown,
  iStatusMap,
  iTabDetails,
} from "./employer.models";
import {
  eApplicantListType,
  eApplicantTabs,
  eCompensationTypes,
  eEmployerContactTags,
  eEmployerTags,
  eFindCandidateTabs,
  eResponseMsg,
} from "src/app/shared/enums/employer.enums";
import { iEmployerJobModel } from "src/app/shared/models/job.model";
import { NoteModel } from "src/app/shared/models/note.model";
import { BouloFileContent } from "src/app/shared/models/BouloFileContent";
import { UserService } from "../../user-root/user-root.service";
import { eJobCardStatus } from "../dashboard/job-posting-card/job-posting-card.component";
import { eSuperAdmin } from "src/app/shared/enums/user.enums";
import { PlacementRecord } from "src/app/shared/models/placement-record.model";
import { cloneDeep } from "lodash";
import {
  FilterOption,
  FilterParameter,
  JobOverview,
  advSearchParam,
  iFieldMultiSelect,
  iFieldMultiSelectOutPut,
} from "src/app/shared/models/filter.model";
import {
  eCompensationFilters,
  eEmploymentTypeFilters,
  eExperienceFilters,
  eFilterCategoryTitle,
  eJobSkillsCategoryFilters,
  eLocationFilters,
} from "../applicants/list/search-candidates-pane/search-candidates-pane.component";
import { CandidateJobNotification } from "src/app/shared/models/candidate-job-notification.model";
import qs from "qs";

const API: string = environment.api;
const proxyApi: string = environment.proxyApi;

@Injectable({
  providedIn: "root",
})
export class EmployerOnboardingService {
  //#region Setup
  user: UserModel;
  bullhornData: BullhornCompanyModel = null;
  private subUnsavedChanges: Subscription | undefined;
  isMobileScreen: boolean;
  isDirty: boolean = false;
  isLoaded: boolean = false;
  isActive: boolean = false;
  isFirstLogin: boolean = false;
  hasPaid: boolean = false; //ACSTODO: use Dustin's call to check if they have paid already
  applicantRootTab: number = 0; //ViewApplicant or FindCandidate
  applicantStatusChildTab: number = 0; //View Applicant by Status Menu Tabs
  applicantSearchChildTab: number = 0; //Find/Saved/FollowedBy Candidate Menu Tabs
  compensationRangeData: iCompensationRange[];
  allFilteredApplicants: iApplicantListModel[] = [];
  isFreeTrialStatus: boolean = false;
  candidateFilterUrl: string = "";
  selectedCandidateFiltersOutput: iFieldMultiSelectOutPut = {
    selectedFields: [],
    isPrioritized: false,
    allFields: [],
    selectedAutoPopulateField: undefined,
  };

  advSearchParam: advSearchParam = {
    keywords: null,
    jobOverview: null,
    selectedFilters: [],
    start: 0,
    count: 20,
  };

  searchForCandidateBtnClicked: boolean = false;

  //#region Candidate Filters Setup:
  skillsFocus = {
    Administrative: "Administrative Skills Focus",
    Business: "Business Skills Focus",
    Creative: "Creative Skills Focus",
    Financial: "Financial Skills Focus",
    Legal: "Legal Skills Focus",
    Marketing: "Marketing Skills Focus",
    Operations: "Operations Skills Focus",
    People: "People Skills Focus",
    "Project Management": "Project Management Skills Focus",
    Sales: "Sales Skills Focus",
    Technical: "Technical Skills Focus",
  };

  employmentType = {
    fulltime: ["Full-Time", "Full-Time (40 Hours a Week)"],
    parttime: ["Part-Time", "Part-Time (10-30 Hours a Week)"],
    contract: ["Contract/Project", "Contract/Project Based Work"],
  };

  workingHours = {
    "Standard Hours": ["Standard Hours", "standard"],
    "School-Friendly Hours": ["School Friendly Hours", "school"],
    "Flexible Hours": ["Flexible Hours", "flexible"],
    "Fixed Schedule": ["Prefers To Set Own Time", "own time"],
  };

  salaryRange = [
    { label: "$25,000-$50,000", value: "1" },
    { label: "$50,000-$75,000", value: "2" },
    { label: "$75,000-$100,000", value: "3" },
    { label: "$100,000-$150,000", value: "4" },
    { label: "$150,000-$200,000", value: "5" },
    { label: "$200,000+", value: "6" },
  ];

  hourlyRange = [
    { label: "$15-$25", value: "1" },
    { label: "$26-$35", value: "2" },
    { label: "$36-$50", value: "3" },
    { label: "$51-$75", value: "4" },
    { label: "$76-$100", value: "5" },
    { label: "100+", value: "6" },
  ];

  filterLabelNames = {
    State: "state",
    "Job Skills Categories": "category",
    "Category Skills": "categorySkills",
  };

  //#endregion Candidate Filters Setup

  //#region Applicant & Candidate Setup
  applicantTabs: iTabDetails[] = [
    { label: "All", index: eApplicantTabs.All, value: "All" },
    { label: eLabels.Active, index: eApplicantTabs.Active, value: eLabels.Active },
    { label: eLabels.Shortlisted, index: eApplicantTabs.Shortlisted, value: eLabels.Shortlisted },
    { label: eLabels.Interviewing, index: eApplicantTabs.Interviewing, value: eLabels.Interviewing },
    { label: eLabels.Hired, index: eApplicantTabs.Hired, value: eLabels.Hired },
    { label: "Saved", index: eApplicantTabs.Saved, value: "Saved" },
  ];
  candidateTabs: iTabDetails[] = [
    { label: "Search", index: eFindCandidateTabs.Search, value: "Search" },
    { label: "Saved", index: eFindCandidateTabs.Saved, value: "Saved" },
    { label: "Followed By", index: eFindCandidateTabs.Followed, value: "Followed" },
  ];
  applicantCounts: iApplicantCounts = { active: 0, shortlisted: 0, interviewing: 0, hired: 0, followed: 0 };
  applicantStatusMap: iStatusMap[] = [
    {
      parentStatus: eLabels.Active,
      childStatus: ["Web Response", "New Submission", "Internally Submitted", "Client Submission", "New Lead"],
    },
    {
      parentStatus: eLabels.Shortlisted,
      childStatus: ["Qualified Candidate"],
    },
    {
      parentStatus: eLabels.Interviewing,
      childStatus: [
        "Second Interview",
        "Third Interview",
        "Final Interview",
        "Interview Scheduled",
        "Internally Rejected",
        "Offer Extended",
      ],
    },
    {
      parentStatus: eLabels.Hired,
      childStatus: ["Placed"],
    },
    {
      parentStatus: eLabels.Rejected, //ACSTODO: this the correct name/term?
      childStatus: ["Internally Rejected", "Client Rejected", "Candidate Not Interested"],
    },
  ];
  applicantChildStatusMap: Map<string, string> = new Map<string, string>();
  applicantStatusDropdown: iStatusDropdown[] = [
    {
      parentStatus: { status: eLabels.Active, label: eLabels.Active, display: true },
      childStatuses: [
        { status: "New Submission", label: "New Submission", display: true },
        // { status: "New Lead", label: "New Lead", display: true }], //left in for testing
      ],
    },
    {
      parentStatus: { status: eLabels.Shortlisted, label: eLabels.Shortlisted, display: true },
      childStatuses: [{ status: "Qualified Candidate", label: "Qualified Candidate", display: true }],
    },
    {
      parentStatus: { status: eLabels.Interviewing, label: eLabels.Interviewing, display: true },
      childStatuses: [
        { status: "Interview Scheduled", label: "Interview Scheduled", display: true },
        { status: "Second Interview", label: "Second Interview", display: true },
        { status: "Third Interview", label: "Third Interview", display: true },
        { status: "Final Interview", label: "Final Interview", display: true },
      ],
    },
    {
      parentStatus: { status: eLabels.Hired, label: eLabels.Hired, display: true },
      childStatuses: [
        { status: "Offer Extended", label: "Offer Extended", display: true },
        { status: "Placed", label: "Offer Accepted", display: true },
      ],
    },
    {
      parentStatus: { status: "Declined", label: "Declined", display: true },
      childStatuses: [
        { status: "Internally Rejected", label: "Reject Applicant", display: true },
        { status: "Client Rejected", label: "Offer Rejected", display: true },
      ],
    },
  ];
  //cachedApplicantDetails: Map<string, iApplicantDetailedModel> = new Map<string, iApplicantDetailedModel>(); //TODO: Use this version when we have applicantdetail call
  cachedApplicantDetails: Map<string, BullhornModel> = new Map<string, BullhornModel>(); //Note: this doesn't actually cache the data, but a quick way to prevent extra data pulling & quick loads
  cachedApplicantJobDetails: Map<string, iBullhornJobSubmission[]> = new Map<string, iBullhornJobSubmission[]>(); //Note: this doesn't actually cache the data, but a quick way to prevent extra data pulling & quick loads
  cachedApplicantList: Map<eApplicantListType, iApplicantListModel[]> = new Map<eApplicantListType, iApplicantListModel[]>(); //Note: this doesn't actually cache the data, but a quick way to prevent extra data pulling & quick loads
  sideDrawerEvent: BehaviorSubject<string> = new BehaviorSubject<string>("initialValue");
  employerRootSideNavContent: BehaviorSubject<string> = new BehaviorSubject<string>("initialValue");
  nextPage = "next-page";
  //#endregion Applicant & Candidate Setup

  constructor(
    private securityService: SecurityService,
    private http: HttpClient,
    private router: Router,
    private _snackBar: MatSnackBar,
    private _userSrvc: UserService // private _jobSearchService: SearchJobsService
  ) {
    this.user = securityService.UserRef;

    this.compensationRangeData = [
      { type: eCompensationTypes.Project, min: 100, max: 10000, step: 10 },
      { type: eCompensationTypes.Hourly, min: 5, max: 275, step: 1 },
      { type: eCompensationTypes.Salary, min: 10000, max: 300000, step: 1000 },
      { type: eCompensationTypes.Unset, min: 1, max: 200, step: 1 },
    ];
  }

  // Define custom headers
  corsHeaders = new HttpHeaders({
    // 'Access-Control-Allow-Origin': 'http://localhost:4200', // Set your desired origin
    "Access-Control-Allow-Credentials": "true", // Add other custom headers if needed
  });
  requestOptions = { headers: this.corsHeaders };
  //#endregion Setup

  //#region Tags
  public get needsOnboarding(): boolean {
    return this.bullhornData.clientContact?.tags?.indexOf(eEmployerContactTags.Contact_Onboarding_Complete) < 0;
  }
  public get missingPayment(): boolean {
    return this.bullhornData.employerTags?.indexOf(eEmployerTags.Paid_Member) < 0;
  }
  public get _getActiveJobs() {
    return this.bullhornData.jobs.filter((x) => x.status === eJobCardStatus.Active);
  }

  public get _getAllJobs() {
    return this.bullhornData.jobs;
  }

  public containsHtml(string): boolean {
    const htmlRegex = /<[^>]*>/;
    return htmlRegex.test(string);
  }

  public async setPaid(): Promise<void> {
    this.hasPaid = true; //obsolete? Remove or update with stripe payment call to backend
    if (!this.bullhornData.employerTags) {
      this.bullhornData.employerTags = [];
    }
    if (this.missingPayment) {
      this.bullhornData.employerTags.unshift(eEmployerTags.Paid_Member);
      Logger.debug(`Added boulo tag to employer company: ${eEmployerTags.Paid_Member}`);
      await this.upsertCompany();
    }
  }

  public async addFreeTrialTag(trialTag: eEmployerTags) {
    if (!this.bullhornData.employerTags.includes(trialTag)) {
      this.bullhornData.employerTags.unshift(trialTag);
      Logger.debug(`Added boulo tag to employer company: ${trialTag}`);
      await this.upsertCompany();
    }
  }

  public removeHTMLTags(text: string) {
    return text?.replace(/<[^>]*>/g, "");
  }

  public setOnboardingComplete(): void {
    var missingContactTag =
      this.bullhornData.clientContact?.tags?.indexOf(eEmployerContactTags.Contact_Onboarding_Complete) < 0;
    if (missingContactTag) {
      if (!this.bullhornData.clientContact?.tags) {
        this.bullhornData.clientContact.tags = [];
      }
      this.bullhornData.clientContact.tags.unshift(eEmployerContactTags.Contact_Onboarding_Complete);
    }

    var missingEmployerTag = this.bullhornData.employerTags?.indexOf(eEmployerTags.Company_Onboarding_Complete) < 0;
    if (missingEmployerTag) {
      if (!this.bullhornData.employerTags) {
        this.bullhornData.employerTags = [];
      }
      this.bullhornData.employerTags.unshift(eEmployerTags.Company_Onboarding_Complete);
    }
  }

  async upsertEmployerContactTag(tag: eEmployerContactTags): Promise<void> {
    var missingTag = this.bullhornData.clientContact?.tags?.indexOf(tag) < 0;

    if (missingTag) {
      this.bullhornData.clientContact.tags.push(tag);
      await this.updateEmployerContactTagsByContactId(
        this.bullhornData.clientContact.id,
        this.bullhornData.clientContact.tags
      );
      Logger.debug(`Added boulo tag to employer contact: ${tag}`);
    } else {
      Logger.debug(`Skipped adding boulo tag to employer contact ${tag} since it already exists`);
    }
  }

  async deleteEmployerContactTag(tag: eEmployerContactTags): Promise<void> {
    const index = this.bullhornData.clientContact.tags.findIndex((t) => t === tag);
    if (index > -1) {
      this.bullhornData.clientContact.tags.splice(index, 1);
      await this.updateEmployerContactTagsByContactId(
        this.bullhornData.clientContact.id,
        this.bullhornData.clientContact.tags
      );
      Logger.debug(`Removed boulo tag to employer contact: ${tag}`);
    } else {
      Logger.debug(`Skipped removing boulo tag ${tag} from employer contact since it didn't exist.`);
    }
  }
  //#endregion Tags

  //#region initialize/clear
  public async initialize() {
    this.user = this.securityService.UserRef;

    if (this.user) {
      const adminStatus = await this.getUserAdminStatus(this.user.email);
      const email =
        adminStatus === eSuperAdmin.Admin && localStorage.getItem(eSuperAdmin.ImitatedEmailKey)
          ? localStorage.getItem(eSuperAdmin.ImitatedEmailKey)
          : this.user.email;
      await this.getEmployerBullhornDataByEmail(email)
        .catch(() => {
          this.isFirstLogin = true;
          this.defaultBullhornData();
        })
        .finally(async () => {
          this.isLoaded = true;
          this.isActive = !!this.bullhornData?.id;
          this.isDirty = false;
          // this.bullhornData.id = encodeURIComponent(this.bullhornData.id);
          // this.bullhornData.clientContact.id = encodeURIComponent(this.bullhornData.clientContact.id);
        });
    } else {
      Logger.error("Unable to retrieve user.");
      this._snackBar.openFromComponent(SnackbarMsgComponent, {
        duration: 5000,
        data: {
          boldMsg: "Issue loading page.",
          detailsMsg: "If this problem persits, try logging out and back in. Contact support if it's still happening.",
        } as SnackbarData,
      });
    }
    for (const parentObj of this.applicantStatusMap) {
      for (const childObj of parentObj.childStatus) {
        this.applicantChildStatusMap.set(childObj, parentObj.parentStatus);
      }
    }
  }

  public async navigateToEmployerDefaultPage(): Promise<void> {
    let loadPage = "";

    if (this.missingPayment) {
      loadPage = "user/login/membership";
      Logger.debug(`Missing Payment: add tag in Bullhorn to company to skip for testing`);
    } else if (this.needsOnboarding) {
      loadPage = "../user/employer/onboarding/basics";
      Logger.debug(`Onboarding Incomplete: add tag in Bullhorn to Contact to skip for testing`);
    } else {
      loadPage = "user/employer/dashboard";
    }

    await this.router.navigate([loadPage]);
  }

  verifyPaymentDetails(): boolean {
    //TODO: Make call to stripe to make sure membership is valid

    return false;
  }

  async getStripeSubscription(): Promise<iStripeSubscriptionResult[]> {
    try {
      return await this.http
        .get<iStripeSubscriptionResult[]>(
          environment.proxyApi + `subscriptions?email=${this.bullhornData?.clientContact?.email}`
        )
        .toPromise();
    } catch (ex) {
      Logger.error(ex);
      return null; // or any default value that suits your needs
    }
  }

  async cancelSubscription(action: string) {
    return this.http
      .delete<any>(
        `${environment.proxyApi}subscriptions-delete?email=${this.bullhornData?.clientContact?.email}&action=${action}`
      )
      .toPromise();
  }

  async stopImitatingUser() {
    localStorage.removeItem(eSuperAdmin.ImitatedEmailKey);
    this.router.navigate(["user/employer/dashboard"]);
    setTimeout(() => {
      location.reload();
    }, 800);
  }

  defaultBullhornData = () => {
    this.bullhornData = {
      id: "0",
      name: "",
      clientContact: {
        id: this.user.id.toString(),
        email: this.user.email,
        firstName: this.user.first_name,
        lastName: this.user.last_name,
        name: `${this.user.first_name} ${this.user.last_name}`,
        tags: [],
        adminStatus: "",
        //include data for 'this.user.role'?
      },
      employeeCount: 0,
      zip: "",
      pitch: "",
      source: "",
      industry: [],
      whyHere: "",
      benefits: "",
      benefitsOther: "",
      jobs: [],
      formattedJobs: [],
      personality: {
        companyURL: "",
        linkedInURL: "",
        twitterURL: "",
        facebookURL: "",
        instagramURL: "",
      },
      savedCandidateIds: [],
      employerTags: [],
      placements: [],
      stripeSubscriptionId: "",
    };
  };

  addJobDefault(): iEmployerJobModel {
    return {
      id: "0",
      clientCorporation: {
        id: this.bullhornData?.id?.toString(),
        name: this.bullhornData?.name,
      },
      title: "",
      // address: {city: "", state: "", zip: ""},
      hoursPerWeek: undefined,
      publicDescription: "",
      // description: "",
      employmentType: "", // (i.e. roleType)
      // workingHours: [], // (i.e. workingHoursChips) //formerly flexibility
      skills: { data: [] },
      datePosted: undefined, //formerly customDate1
      // datePosted: "",
      dateAdded: undefined,
      hourlyRate: 0,
      exactRate: "",
      compensationLow: 0,
      compensationHigh: undefined,
      benefits: [],
      // onSite: "",
      onsite: "",
      isOnSiteRequired: false,
      address: undefined,
      appCreatedJob: true,
      compensationType: eCompensationTypes.Unset,
      arrangementType: "",
      arrangementSplit: "",
      isExperienceRequired: 0,
      yearsRequired: undefined,
      isEducationRequired: 0,
      educationDegree: "",
      certifications: "",
      combinedSkills: {
        categorySpecificSkills: {},
        basicSkills: [],
        skillCategories: [],
        customSkills: [],
      },
      locationZip: "",
      description: "",
      screening: {
        questions: [{ category: "", title: "", format: "", isActive: false, question: "" }],
      },
      submissions: [],
      status: eJobCardStatus.Draft,
      workingLocationZip: "",
      flexibility: [], //flexibility //formerly customText1
      dutiesList: null,
      certsList: null,
      idealFitList: null,
      saved: null,
      applied: null,
      // customSkills: [],
      // workingHoursList: [],
      numViews: null,
      numSubmissions: null,
      newApplicant: null,
      submissionIDs: [],
    };
  }
  //#endregion initialize/clear

  //#region API Calls
  //TODO: add patch Company
  //TODO: add upsert Job
  //TODO: add patch Job
  async upsertCompany(): Promise<string> {
    try {
      if (this.bullhornData.clientContact.name == null || this.bullhornData.clientContact.name == "") {
        this.bullhornData.clientContact.firstName = this.user.first_name;
        this.bullhornData.clientContact.lastName = this.user.last_name;
        this.bullhornData.clientContact.name = `${this.user.first_name} ${this.user.last_name}`;
      }
      const body = {
        ...this.bullhornData,
      };
      Logger.debug("body to api", body);
      const bullhornData: number = await this.http.put<number>(`${proxyApi}employers`, body).toPromise();
      this.bullhornData.id = bullhornData.toString();

      const jobsWithoutIds = this.bullhornData.jobs?.some((job) => typeof job.id !== "number" || isNaN(job.id));
      Logger.debug("jobswithoutids", jobsWithoutIds);
      if (jobsWithoutIds) {
        await this.getEmployerBullhornDataByEmail(this.user.email);
        Logger.debug("bh data after", this.bullhornData);
      }

      return eResponseMsg.Success;
    } catch (err) {
      Logger.error(`Error upserting company. Error: ${err.message}.`);
      return eResponseMsg.Error;
    }
  }

  async upsertJob(job: iEmployerJobModel): Promise<string> {
    try {
      if (!this.bullhornData.clientContact?.id) {
        Logger.warn(`Unable to find valid contact id.`);
        return eResponseMsg.Error;
      }
      const loc = this._userSrvc.getLocationByZipCode(
        job.workingLocationZip == "" ? job.address?.zip : job.workingLocationZip
      );
      if (loc) {
        job.address = {
          city: loc.city,
          state: loc.state,
          zip: loc.zip,
          address1: "",
          address2: "",
          countryCode: "US",
          countryId: "1",
          countryName: "United States",
          timezone: "",
        };
      }
      job.appCreatedJob = true; //if it's saving on the app - it's an app managed job (fix jobs created before this was added)
      const body = {
        ...job,
        clientCorporation: { id: this.bullhornData.id },
        clientContact: { id: this.bullhornData.clientContact.id },
      };
      const jobId: string = await this.http.put<string>(`${proxyApi}jobs`, body).toPromise();

      //apply the new id to the job in the bullhornData
      const jobIndex = this.bullhornData.jobs.findIndex((j) => j.id === job.id);
      this.bullhornData.jobs[jobIndex].id = jobId.toString();

      return eResponseMsg.Success;
    } catch (err) {
      Logger.error(`Error upserting job. Error: ${err.message}.`);
      return eResponseMsg.Error;
    }
  }

  async getEmployerBullhornDataByEmail(email: string) {
    try {
      const pulledData = await this.http.get<any>(`${proxyApi}employers?email=${email}`, this.requestOptions).toPromise();
      this.bullhornData = pulledData;
      if (this.bullhornData?.jobs?.length > 0) {
        var jobPostings = cloneDeep(this.bullhornData.jobs);
        this.bullhornData.formattedJobs = await this._userSrvc.setupJobDataAfterImport(jobPostings);
      }
    } catch (err) {
      Logger.error(`Couldn't pull employer data from email. Error: ${err.message}`);
      this.defaultBullhornData();
      this.isFirstLogin = true;
    }
  }

  async getUserAdminStatus(email: string): Promise<string> {
    try {
      const adminStatus = await this.http.get<string>(`${proxyApi}admin?email=${email}`, this.requestOptions).toPromise();
      return adminStatus;
    } catch (err) {
      Logger.error(`Couldn't pull user admin status from email. Error: ${err.message}`);
    }
  }

  async getDefaultApplicantListByEmployerId(fakeEmployerId: number) {
    //38037;  //32415 //8703 //49808 //38037 //48717
    let list: iApplicantListModel[] = [];

    try {
      list = await this.http
        .get<iApplicantListModel[]>(`${proxyApi}employer-applicants?employerId=${fakeEmployerId}`)
        .toPromise(); //, this.requestOptions).toPromise();
      Logger.debug("Generated default applicant list", list);
    } catch (err) {
      Logger.warn(`Couldn't pull applicants for getDefaultApplicantListByEmployerId. Error: ${err.message}`);
    }

    return list;
  }

  //#region Note Services

  async getNotes(candidateId: string, employerContactId: string): Promise<NoteModel[]> {
    try {
      let encodedCandidateId = encodeURIComponent(candidateId.toString());
      let encodedEmployerId = encodeURIComponent(employerContactId.toString());
      let responseNotes = await this.http
        .get<NoteModel[]>(`${proxyApi}notes?candidateId=${encodedCandidateId}&employerContactId=${encodedEmployerId}`)
        .toPromise();
      if (responseNotes?.length > 0) {
        Logger.debug("response notes by candidate id and employer id", responseNotes);
        return responseNotes;
      }
      return [];
    } catch (err) {
      Logger.warn(`Couldn't get notes. Error: ${err.message}`);
    }
  }

  async addNote(noteObj: any): Promise<any> {
    try {
      Logger.debug("body to api", noteObj);
      const addedNote: any = await this.http.post<NoteModel>(`${proxyApi}notes`, noteObj).toPromise();
      return addedNote;
    } catch (err) {
      Logger.error(`Error adding note. Error: ${err.message}.`);
      return eResponseMsg.Error;
    }
  }

  async updateNote(noteObj: any, noteId: string): Promise<any> {
    try {
      Logger.debug("body to api", noteObj);
      const updatedNote: any = await this.http.patch<NoteModel>(`${proxyApi}notes?noteId=${noteId}`, noteObj).toPromise();
      return updatedNote;
    } catch (err) {
      Logger.error(`Error updating note. Error: ${err.message}.`);
      return eResponseMsg.Error;
    }
  }

  async deleteNote(noteId: string): Promise<any> {
    try {
      const deletedNote: any = await this.http.delete<number>(`${proxyApi}notes/${noteId}`).toPromise();
      return deletedNote;
    } catch (err) {
      Logger.error(`Error deleting note. Error: ${err.message}.`);
      return eResponseMsg.Error;
    }
  }

  //#endRegion Note Services

  //#Region Candidate File Services

  async getCandidateFiles(candidateId: number): Promise<BouloFileContent[]> {
    try {
      let encodedId = encodeURIComponent(candidateId.toString());
      let responseFiles = await this.http.get<BouloFileContent[]>(`${proxyApi}file?candidateId=${encodedId}`).toPromise();
      if (responseFiles?.length > 0) {
        Logger.debug("response files by candidate id", responseFiles);
        return responseFiles;
      }
      // return [];
    } catch (err) {
      Logger.warn(`Couldn't get candidate files. Error: ${err.message}`);
    }
  }

  //#endRegion Candidate File Services

  //Unsure if this is setup on the backend
  async getApplicantCountsBySubmissionIds(ids: number[]) {
    try {
      if (!ids) {
        Logger.warn("No submissions to retrieve candidates");
        return;
      }
      const stringifiedIds = ids.toString().concat(",");
      const responseCounts = await this.http
        .get<iApplicantCounts>(`${proxyApi}applicant-counts?submissionids=${stringifiedIds}`)
        .toPromise(); //, this.requestOptions).toPromise();
      if (responseCounts) {
        Logger.debug("response counts by sumission id", responseCounts);
        this.applicantCounts = responseCounts;
      }
    } catch (err) {
      Logger.warn(`Couldn't pull applicant counts. Error: ${err.message}`);
    }
  }

  async getApplicantCountsByEmployerId() {
    try {
      let encodedId = encodeURIComponent(this.bullhornData.id);
      const responseCounts = await this.http
        .get<iApplicantCounts>(`${proxyApi}employer-applicants-counts?employerId=${encodedId}`)
        .toPromise(); //, this.requestOptions).toPromise();
      if (responseCounts) {
        Logger.debug("response counts by employer id", responseCounts);
        this.applicantCounts = responseCounts;
      }
    } catch (err) {
      Logger.warn(`Couldn't pull applicant counts. Error: ${err.message}`);
    }
  }

  async getApplicantsWithStatusByEmployerId(status: string): Promise<iApplicantListModel[]> {
    let list: iApplicantListModel[] = [];

    if (status != "") {
      try {
        list = await this.http
          .get<any>(`${proxyApi}employer-applicants?employerId=${this.bullhornData.id}&status=${status}`)
          .toPromise(); //, this.requestOptions).toPromise();
      } catch (err) {
        Logger.warn(`Couldn't pull applicants with ${status} status. Error: ${err.message}`);
      }
    } else {
      Logger.warn("No status given to lookup applications");
    }

    return list;
  }

  async getApplicantsByEmployerId(allowCachedResults: boolean = true): Promise<iApplicantListModel[]> {
    //make-shift caching
    let list: iApplicantListModel[] = [];

    try {
      const cachedList = this.cachedApplicantList.get(eApplicantListType.AllApplicants);
      if (cachedList && allowCachedResults) {
        Logger.debug(`returned cached list for getApplicantsByEmployerId()`, cachedList);
        return cachedList;
      } else {
        list = await this.http
          .get<iApplicantListModel[]>(`${proxyApi}employer-applicants?employerId=${this.bullhornData.id}`)
          .toPromise(); //, this.requestOptions).toPromise();
        if (list) {
          this.cachedApplicantList.set(eApplicantListType.AllApplicants, list);
          return list;
        }
      }
    } catch (err) {
      Logger.warn(`Couldn't pull applicants. Error: ${err.message}`);
    }

    return list;
  }

  async getFilleredApplicants(pageIndex: number): Promise<any> {
    let response;
    let encodedId = encodeURIComponent(this.bullhornData.id);
    try {
      response = await this.http
        .get<FilteredCandidateReturnData>(
          `${proxyApi}employer-applicants-filtered?employerId=${encodedId}&pageIndex=${pageIndex}&pageSize=100`
        )
        .toPromise();
      if (response.data) {
        this.cachedApplicantList.set(eApplicantListType.AllApplicants, response.data);
      }
      return response;
    } catch (err) {
      Logger.warn(`Couldn't pull filtered applicants. Error: ${err.message}`);
    }
    return response;
  }

  async getAllFilteredApplicants() {
    this.allFilteredApplicants = [];
    let pageIndex = 0;
    let response = await this.getFilleredApplicants(pageIndex);
    if (!response) {
      return;
    }
    this.modifyFilteredDataList(response);
    if (this.allFilteredApplicants.length === 0) {
      return;
    }
    while (this.allFilteredApplicants.length < response.total) {
      // need to concatenate not just assign
      pageIndex += 1;
      let response = await this.getFilleredApplicants(pageIndex);
      this.modifyFilteredDataList(response);
    }
  }

  async toggleSaveCandidate(id: string | null) {
    if (!id) {
      Logger.warn("Can't add/remove Saved Candidate without id");
      return;
    }
    const idIdx = this.bullhornData.savedCandidateIds?.indexOf(id);
    if (idIdx !== -1) {
      this.bullhornData.savedCandidateIds = this.bullhornData.savedCandidateIds.filter((ids) => ids != id);
      await this.updateSavedApplicantsByUserId(this.bullhornData.savedCandidateIds);
    } else {
      this.bullhornData.savedCandidateIds.push(id);
      await this.updateSavedApplicantsByUserId(this.bullhornData.savedCandidateIds);
    }
  }

  async updateApplicantJobStatus(
    newStatus: string,
    selectedCandidate: iApplicantListModel,
    hasJobAppliedDetails: boolean
  ): Promise<iApplicantListModel> {
    if (hasJobAppliedDetails) {
      //Is Applicant
      const result = await this.updateApplicantStatusBySubmissionId(selectedCandidate.submissionId, newStatus);
      if (result === eResponseMsg.Success) {
        selectedCandidate.status = newStatus;
        selectedCandidate.childStatus = newStatus;
        selectedCandidate.parentStatus = this.applicantChildStatusMap.get(newStatus);
        selectedCandidate.latestJobApplied.status = newStatus;
        this.saveSuccessMsg();
        return selectedCandidate;
      } else {
        this.generalMsg("Issue Updating Applicant Status.", "Reach out to support if this issue persists.");
      }
    } else {
      //Is Candidate
      this.generalMsg(
        "Can't Update Candidate's Job Status",
        "Only Applicants who have submitted a job application can have their job status updated."
      );
    }
  }

  modifyFilteredDataList(response: FilteredCandidateReturnData) {
    response.data = this.parseApplicantData(response?.data);
    this.allFilteredApplicants = this.allFilteredApplicants.concat(response?.data);
  }

  parseApplicantData(appliantListResponse: iApplicantListModel[], createStatus: boolean = false): iApplicantListModel[] {
    return appliantListResponse.map((applicant) => {
      var newStatus = "";
      // const testingOldStatus = createStatus ? getRandomNumber(1, 4).toString() : applicant.latestJobApplied.status;
      var bullhornStatus = applicant.latestJobApplied?.status;
      if (
        bullhornStatus == "Web Response" ||
        bullhornStatus == "New Submission" ||
        bullhornStatus == "Internally Submitted" ||
        bullhornStatus == "Client Submission" ||
        bullhornStatus == "New Lead"
      ) {
        newStatus = eLabels.Active;
      } else if (bullhornStatus == "Qualified Candidate") {
        newStatus = eLabels.Shortlisted;
      } else if (
        bullhornStatus == "Interview Scheduled" ||
        bullhornStatus == "Second Interview" ||
        bullhornStatus == "Third Interview" ||
        bullhornStatus == "Final Interview" ||
        bullhornStatus == "Offer Extended"
      ) {
        newStatus = eLabels.Interviewing;
      } else if (bullhornStatus == "Placed") {
        newStatus = eLabels.Hired;
      } else if (
        bullhornStatus == "Candidate Not Interested" ||
        bullhornStatus == "Internally Rejected" ||
        bullhornStatus == "Client Rejected"
      ) {
        newStatus = eLabels.Rejected;
      } else {
        newStatus = ""; //bullhornStatus;
      }

      var checkIsSavedCandidate = false;
      if (this.bullhornData?.savedCandidateIds?.length > 0) {
        if (this.bullhornData.savedCandidateIds.includes(applicant.id)) {
          checkIsSavedCandidate = true;
        }
      }

      return {
        id: applicant.id,
        submissionId: applicant.latestJobApplied?.id ? applicant.latestJobApplied.id : null,
        firstName: applicant.firstName,
        lastInitial: applicant.lastInitial,
        email: applicant.email,
        experience: applicant.experience,
        state: applicant.state,
        city: applicant.city,
        certifications: applicant.certifications,
        elevatorPitch: applicant.elevatorPitch,
        status: bullhornStatus, //bullhorn status
        parentStatus: newStatus, //category status
        childStatus: applicant.latestJobApplied?.status?.length > 0 ? applicant.latestJobApplied?.status : "",
        isSavedByCompany: checkIsSavedCandidate, //ACSTODO: revisit this to attach to user
        latestJobApplied: applicant.latestJobApplied,
        isVerified: applicant.isBouloMember == "Yes" && applicant.status == eLabels.Active,
        bouloTags: applicant.bouloTags,
        hoursPreference: applicant.hoursPreference,
        locationPreference: applicant.locationPreference,
        jobSpecificSkills: applicant.jobSpecificSkills,
        jobSpecificSkillsDetails: applicant.jobSpecificSkillsDetails,
        compensationPreference: applicant.compensationPreference,
        minimumHourlyRate: applicant.minimumHourlyRate,
        minimumProjectRate: applicant.minimumProjectRate,
        minimumYearlyRate: applicant.minimumYearlyRate,
        yearsOfExperience: applicant.yearsOfExperience,
        rolePreference: applicant.rolePreference,
        zip: applicant.zip,
      };
    });
  }

  async getApplicantsByIds(ids: number[]): Promise<iApplicantListModel[]> {
    let list: iApplicantListModel[] = [];

    try {
      if (ids?.length == 0) {
        Logger.warn(`No ids provided to search.`);
        return list;
      }
      const stringifiedIds = ids.toString().concat(",");
      list = await this.http
        .get<iApplicantListModel[]>(`${proxyApi}employer-applicants?ids=(${stringifiedIds})`)
        .toPromise(); //, this.requestOptions).toPromise();
    } catch (err) {
      Logger.warn(`Unable to retrieve saved applicants by Ids. Error: ${err.message}`);
    }

    return list;
  }

  async getApplicantsFollowingCompany(allowCachedResults: boolean = true): Promise<iApplicantListModel[]> {
    //make-shift caching
    let list: iApplicantListModel[] = [];
    let encodedId = encodeURIComponent(this.bullhornData.id);
    try {
      const cachedList = this.cachedApplicantList.get(eApplicantListType.FollowedByCandidates);
      if (cachedList && allowCachedResults) {
        Logger.debug(`returned cached list for getApplicantsFollowingCompany()`, cachedList);
        return cachedList;
      }
      list = await this.http
        .get<iApplicantListModel[]>(`${proxyApi}employer-following-candidates?employerId=${encodedId}`)
        .toPromise(); //, this.requestOptions).toPromise();
      Logger.debug("getApplicantsFollowingCompany()", list);
      if (list) {
        this.cachedApplicantList.set(eApplicantListType.FollowedByCandidates, list);
        return list;
      }
    } catch (err) {
      Logger.warn(`Unable to retrieve applicants following company. Error: ${err.message}`);
    }

    return list;
  }

  isApplicantHired(applicant: iApplicantListModel): boolean {
    let applicantId = applicant.id;
    let jobId = applicant.latestJobApplied?.jobOrder?.id;
    let applicantHired = this.bullhornData.placements?.filter(
      (record) => record?.candidate?.id === applicantId && record?.jobOrder?.id === jobId
    );
    return applicantHired?.length >= 1;
  }

  async addPlacementRecordForApplicant(placementRecord: PlacementRecord): Promise<PlacementRecord> {
    try {
      Logger.debug("body to api", placementRecord);
      const addedRecord = await this.http.post<PlacementRecord>(`${proxyApi}placement-records`, placementRecord).toPromise();
      return addedRecord;
    } catch (err) {
      Logger.error(`Error adding note. Error: ${err.message}.`);
    }
  }

  async updatePlacementRecord(placementRecord: PlacementRecord, placementId: string): Promise<any> {
    try {
      Logger.debug("body to api", placementRecord);
      const updatedPlacementRecord: any = await this.http
        .patch<PlacementRecord>(`${proxyApi}placement-records?placementRecordId=${placementId}`, placementRecord)
        .toPromise();
      return updatedPlacementRecord;
    } catch (err) {
      Logger.error(`Error updating placement record. Error: ${err.message}.`);
      return eResponseMsg.Error;
    }
  }

  async deletePlacementRecord(placementId: string): Promise<any> {
    try {
      const deletedRecord: any = await this.http.delete<number>(`${proxyApi}placement-records/${placementId}`).toPromise();
      return deletedRecord;
    } catch (err) {
      Logger.error(`Error deleting placement record. Error: ${err.message}.`);
      return eResponseMsg.Error;
    }
  }

  async getSavedApplicantsByUserId(allowCachedResults: boolean = true): Promise<iApplicantListModel[]> {
    //make-shift caching
    let list: iApplicantListModel[] = [];

    try {
      const cachedList = this.cachedApplicantList.get(eApplicantListType.AllSavedCandidates);
      if (cachedList && allowCachedResults) {
        Logger.debug(`returned cached list for getSavedApplicantsByUserId()`, cachedList);
        return cachedList;
      }
      if (!this.bullhornData.clientContact?.id) {
        Logger.warn(`Unable to find valid contact id.`);
        return list;
      }
      list = await this.http
        .get<any>(`${proxyApi}employer-applicants?userId=${this.bullhornData.clientContact.id}`)
        .toPromise(); //, this.requestOptions).toPromise();
      if (list) {
        this.cachedApplicantList.set(eApplicantListType.AllSavedCandidates, list);
        return list;
      }
    } catch (err) {
      Logger.warn(`Unable to retrieve saved applicants for this user. Error: ${err.message}`);
    }

    return list;
  }

  modifyReturnedCandidatesToApplicantModel(response: iPagedApplicantResponse): iApplicantModel[] {
    return response?.data?.map((applicant: iApplicantModel) => {
      return {
        id: applicant.id,
        firstName: applicant.firstName,
        lastInitial: applicant.lastInitial,
        email: applicant.email,
        experience: applicant.experience,
        state: applicant.state,
        city: applicant.city,
        certifications: applicant.certifications,
        elevatorPitch: applicant.elevatorPitch,
        isVerified: applicant.isBouloMember == "Yes" && applicant.status == "Active",
        bouloTags: applicant.bouloTags,
        hoursPreference: applicant.hoursPreference,
        yearsOfExperience: applicant.yearsOfExperience,
        compensationPreference: applicant.compensationPreference,
        rolePreference: applicant.rolePreference,
        locationPreference: applicant.locationPreference,
        jobSpecificSkills: applicant.jobSpecificSkills,
        jobSpecificSkillsDetails: applicant.jobSpecificSkillsDetails,
        minimumHourlyRate: applicant.minimumHourlyRate,
        minimumProjectRate: applicant.minimumProjectRate,
        minimumYearlyRate: applicant.minimumYearlyRate,
        zip: applicant.zip,
        matchPercent: Math.ceil(applicant.matchPercent),
      };
    });
  }

  calculateYearsOfExperience(experience: number): string[] {
    if (experience < 1) {
      return ["Entry Level (0-1 years)", "1"];
    } else if (experience >= 1 && experience < 4) {
      return ["Associate (1-4 years)", "2"];
    } else if (experience >= 5 && experience < 8) {
      return ["Mid Level (5-8 years)", "3"];
    } else {
      return ["Senior Level (8+ years)", "4"];
    }
  }

  async sendEmailNotificationToCandidate(candidateNotfications: CandidateJobNotification) {
    // TODO: fix aws ses spam issue then utilize this method
    // const sentNotifications = this.http.post<CandidateJobNotification>(`${proxyApi}candidate-job-notification`, candidateNotfications).toPromise();
  }

  async notifyCandidatesBasedOnJobs(jobId: string = null) {
    if (!this.bullhornData?.jobs) {
      return;
    }
    let activeJobs =
      jobId == null
        ? this.bullhornData?.jobs?.filter((job) => job.status === eJobCardStatus.Active)
        : this.bullhornData?.jobs?.filter((job) => job.status === eJobCardStatus.Active && job.id.toString() === jobId);
    let notifiedJobs: CandidateJobNotification[] = [];
    for (let job of activeJobs) {
      let jobId = job.id.toString();
      let selectedFields: iFieldMultiSelect[] = this.createSelectedFieldBasedOnJobView(job);
      let lst = [];
      for (let field of selectedFields) {
        field.label = this.filterLabelNames[field.label];
      }
      if (selectedFields.length >= 2) {
        notifiedJobs.push({ jobId: jobId, data: selectedFields, isProd: environment.production });
      }
    }
    for (let job of notifiedJobs) {
      await this.sendEmailNotificationToCandidate(job);
    }
  }

  createSelectedFieldBasedOnJobView(job: iEmployerJobModel): iFieldMultiSelect[] {
    let selectedFields: iFieldMultiSelect[] = [];
    // Job Skills Categories:
    if (job?.combinedSkills?.skillCategories.length > 0) {
      const jobCategorySkills: iFieldMultiSelect = { label: eJobSkillsCategoryFilters.JobSkillsCategory, data: [] };
      for (let skills of job?.combinedSkills?.skillCategories) {
        jobCategorySkills.data.push({ label: skills, value: this.skillsFocus[skills] });
      }
      selectedFields.push(jobCategorySkills);
    }

    // Category Skills:
    if (
      job?.combinedSkills?.categorySpecificSkills &&
      Object.entries(job?.combinedSkills?.categorySpecificSkills)?.length > 0
    ) {
      const categorySkills: iFieldMultiSelect = { label: eJobSkillsCategoryFilters.CategorySkills, data: [] };
      for (const [key, value] of Object.entries(job?.combinedSkills?.categorySpecificSkills)) {
        let displayName = this._userSrvc.getIndivdualCategorySkill(key);
        categorySkills.data.push({ label: `${displayName}`, value: `${displayName}` });
      }
      selectedFields.push(categorySkills);
    }

    // State:
    if (job?.address?.state && job?.arrangementType !== "remote") {
      const state: iFieldMultiSelect = { label: "State", data: [] };
      state.data.push({ label: this._userSrvc.abbrState(job?.address?.state, "full"), value: job?.address?.state });
      selectedFields.push(state);
    }

    return selectedFields;
  }

  // seting up AI PARAMS:

  getAllStates(): FilterOption[] {
    const fullNames = this._userSrvc.getAllStateNames();
    const abbreviatedNames = this._userSrvc.getAllStateAbbreviations();
    let allStates: FilterOption[] = [];
    for (let i = 0; i < fullNames.length; i++) {
      allStates.push({ label: fullNames[i], value: abbreviatedNames[i] });
    }
    return allStates;
  }

  initLocationCategory(): FilterParameter {
    let filterCategory: FilterParameter = {
      category: eFilterCategoryTitle.Location,
      filters: [
        {
          label: eLocationFilters.LocationPreference,
          data: [
            { label: "Remote", value: "remote" },
            { label: "Hybrid", value: "hybrid" },
            { label: "Onsite", value: "onsite" },
          ],
        },
        { label: eLocationFilters.State, data: this.getAllStates() }, // add all 50 states
        // { label: eLocationFilters.City, data: [] }, // add all relevant cities
        // { label: eLocationFilters.Zip, data: [] },
      ],
      selectedCount: 0,
    };
    return filterCategory;
  }

  initEmploymentTypeCategory(): FilterParameter {
    let filterCategory: FilterParameter = {
      category: eFilterCategoryTitle.EmploymentType,
      filters: [
        {
          label: eEmploymentTypeFilters.EmploymentType,
          data: [
            { label: "Full-Time", value: "full-time (40 hours a week)" },
            { label: "Part-Time", value: "part-time (10-30 hours a week)" },
            { label: "Contract/Project", value: "contract/project based work" },
          ],
        },
        {
          label: eEmploymentTypeFilters.WorkingHours,
          data: [
            { label: "Standard Hours", value: "standard" },
            { label: "School Friendly Hours", value: "school" },
            { label: "Flexible Hours", value: "flexible" },
            { label: "Prefers To Set Own Time", value: "own time" },
          ],
        }, // add all relevant cities
      ],
      selectedCount: 0,
    };
    return filterCategory;
  }

  initExperienceCategory(): FilterParameter {
    let filterCategory: FilterParameter = {
      category: eFilterCategoryTitle.Experience,
      filters: [
        {
          label: eExperienceFilters.Experience,
          data: [
            { label: "Entry Level (0-1 years)", value: "1" },
            { label: "Associate (1-4 years)", value: "2" },
            { label: "Mid Level (5-8 years)", value: "3" },
            { label: "Senior Level (8+ years)", value: "4" },
          ],
        },
      ],
      selectedCount: 0,
    };
    return filterCategory;
  }

  initCompensationCategory(): FilterParameter {
    let filterCategory: FilterParameter = {
      category: eFilterCategoryTitle.Compensation,
      filters: [
        {
          label: eCompensationFilters.CompensationType,
          data: [
            { label: eCompensationFilters.Salary, value: "salary" },
            { label: eCompensationFilters.Hourly, value: "hourly" },
            { label: eCompensationFilters.Contract, value: "contract" },
          ],
        },
        {
          label: eCompensationFilters.Salary,
          data: [
            { label: "$25,000-$50,000", value: "1" },
            { label: "$50,000-$75,000", value: "2" },
            { label: "$75,000-$100,000", value: "3" },
            { label: "$100,000-$150,000", value: "4" },
            { label: "$150,000-$200,000", value: "5" },
            { label: "$200,000+", value: "6" },
          ],
        },
        {
          label: eCompensationFilters.Hourly,
          data: [
            { label: "$15-$25", value: "1" },
            { label: "$26-$35", value: "2" },
            { label: "$36-$50", value: "3" },
            { label: "$51-$75", value: "4" },
            { label: "$76-$100", value: "5" },
            { label: "100+", value: "6" },
          ],
        },
      ],
      selectedCount: 0,
    };
    return filterCategory;
  }

  initJobTypeCategory(): FilterParameter {
    let filterCategory: FilterParameter = {
      category: eFilterCategoryTitle.JobCategory,
      filters: [
        {
          label: eJobSkillsCategoryFilters.JobSkillsCategory,
          data: [
            { label: "Administrative", value: "Administrative Skills Focus" },
            { label: "Business", value: "Business Skills Focus" },
            { label: "Creative", value: "Creative Skills Focus" },
            { label: "Financial", value: "Financial Skills Focus" },
            { label: "Legal", value: "Legal Skills Focus" },
            { label: "Marketing", value: "Marketing Skills Focus" },
            { label: "Operations", value: "Operations Skills Focus" },
            { label: "People", value: "People Skills Focus" },
            { label: "Project Management", value: "Project Management Skills Focus" },
            { label: "Sales", value: "Sales Skills Focus" },
            { label: "Technical", value: "Technical Skills Focus" },
          ],
        },
        {
          label: eJobSkillsCategoryFilters.CategorySkills,
          data: this.getAllCategorySkills(),
        },
      ],
      selectedCount: 0,
    };
    return filterCategory;
  }

  getAllCategorySkills(): FilterOption[] {
    let lst = [];
    let value = this._userSrvc.setSkillData();
    for (let skills of value) {
      for (let skill of skills.skills) {
        lst.push({ label: skill.displayName, value: skill.displayName });
      }
    }
    return lst;
  }

  getAllCategoryFields() {
    return [
      // this.initJobTypeCategory(),
      this.initLocationCategory(),
      this.initEmploymentTypeCategory(),
      this.initExperienceCategory(),
      this.initCompensationCategory(),
    ];
  }

  getCategoryNameBasedOnFilter(filterLabel: string, allFields: FilterParameter[]): string {
    for (let item of allFields) {
      for (let filter of item.filters) {
        if (filter.label === filterLabel) {
          return item.category;
        }
      }
    }
  }

  setupAISearchParams(selectedJob: iEmployerJobModel): advSearchParam {
    let selectedFilters = this.createSelectedFieldBasedOnJob(selectedJob);
    let industryField = this.setupIndustryFields(selectedJob);
    let bouloSkills = this.setupBouloSkills(selectedJob);

    var jobOverview: JobOverview = {
      jobTitle: selectedJob.title,
      jobDescription: selectedJob.description,
      education: selectedJob.educationDegree,
      industryFields: industryField,
      customSkills: selectedJob.combinedSkills.customSkills.map((skill) => skill.display),
      bouloSkills: bouloSkills,
      certifications: selectedJob.certsList.items.map((item) => item.txt),
    };
    var param: advSearchParam = {
      keywords: "",
      selectedFilters: selectedFilters,
      jobOverview: jobOverview,
      start: 0,
      count: 20,
    };

    return param;
  }

  setCandidateAIFilter(selectedJob: iEmployerJobModel): void {
    const filterParam = this.setupAISearchParams(selectedJob);
    this.advSearchParam = filterParam;
  }

  setupIndustryFields(job: iEmployerJobModel): string[] {
    let industryFields = [];
    if (job?.combinedSkills?.skillCategories.length > 0) {
      const jobCategorySkills: iFieldMultiSelect = { label: eJobSkillsCategoryFilters.JobSkillsCategory, data: [] };
      for (let skills of job?.combinedSkills?.skillCategories) {
        industryFields.push(this.skillsFocus[skills]);
        // jobCategorySkills.data.push({ label: skills, value: this.skillsFocus[skills] });
      }
    }
    return industryFields;
  }

  setupBouloSkills(job: iEmployerJobModel) {
    let bouloSkills = [];
    if (
      job?.combinedSkills?.categorySpecificSkills &&
      Object.entries(job?.combinedSkills?.categorySpecificSkills)?.length > 0
    ) {
      for (const [key, value] of Object.entries(job?.combinedSkills?.categorySpecificSkills)) {
        let skillName = this._userSrvc.getIndivdualCategorySkill(key);
        bouloSkills.push(skillName);
        // categorySkills.data.push({ label: `${displayName}`, value: `${displayName}` });
      }
    }
    return bouloSkills;
  }

  createSelectedFieldBasedOnJob(job: iEmployerJobModel): iFieldMultiSelect[] {
    let selectedFields: iFieldMultiSelect[] = [];
    // State:
    if (job?.address?.state && job?.arrangementType !== "remote") {
      const state: iFieldMultiSelect = { label: eLocationFilters.State, data: [] };
      state.data.push({ label: this._userSrvc.abbrState(job?.address?.state, "full"), value: job?.address?.state });
      selectedFields.push(state);
    }

    return selectedFields;
  }

  getSalaryRange(salary: number) {
    if (salary <= 50000) {
      return this.salaryRange[0];
    } else if (salary <= 75000) {
      return this.salaryRange[1];
    } else if (salary <= 100000) {
      return this.salaryRange[2];
    } else if (salary <= 150000) {
      return this.salaryRange[3];
    } else if (salary <= 200000) {
      return this.salaryRange[4];
    } else {
      return this.salaryRange[5];
    }
  }

  getHourlyRange(hourly: number) {
    if (hourly <= 25) {
      return this.hourlyRange[0];
    } else if (hourly <= 35) {
      return this.hourlyRange[1];
    } else if (hourly <= 50) {
      return this.hourlyRange[2];
    } else if (hourly <= 75) {
      return this.hourlyRange[3];
    } else if (hourly <= 100) {
      return this.hourlyRange[4];
    } else {
      return this.hourlyRange[5];
    }
  }

  concatFilterOptions(options: FilterOption[]): string {
    let value = "";
    for (let val of options) {
      value += val.value + ",";
    }
    return value.substring(0, value.length - 1);
  }

  async getSavedApplicantIdsByUserId(): Promise<string[]> {
    let list: string[] = [];

    try {
      if (!this.bullhornData.clientContact?.id) {
        Logger.warn(`Unable to find valid contact id.`);
        return list;
      }
      const response = await this.http
        .get<string>(`${proxyApi}user-saved-applicants?userId=${this.bullhornData.clientContact.id}`, this.requestOptions)
        .toPromise();
      list = response.split(",").map((id) => id.trim());
    } catch (err) {
      Logger.warn(`Unable to retrieve saved applicants for this user. Error: ${err.message}`);
    }

    return list;
  }

  async updateSavedApplicantsByUserId(ids: string[]): Promise<string> {
    try {
      let encodedClientContactId = encodeURIComponent(this.bullhornData.clientContact.id);
      if (!this.bullhornData.clientContact?.id) {
        Logger.warn(`Unable to find valid contact id.`);
        return eResponseMsg.InvalidParameters;
      }
      const stringifiedIds = ids.toString().concat(",");
      await this.http
        .patch<string>(`${proxyApi}user-saved-applicants?userId=${encodedClientContactId}`, stringifiedIds)
        .toPromise();
      return eResponseMsg.Success;
    } catch (err) {
      Logger.error(`Error updating saved ids for user. Error: ${err.message}.`);
      return eResponseMsg.Error;
    }
  }

  async updateEmployerContactTagsByContactId(contactId: number | string, tags: string[]): Promise<string> {
    try {
      if (!contactId || !tags || tags.length < 1) {
        Logger.info(`Unable to update company contact status. Need valid tags and contact id.`);
        return eResponseMsg.InvalidParameters;
      }

      await this.http.patch<string>(`${proxyApi}/employer/tags?contactId=${contactId}`, { tags }).toPromise();
      return eResponseMsg.Success;
    } catch (err) {
      Logger.error(`Issue updating contact's tags. Error: ${err.message}.`);
      return eResponseMsg.Error;
    }
  }

  private buildSearchQuery(keywords: string = "", location: string = ""): string {
    var query = "";
    if (keywords != "") {
      query += `search=${keywords}`;
    }
    if (keywords != "" && location != "") {
      query += `&`;
    }
    if (location != "") {
      location = this._userSrvc.abbrState(location, "search-query-abbreviation");
      query += `location=${location}`;
    }
    return query;
  }
  async searchCandidates(keywords: string = "", location: string = ""): Promise<iPagedApplicantResponse> {
    try {
      if (keywords == "" && location == "") {
        return;
      }
      var query = this.buildSearchQuery(keywords, location);
      const startIndex = 0;
      const response = await this.http
        .get<iPagedApplicantResponse>(`${proxyApi}candidates?startIndex=${startIndex}&${query}`)
        .toPromise();
      return response;
      //TODO: add location to search
    } catch (err) {
      Logger.error(`Couldn't pull candidates. Error: ${err}`);
    }
  }

  async filterCandidates(params: string): Promise<iPagedApplicantResponse> {
    try {
      if (params === "") {
        return;
      }
      var query = params;
      const startIndex = 0;
      const response = await this.http
        .get<iPagedApplicantResponse>(`${proxyApi}advanced-candidate-search?${query}`)
        .toPromise();
      return response;
      //TODO: add location to search
    } catch (err) {
      Logger.error(`Couldn't pull filtered candidates. Error: ${err}`);
    }
  }

  async getAIFilteredCandidates(params: advSearchParam): Promise<iPagedApplicantResponse> {
    try {
      if (params.keywords.length === 0 && params.selectedFilters.length == 0 && params.jobOverview === null) {
        return;
      }
      const response = await this.http.post<iPagedApplicantResponse>(`${proxyApi}llm-candidate-matcher`, params).toPromise();
      return response;
    } catch (err) {
      Logger.error(`Couldn't pull AI filtered candidates. Error: ${err}`);
    }
  }

  async searchPagedCandidates(findModel: iCandidateFindModel): Promise<iPagedApplicantResponse> {
    try {
      if (!findModel) {
        return;
      } // || findModel.searchQuery == "")
      var query = this.buildSearchQuery(findModel.searchKeywords, findModel.searchLocation);
      const startIndex = findModel.pageIndex * findModel.pageSize;
      const response = await this.http
        .get<iPagedApplicantResponse>(`${proxyApi}candidates?startIndex=${startIndex}&${query}`)
        .toPromise();
      return response;
      //TODO: add location to search
    } catch (err) {
      Logger.error(`Couldn't pull paged candidates. Error: ${err}`);
    }
  }

  //This is the call for the the info for the Applicant Details Dialog
  async getApplicantBullhornDataByEmail(email: string, id: string): Promise<BullhornApplicantModel> {
    //make-shift caching //TODO: make separate call to pull CandidateDetails for Employers, currently reusing Candidate's get self data call.
    try {
      const cachedDetails = this.cachedApplicantDetails.get(id);
      if (cachedDetails) {
        Logger.debug("getApplicantBullhornDataByEmail returned cachedDetails", cachedDetails);
        return cachedDetails;
      } else {
        const details = await this.http.get<any>(`${proxyApi}users?email=${email}`, this.requestOptions).toPromise();

        details.jobSpecificSkillsDetails = details.jobSpecificSkillsDetails.reduce((prev, curr) => {
          const keyValue = curr.split(": ");
          prev[keyValue[0]] = keyValue[1];

          return prev;
        }, {});

        this.cachedApplicantDetails.set(id, details);
        return details;
      }
    } catch (err) {
      Logger.warn(`Unable to get applicant details. Error: ${err.message}`);
    }
  }

  //This is the call for the job info for the Applicant Details Dialog
  async getJobDetailsForCandidateDetails(employerId: string, candidateId: string): Promise<iBullhornJobSubmission[]> {
    try {
      const cachedDetails = this.cachedApplicantJobDetails.get(candidateId);
      if (cachedDetails || this.cachedApplicantJobDetails.has(candidateId)) {
        Logger.debug("getJobDetailsForCandidateDetails returned cachedDetails", cachedDetails);
        return cachedDetails;
      } else {
        const details = await this.http
          .get<iBullhornJobSubmission[]>(
            `${proxyApi}/employer-submissions-by-candidate?employerId=${employerId}&candidateId=${candidateId}`,
            this.requestOptions
          )
          .toPromise();
        this.cachedApplicantJobDetails.set(candidateId, details);
      }
    } catch (err) {
      Logger.warn(`Unable to get job details. Error: ${err.message}`);
      this.cachedApplicantJobDetails.set(candidateId, null);
    }
  }

  async updateApplicantStatusBySubmissionId(submissionId: number | string, bullhornStatus: string): Promise<string> {
    try {
      let encodedSubmissionId = encodeURIComponent(submissionId.toString());
      if (!submissionId || !bullhornStatus || bullhornStatus?.trim().length < 1) {
        Logger.info(`Unable to update status. Need valid status and submission.`);
        return eResponseMsg.InvalidParameters;
      }
      await this.http
        .patch<string>(`${proxyApi}/update-submission-status?submissionId=${encodedSubmissionId}`, bullhornStatus)
        .toPromise();
      return eResponseMsg.Success;
    } catch (err) {
      Logger.error(`Issue updating applicant's status. Error: ${err.message}.`);
      return eResponseMsg.Error;
    }
  }
  //#endregion API Calls
  private toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  //Triggered every 1 minute to see if there are unsaved changes
  async saveUnsavedChanges(): Promise<void> {
    if (this.isDirty) {
      this.isDirty = false;
      await this.upsertCompany();
    }
  }
  startPeriodicCheckForUnsavedChangesCall(intervalTime: number) {
    if (!this.subUnsavedChanges) {
      this.subUnsavedChanges = interval(intervalTime).subscribe(() => {
        this.saveUnsavedChanges();
      });
    }
  }

  stopPeriodicCheckForUnsavedChangesCall() {
    if (this.subUnsavedChanges) {
      this.subUnsavedChanges.unsubscribe();
      this.subUnsavedChanges = undefined;
    }
  }

  clearBullhornData() {
    this.isLoaded = false;
    this.defaultBullhornData();
  }

  cloneJob(job: iEmployerJobModel): iEmployerJobModel {
    let clonedJob = cloneDeep(job);
    clonedJob.id = 0;
    clonedJob.title = `${job.title} (Copy)`;
    clonedJob.status = eJobCardStatus.Draft;
    clonedJob.dateAdded = null;
    clonedJob.datePosted = null;
    clonedJob.locationZip = null;
    clonedJob.workingLocationZip = null;
    clonedJob.submissionIDs = [];
    clonedJob.numSubmissions = 0;
    clonedJob.numViews = 0;
    clonedJob.newApplicant = false;
    clonedJob.appCreatedJob = true;
    clonedJob.saved = false;
    clonedJob.applied = false;
    clonedJob.notes = [];

    return clonedJob;
  }

  saveSuccessMsg() {
    this._snackBar.openFromComponent(SnackbarMsgComponent, {
      duration: 5000,
      data: {
        boldMsg: "Changes have been saved!",
        detailsMsg: "",
      } as SnackbarData,
      panelClass: ["snackbar-success"], //applies success styling, remove this line for generic style
    });
  }
  saveFailedMsg() {
    this._snackBar.openFromComponent(SnackbarMsgComponent, {
      duration: 5000,
      data: {
        boldMsg: "Issue saving. Try again.",
        detailsMsg: "",
      } as SnackbarData,
    });
  }

  generalMsg(bold: string, details: string, seconds: number = 5, classColor: string = null) {
    this._snackBar.openFromComponent(SnackbarMsgComponent, {
      duration: seconds * 1000,
      data: {
        boldMsg: bold,
        detailsMsg: details,
      } as SnackbarData,
      panelClass: [classColor],
    });
  }
}

export interface FilteredCandidateReturnData {
  count: number;
  start: number;
  total: number;
  data: iApplicantListModel[];
}

export interface iStripeSubscriptionResult {
  status: string;
  plan: {
    id: string;
    displayName: string | null;
  };
  productId: string;
}
