export const MessageDisplayText = {
  Interview: {
    TimesSent: "Interview Times Sent!",
    ProposedTimes: "Proposed Interview Times",
    NoneSelectedYet: "No interview times have been selected yet.",
    SelectTime: "We'd like to schedule an interview! Please choose a date/time:",
    TimesCancelled: "Interview Times Cancelled",
    NoneOfTheAbove: "None of the above.",
  },
  Error: {
    LoadingInboxChatsIssue: "Unable to load chats: Issue connecting to Sendbird",
    LoadingInboxChatsResolution: "Try logging out and back in. If problem persists, contact us for help.",
    InvalidFileType: "Invalid file type.",
    SelectMessage: "Please select an image or document.",
  },
};
