import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-hero-text",
  templateUrl: "./hero-text.component.html",
  styleUrls: ["./hero-text.component.scss"],
})
export class HeroTextComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
