import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({
  name: "linkify",
})
export class LinkifyPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.stylize(value));
  }

  private stylize(text: string): string {
    // Updated regex to recognize popular TLDs
    const urlRegex = /(?:https?:\/\/|www\.)[^\s]+(?:\.com|\.org|\.net|\.gov|\.edu|\.info|\.io)/g;
    return text.replace(urlRegex, (url) => {
      let prefix = "";
      // Check if the URL starts with 'http://' or 'https://'
      if (!/^https?:\/\//i.test(url)) {
        prefix = "http://";
      }
      // Replace the URL with an anchor tag
      return `<a href="${prefix}${url}" target="_blank">${url}</a>`;
    });
  }
}
