<div class="message-inbox-wrapper">
  <app-inbox-root
    [messageDetails]="_getMessageDetails"
    [isLoading]="isLoading"
    [isEmployer]="'true'"
    [showDeleteButton]="showDelete"
    (channelSelect)="selectChannel($event)"
    (deleteChannelSelect)="prepareDeleteChannel($event)"
    (optInForEmailChanged)="updateEmailOptIn($event)"
    class="inbox-root-container"
  ></app-inbox-root>
</div>
