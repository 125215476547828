import { Component, Inject, inject } from "@angular/core";
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { CommonModule } from "@angular/common";

export interface SnackbarData {
  boldMsg: string;
  detailsMsg: string;
}

@Component({
  selector: "app-snackbar-msg",
  templateUrl: "./snackbar-msg.component.html",
  styleUrls: ["./snackbar-msg.component.scss"],
})
export class SnackbarMsgComponent {
  snackBarRef = inject(MatSnackBarRef);

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData) {}

  get hasDetailsMsg() {
    return !!(this.data.detailsMsg?.length > 0);
  }
}
