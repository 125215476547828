import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { OpenCommonService } from "../open-common.service";
import { FaqInterface } from "./faq-interface";
import { FaqService } from "./faq-service";

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.scss"],
})
export class FaqComponent implements OnInit {
  criteria: string;
  showBanner: boolean;
  foundQuestions: FaqInterface[];
  questionMadeIt: boolean = false;

  constructor(
    private faqService: FaqService,
    public common: OpenCommonService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.common.showBannerObservable.subscribe((val) => {
      this.showBanner = val;
    });
    this.foundQuestions = this.faqService.getData();
  }

  search(event): void {
    if (!event || event.keyCode == 13) {
      this.foundQuestions = this.faqService.getData();
      if ((this.questionMadeIt = this.criteria && this.criteria.trim().length > 0)) {
        this.foundQuestions = this.foundQuestions.filter((n) => {
          return (
            n.question.toLocaleLowerCase().indexOf(this.criteria.toLocaleLowerCase()) >= 0 ||
            n.answer.toLocaleLowerCase().indexOf(this.criteria.toLocaleLowerCase()) >= 0
          );
        });
      }
    }
  }

  clearSearch(): void {
    this.criteria = null;
    this.questionMadeIt = false;
    this.foundQuestions = this.faqService.getData();
  }

  goTo(evt) {
    const href = evt.target.getAttribute("href");
    if (href.indexOf("/") !== -1) {
      evt.preventDefault();
      this.router.navigate([href]);
    }
  }
}
