export enum eUserRole {
  Candidate = "user",
  Employer = "employer",
  Both = "both",
}
export enum eSuperAdmin {
  ImitatedEmailKey = "imitateEmail",
  Admin = "SUPERADMIN",
}

export enum eRoleType {
  Candidate = "Candidate",
  Employer = "Employer",
  Both = "Both",
}
