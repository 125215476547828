import { Component, Input, OnInit } from "@angular/core";
import { SecurityService, SignUp } from "src/app/services/security.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-confirmation-message",
  templateUrl: "./confirmation-message.component.html",
  styleUrls: ["./confirmation-message.component.scss"],
})
export class ConfirmationMessageComponent implements OnInit {
  resentEmail: boolean;
  errorMessage: string = null;
  email: string;

  constructor(
    private securityService: SecurityService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParams.subscribe((params) => {
      this.email = params["email"];
      if (params["resent"]) {
        this.resentEmail = params["resent"] == "true" ? true : false;
      }
    });
  }

  ngOnInit(): void {}

  onClickSendAgain(): void {
    this.securityService.resentConfirmationEmail(this.email).subscribe(
      (res) => {
        this.router.navigate(["signup/confirmationmessage"], { queryParams: { email: this.email } });
      },
      (errorMessage) => {
        this.errorMessage = errorMessage;
      }
    );
  }
}
