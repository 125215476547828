<app-header></app-header>
<div class="container-fluid" id="privacy-policy">
  <p class="title-3 text-center">Privacy Policy</p>

  <p class="text">
    DCG Group, LLC ( “DCG” or “We”) is deeply committed to maintaining the privacy of the users of its website
    (www.boulosolutions.com) and has created the following Privacy Policy to inform you of its policies and practices
    regarding the collection, use and disclosure of information through the Site.
    <br />
    Capitalized terms not defined in this Privacy Policy shall have the meaning attributed in the Terms of Use. By your
    continued use of the Site or Platform you accept the terms contained in this Privacy Policy.
    <br />
    <span class="text-bold">1. Your Privacy is Important.<br /></span>
    DCG is committed to protecting your privacy, and We are firmly devoted to maintaining your trust in us. We work
    extremely hard at safeguarding important information provided to us by Companies and Talent and keeping it secure.
    This Privacy Policy is designed to help You understand our genuine dedication to preserving your privacy.<br />
    <span class="text-bold">2. Your Personal Information.<br /></span>
    DCG limits the collection, use and processing of collected information to that required to provide the Services and
    assist in the usability of the Site and the Platform. Information that is personally identifiable to You such as
    your name, address, email address or phone number is referred as “Personal Information”. By submitting Personal
    Information to us through the Site or Platform (when creating your account or profile, for example) You expressly
    agree and consent to the processing of your Personal Information in accordance with this Privacy Policy.<br />
    <span class="text-bold">3. Information We Collect.<br /></span>
    When You create an account on the Site or use the Platform, We collect and store Personal Information. The following
    are examples of the types of information We receive from You including certain Personal Information:
    <br />a. From Companies: First and last name, company name, company role and department specifics, user ID,
    password, contact information (phone number, street address, city, state, postal code, country and email address),
    project description, bank account or other payment and invoice-related information. <br />b. From Talent: First and
    last name, user ID, password, contact information (phone number, street address, city, state, postal code, country
    and email address), short personal summary, your skills, the type of work you are interested in and bank account
    information (for you to get paid). <br />DCG does not collect Personal Information from you unless You provide it to
    us. This means that you can visit our Site without telling us who You are or revealing any Personal Information
    about yourself.<br />
    <span class="text-bold">4. How we use your Personal Information.<br /></span>
    To facilitate connecting its Companies and Talent, DCG will share certain Personal Information You provided to the
    Platform with prospective organizations who are registered as Companies regarding specific placement opportunities.
    Also, Services provided through the Platform require payment by wire transfer, check, ACH or credit card.
    Accordingly, DCG will collect applicable financial information from Company and Talent and will use that information
    to perform billing and payment operations. Certain Personal Information may be shared with Goodhire, who may contact
    Talent directly to obtain information necessary to perform a background check in connection with the Services. To
    the extent necessary to provide You with the Services, we may provide your Personal Information to other third party
    contractors or business partners who work on our behalf to help us provide You with the Services or maintain the
    Site or Platform. DCG will only share your Personal Information with third parties necessary to perform these
    function or services.
    <br />
    DCG will not sell or share Personal Information with any other third parties or sell your e-mail address or any
    other contact information to any third parties for any purpose.<br />
    <span class="text-bold">5. Security.<br /></span>
    DCG strives to protect your Personal Information. Personal Information is stored on our database and is not publicly
    accessible. Each employee of DCG signs a confidentiality and nondisclosure agreement to ensure your privacy. The
    Site has security measures in place to protect the loss, misuse and alteration of the information under our control,
    and meets or exceeds all industry standards for the protection and confidentiality of personal identifiable
    information. DCG operates its payment system using secure Internet connections, using SSL encryption to safeguard
    all financial information. Despite our efforts to keep your Personal Information protected, please know that We
    cannot guarantee the security of data traveling over the Internet.<br />
    <span class="text-bold">6. Testimonials.<br /></span>
    From time to time, We may post Company and Talent testimonials, which may contain Personal Information such as the
    Company or Talent’s name, on the Site, but will obtain the Company or Talent’s consent prior to posting the
    testimonial.<br />
    <span class="text-bold">7. Opt-out.<br /></span>
    When you create an account on the Site and create a profile on the Platform, you are opting in to receiving
    newsletters, emails and promotional materials from DCG. At any time, You may opt-out of sharing your Personal
    Information posted on the Platform or receiving such communications from DCG by e-mailing us with your specific
    request at support@boulosolutions.com. Note that opting out from such communications does not apply to our ability
    to communicate with you regarding non-marketing related matters regarding your account or use of the Platform.<br />
    <span class="text-bold">8. Correct/Update.<br /></span>
    If You would like to verify the information We have received from You or to make corrections to it, please log into
    your account to do so. If You would like to deactivate the use of your Personal Information on the Site or Platform,
    you may contact us at support@boulosolutions.com.<br />
    <span class="text-bold">9. Anonymous Information Collected.<br /></span>
    IP addresses are briefly logged but discarded within a short period of time. No anonymous information that may be
    received from visitors is used in any of our Services, promotions, or research.<br />
    <span class="text-bold">10. Use of Cookies.<br /></span>
    Some pages on the Site may use cookies to identify your browser as You visit pages on the Site. The cookies are used
    for site registration and customization the next time You visit the Site. No information from the cookies are used
    in any of our Services, promotions or research.
    <br />
    Some parts of the Site may also use cookies to track user traffic patterns to determine the usefulness of
    information on our Site to our users and to see how effective our navigational structure is in helping users reach
    that information. Cookies cannot read data off of your hard drive. Your Web browser may allow you to be notified
    when you are receiving a cookie, giving you the choice to accept it or not. If You prefer not to receive cookies
    while browsing our Site, You can set your browser to warn You before accepting cookies and refuse the cookie when
    your browser alerts you to its presence. You can also refuse all cookies by turning them off in your browser. By not
    accepting cookies, some pages may not fully function, and You may not be able to access certain information on the
    Site.
    <br />
    We do not link the information we store in cookies to any Personal information you submit while on the Site.<br />
    <span class="text-bold">11. Links to Third Party Sites.<br /></span>
    The Site may contain links to other websites including social media widgets and “like” or “share” buttons. These
    other websites, third party widgets or buttons are hosted by third parties and not by DCG. DCG is not responsible
    for the privacy practices or the content of such third party websites or links. We encourage you to learn about the
    privacy practices and terms of use of those other companies, including their opt-out processes.<br />
    <span class="text-bold">12. Protecting the Privacy of Children.<br /></span>
    Children under 18 years old are not the target audience for our Site. To protect their privacy, We prohibit the
    solicitation of personal information from children under 13 years old and do not knowingly collect Personal
    Information from such children.<br />
    <span class="text-bold">13. Legal Disclaimer.<br /></span>
    DCG may disclose Personal Information when required by law or in the good-faith belief that such action is necessary
    in order to conform to the edicts of the law or comply with legal process served on DCG.<br />
    <span class="text-bold">14. Changes to this Privacy Policy.<br /></span>
    We reserve the right to change, modify or update this Privacy Policy at any time, so please review it frequently.
    Any material changes in the way We use your Personal Information will be contained in the updated Privacy Policy
    posted on the Site. Your continued use of the updated Policy will signify acceptance of the changes.<br />
    <span class="text-bold">15. Your California Privacy Rights.<br /></span>
    California Civil Code Section § 1798.83 permits users of our Website that are California residents to request
    certain information regarding our disclosure of personal information to third parties for their direct marketing
    purposes. To make such a request, please send an email to support@boulosolutions.com.<br />
    <span class="text-bold">16. More Information.<br /></span>
    If you have questions or concerns about our Privacy Policy, please email us at support@boulosolutions.com.
    <br />
    This Privacy Policy is effective as of July 13, 2018.<br />
  </p>
</div>
<app-footer></app-footer>
