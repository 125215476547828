import { AfterViewInit, ChangeDetectorRef, Component, Inject, Injectable, OnInit } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DialogConfirmCloseComponent } from "src/app/shared/components/dialog-confirm-close/dialog-confirm-close.component";
import { SnackbarData, SnackbarMsgComponent } from "src/app/shared/components/snackbar-msg/snackbar-msg.component";
import { EmployerOnboardingService } from "../../employer-root/employer-root.service";
import { LoggerService as Logger } from "src/app/shared/services/logger.service";
import { iCompensationRange } from "../../employer-root/employer.models";
import { eCompensationTypes, eResponseMsg } from "src/app/shared/enums/employer.enums";
import { iCustomSkill } from "src/app/shared/models/shared.models";
import { eButtonLabels, eDialogType, eSkillLevel } from "src/app/shared/enums/shared.enums";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { MatStepperIntl } from "@angular/material/stepper";
import { iCtrlUpdate } from "./step-job-location/step-job-location.component";
import { UserService } from "src/app/user/user-root/user-root.service";
import { iEmployerJobModel, iJob_DetailsListItem, iQuestion } from "src/app/shared/models/job.model";
import { ModalAddScreeningQuestionComponent } from "../../onboarding/modal-add-screening-question/modal-add-screening-question.component";
import { eJobEditStatus } from "../dialog-employer-job-view/dialog-job-view-root/dialog-job-view-root.component";
import { OnboardingRootService } from "src/app/user/onboarding/onboarding-root/onboarding-root.service";

export interface DialogData {
  selectedJob: iEmployerJobModel;
  dialogTitleType: eDialogType;
  isCreateJob: boolean;
}

@Injectable()
export class StepperIntl extends MatStepperIntl {
  // the default optional label text, if unspecified is "Optional"
  override optionalLabel = "Optional Label ACS";
}

@Component({
  selector: "app-dialog-employer-job-edit",
  templateUrl: "./dialog-employer-job-edit.component.html",
  styleUrls: ["./dialog-employer-job-edit.component.scss"],
  providers: [{ provide: MatStepperIntl, useClass: StepperIntl }],
})
export class DialogEmployerJobEditComponent implements OnInit {
  basicsFormGrp: FormGroup;
  compensationFormGrp: FormGroup;
  aboutRoleFormGrp: FormGroup;
  candidateFormGrp: FormGroup;
  form10: FormGroup;
  formResponsibilities: FormGroup = new FormGroup({
    descriptionCtrl: new FormControl(""),
    listInputCtrl: new FormControl(""),
  });

  //#region Arvine Code
  customToolBarIcons = [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
  ];
  config: Object;
  //#endregion Arvine Code

  animationDuration = 400;
  isBusy: boolean = false;
  expandChk: boolean = false;
  jobDescription: string = "";
  editingJob = null;

  toggleHelp(): void {
    this.expandChk = !this.expandChk;
  }

  fg1 = {
    jobTitleCtrl: "jobTitleCtrl",
    roleTypeCtrl: "roleTypeCtrl",
    arrangementTypeCtrl: "arrangementTypeCtrl",
    arrangementSplitCtrl: "arrangementSplitCtrl",
    jobLocationCtrl: "jobLocationCtrl",
    resideOnsiteCtrl: "resideOnsiteCtrl",
    workingLocationCtrl: "workingLocationCtrl",
  };

  _labels = {
    intro:
      "Follow these step-by-step instructions to create a compelling job listing that will attract your ideal candidates.",
    basics: "Expand for more information.",
    location: "In this section, you'll specify the location preferences for your job listing.",
    compensation: "Compensation details.",
    summary:
      "This is where you enter the duties and activities of the role. Paint a picture of what the candidate's day would look like. You'll enter the specific skills required for the role in another section",
    duties: "Outline the day-to-day responsibilities and duties of the role.",
    softskills: "Finding a candidate who aligns with your organization's values and dynamics is key.",
    background: "Define the desired qualifications for the candidate's background and experience.",
    certificates: "List Certificates for this Position",
    skillCategories: "First step in outlining desired skills needed for the role.",
    skillDetails: "Common skills related to this category.",
    specificSkills: "Already know what you are looking for in a candidate? Start adding skills below!",
    questions: "Guidelines for selecting questions",
    howToConvert: "How to use 'Convert Text to List'",
  };
  _instructions = {
    intro: `Some sections may allow you to provide additional information that doesn't fit into predefined fields. 
      <br/><br/>
      Remember, clear and detailed information in these free-form sections can significantly impact the quality of applicants you attract.
      <br/><br/>`,
    basics: `
      <b>Role Type:</b> In this section, you'll define the type of employment for your job listing. Choose the role type that aligns with your hiring needs and accurately conveys the time commitment the candidate can expect.
        <ul>
        <li><b>Full-Time:</b> The role is a standard full-time position with regular working hours.</li>
        <li><b>Part-Time:</b> The role offers reduced working hours compared to a full-time position.</li>
        <li><b>Contract/Project-Based:</b> The role is for a specific project or has a defined contract duration.</li>
        </ul>
      <br/>
      <b>Working Hours & Schedule:</b> In this section, you'll define the working hours and schedule preferences for your listing. If none of the preselected options perfectly fit your role, you can use the custom field to create your own options. 
      <br/><br/>`,
    location: `<b>Location type:</b> Choose the type of location that best describes the work arrangement for this role<br/>
        <ul>
        <li><b>Remote:</b> The role is entirely remote, and physical presence in an office is not required.</li>
        <li><b>Onsite:</b> The role requires the candidate to work primarily from a specific office location.</li>
        <li><b>Hybrid:</b> The role allows a mix of remote work and in-office collaboration.</li>
        </ul>
      <br/>
      <b>Job Location:</b> This helps potential candidates assess the commute and make informed decisions.<br/>
        <ul>
        <li><b>Onsite:</b> If you selected "Onsite" or "Hybrid," enter the zip code of the primary office location.</li>
        <li><b>Remote:</b> No further details on Location are required, continue to the next step.</li>
        <li><b>Hybrid:</b> Choose the hybrid work model that best describes the expected balance between onsite and remote work.  
          <ul>
          <li><b>Rarely Onsite:</b> Candidates can expect to work from the office only on rare occasions.</li>  
          <li><b>Mostly Remote:</b> The role is predominantly remote, with occasional visits to the office.</li> 
          <li><b>Evenly Split:</b> An equal balance between onsite and remote work.</li>
          <li><b>Mostly Onsite:</b> Most of the work is expected to be performed from the office.</li>  
          <li><b>Rarely Remote:</b> Candidates will spend most of their time working from the office, with rare remote work opportunities.</li>
          </ul> 
        </li>
        </ul>        
      <br/><br/>`,
    compensation: `<b>Compensation type:</b> Choose the type of compensation that best suits your role:
        <ul>
        <li><b>Annual Salary:</b> A fixed yearly compensation for full-time positions.
        <li><b>Hourly Pay:</b> Compensation based on an hourly rate for part-time or hourly positions.</li>
        <li><b>Per Project:</b> A fixed compensation for completing a specific project.</li>
        </ul>
      <br/>
      <b>Compensation range:</b> Choose either the compensation range or the exact rate, but not both. This ensures clarity for potential candidates.
        <ul>
        <li><b>Range:</b> Use the slider bar to set the minimum and maximum values that reflect the compensation you're offering. </li>
        <li><b>Exact Rate:</b> If you choose to enter an exact rate, use the freeform box to input the specific salary, hourly pay, or project-based compensation.</li>
        </ul>
      <br/><br/>`,
    summary: `In this section, you have the opportunity to create a captivating "elevator pitch" for your job description. 
      <b>This is your chance to grab the attention of the best candidates</b> and give them a glimpse into what makes this opportunity exciting. 
      <br/><br/>
      Try to keep your description <b>concise and engaging</b> - Imagine you have a short elevator ride to convince someone your job is a perfect fit. 
      Aim to capture attention within 2-3 sentences. You'll have the opportunity to provide more detailed information about the role in upcoming sections.
      <br/><br/>`,
    duties: `In this section, you'll outline the day-to-day responsibilities and duties of the role. You have the flexibility to input this information in freeform 
      or use a list format for clarity. There are a few options to choose from:
      <br/><br/>
        <ol>
        <li><b>Creating the Description:</b>    
          <ul>
          <li><b>Copy and Paste:</b>If you already have the responsibilities and duties information prepared, feel free to copy and paste it into the freeform box. This allows you to easily transfer existing details into your Boulo listing.</li>    
          <li><b>Freeform Box:</b> Use the freeform box to provide a detailed description of the day-to-day responsibilities. Feel free to format the text as needed to convey the information clearly.</li>
          </ul>
        
        <li><b>Formatting:</b> 
        <ul>
          <li><b>Convert to List (Optional):</b> If you prefer a list format, click the "Convert to List" button after pasting your text. This can enhance readability and make it easier for candidates to grasp the key points.</li>    
          <li><b>Individual List Items:</b> Alternatively, you can enter individual list items directly into the freeform box. Each item should represent a specific responsibility or duty. Use bullet points or numbering for clarity.
            <ul>
            <li>You can select whether the list items are a requirement for the position by clicking the icon.</li>    
            <li>Click and drag the line items if you would like to change the order in which they show.</li>
            </ul>
          </li>
        </ul>
        </ol>
      <br/><br/>`,
    softskills: `In this section, you have the opportunity to highlight the personality traits and cultural aspects that are important for success in your company. Finding a candidate who aligns with your organization's values and dynamics is key. 
      <br/><br/>
      Tailor the description to reflect the specific dynamics of your team. Consider the personalities and working styles that will complement and enhance your existing team.
      <br/><br/>
      As in the previous section, you can copy and paste a description or enter it manually. You can also convert the text to a list or add individual list items.
      <br/><br/>
      <b>Tips:</b>
        <ul>
        <li><b>Emphasize the significance of personality and culture fit:</b> explain that you're not just looking for qualifications but also a candidate who will thrive within the company's environment and contribute positively to the team.</li>      
        <li><b>Be specific about the traits and values that are important to your company:</b> whether it's teamwork, adaptability, innovation, or other qualities, clearly communicate what you're looking for in a candidate.</li>      
        <li><b>Encourage authenticity in applicants:</b> Let them know that you value diversity and are looking for individuals who bring their unique perspectives to the team.</li>
        </ul>
      <br/><br/>`,
    background: `<b>Years of Experience:</b> Select the number of years of experience you would like from the candidate using the drop-down menu. Consider the level of expertise needed for the role and choose a range that aligns with your expectations.
      <br/><br/>
      <b>Education Level:</b> Choose the education level you would like from the candidate using the drop-down menu. Indicate whether a specific degree or level of schooling  is necessary for the role.
      <br/><br/>
      <b>Preferred vs. Required:</b> you can select whether the selected years of experience and education level are preferred or required. This helps candidates understand the level of flexibility in meeting these criteria
      <br/><br/>`,
    certificates: `In this section, you can list the certificates that may be required for the position (if applicable). 
      <br/><br/>
      Use the freeform box to enter the name or type of certificate needed then press 'Enter' or the '+' button to add it to the list. Order the certificates by dragging and dropping them. The certificates will appear on the job posting in the order they are shown below.
      <br/><br/>
      You can make the certificate required by clicking on the bookmark icon, otherwise the certificate will be preferred.
      <br/><br/>`,
    skillCategories: `In this section, you will outline the skills you are seeking in a candidate and specify the proficiency level for each skill. 
      <br/><br/>
      If you need guidance, don't worry -- we have put together some common job categories with related skills to help you find your perfect candidate. 
      <br/><br/>
      If you already have skills in mind or if you would like to include skills that aren't listed in these preselected options - you can jump to the step below: <i>“Role Specific Skills</i>.”
      <br/><br/>
      <b>Choosing a category:</b>
        <ul>
        <li>You can choose <b>up to 2</b> skill categories. </li>
        <li>Once you choose a category, you'll be able to see a detailed list of skills related to that category in the next steps.</li>
        </ul>
      <br/><br/>`,
    skillDetails: `In this section, specify the skills and the skill level for one of the categories you selected. If you don't see a skill in your selected category that you would like to include, add them on the step below: <i>“Role Specific Skills</i>.”
      <br/><br/>      
      <b>Choosing specific skills:</b>
        <ul>
        <li>Use the slider bar to choose the skill level you would like to see in a candidate. </li>
        <li>Only choose the skills that are important and relevant to the role. </li>
        <li>Skills that are skipped will <i>not</i> be included in the posting.</li>
        </ul>
      <br/><br/>`,
    specificSkills: `In this section, you can enter the role-specific skills you would like to see in a candidate. 
        <ul>
        <li>You can enter <b>up to 15 skills</b> using the freeform box. </li>
        <li>For each skill, specify the <b>proficiency</b> and whether this skill is <b>preferred or required</b>. </li>
        <li>Drag and drop skills to arrange them in your preferred order of importance. This will help the candidate understand the priority you place on each skill.</li>
        </ul> 
      <br/><br/>`,
    questions: `In this section, you can select or create knock-out questions to ensure candidates are meeting specfic criteria for the application. 
      This step helps ensure that you focus on candidates who are the best fit for the role and company.
      <br/><br/>
      We have provided you with some common screening questions to choose from. You can also create your own screening questions if you don't see what you need here.
      <br/><br/>
      We recommend selecting one or two questions to avoid exclusing too many candidates.
      <br/><br/>`,
    howToConvert: `The pasted code will be automatically converted to list items when you click 'Convert to List'.
    <br/><br/>
    <b>Requirements for Conversion:</b>
      <ul>
        <li><b>Colon (:) </b> will split the introduction to the list and the list items.</li>
        <li><b>List items are split by new lines.</b>
          <ul>
          <li>List items will appear as a drag-and-drop style box in this editor, but will appear as a formatted list on the public job posting.</li>
          <li>The conversion will remove numbers and dashes at the start since list formatting is automatically applied later.</li>
          </ul>
        </li>
      </ul>
    <br/>
    <b>Example Copied Text:</b><br/>
    These are the day to day responsibilities for this position:<br/>
    Plan, supervise, and complete multiple engagements <br/>
    Collaborate with and support other managers and partners <br/>
    Build and nurture internal and external relationships<br/>    
    <br/><br/>
    <b>Example After Format (How it will Appear on Public Job Posting):</b><br/>
    These are the day to day responsibilities for this position:<br/>
      <ul>
        <li>Plan, supervise, and complete multiple engagements</li>
        <li>Collaborate with and support other managers and partners</li>
        <li>Build and nurture internal and external relationships</li>
      </ul>
    `,
  };

  //#region Setup
  constructor(
    public jobDialogRef: MatDialogRef<DialogEmployerJobEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private _service: EmployerOnboardingService,
    private _usrSrvc: UserService,
    private formBuilder: FormBuilder,
    private _matStepperIntl: MatStepperIntl,
    private cdr: ChangeDetectorRef
  ) {
    jobDialogRef.disableClose = true;

    jobDialogRef.backdropClick().subscribe(() => {
      this.openConfirmationDialog();
    });

    this.form10 = this.formBuilder.group({
      inputFields: this.formBuilder.array([]),
    });
  }

  ngOnInit(): void {
    this.editingJob = structuredClone(this.data.selectedJob);
    //#region Arvine Code
    this.config = this._usrSrvc.SetFullCustomTextEditorConfig(this.customToolBarIcons);
    //#endregion Arvine Code
    this.setupJobBasics();

    this.setupCompensation();

    this.setupAboutRole();

    this.setupIdealCandidate();

    this.addInputField(); // Add an initial input field
    this.updateOptionalLabel();
    this.jobDescription = this._job?.description;

    //disable if recruiter created job
    if (this._job?.hasOwnProperty("appCreatedJob") && this._job?.appCreatedJob === false) {
      this.basicsFormGrp.disable();
      this.aboutRoleFormGrp.disable();
      this.candidateFormGrp.disable();
      this.compensationFormGrp.disable();
    } else {
      this.basicsFormGrp.enable();
      this.aboutRoleFormGrp.enable();
      this.candidateFormGrp.enable();
      this.compensationFormGrp.enable();
    }
  }

  scrollToHeader(event: any, className: string): void {
    // Optionally, you can use event to determine which step was selected
    const element = document.querySelector(className);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  public onStepperSelectionChange(evant: any) {
    this.scrollToSectionHook(evant.selectedIndex);
  }

  private scrollToSectionHook(index: any) {
    const element = document.querySelector(`mat-step-header[aria-posinset="${index}"]`);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }, 250);
    }
  }

  private setupJobBasics(): void {
    const zip = this._job?.address?.zip?.length > 0 ? this._job?.address?.zip : this._job?.locationZip;
    this.basicsFormGrp = this._formBuilder.group({
      jobTitleCtrl: new FormControl(this._job?.title, [Validators.required]),
      roleTypeCtrl: new FormControl(this._job?.employmentType, [Validators.required]),
      resideOnsiteCtrl: new FormControl(this._job?.isOnSiteRequired === true),
      workingLocationCtrl: new FormControl(this._job?.workingLocationZip, [
        Validators.pattern("^[0-9]*$"), // Only numbers allowed
        Validators.maxLength(5), // Maximum length of 5
      ]),
      arrangementTypeCtrl: new FormControl(this._job?.arrangementType, [Validators.required]),
      arrangementSplitCtrl: new FormControl(this._job?.arrangementSplit, [Validators.required]),
      jobLocationCtrl: new FormControl(zip, [
        Validators.required,
        Validators.pattern("^[0-9]*$"), // Only numbers allowed
        Validators.maxLength(5), // Maximum length of 5
        Validators.minLength(5), // Minimum length of 5
      ]),
    });

    //Arrangement (Hybrid%)
    const splitControl = this.basicsFormGrp.get(this.fg1.arrangementSplitCtrl);
    if (
      this.basicsFormGrp.get(this.fg1.arrangementTypeCtrl).value === eValues.Hybrid &&
      (splitControl.value === eArrangementSplit.NotSet || !splitControl.value || splitControl.value == undefined)
    ) {
      splitControl.setValue(eArrangementSplit.EvenlySplit);
    }
  }

  get _isMobile(): boolean {
    return this._service.isMobileScreen;
  }

  private setupCompensation(): void {
    this.compensationFormGrp = this._formBuilder.group({
      compensationTypeCtrl: new FormControl(this._job?.compensationType),
      compensationLowCtrl: new FormControl(this._job?.compensationLow),
      compensationHighCtrl: new FormControl(this._job?.compensationHigh),
      exactRateCtrl: new FormControl(Number(this._job?.exactRate) == 0 ? null : this._job?.exactRate),
    });
    this.updateRangeSliderConfig(this._job?.compensationType);

    var hasCompensationRangeSet = false;
    if (
      this.compensationFormGrp.get("compensationLowCtrl").value != undefined &&
      this.compensationFormGrp.get("compensationHighCtrl").value != undefined
    ) {
      hasCompensationRangeSet = true;
      this.tempCompensationRange = [
        this.compensationFormGrp.get("compensationLowCtrl").value,
        this.compensationFormGrp.get("compensationHighCtrl").value,
      ];
    } else {
      this.tempCompensationRange = [0, this.step];
    }
  }

  updateJobDescription(text: string): void {
    this.jobDescription = text == null ? "" : text;
  }

  private setupAboutRole(): void {
    this.aboutRoleFormGrp = this._formBuilder.group({
      roleSummaryCtrl: new FormControl(this._job.description),
      benefitsCtrl: new FormControl(this._job.benefits),
      dutiesSummaryCtrl: new FormControl(this._job.dutiesList?.summaryText ?? ""),
      dutiesInputCtrl: new FormControl(""),
      idealFitSummaryCtrl: new FormControl(this._job.idealFitList?.summaryText ?? ""),
      idealFitInputCtrl: new FormControl(""),
    });

    this._job.dutiesList?.items?.forEach((x) => {
      this.listOfDuties.push(x);
    });
    this._job.idealFitList?.items?.forEach((x) => {
      this.listOfIdealTraits.push(x);
    });
    this._job.certsList?.items?.forEach((x) => {
      this.listOfCerts.push(x);
    });
  }

  private setupIdealCandidate(): void {
    this.candidateFormGrp = this._formBuilder.group({
      yearsExperienceCtrl: new FormControl(this._job?.yearsRequired),
      expRequiredCtrl: new FormControl(this.getStringFromNumericBool(this._job?.isExperienceRequired)),
      educationLevelCtrl: new FormControl(this._job?.educationDegree),
      eduRequiredCtrl: new FormControl(this.getStringFromNumericBool(this._job?.isEducationRequired)),
      eduCertificatesCtrl: new FormControl(this._job?.certifications),
      certsInputCtrl: new FormControl(""),
    });

    //Specific/Custom Skills
    this.selectedBasicSkillsChips = this._job.combinedSkills?.basicSkills ?? [];
    this.selectedBasicSkillsChips.forEach((element) => {
      if (!this.allBasicSkillsChips.includes(element)) this.allBasicSkillsChips.push(element);
    });
    this.selectedBenefitsChips = this._job.benefits ?? [];
    this.selectedBenefitsChips.forEach((element) => {
      if (!this.allBenefitsChips.includes(element)) this.allBenefitsChips.push(element);
    });
  }

  private getStringFromNumericBool(val: number | null): string {
    if (val === 1) return "1";
    return "0";
  }

  updateOptionalLabel() {
    this._matStepperIntl.optionalLabel = "this is an example of optional text";
    // Required for the optional label text to be updated
    // Notifies the MatStepperIntl service that a change has been made
    this._matStepperIntl.changes.next();
  }
  //#endregion Setup

  //#region Gets
  get _jobTitleCtrl(): AbstractControl {
    return this.basicsFormGrp.get("jobTitleCtrl");
  }
  get _roleTypeCtrl(): AbstractControl {
    return this.basicsFormGrp.get("roleTypeCtrl");
  }
  get _arrangementTypeCtrl(): AbstractControl {
    return this.basicsFormGrp.get("arrangementTypeCtrl");
  }
  get _jobLocationCtrl(): AbstractControl {
    return this.basicsFormGrp.get("jobLocationCtrl");
  }
  get _getDuty(): eInputListTypes {
    return eInputListTypes.Duty;
  }
  get _getTrait(): eInputListTypes {
    return eInputListTypes.Trait;
  }
  get _getCert(): eInputListTypes {
    return eInputListTypes.Cert;
  }
  get _showSkillDeets1(): boolean {
    //Bool to show the 1st skill details step section
    const selectedCats = this._job?.combinedSkills?.skillCategories ?? [];
    return selectedCats?.length > 0;
  }
  get _showSkillDeets2(): boolean {
    //Bool to show the 2nd skill details step section
    const selectedCats = this._job?.combinedSkills?.skillCategories ?? [];
    return selectedCats?.length > 1;
  }
  get _isJobBasicInvalid(): boolean {
    if (this.basicsFormGrp.invalid && (this._roleTypeCtrl.invalid || this._jobTitleCtrl.invalid)) {
      return true;
    }

    return false;
  }

  get isJobDescriptionInvalid(): boolean {
    return this.jobDescription?.length < 1;
  }

  isJobLocationInvalid: boolean = false;
  get _isJobLocationInvalid(): boolean {
    if (this.basicsFormGrp.invalid && (this._arrangementTypeCtrl.invalid || this._jobLocationCtrl.invalid)) {
      this.isJobLocationInvalid = true;
      return true;
    }

    this.isJobLocationInvalid = false;
    return false;
  }

  get _getHelpIcon(): string {
    return this.expandChk ? "expand_less" : "expand_more";
  }

  get _job(): iEmployerJobModel {
    return this.editingJob;
  }
  get canSubmit(): boolean {
    return this.basicsFormGrp.valid && this.jobDescription?.length > 0;
  }

  get _submitBtnText(): string {
    return this.data?.isCreateJob ? `${eButtonLabels.Create} Job` : eButtonLabels.Save_Changes;
  }

  get _getJob(): iEmployerJobModel {
    if (!this.data.selectedJob) {
      Logger.debug("selectedJob", this.editingJob);
    }
    return this.editingJob;
  }

  get _questions(): iQuestion[] {
    return this._job.screening.questions.filter((x) => x.question?.length > 0);
  }

  get _hasQuestions(): boolean {
    return this._job?.screening?.questions?.length > 0;
  }

  get inputFields() {
    return this.form10.get("inputFields") as FormArray;
  }

  isSelected(ctrl: string, chipValue: string): boolean {
    return chipValue === this.basicsFormGrp.get(ctrl).value;
  }
  //#endregion Gets

  //#region Actions
  chipClicked(ctrl: string, chipValue: string, element: any) {
    this.basicsFormGrp.get(ctrl).setValue(chipValue);
    element.target.blur();

    // Set Work Arrangements Split Defaults
    if (ctrl === this.fg1.arrangementTypeCtrl) {
      const isHybrid = this.basicsFormGrp.get(ctrl).value.toLowerCase() === eValues.Hybrid;

      const splitCtrl = this.basicsFormGrp.get(this.fg1.arrangementSplitCtrl);
      //set default on hybrid or clear on !hybrid
      if (isHybrid && splitCtrl.value == eArrangementSplit.NotSet) {
        splitCtrl.setValue(eArrangementSplit.EvenlySplit);
      } else if (!isHybrid && splitCtrl.value != eArrangementSplit.NotSet) {
        splitCtrl.setValue(eArrangementSplit.NotSet);
      }
    }
  }

  addInputField() {
    Logger.debug("AddInputField");
    this.inputFields.push(new FormControl(""));
  }

  openConfirmationDialog() {
    const confirmationRef = this.dialog.open(DialogConfirmCloseComponent, { width: "600px" });

    confirmationRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.jobDialogRef.close(null);
      } else {
        // User cancelled the dialog closing
      }
    });
  }

  get _getQuestions(): iQuestion[] {
    const questions = this._job?.screening?.questions?.filter((x) => x.question?.length > 0);
    return questions?.length > 0 ? questions : [];
  }

  openAddQuestionDialog(): void {
    const dialogRef = this.dialog.open(ModalAddScreeningQuestionComponent, {
      width: "1000px",
      data: {
        selectedQuestions: this._getQuestions,
      },
    });

    dialogRef.afterClosed().subscribe((result: iQuestion[]) => {
      this._job.screening.questions = result;
    });
  }
  //#endregion Actions

  //#region COMPENSATION
  optionsCompensationPreferences = [
    { value: eCompensationTypes.Salary, label: "Annual Salary" },
    { value: eCompensationTypes.Hourly, label: "Hourly Pay" },
    { value: eCompensationTypes.Project, label: "Per Project" },
  ];
  range: number[];
  min: number;
  max: number;
  step: number = 0;
  connect: boolean = true;
  tempCompensationRange: number[];

  get _compensationType() {
    return this.compensationFormGrp.get("compensationTypeCtrl").value;
  }

  get isCompensationPreferenceSet() {
    return this._compensationType && this._compensationType?.trim() && this._compensationType.length > 1;
  }
  get _isExactRateCompensation(): boolean {
    const switchCheckbox = document.getElementById("switch") as HTMLInputElement;
    return !switchCheckbox?.checked;
  }

  updateBasicsCtrl(data: iCtrlUpdate): void {
    this.basicsFormGrp.get(data.name).setValue(data.val);
    this.cdr.detectChanges();
    Logger.debug(`updated val of ${data.name} to ${data.val}`);
  }

  private resetRangeIfEmpty(compRangeObj: iCompensationRange, newTypeValue: string) {
    if (newTypeValue == this._job?.compensationType && this._job?.compensationLow && this._job?.compensationHigh) {
      this.tempCompensationRange = [this._job?.compensationLow, this._job?.compensationHigh];
    } else {
      this.tempCompensationRange = this.getDefaultRange(newTypeValue);
    }
  }

  private getDefaultRange(compType: string): number[] {
    let rangeLow = 0;
    let rangeHigh = 100;
    if (this._compensationType === eCompensationTypes.Salary) {
      rangeLow = 35000;
      rangeHigh = 50000;
    }
    if (this._compensationType === eCompensationTypes.Hourly) {
      rangeLow = 10;
      rangeHigh = 25;
    }
    if (this._compensationType === eCompensationTypes.Project) {
      rangeLow = 400;
      rangeHigh = 1000;
    }

    return [rangeLow, rangeHigh];
  }

  updateRangeSliderConfig(value: string): any {
    let compType = this._service.compensationRangeData.find((x) => x.type == value);

    if (!compType) {
      compType = this._service.compensationRangeData.find((x) => x.type === "");
    }

    if (compType.max <= compType.min) {
      compType.max = compType.min + compType.step * 10;
    } // Ensure max is greater than min and has at least 10 steps if not

    this.step = compType.step;
    this.min = compType.min;
    this.max = compType.max;
    this.resetRangeIfEmpty(compType, value);
  }

  private formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });

  get formattedMinRange(): string {
    if (this.tempCompensationRange == undefined) return "";
    return this.formatter.format(this.tempCompensationRange[0]);
  }

  get formattedMaxRange(): string {
    if (this.tempCompensationRange == undefined) return "";
    return this.formatter.format(this.tempCompensationRange[1]);
  }
  //#endregion COMPENSATION

  //#region ABOUT THE ROLE - duties
  listOfDuties: iJob_DetailsListItem[] = [];
  listOfIdealTraits: iJob_DetailsListItem[] = [];
  listOfCerts: iJob_DetailsListItem[] = [];
  get _getDutiesList(): iInputListItem[] {
    return this.listOfDuties.map((x) => ({ value: x.txt, isRequired: x.isRequired }));
  }
  updateDutiesList(data: iInputListUpdate): void {
    this.listOfDuties = data.items.map((item) => ({ txt: item.value, isRequired: item.isRequired === true }));
    this.aboutRoleFormGrp.get("dutiesSummaryCtrl").setValue(data.summaryText);
  }
  addDuty(): void {
    this.listOfDuties.push({ txt: this.aboutRoleFormGrp.get("dutiesInputCtrl").value, isRequired: false });
    this.aboutRoleFormGrp.get("dutiesInputCtrl").setValue("");
  }
  removeDuty(idx: number): void {
    this.listOfDuties.splice(idx, 1);
  }
  get _getTraitsList(): iInputListItem[] {
    return this.listOfIdealTraits.map((x) => ({ value: x.txt, isRequired: x.isRequired === true }));
  }
  updateTraitsList(data: iInputListUpdate): void {
    this.listOfIdealTraits = data.items.map((item) => ({ txt: item.value, isRequired: item.isRequired === true }));
    this.aboutRoleFormGrp.get("idealFitSummaryCtrl").setValue(data.summaryText);
  }
  addIdealTrait(): void {
    this.listOfIdealTraits.push({ txt: this.aboutRoleFormGrp.get("idealFitInputCtrl").value, isRequired: false });
    this.aboutRoleFormGrp.get("idealFitInputCtrl").setValue("");
  }
  removeIdealTrait(idx: number): void {
    this.listOfIdealTraits.splice(idx, 1);
  }
  get _getCertsList(): iInputListItem[] {
    return this.listOfCerts.map((x) => ({ value: x.txt, isRequired: x.isRequired === true }));
  }
  updateCertsList(data: iInputListUpdate): void {
    this.listOfCerts = data.items.map((item) => ({ txt: item.value, isRequired: item.isRequired === true }));
  }
  addCert(): void {
    this.listOfCerts.push(this.candidateFormGrp.get("certsInputCtrl").value);
    this.candidateFormGrp.get("certsInputCtrl").setValue("");
  }
  removeCert(idx: number): void {
    this.listOfCerts.splice(idx, 1);
  }
  //#endregion ABOUT THE ROLE - duties

  //#region ATTRACT TALENT
  selectedBenefitsChips: string[];
  public allBenefitsChips: string[] = [
    "Health Insurance",
    "Dental Insurance",
    "Vision Insurance",
    "Life Insurance",
    "Retirement Plan",
    "Paid Time Off",
    "Flexible Schedule",
    "Remote Work",
    "Tuition Reimbursement",
    "Professional Development",
    "Employee Discounts",
    "Relocation Assistance",
    "Stock Options",
    "Performance Bonuses",
    "Childcare Benefits",
    "Paid Parental Leave",
    "Gym Membership",
    "Casual Dress Code",
    "Company Social Events",
  ];
  maxBenefitsChipsAllowed: number = 10;
  get _selectedBenefitsChips(): string[] {
    return this._job.benefits ?? [];
  }
  addBenefitsChip(chip: string): void {
    this.allBenefitsChips.push(chip);
  }
  selectBenefitsChip(chip: string): void {
    this._job.benefits.push(chip);
  }
  unselectBenefitsChip(chip: string): void {
    const index = this._job.benefits.indexOf(chip);
    if (index >= 0) {
      this._job.benefits.splice(index, 1);
    }
  }
  //#endregion ATTRACT TALENT

  //#region EDUCATION & EXPERIENCE
  optionsExp = [
    { value: 0, label: "No experience required" },
    { value: 1, label: "Less than 1 year" },
    { value: 3, label: "1-4 years" },
    { value: 7, label: "5-8 years" },
    { value: 10, label: "8+ years" },
  ];

  optionsEdu = [
    { value: "No Education Requirement", label: "No Education Requirement" },
    { value: "Some College", label: "Some College" },
    { value: "Associates", label: "Associate's Degree" },
    { value: "Bachelors", label: "Bachelor's Degree" },
    { value: "Graduate Degree", label: "Graduate Degree" },
    { value: "Doctorate or Professional Degree", label: "Doctorate or Professional Degree" },
  ];

  optionsSkillLevel = [
    { value: eSkillLevel.Unset, label: "Unset" },
    { value: eSkillLevel.Any, label: "Any" },
    { value: eSkillLevel.Beginner, label: "Beginner" },
    { value: eSkillLevel.Intermediate, label: "Intermediate" },
    { value: eSkillLevel.Advanced, label: "Advanced" },
  ];
  //#endregion EDUCATION & EXPERIENCE

  //#region CUSTOM SKILLS
  selectedWorkHours: string[] = []; //todo - review this
  public allBasicSkillsChips: string[] = [];
  selectedBasicSkillsChips: string[];
  maxBasicSkillsChipsAllowed: number = 15;

  unselectBasicSkillsChip(chip: string) {
    const index = this.selectedBasicSkillsChips.indexOf(chip);
    if (index >= 0) {
      this.selectedBasicSkillsChips.splice(index, 1);
      this.removeCustomSkill(chip);
    }
  }
  selectBasicSkillsChip(chip: string) {
    const labelVal = this.getCustomSkillValFromInput(chip);
    const exists = this.doesCustomSkillExistAlready(labelVal);
    if (!exists) {
      this.selectedBasicSkillsChips.push(chip);
      this.setCustomSkill(chip, labelVal);
    } else {
      this._service.generalMsg(
        "Skill not selected.",
        `Selected skill of ${chip} (${labelVal}) is too similar to another selected chip. Unselect similar chip to select this one instead.`
      );
    }
  }
  addedBasicSkillsChip(chip: string) {
    const labelVal = this.getCustomSkillValFromInput(chip);
    const exists = this.doesCustomSkillExistAlready(labelVal);
    this.allBasicSkillsChips.push(chip);
    if (!exists) {
      this.selectedBasicSkillsChips.push(chip);
      this.setCustomSkill(chip, labelVal);
    } else {
      this._service.generalMsg(
        "Skill not selected.",
        `Added skill of ${chip} (${labelVal}) is too similar to another selected chip. Unselect similar chip to select this one instead.`
      );
    }
  }
  getCustomSkillValFromInput(label: string): string {
    return label
      .toLowerCase()
      .trim()
      .replace(/[^a-zA-Z0-9#+&.!]/g, ""); //remove all special characters- leave only letters, numbers, #, ., +, &, !
  }
  setCustomSkill(label: string, labelVal: string): void {
    if (!this._job?.combinedSkills.customSkills) {
      this._job.combinedSkills.customSkills = [];
    } else {
      const alreadyExists = this.doesCustomSkillExistAlready(labelVal);
      if (alreadyExists) {
        Logger.log(
          `Custom Skill ${label}'s value of ${labelVal} either already exists or is too similar to an existing selected skill.`
        );
        return;
      }
    }

    const customSkill: iCustomSkill = {
      display: label,
      value: labelVal,
      level: eSkillLevel.Unset,
      isRequired: true,
    };
    this._job?.combinedSkills.customSkills.push(customSkill);
    Logger.info("customSkills", this._job?.combinedSkills.customSkills);
  }
  doesCustomSkillExistAlready(val: string): boolean {
    const exists = this._job?.combinedSkills.customSkills.some((skill) => skill.value === val);

    return exists;
  }
  removeCustomSkill(label: string): void {
    const labelVal = this.getCustomSkillValFromInput(label);
    const indexToRemove = this._job?.combinedSkills.customSkills.findIndex((skill) => skill.value === labelVal);

    if (indexToRemove !== -1) {
      // Remove the item at the found index
      this._job?.combinedSkills.customSkills.splice(indexToRemove, 1);
      console.log(`Removed the item "${label}"/"${labelVal}" from the custom skills.`);
    } else {
      console.log(`The string "${label}"/"${labelVal}" was not found in the custom skills.`);
    }
  }

  listOfDetails: string[] = [];
  addDetail() {
    this.listOfDetails.push(this.formResponsibilities.get("listInputCtrl").value);
    this.formResponsibilities.get("listInputCtrl").setValue("");
  }
  removeDetail(idx: number): void {
    this.listOfDetails.splice(idx, 1);
  }
  toggleRequiredSkill(skillOrigIdx: string): void {
    this._job.combinedSkills.customSkills[skillOrigIdx].isRequired =
      !this._job?.combinedSkills.customSkills[skillOrigIdx].isRequired;
  }
  get _getCustomSkills(): iCustomSkill[] {
    return this._job?.combinedSkills.customSkills;
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    //todo: save final version
  }

  //#endregion CUSTOM SKILLS

  //#region SIX-EIGHT STEPS (SKILL CATEGORIES)

  _getSkillCategory(skillIdx: number): string {
    let catName = "";
    const selectedCats =
      this._job?.combinedSkills?.skillCategories?.length > 0 ? this._job?.combinedSkills.skillCategories : [];
    if (selectedCats?.length > skillIdx) {
      catName = selectedCats[skillIdx];
    }

    return catName;
  }

  _getSkillDetailsStepLabel(skillIdx: number): string {
    let catName = "Choose a Skill Category to See ";
    const selectedCats = this._job?.combinedSkills?.skillCategories ? this._job?.combinedSkills.skillCategories : [];
    if (selectedCats?.length > skillIdx) {
      catName = selectedCats[skillIdx];
    }

    return catName;
  }

  //#endregion SIX-EIGHT STEPS (SKILL CATEGORIES)

  //#region Save / Update / Delete
  setJobData(job: iEmployerJobModel) {
    job.address = { city: "", state: "", zip: "" };
    job.title = this.basicsFormGrp.get(this.fg1.jobTitleCtrl).value;
    job.locationZip = this.basicsFormGrp.get(this.fg1.jobLocationCtrl)?.value;
    job.address.zip = job.locationZip;
    job.isOnSiteRequired = this.basicsFormGrp.get(this.fg1.resideOnsiteCtrl).value;
    job.onsite = this.convertArrangementTypeToOnsite(this.basicsFormGrp.get(this.fg1.arrangementTypeCtrl).value);
    job.workingLocationZip = job.isOnSiteRequired === true ? this.basicsFormGrp.get(this.fg1.workingLocationCtrl).value : "";
    job.arrangementType = this.basicsFormGrp.get(this.fg1.arrangementTypeCtrl).value;
    job.arrangementSplit = this.basicsFormGrp.get(this.fg1.arrangementSplitCtrl).value;
    job.employmentType = this.basicsFormGrp.get(this.fg1.roleTypeCtrl).value;
    job.flexibility = this._job.flexibility?.length > 0 ? this._job.flexibility : [];
    job.benefits = this._job.benefits?.length > 0 ? this._job.benefits : [];
    // second form group
    job.compensationType = this.compensationFormGrp.get("compensationTypeCtrl").value;

    const exactRateCtrl = this.compensationFormGrp.get("exactRateCtrl");
    const defaultRange = this.getDefaultRange(job.compensationType);
    const rangeUpdated =
      this.tempCompensationRange[0] !== 0 &&
      this.tempCompensationRange[1] !== 1 &&
      (this.tempCompensationRange[0] !== defaultRange[0] || this.tempCompensationRange[1] !== defaultRange[1]);
    const valuesUpdates = exactRateCtrl.dirty || rangeUpdated;
    if (job.compensationType === eCompensationTypes.Unset) {
      job.compensationLow = null;
      job.compensationHigh = null;
      job.exactRate = null;
    } else if (this._isExactRateCompensation && valuesUpdates) {
      job.exactRate = exactRateCtrl.value?.trim().length > 0 ? exactRateCtrl.value.trim() : null;
      job.compensationLow = job.exactRate !== null ? null : this.tempCompensationRange[0];
      job.compensationHigh = job.exactRate !== null ? null : this.tempCompensationRange[1];
    } else if (valuesUpdates) {
      job.compensationLow = this.tempCompensationRange[0];
      job.compensationHigh = this.tempCompensationRange[1];
      job.exactRate = null;
    }

    // third form group - Attract Talent
    job.description = this.jobDescription;
    job.dutiesList == null ? (job.dutiesList = { summaryText: "", items: [] }) : job.dutiesList;
    job.dutiesList.summaryText = this.aboutRoleFormGrp.get("dutiesSummaryCtrl").value;
    job.dutiesList.items = this.listOfDuties;
    job.idealFitList == null ? (job.idealFitList = { summaryText: "", items: [] }) : job.idealFitList;
    job.idealFitList.summaryText = this.aboutRoleFormGrp.get("idealFitSummaryCtrl").value;
    job.idealFitList.items = this.listOfIdealTraits;
    job.certsList == null ? (job.certsList = { summaryText: "", items: [] }) : job.certsList;
    job.certsList.summaryText = "";
    job.certsList.items = this.listOfCerts;

    // fourth form group
    job.yearsRequired = this.candidateFormGrp.get("yearsExperienceCtrl").value;
    job.isExperienceRequired = this.candidateFormGrp.get("expRequiredCtrl").value === "1" ? 1 : 0;
    job.educationDegree = this.candidateFormGrp.get("educationLevelCtrl").value;
    job.isEducationRequired = this.candidateFormGrp.get("eduRequiredCtrl").value === "1" ? 1 : 0;
    job.certifications = this.candidateFormGrp.get("eduCertificatesCtrl").value;
    // fifth form group
    job.combinedSkills.basicSkills = this.selectedBasicSkillsChips;
    job.screening.questions = this._job?.screening?.questions?.filter((x) => x.isActive === true);

    //Skills
    job.combinedSkills.skillCategories = this._job?.combinedSkills?.skillCategories;
    job.combinedSkills.categorySpecificSkills = this._job?.combinedSkills?.categorySpecificSkills;
    job.combinedSkills.customSkills = this._job?.combinedSkills?.customSkills;
    job.combinedSkills.skillCategories = this._job?.combinedSkills?.skillCategories;
  }
  createJob(job: iEmployerJobModel) {
    this.setJobData(job);
    this._service.bullhornData.jobs.unshift(job);
  }
  updateJob(job: iEmployerJobModel) {
    if (job) {
      job.combinedSkills.skillCategories = this._job?.combinedSkills.skillCategories;
      job.combinedSkills.categorySpecificSkills = this._job?.combinedSkills.categorySpecificSkills;
      this.setJobData(job);
    } else {
      Logger.error("Unable to find job in current data to update.");
      return;
    }
  }

  setSkillLevel(option: eSkillLevel, idx: number) {
    Logger.log(`Setting index:${idx} to eSkillLevel val of ${option}`);
    this._job.combinedSkills.customSkills[idx].level = option;
    Logger.info(`Updated skill:${this._job?.combinedSkills.customSkills[idx]}`);
  }

  convertArrangementTypeToOnsite(value: eValues): string {
    if (value === eValues.Remote) {
      return eValues.OffSite;
    }
    return value;
  }

  async saveAll() {
    this.isBusy = true;
    let isUpdate = false;
    let job: iEmployerJobModel = this.data.isCreateJob
      ? this.editingJob
      : this._service.bullhornData.jobs.find((x) => x.id == this._job?.id);
    if (this.data.isCreateJob) {
      this.createJob(job);
    } else {
      this.updateJob(job);
      isUpdate = true;
    }
    const msg = await this._service.upsertJob(job);
    if (isUpdate) {
      this._service.notifyCandidatesBasedOnJobs(job.id.toString());
    }

    if (msg == eResponseMsg.Success) {
      // if (job) {
      //   this.setJobData(job);
      // }
      this.jobDialogRef.close(job);
      this._service.saveSuccessMsg();
    } else {
      this._service.saveFailedMsg();
    }
    this.isBusy = false;
  }

  saveSuccessMsg() {
    this._snackBar.openFromComponent(SnackbarMsgComponent, {
      duration: 5000,
      data: {
        boldMsg: "Changes have been saved!",
        detailsMsg: "",
      } as SnackbarData,
      panelClass: ["snackbar-success"], //applies success styling, remove this line for generic style
    });
  }

  deleteTimer: any; // To store the timer reference
  startDeleteTimer(type: eInputListTypes, idx: number) {
    this.deleteTimer = setTimeout(() => {
      switch (type) {
        case eInputListTypes.Duty:
          this.removeDuty(idx);
          break;
        case eInputListTypes.Cert:
          this.removeCert(idx);
          break;
        case eInputListTypes.Trait:
          this.removeIdealTrait(idx);
          break;
      }
    }, 1000); // Delete after 1 second hold
  }

  cancelDeleteTimer() {
    if (this.deleteTimer) {
      clearTimeout(this.deleteTimer);
    }
  }
  //#endregion Save / Update / Delete
}
export interface iInputListUpdate {
  summaryText: string;
  items: iInputListItem[];
}
export interface iInputListItem {
  value: string;
  isRequired?: boolean;
  ctrl?: FormControl | AbstractControl;
  ctrlName?: string;
}

export enum eInputListTypes {
  Duty = 0,
  Trait = 1,
  Cert = 2,
}

export enum eArrangementSplit {
  NotSet = "0",
  RarelyOnSite = "1",
  MostlyRemote = "2",
  EvenlySplit = "3",
  MostlyOnSite = "4",
  RarelyRemote = "5",
}

export enum eValues {
  FullTime = "fulltime",
  PartTime = "parttime",
  Contract = "contract",
  Remote = "remote",
  Hybrid = "hybrid",
  OnSite = "onsite",
  NotFound = "not found",
  EmptyZip = "00000",
  OffSite = "offsite",
}

export enum eLabels {
  FullTime = "Full-Time",
  FullTime_Long = "Full-Time (40 Hours a Week)",
  PartTime = "Part-Time",
  PartTime_Long = "Part-Time (10-30 Hours a Week)",
  Contract = "Contract",
  Contract_Long = "Contract/Project Based Work",
  OnSite = "On-Site",
  Hybrid = "Hybrid",
  Remote = "Remote",
  //job status categories
  Active = "Active",
  Shortlisted = "Shortlisted",
  Interviewing = "Interviewing",
  Hired = "Hired",
  Rejected = "Rejected",
}

export enum eIcons {
  EmploymentType = "schedule",
  JobSave = "favorite",
  JobSave_Add = "heart_plus",
  JobSaved = "heart_check",
  JobSaved_Remove = "heart_minus",
  Location = "location_on",
  Remote = "home",
  Hybrid = "work",
  OnSite = "location_city",
  Compensation = "attach_money",
  Education = "school",
  YearsExperience = "explore",
  Certifications = "verified_user", // "priority_high",
  Transportation = "emoji_transportation",
  Required = "report",
  Advanced = "stat_3",
  Intermediate = "stat_2",
  Beginner = "stat_1",
}
